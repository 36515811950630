import { DateTime } from "luxon";
import { InformedByDto } from "../http";
import { IInformedBy } from "../models";

function fromDto(dto: InformedByDto): IInformedBy {
  return {
    ...dto,
    updatedVersion: {
      ...dto.updatedVersion,
      lastModifiedUtc: DateTime.fromISO(dto.updatedVersion?.lastModifiedUtc, {zone: "utc"}),
    }
  };
}

const InformedByMapper = {
  fromDto,
};

export { InformedByMapper };
