import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { Box, Link, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useRef, useState } from "react";
import { Autocomplete, PlanContext, useDrawer } from "..";
import { Links } from "../../constants";
import { useSession } from "../../hooks";
import { GetActionItemDto, useDeleteActionItem, useGetUsers } from "../../http";
import { useEditActionItemOwners } from "../../http/hooks/plans/useEditActionItemOwners";
import { IUser } from "../../models";
import { UserPageLayoutContext } from "../../pages";
import { ItemProperty } from "../ItemProperty";

interface IProps {
  actionItem: GetActionItemDto;
}

const ActionItemOwnershipProperty = (props: IProps) => {
  const planPageContext = useContext(PlanContext);
  const userPageContext = useContext(UserPageLayoutContext);
  const [isEditing, setIsEditing] = useState(false);
  const { userId, planGuid: userPlanGuid } = useSession();
  const { isFetching: isLoadingUsers, data: users } = useGetUsers(isEditing);
  const { mutate: editOwners } = useEditActionItemOwners();
  const { mutate: deleteActionItem } = useDeleteActionItem();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const { closeDrawer } = useDrawer();
  const pickerRef = useRef<any>();

  async function handleOwnersUpdated(owners: IUser[]) {
    if (props.actionItem === undefined) {
      throw new Error("Action item is undefined");
    }

    let removedUsers = props.actionItem.owners.filter((o1) => owners.findIndex((o2) => o2.userId === o1.userId) < 0);
    let isLoggedInUserRemoved = removedUsers.some((x) => x.userId === userId);
    let isUserFromUserPageContextRemoved =
      userPageContext && userPageContext.user
        ? removedUsers.some((x) => x.userId === userPageContext.user!.userId)
        : false;

    if (owners.length <= 0 && props.actionItem.teamPlanSection == null && props.actionItem.team == null) {
      try {
        await confirm({
          dialogActionsProps: {
            sx: {
              justifyContent: "space-between",
            },
          },
          title: "Remove Ownership",
          content: (
            <Typography>
              Removing the last owner from a personal card will delete the card. Are you sure you want to delete this
              card?
            </Typography>
          ),
          confirmationText: "Delete Card",
          confirmationButtonProps: {
            color: "error",
          },
          cancellationText: "Cancel",
        });
      } catch {
        return;
      }

      if (planPageContext) {
        planPageContext.setSelectedActionItemGuid(undefined);
      }

      deleteActionItem({
        planGuid: userPlanGuid,
        guid: props.actionItem.guid,
        modifiedByUserId: userId,
      });

      closeDrawer();
    } else {
      editOwners({
        guid: props.actionItem.guid,
        modifiedByUserId: userId,
        owners: owners,
        planGuidToClearItemFrom: isUserFromUserPageContextRemoved ? planPageContext?.planGuid : undefined,
        onSuccess: () => {
          if (userPlanGuid === planPageContext?.planGuid && isLoggedInUserRemoved) {
            enqueueSnackbar(
              `${
                props.actionItem.teamPlanSection?.teamName
                  ? props.actionItem.teamPlanSection?.teamName + " item"
                  : "Card"
              } has been removed from your personal plan.`,
              { variant: "success" }
            );
            planPageContext.setSelectedActionItemGuid(undefined);
          }
        },
      });
    }
  }

  useEffect(() => {
    if (isEditing) {
      pickerRef.current?.focus();
    }
  }, [isEditing]);

  return (
    <ItemProperty
      labelIcon={<GroupOutlinedIcon />}
      label="Ownership"
      canEdit={props.actionItem.userCanEdit}
      isEditing={isEditing}
      onEditClicked={() => setIsEditing(true)}
    >
      <>
        {isEditing ? (
          <Autocomplete
            isLoading={isLoadingUsers}
            disabled={isLoadingUsers || !users}
            options={users ? users : []}
            getOptionLabel={(option) => option.displayName}
            onBlur={() => setIsEditing(false)}
            onChange={(_, value) => handleOwnersUpdated(value)}
            renderOption={(props, option) => (
              <Box sx={{ pl: "10px !important" }} component="li" {...props} key={option.userId}>
                <Stack>
                  <Typography>{option.displayName}</Typography>
                  <Typography variant="caption">{option.email}</Typography>
                </Stack>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={pickerRef}
                placeholder="Select Ownership"
                inputProps={{ ...params.inputProps }}
              />
            )}
            value={props.actionItem.owners
              .sort((a, b) => a.displayName.localeCompare(b.displayName))
              .map((u) => ({
                displayName: u.displayName,
                profilePhoto: u.profilePhoto,
                email: u.email,
                userId: u.userId,
                enabled: u.enabled,
              }))}
            blurOnSelect={false}
            isOptionEqualToValue={(option, value) => option.userId === value?.userId}
            multiple
          />
        ) : props.actionItem.owners.length > 0 ? (
          <Typography variant={"body1"}>
            {props.actionItem.owners
              .sort((a, b) => a.displayName.localeCompare(b.displayName))
              .map((owner, index) => (
                <Box key={index} component="span">
                  {index > 0 ? ", " : ""}
                  <Link onClick={(e) => e.stopPropagation()} href={Links.UserMap(owner.userId)}>
                    {owner.displayName}
                    {owner.enabled ? "" : " (deactivated)"}
                  </Link>
                </Box>
              ))}
          </Typography>
        ) : (
          <Typography variant="placeholder">
            {props.actionItem.userCanEdit ? "Assign Ownership" : "No owner"}
          </Typography>
        )}
      </>
    </ItemProperty>
  );
};

export { ActionItemOwnershipProperty };
