import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IPlanV2 } from "../../../models";
import { DeleteBlockDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  planGuid: string;
  guid: string;
  modifiedByUserId: string;
};

const useDeleteBlockRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteBlockRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: DeleteBlockDto = {
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/blocks/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteBlockRequest };
};

const useDeleteBlock = () => {
  const { deleteBlockRequest } = useDeleteBlockRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteBlockRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.plans.getPlan(variables.planGuid));

      // Snapshot the current cache value
      const cachedData = queryClient.getQueryData<IPlanV2>(queryKeys.plans.getPlan(variables.planGuid));

      if (cachedData) {
        // Optimistically update the cache
        let optimisticallyUpdatedData: IPlanV2 = {
          ...cachedData,
          blocks: cachedData.blocks.filter((value) => value.guid !== variables.guid),
        };

        queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), optimisticallyUpdatedData);
      }
      return { cachedData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), context?.cachedData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.plans.getPlan(variables.planGuid));
    },
  });
};

export { useDeleteBlock };
