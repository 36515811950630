import { Card, CardHeader, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTeamPageLayoutContext } from "..";
import { TeamActionItemsList, TeamIssuesList } from "./components";

const TeamIssuesPage = () => {
  const { team } = useTeamPageLayoutContext();

  return (
    <Grid container sx={{ justifyContent: "center" }}>
      <Grid item xs={12} md={8}>
        <Card sx={{ width: "100%" }}>
          <CardHeader title={<Typography variant="h6">Processing</Typography>} />
          <Divider />
          <Stack spacing={{ xs: 0.5, sm: 1 }} sx={{ p: { xs: 0.5, sm: 1 } }}>
            <TeamActionItemsList teamSlug={team?.slug} />
            <TeamIssuesList teamSlug={team?.slug} teamName={team?.name} />
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};

export { TeamIssuesPage };
