import { Button, Card, CardContent, Stack } from "@mui/material";
import { useContext } from "react";
import { SettingsContext, Typography } from "../../../../components";

interface IProps {
  title: string;
  children?: React.ReactNode;
}

const EditHintCard = (props: IProps) => {
  const { editOpspSettings, updateEditOpspSettings } = useContext(SettingsContext);

  function handleHideClicked() {
    updateEditOpspSettings({
      showHints: false,
    });
  }

  if (!editOpspSettings.showHints) {
    return null;
  }

  return (
    <Card elevation={0} sx={{ overflow: "visible" }}>
      <CardContent>
        <Stack direction="column" spacing={0.5}>
          <Stack
            direction="row"
            sx={{ alignItems: "center", justifyContent: "space-between" }}
            flexWrap="wrap"
            gap={0.5}
          >
            <Typography variant="h6" sx={{ textTransform: "uppercase" }}>
              {props.title}
            </Typography>
            <Button variant="tertiary" onClick={handleHideClicked}>
              Hide
            </Button>
          </Stack>
          {props.children}
        </Stack>
      </CardContent>
    </Card>
  );
};

export { EditHintCard };
