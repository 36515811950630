import { Divider, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useGetPlanHistory } from "../../http";
import { BlockLink } from "../BlockLink";

interface IProps {
  planGuid: string;
  planLastModified: string | undefined;
}

const PlanVersionsDrawerContent = (props: IProps) => {
  const { data, isLoading } = useGetPlanHistory(props.planGuid, new Date().getTimezoneOffset());
  const [queryParams] = useSearchParams();
  const [dateQueryParam, setDateQueryParam] = useState<string | null>(null);
  const theme = useTheme();
  const location = useLocation();
  const localTimezoneOffset = new Date().getTimezoneOffset();

  useEffect(() => {
    const date = queryParams.get("date");
    setDateQueryParam(date);
  }, [queryParams]);

  return (
    <Stack sx={{ overflow: "auto" }} divider={<Divider variant="dashed" sx={{ mx: 1 }} />}>
      {isLoading && <Skeleton />}
      {!isLoading &&
        props.planLastModified &&
        data !== undefined &&
        data.versions.map((version, index) => {
          const formattedDate = DateTime.fromISO(version.date).toFormat("yyyy-MM-dd");

          return (
            <BlockLink
              key={index}
              href={
                index === 0
                  ? `${location.pathname}`
                  : `${location.pathname}?date=${formattedDate}&offset=${localTimezoneOffset}`
              }
              backgroundColorOnHover={theme.palette.primary.transparent5}
              sx={{ px: 1 }}
            >
              <Stack direction="row" sx={{ py: 0.5, alignItems: "center", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    fontWeight:
                      formattedDate === dateQueryParam || (dateQueryParam == null && index === 0) ? "bold" : undefined,
                  }}
                >
                  {DateTime.fromISO(version.date).toLocaleString(DateTime.DATE_FULL)}
                </Typography>
              </Stack>
            </BlockLink>
          );
        })}
    </Stack>
  );
};

export { PlanVersionsDrawerContent };
