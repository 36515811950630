import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetSurveyInstanceResponseDto } from "../../types";
import { CreateSurveyInstanceQuestionAnswerDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  answerGuid: string;
  answerValue: string;
  responseGuid: string;
  questionGuid: string;
  instanceGuid: string;
};

const useCreateSurveyInstanceQuestionAnswerRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createSurveyInstanceQuestionAnswerRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();

    const body: CreateSurveyInstanceQuestionAnswerDto = {
      answerGuid: variables.answerGuid,
      answerValue: variables.answerValue,
      questionGuid: variables.questionGuid,
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-response/${variables.responseGuid}/answer`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { createSurveyInstanceQuestionAnswerRequest };
};

const useCreateSurveyInstanceQuestionAnswer = () => {
  const { createSurveyInstanceQuestionAnswerRequest } = useCreateSurveyInstanceQuestionAnswerRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSurveyInstanceQuestionAnswerRequest,
    onMutate: async (variables) => {
      // Optimistically update cache
      const cachedData = await optimisticallyUpdateResponseAnswers(queryClient, variables);

      return { cachedData };
    },
    onError: (_error, variables, context) => {
      // On error, rollback
      queryClient.setQueryData(
        queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid),
        context?.cachedData
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid));
    },
  });
};

async function optimisticallyUpdateResponseAnswers(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid));
  const cachedData = queryClient.getQueryData<GetSurveyInstanceResponseDto>(
    queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid)
  );

  if (cachedData) {
    const optimisticallyUpdatedData: GetSurveyInstanceResponseDto = {
      ...cachedData,
      questions: cachedData.questions.map((question) => {
        if (question.questionGuid === variables.questionGuid) {
          return {
            ...question,
            answer: { answerGuid: variables.answerGuid, value: variables.answerValue },
          };
        }
        return question;
      }),
    };

    queryClient.setQueryData(
      queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid),
      optimisticallyUpdatedData
    );

    return cachedData;
  }
}

export { useCreateSurveyInstanceQuestionAnswer };
