import { Box, Chip, Divider, Stack, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";

interface IProps {
  question: { name: string; tags: string[] };
  teamName: string;
  dateTimeUtc: string;
  response: string;
}

const TextResponse = (props: IProps) => {
  const theme = useTheme();

  return (
    <Stack spacing={0.5}>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.25}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ color: theme.palette.grey[300], height: "15px", alignSelf: "center" }}
            />
          }
        >
          <Typography variant="caption">{props.teamName}</Typography>
          <Typography variant="caption">
            {DateTime.fromISO(props.dateTimeUtc, { zone: "utc" }).toLocal().toLocaleString(DateTime.DATE_MED)}
          </Typography>
        </Stack>
        <Typography variant="body2" sx={{ color: theme.palette.common.black }}>
          {props.question.name}
        </Typography>
      </Box>
      {props.question.tags && props.question.tags.length > 0 && (
        <Stack direction="row" spacing={0.5}>
          {props.question.tags.map((questionTag, index) => (
            <Chip key={index} label={questionTag} sx={{ color: theme.palette.grey[300] }} />
          ))}
        </Stack>
      )}
      <Typography variant="body1" sx={{ color: theme.palette.common.black }}>
        {props.response}
      </Typography>
    </Stack>
  );
};

export { TextResponse };
