import { Link, SxProps, Tooltip } from "@mui/material";
import { Typography } from "./Typography";
import { Links } from "../constants";
import { IUser } from "../models";

interface IProps {
  owners: IUser[];
  faded?: boolean;
  sx?: SxProps;
}

const KeyInitiativeOwnership = (props: IProps) => {
  if (props.owners.length === 0) {
    return null;
  }

  const firstOwner = props.owners[0];
  const additionalOwners = props.owners.slice(1);

  return (
    <Typography variant="caption" sx={{ fontWeight: 700, ...props.sx }} faded={props.faded}>
      {props.faded ? (
        <>
          <Typography variant="caption">{`${firstOwner.displayName}${
            firstOwner.enabled ? "" : " (deactivated)"
          }`}</Typography>
          <AdditionalOwners additionalOwners={additionalOwners} faded={props.faded} />
        </>
      ) : (
        <>
          <Link onClick={(e) => e.stopPropagation()} href={Links.User(firstOwner.userId)}>
            {`${firstOwner.displayName}${firstOwner.enabled ? "" : " (deactivated)"}`}
          </Link>
          <AdditionalOwners additionalOwners={additionalOwners} faded={props.faded} />
        </>
      )}
    </Typography>
  );
};

interface IAdditionalOwnersProps {
  additionalOwners: IUser[];
  faded?: boolean;
}

const AdditionalOwners = (props: IAdditionalOwnersProps) => {
  if (props.additionalOwners.length === 0) {
    return null;
  }

  return (
    <>
      <Tooltip
        title={props.additionalOwners.map((u, i) => (
          <span key={i}>
            {props.faded ? (
              <Typography sx={{ fontSize: 13 }}>
                {u.displayName}
                {u.enabled ? "" : " (deactivated)"}
              </Typography>
            ) : (
              <Link href={Links.User(u.userId)}>
                <Typography sx={{ fontSize: 13 }}>
                  {u.displayName}
                  {u.enabled ? "" : " (deactivated)"}
                </Typography>
              </Link>
            )}
          </span>
        ))}
      >
        <span>
          <Typography variant="caption">&nbsp;+{props.additionalOwners.length}</Typography>
        </span>
      </Tooltip>
    </>
  );
};

export { KeyInitiativeOwnership };
