import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError } from "../../types";
import { EditSurveyDescriptionDto } from "../../types/surveys";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  description: string;
  modifiedByUserId: string;
};

const useEditSurveyDescriptionRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editSurveyDescriptionRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();

    const body: EditSurveyDescriptionDto = {
      description: variables.description,
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/surveys/${variables.guid}/description`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editSurveyDescriptionRequest };
};

const useEditSurveyDescription = () => {
  const { editSurveyDescriptionRequest } = useEditSurveyDescriptionRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editSurveyDescriptionRequest,
    onSuccess: async (_data, variables) => {
      await queryClient.refetchQueries(queryKeys.surveys.getSurvey(variables.guid));
    },
  });
};

export { useEditSurveyDescription };
