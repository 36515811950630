import { AddRounded } from "@mui/icons-material";
import { Button, Divider, IconButton, Skeleton, Stack, SxProps, Typography } from "@mui/material";
import React, { useState } from "react";
import { Accordion } from "./Accordion";
import { ViewList } from "./ViewList";

interface IProps<T> {
  title: string;
  icon?: React.ReactNode;
  dataSource?: T[];
  isLoading: boolean;
  isExpanded: boolean;
  backgroundColorOnHover?: string;
  divider?: React.ReactNode;
  renderItem: (item: T, index: number) => React.ReactNode;
  sortFunction: (a: T, b: T) => number;
  closedItemFilter: (item: T) => boolean;
  onAddClicked: (e: React.MouseEvent) => void;
  onExpandClicked: () => void;
  sx?: SxProps;
}

const AccordionViewList = <T extends unknown>(props: IProps<T>) => {
  const [showClosed, setShowClosed] = useState(false);

  const activeItemCount = props.dataSource ? props.dataSource.filter(props.closedItemFilter).length : 0;
  const closedItemCount = props.dataSource
    ? props.dataSource.filter((item) => !props.closedItemFilter(item)).length
    : 0;

  return (
    <Accordion
      title={
        <Stack direction="row" spacing={0.25} flexGrow={1} alignItems="center">
          {props.icon}
          <Typography variant="caption" sx={{ color: "grey.400" }}>
            {props.title}
            <span style={{ textTransform: "none" }}>
              {!props.isExpanded ? ` (${activeItemCount} Open, ${closedItemCount} Closed)` : ""}
            </span>
          </Typography>
        </Stack>
      }
      hoverActions={
        <IconButton size="small" onClick={props.onAddClicked}>
          <AddRounded />
        </IconButton>
      }
      expanded={props.isExpanded}
      hideTitleUnderline={!props.isExpanded}
      onExpandClicked={() => {
        if (showClosed && props.isExpanded) {
          setShowClosed(false);
        }

        props.onExpandClicked();
      }}
      backgroundColorOnHover={props.backgroundColorOnHover}
    >
      <ViewList
        dataSource={props.dataSource}
        isLoading={props.isLoading}
        divider={<Divider variant="dashed" sx={{ mx: { xs: 0.5, sm: 1 } }} />}
        sortFunction={props.sortFunction}
        closedItemListProps={{
          showClosed: showClosed,
          closedItemFilter: props.closedItemFilter,
          renderShowClosedButton: (params) => {
            return (
              <Button
                variant="tertiary"
                size="small"
                onClick={() => {
                  params.onClick();
                  setShowClosed(!showClosed);
                }}
                sx={{ alignSelf: "flex-start", m: 0.5, ...props.sx }}
              >
                {!params.closedItemsVisible ? `Show Closed (${params.closedItemCount})` : `Hide Closed`}
              </Button>
            );
          },
        }}
        renderSkeleton={(index) => <Skeleton variant="rectangular" key={index} height={24} sx={{ mx: 1, my: 0.5 }} />}
        renderItem={props.renderItem}
      />
    </Accordion>
  );
};

export { AccordionViewList };
