import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditSurveyQuestionTagsDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  surveyGuid: string;
  tags: string[]
};

const useEditSurveyQuestionTagsRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editSurveyQuestionTagsRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditSurveyQuestionTagsDto = {
      tags: variables.tags
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-questions/${variables.guid}/tags`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response;
  }

  return { editSurveyQuestionTagsRequest };
};

const useEditSurveyQuestionTags = () => {
  const { editSurveyQuestionTagsRequest } = useEditSurveyQuestionTagsRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editSurveyQuestionTagsRequest,
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurveyQuestion(variables.guid) });
    },
  });
}

export { useEditSurveyQuestionTags };
