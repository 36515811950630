import { Button, Divider, Link, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { IssueActions } from ".";
import { ContextProperty, IssueFeed, ItemDescriptionField, SnackbarContent, useDrawer } from "..";
import { Links, SupportingItemType } from "../../constants";
import { useSession } from "../../hooks";
import { useEditIssue, useEditIssueStatus, useGetIssueDetail, useRemoveIssueUpvote, useUpvoteIssue } from "../../http";
import { ITeamSummary } from "../../models";
import { useDialog } from "../DialogProvider";
import { IssueClosedDialogContent } from "./IssueClosedDialogContent";
import { IssueProperties } from "./IssueProperties";

interface IProps {
  issueGuid: string;
}

const IssueDrawerContent = (props: IProps) => {
  const user = useSession();
  const { isLoading, data: issue } = useGetIssueDetail(props.issueGuid);
  const { mutate: editIssue } = useEditIssue();
  const { mutate: editIssueStatus } = useEditIssueStatus();
  const { mutate: upvoteIssue } = useUpvoteIssue();
  const { mutate: removeIssueUpvote } = useRemoveIssueUpvote();
  const { showDialog, closeDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { closeDrawer } = useDrawer();

  const isCreatedByUser = issue ? issue.createdByUser.userId === user.userId : false;

  async function handleDescriptionChanged(value: string) {
    if (issue === undefined) {
      return;
    }

    editIssue({
      guid: issue.guid,
      oldTeamSlug: issue.assignedTeam.slug,
      newTeamSlug: issue.assignedTeam.slug,
      newTeamName: issue.assignedTeam.name,
      description: value,
      createdByUserId: issue.createdByUser.userId,
      modifiedByUserId: user.userId,
    });
  }

  async function handleCloseClicked() {
    if (issue === undefined) {
      return;
    }

    if (issue.userCanEdit) {
      if (issue.closedOnDateTimeUtc === null) {
        showDialog({
          content: <IssueClosedDialogContent issueGuid={issue.guid} onDone={closeDialog} onCancel={closeDialog} />,
        });
      }

      editIssueStatus({
        guid: issue.guid,
        teamSlug: issue.assignedTeam.slug,
        closed: issue.closedOnDateTimeUtc === null,
        createdByUserId: issue.createdByUser.userId,
        modifiedByUserId: user.userId,
      });
    }
  }

  async function handleUpvoteClicked() {
    if (issue === undefined) {
      return;
    }

    if (issue.upvotes.findIndex((x) => x.userId === user.userId) > -1) {
      removeIssueUpvote({
        guid: issue.guid,
        teamSlug: issue.assignedTeam.slug,
        createdByUserId: issue.createdByUser.userId,
        userId: user.userId,
      });
    } else {
      upvoteIssue({
        guid: issue.guid,
        teamSlug: issue.assignedTeam.slug,
        createdByUserId: issue.createdByUser.userId,
        user: { userId: user.userId, displayName: user.displayName, enabled: true, profilePhoto: "" },
      });
    }
  }

  function handleTeamChanged(team: ITeamSummary) {
    if (issue === undefined) {
      return;
    }

    enqueueSnackbar(
      <SnackbarContent
        title={
          <>
            Issue moved to{" "}
            <Link onClick={(e) => e.stopPropagation()} href={Links.TeamIssues(team.slug)}>
              {team.name}
            </Link>
            .
          </>
        }
      />,
      {
        action: <Button href={Links.IssueDetail(issue.guid)}>Open Issue</Button>,
      }
    );

    closeDrawer();
  }

  return (
    <>
      <Stack spacing={0.5} divider={<Divider variant="dashed" />} sx={{ px: { xs: 0.5, sm: 1 }, py: 0.5 }}>
        <Stack>
          {!isLoading && issue && (
            <ContextProperty
              itemGuid={issue.guid}
              itemType={SupportingItemType.Issue}
              context={issue.context}
              isEditable={issue.userCanEdit}
            />
          )}
          <ItemDescriptionField
            isLoading={isLoading}
            isEditable={isCreatedByUser}
            value={issue?.description}
            onChange={handleDescriptionChanged}
            variant="h5"
          />
        </Stack>

        <IssueActions
          isLoading={isLoading}
          issue={issue}
          onToggleClosed={handleCloseClicked}
          onUpvoteClicked={handleUpvoteClicked}
        />
      </Stack>
      <Divider />
      <Stack divider={<Divider flexItem />} sx={{ overflow: "auto" }}>
        <IssueProperties issue={issue} isLoading={isLoading} onTeamChanged={handleTeamChanged} />
        <IssueFeed issueGuid={props.issueGuid} />
      </Stack>
    </>
  );
};

export { IssueDrawerContent };
