import { Typography } from "@mui/material";
import { EditHintCard } from "../EditHintCard";

const KeyInitiativeHintCard = () => {
  return (
    <EditHintCard title="Priority">
      <Typography variant="body1">
        Priorities are the deeper specifics of an area of focus and will represent new initiatives or twists on existing
        initiatives to create change that changes results towards the goals. List them in order of Priority.
      </Typography>
      <Typography variant="body1">What is an action that will help us focus?</Typography>
    </EditHintCard>
  );
};

export { KeyInitiativeHintCard };
