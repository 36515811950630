import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useSession, useSlugify } from "../hooks";
import { useCreateTeam, useGetTeams } from "../http";
import { ITeam, ITeamSummary } from "../models";
import { Autocomplete } from "./Autocomplete";
import { SmallInputLabel } from "./SmallInputLabel";
import { useEffect } from "react";

const MenuItem = styled(Box)`
  &:first-child {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }

  &:last-child {
    margin-bottom: 2px;
  }
` as typeof Box;

interface ICreateTeamFormState {
  parentTeam: ITeam | null;
  name: string;
  slug: string;
  description: string;
}

interface Props {
  visible: boolean;
  parentTeam?: ITeamSummary;
  onCreated: (slug: string) => void;
  onCancel: () => void;
}

const CreateTeamModal = (props: Props) => {
  const { userId } = useSession();
  const { mutate, isLoading: isCreatingTeam } = useCreateTeam();
  const { isLoading: isLoadingTeams, data: teams } = useGetTeams(undefined, props.visible);
  const { slugify } = useSlugify();

  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm<ICreateTeamFormState>({
    defaultValues: {
      name: "",
      slug: "",
      parentTeam: props.parentTeam ?? null,
      description: "",
    },
  });

  useEffect(() => {
    reset(
      {
        parentTeam: props.parentTeam ?? null,
      },
      { keepDefaultValues: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentTeam]);

  function handleClose(_: object, reason: string) {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    props.onCancel();
  }

  async function handleCreateClicked() {
    handleSubmit((data) => {
      mutate(
        {
          name: data.name,
          slug: data.slug,
          parentTeamSlug: data.parentTeam?.slug,
          description: data.description,
          createdByUserId: userId!,
        },
        {
          onSuccess: () => {
            reset(undefined, { keepDefaultValues: true });
            props.onCreated(data.slug);
          },
        }
      );
    })();
  }

  function handleCancelClicked() {
    reset(undefined, { keepDefaultValues: true });
    props.onCancel();
  }

  return (
    <Dialog open={props.visible} onClose={handleClose} fullWidth>
      <DialogTitle>Create Team</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Stack spacing={0.25}>
            <SmallInputLabel>Team Name *</SmallInputLabel>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Team Name is required",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(e) => {
                    setValue("name", e.target.value);
                    setValue("slug", slugify(e.target.value));
                  }}
                  error={errors["name"] !== undefined}
                  helperText={errors["name"]?.message}
                  placeholder="Enter the team name..."
                  inputProps={{ maxLength: 64 }}
                />
              )}
            />
          </Stack>
          <Stack spacing={0.25}>
            <SmallInputLabel>URL Slug *</SmallInputLabel>
            <Controller
              name="slug"
              control={control}
              rules={{ required: "URL Slug is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  onBlur={(e) => setValue("slug", slugify(e.target.value))}
                  error={errors["slug"] !== undefined}
                  helperText={errors["slug"]?.message}
                  inputProps={{ maxLength: 64 }}
                />
              )}
            />
          </Stack>
          <Stack spacing={0.5}>
            <SmallInputLabel>Connected To</SmallInputLabel>
            <Controller
              name="parentTeam"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  isLoading={isLoadingTeams}
                  disabled={isLoadingTeams}
                  options={teams ?? []}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <MenuItem key={option.slug} component="li" {...props}>
                      <Typography>{option.name}</Typography>
                    </MenuItem>
                  )}
                  onChange={(_, option) => field.onChange(option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select a team..."
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                  value={field.value}
                  isOptionEqualToValue={(option, value) => option.slug === value.slug}
                />
              )}
            />
          </Stack>
          <Stack spacing={0.25}>
            <SmallInputLabel>Team Purpose *</SmallInputLabel>
            <Controller
              name="description"
              control={control}
              rules={{ required: "Team Purpose is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors["description"] !== undefined}
                  helperText={errors["description"]?.message}
                  placeholder="Describe why this team exists..."
                  inputProps={{ maxLength: 512 }}
                />
              )}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isCreatingTeam} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isCreatingTeam}
          endIcon={isCreatingTeam ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleCreateClicked}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { CreateTeamModal };
