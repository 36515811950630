import { Box, BoxProps } from "@mui/material";

const IssueClosedAnimation = (props: BoxProps) => {
  return (
    <Box component="object" type="image/svg+xml" data="/svg/issue-closed.svg" width={100} {...props}>
      Issue Closed Animation
    </Box>
  );
};

export { IssueClosedAnimation };
