import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { DEFAULT_ACTION_ITEM_STATUS } from "../../../constants";
import { IActionItem, IPlanV2, IUser } from "../../../models";
import { CreatePlanActionItemDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  planGuid: string;
  guid: string;
  sectionGuid: string;
  owners: IUser[];
  sortOrder: string;
  createdByUserId: string;
};

const useCreatePlanActionItemRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createPlanActionItemRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: CreatePlanActionItemDto = {
      guid: variables.guid,
      sectionGuid: variables.sectionGuid,
      ownerUserIds: variables.owners?.map((o) => o.userId),
      description: "",
      sortOrder: variables.sortOrder,
      createdByUserId: variables.createdByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/action-items`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { createPlanActionItemRequest };
};

const useCreatePlanActionItem = () => {
  const { createPlanActionItemRequest } = useCreatePlanActionItemRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPlanActionItemRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.plans.getPlan(variables.planGuid));

      let newActionItem: IActionItem = {
        guid: variables.guid,
        teamPlanSection: undefined,
        description: "",
        owners: variables.owners,
        currentStatus: DEFAULT_ACTION_ITEM_STATUS,
        statusLastModifiedDateTimeUtc: DateTime.now().toUTC().toString(),
        note: "",
        propertiesSummary: { commentCount: 0, supportingItemsCount: 0 },
        sortOrder: variables.sortOrder,
        userCanEdit: true,
      };

      // Snapshot the current cache value
      const cachedData = queryClient.getQueryData<IPlanV2>(queryKeys.plans.getPlan(variables.planGuid));

      if (cachedData) {
        const optimisticallyUpdatedData: IPlanV2 = {
          ...cachedData,
          blocks: cachedData.blocks.map((block) => ({
            ...block,
            sections: block.sections.map((section) => {
              if (section.guid === variables.sectionGuid) {
                return {
                  ...section,
                  actionItems: [...section.actionItems, newActionItem],
                };
              }

              return section;
            }),
          })),
        };

        queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), optimisticallyUpdatedData);
        queryClient.setQueryData(queryKeys.actionItems.getActionItemSummary(variables.guid), newActionItem);
      }

      return { cachedData };
    },
    onError: (_error, variables, context) => {
      // On error, rollback
      queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), context?.cachedData);
      queryClient.removeQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.plans.getPlan(variables.planGuid));
    },
  });
};

export { useCreatePlanActionItem };
