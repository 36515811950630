import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSession } from "../../hooks";
import { useCreateRole } from "../../http";
import { IRoleAccountability, ITeam, IUser } from "../../models";
import { CreateEditRoleForm, ICreateEditRoleFormState } from "./CreateEditRoleForm";

interface IProps {
  team: ITeam;
  visible: boolean;
  onAdded: (guid: string) => void;
  onCancelled: () => void;
}

const CreateRoleModal = (props: IProps) => {
  const { userId } = useSession();
  const { mutate, isLoading: isCreatingRole } = useCreateRole();

  const { formState, control, handleSubmit, reset } = useForm<ICreateEditRoleFormState>({
    defaultValues: {
      team: props.team,
      name: "",
      description: "",
      isOpen: false,
      accountabilities: [] as IRoleAccountability[],
      tags: [],
      members: [] as IUser[],
    },
  });

  async function handleAddClicked() {
    handleSubmit((data) => {
      mutate(
        {
          teamSlug: data.team!.slug,
          name: data.name,
          description: data.description,
          isOpen: data.isOpen,
          accountabilities: data.accountabilities,
          tags: data.tags,
          members: data.members.map((x) => x.userId),
          createdByUserId: userId!,
        },
        {
          onSuccess: (data) => {
            reset(undefined, { keepDefaultValues: true });
            props.onAdded(data.guid);
          },
        }
      );
    })();
  }

  function handleClose(_: object, reason: string) {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    handleCancelClicked();
  }

  function handleCancelClicked() {
    reset(undefined, { keepDefaultValues: true });
    props.onCancelled();
  }

  return (
    <Dialog open={props.visible} onClose={handleClose}>
      <DialogTitle>Create Role</DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <CreateEditRoleForm formState={formState} control={control} loadingEnabled={props.visible} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isCreatingRole} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isCreatingRole}
          endIcon={isCreatingRole ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleAddClicked}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { CreateRoleModal };
