import { Skeleton, Stack, styled } from "@mui/material";
import React from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { DraggableListItem } from "./DraggableListItem";

const List = styled("ul")`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

interface IUniquelyIdentifiable {
  guid: string;
}

interface IProps<T extends IUniquelyIdentifiable> {
  id: string;
  dataSource: T[];
  isLoading?: boolean;
  showDeleteOnHover?: boolean;
  confirmDeletion?: boolean;
  renderItem: (item: T, index: number) => React.ReactNode;
  renderEmptyPlaceholder?: () => React.ReactNode;
  onReorder: (sourceIndex: number, destinationIndex: number) => void;
  onDelete?: (index: number) => void;
}

const DraggableList = <T extends IUniquelyIdentifiable>(props: IProps<T>) => {
  function handleDragEnd(result: DropResult) {
    const { destination, source } = result;

    // User dragged the item outside of the container
    if (destination === undefined || destination === null) {
      return;
    }

    // User dragged the item back to where it was
    if (destination?.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    props.onReorder(source.index, destination.index);
  }

  return (
    <>
      {props.isLoading ? (
        <Stack spacing={0.75} sx={{ pr: 1, pl: 0.75, py: 1 }}>
          {[...Array(3)].map((_, i) => (
            <Skeleton variant="rectangular" key={i} />
          ))}
        </Stack>
      ) : props.dataSource.length === 0 ? (
        <>{props.renderEmptyPlaceholder !== undefined && props.renderEmptyPlaceholder()}</>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={props.id}>
            {(provided) => (
              <List ref={provided.innerRef} {...provided.droppableProps}>
                {props.dataSource.map((item: T, index: number) => (
                  <Draggable key={item.guid} draggableId={item.guid} index={index}>
                    {(provided, snapshot) => (
                      <DraggableListItem
                        ref={provided.innerRef}
                        draggableProps={provided.draggableProps}
                        dragHandleProps={provided.dragHandleProps}
                        index={index}
                        isDragging={snapshot.isDragging}
                        showDeleteOnHover={props.showDeleteOnHover}
                        confirmDeletion={props.confirmDeletion}
                        onDelete={props.onDelete}
                      >
                        {props.renderItem(item, index)}
                      </DraggableListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export { DraggableList };
