import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditResourceDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  teamSlug: string;
  title: string;
  url: string;
  isEmbedded: boolean;
  sortOrder: string;
  modifiedByUserId: string;
};

const useEditResourceRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editResourceRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditResourceDto = {
      title: variables.title,
      url: variables.url,
      isEmbedded: variables.isEmbedded,
      sortOrder: variables.sortOrder,
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/resources/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editResourceRequest };
};

const useEditResource = () => {
  const { editResourceRequest } = useEditResourceRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editResourceRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.resources.getResourcesForTeam(variables.teamSlug) });
    },
  });
};

export { useEditResource };
