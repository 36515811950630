import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../../use-access-token";
import { configuration } from "../../../configuration";
import { FetchError } from "../../types";
import { queryKeys } from "../queryKeys";
import { CommentSubject } from "../../../constants";

type Variables = {
  subjectType: CommentSubject;
  subjectGuid: string;
  commentGuid: string;
};

const useDeleteCommentRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteCommentRequest(variables: Variables) {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/comments/${variables.commentGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "DELETE",
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteCommentRequest };
};

const useDeleteComment = () => {
  const { deleteCommentRequest } = useDeleteCommentRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCommentRequest,
    onSuccess: (_, variables) => {
      switch (variables.subjectType) {
        case CommentSubject.ActionItem:
          queryClient.refetchQueries(queryKeys.actionItems.getActionItemFeed(variables.subjectGuid));
          queryClient.refetchQueries(queryKeys.actionItems.getActionItemSummary(variables.subjectGuid));
          break;
        case CommentSubject.Issue:
          queryClient.refetchQueries(queryKeys.issues.getIssueFeed(variables.subjectGuid));
          break;
      }
    },
  });
};

export { useDeleteComment };
