import { DateTime } from "luxon";
import { ACTION_ITEM_STATUSES, Links } from "../constants";
import { useGetPlan } from "../http";
import { configuration } from "../configuration";

function usePlanToCsv(planGuid: string) {
  const { data, isFetching, refetch } = useGetPlan(planGuid);

  async function convertAndDownloadPlanAsCsv(filename: string) {
    await refetch();

    if (isFetching) {
      // If refetch is in progress, wait for it to complete before exporting
      await new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (!isFetching) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    }

    if (!data) {
      return;
    }

    const headers = [
      "Block Name",
      "Section Name",
      "Card URL",
      "Card Description",
      "Card Status",
      "Status Last Modified Date",
      "Status Last Modified By",
      "Owners",
    ];

    const rows = data.blocks.flatMap((block) =>
      block.sections.flatMap((section) =>
        section.actionItems.map((item) => {
          const status = ACTION_ITEM_STATUSES.find((x) => x.id === item.currentStatus);
          return [
            block.name,
            section.name,
            configuration.webRootUrl + Links.ActionItemDetail(item.guid),
            item.description,
            status !== undefined ? status.name : ACTION_ITEM_STATUSES[0].name,
            item.statusLastModifiedDateTimeUtc
              ? DateTime.fromISO(item.statusLastModifiedDateTimeUtc, { zone: "utc" })
                  .toLocal()
                  .toLocaleString(DateTime.DATETIME_FULL)
              : "",
            item.statusLastModifiedByDisplayName ? item.statusLastModifiedByDisplayName : "",
            item.owners.map((owner) => owner.displayName).join(", "),
          ];
        })
      )
    );

    const output = [headers, ...rows].map((row) => row.map((cell) => `"${cell}"`).join(",")).join("\n");

    downloadCsv(output, filename);
  }

  function downloadCsv(data: string, filename: string) {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const anchorElement = document.createElement("a");

    anchorElement.setAttribute("href", url);
    anchorElement.setAttribute("download", `${filename}.csv`);

    anchorElement.click();

    window.URL.revokeObjectURL(url);
    anchorElement.remove();
  }

  return {
    convertAndDownloadPlanAsCsv,
  };
}

export { usePlanToCsv };
