import { Grid } from "@mui/material";
import { TeamAbout, TeamFeed, TeamNetwork } from "./components";
import { useBreakpoints } from "../../../hooks";

const TeamOverviewPage = () => {
  const { isMdUp } = useBreakpoints();

  return (
    <Grid container spacing={{ xs: 0.75, md: 1 }} sx={{ justifyContent: "center" }}>
      <Grid item xs={12} md={4}>
        {isMdUp ? <TeamFeed /> : <TeamAbout />}
      </Grid>
      <Grid item xs={12} md={4}>
        {isMdUp ? <TeamAbout /> : <TeamFeed />}
      </Grid>
      <Grid item xs={12} md={4}>
        <TeamNetwork />
      </Grid>
    </Grid>
  );
};

export { TeamOverviewPage };
