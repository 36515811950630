import { QueryFunctionContext, useQuery, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetIssuesDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetIssuesRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getIssuesRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.issues.getIssues>>): Promise<GetIssuesDto> {
    const accessToken = await getAccessToken();
    const [{ teamSlug, userId }] = queryKey;

    const url = new URL(`${configuration.apiRootUrl}/issues`);

    if (teamSlug) {
      url.searchParams.append("teamSlug", teamSlug);
    }

    if (userId) {
      url.searchParams.append("createdByUserId", userId);
    }

    const response = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getIssuesRequest };
};

const useGetIssues = (teamSlug: string | undefined, userId: string | undefined) => {
  const { getIssuesRequest } = useGetIssuesRequest();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: queryKeys.issues.getIssues(teamSlug, userId),
    queryFn: getIssuesRequest,
    onSuccess: (data) => {
      data.issues.forEach((issue) => {
        queryClient.setQueryData(queryKeys.issues.getIssueDetail(issue.guid), issue);
      });
    },
    enabled: teamSlug !== undefined || userId !== undefined,
  });
};

export { useGetIssues };
