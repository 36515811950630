import { Link, Typography } from "@mui/material";
import {
  DefaultHistoryLedgerMappingStrategy,
  HistoryLedgerItemValue,
  IHistoryLedgerMappingStrategy,
} from "../../../components";
import { Links } from "../../../constants";
import {
  IHistoryLedgerItem,
  IHistoryLedgerItemChange,
  IHistoryLedgerItemTeamValue,
  IHistoryLedgerItemUserValue,
} from "../../../models";

class RoleHistoryLedgerMappingStrategy implements IHistoryLedgerMappingStrategy {
  defaultStrategy: IHistoryLedgerMappingStrategy;

  constructor() {
    this.defaultStrategy = new DefaultHistoryLedgerMappingStrategy("Role");
  }

  getCreatedText(historyLedgerItem: IHistoryLedgerItem) {
    const roleNames = historyLedgerItem.changes.filter((x) => x.propertyName === "role name");

    if (roleNames.length > 0) {
      return (
        <Typography>
          {`Added role `}
          <HistoryLedgerItemValue value={roleNames[0].newValue} />
        </Typography>
      );
    }
  }

  getDeletedText(historyLedgerItem: IHistoryLedgerItem) {
    const roleNames = historyLedgerItem.changes.filter((x) => x.propertyName === "role name");

    if (roleNames.length > 0) {
      return (
        <Typography>
          {`Deleted role `}
          <HistoryLedgerItemValue value={roleNames[0].newValue} />
        </Typography>
      );
    }
  }

  getEditedText(change: IHistoryLedgerItemChange) {
    switch (change.propertyName) {
      case "team": {
        const oldTeamValue = change.oldValue as IHistoryLedgerItemTeamValue;
        const newTeamValue = change.newValue as IHistoryLedgerItemTeamValue;

        return (
          <Typography>
            {`Updated team from `}
            <HistoryLedgerItemValue value={<Link href={Links.Team(oldTeamValue.slug)}>{oldTeamValue.name}</Link>} />
            {` to `}
            <HistoryLedgerItemValue value={<Link href={Links.Team(newTeamValue.slug)}>{newTeamValue.name}</Link>} />
          </Typography>
        );
      }

      case "accountability": {
        const oldAccountabilityValue = change.oldValue as string;
        const newAccountabilityValue = change.newValue as string;

        if (change.oldValue == null && newAccountabilityValue != null) {
          return (
            <Typography>
              {`Added accountability `}
              <HistoryLedgerItemValue value={newAccountabilityValue} />
            </Typography>
          );
        }

        if (change.oldValue != null && newAccountabilityValue == null) {
          return (
            <Typography>
              {`Removed accountability `}
              <HistoryLedgerItemValue value={oldAccountabilityValue} />
            </Typography>
          );
        }

        return (
          <Typography>
            {`Updated accountability from `}
            <HistoryLedgerItemValue value={oldAccountabilityValue} />
            {` to `}
            <HistoryLedgerItemValue value={newAccountabilityValue} />
          </Typography>
        );
      }

      case "tag": {
        const oldTagValue = change.oldValue as string;
        const newTagValue = change.newValue as string;

        if (oldTagValue == null && newTagValue != null) {
          return (
            <Typography>
              {`Added tag `}
              <HistoryLedgerItemValue value={newTagValue} />
            </Typography>
          );
        }

        if (oldTagValue != null && newTagValue == null) {
          return (
            <Typography>
              {`Removed tag `}
              <HistoryLedgerItemValue value={oldTagValue} />
            </Typography>
          );
        }

        break;
      }

      case "member": {
        const oldUserValue = change.oldValue as IHistoryLedgerItemUserValue | null;
        const newUserValue = change.newValue as IHistoryLedgerItemUserValue | null;

        if (oldUserValue == null && newUserValue != null) {
          return (
            <Typography>
              {`Added `}
              <HistoryLedgerItemValue
                value={<Link href={Links.User(newUserValue.userId)}>{newUserValue.displayName}</Link>}
              />
              {` to the role`}
            </Typography>
          );
        }

        if (oldUserValue != null && newUserValue == null) {
          return (
            <Typography>
              {`Removed `}
              <HistoryLedgerItemValue
                value={<Link href={Links.User(oldUserValue.userId)}>{oldUserValue.displayName}</Link>}
              />
              {` from the role`}
            </Typography>
          );
        }

        break;
      }

      default: {
        return this.defaultStrategy.getEditedText(change);
      }
    }
  }
}

export { RoleHistoryLedgerMappingStrategy };
