import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IPlanV2 } from "../../../models";
import { EditSectionDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  planGuid: string;
  guid: string;
  blockGuid: string;
  name: string;
  hintText: string;
  sortOrder: string;
  modifiedByUserId: string;
};

const useEditSectionRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editSectionRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: EditSectionDto = {
      blockGuid: variables.blockGuid,
      name: variables.name,
      hintText: variables.hintText,
      sortOrder: variables.sortOrder,
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/sections/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editSectionRequest };
};

const useEditSection = () => {
  const { editSectionRequest } = useEditSectionRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editSectionRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.plans.getPlan(variables.planGuid));

      // Snapshot the current cache value
      const cachedData = queryClient.getQueryData<IPlanV2>(queryKeys.plans.getPlan(variables.planGuid));

      if (cachedData) {
        const optimisticallyUpdatedData: IPlanV2 = {
          ...cachedData,
          blocks: cachedData.blocks.map((block) => {
            if (block.guid === variables.blockGuid) {
              return {
                ...block,
                sections: block.sections.map((section) => {
                  if (section.guid === variables.guid) {
                    return {
                      ...section,
                      name: variables.name,
                      hintText: variables.hintText,
                      sortOrder: variables.sortOrder,
                    };
                  }

                  return section;
                }),
              };
            }

            return {
              ...block,
              sections: block.sections.filter((section) => section.guid !== variables.guid),
            };
          }),
        };

        queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), optimisticallyUpdatedData);
      }

      return { cachedData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), context?.cachedData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.plans.getPlan(variables.planGuid));
    },
  });
};

export { useEditSection };
