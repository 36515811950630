import { Divider, Stack, useTheme } from "@mui/material";
import { gridFilterModelSelector, gridFilteredSortedRowIdsSelector } from "@mui/x-data-grid-pro";
import { GridApiCommunity } from "@mui/x-data-grid-pro/internals";
import { useEffect, useState } from "react";
import { Typography } from "../../../../components";
import { OrganizationReportRow } from "../../../../models";

interface IProps {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  isLoading: boolean;
  rows: OrganizationReportRow[] | undefined;
}

const OrganizationReportResultsSummary = (props: IProps) => {
  const [teamsCount, setTeamsCount] = useState(0);
  const [rolesCount, setRolesCount] = useState(0);
  const [peopleCount, setPeopleCount] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    return props.apiRef.current.subscribeEvent("filteredRowsSet", () => {
      const filteredRowIds = gridFilteredSortedRowIdsSelector(props.apiRef);
      const filterModel = gridFilterModelSelector(props.apiRef);
      const filteredRows =
        filterModel.items.length === 0 || (filterModel.items.length === 1 && filterModel.items[0].field === "")
          ? props.rows ?? []
          : props.rows?.filter((x) => filteredRowIds.includes(x.id as string)) ?? [];

      setTeamsCount(new Set(filteredRows.filter((x) => x.teamId !== undefined).map((x) => x.teamId)).size);
      setRolesCount(new Set(filteredRows.filter((x) => x.roleId !== undefined).map((x) => x.roleId)).size);
      setPeopleCount(new Set(filteredRows.map((x) => x.personId)).size);
    });
  }, [props.apiRef, props.rows]);

  return (
    <Stack direction="row" divider={<Divider flexItem orientation="vertical" />} spacing={1}>
      <Stack flex={{ xs: 1, sm: 0 }} sx={{ pr: { sm: 3 } }}>
        <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
          Teams
        </Typography>
        <Typography variant="h1" sx={{ color: props.isLoading ? theme.palette.grey[200] : undefined }}>
          {teamsCount}
        </Typography>
      </Stack>

      <Stack flex={{ xs: 1, sm: 0 }} sx={{ pr: { sm: 3 } }}>
        <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
          Roles
        </Typography>
        <Typography variant="h1" sx={{ color: props.isLoading ? theme.palette.grey[200] : undefined }}>
          {rolesCount}
        </Typography>
      </Stack>

      <Stack flex={{ xs: 1, sm: 0 }}>
        <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
          People
        </Typography>
        <Typography variant="h1" sx={{ color: props.isLoading ? theme.palette.grey[200] : undefined }}>
          {peopleCount}
        </Typography>
      </Stack>
    </Stack>
  );
};

export { OrganizationReportResultsSummary };
