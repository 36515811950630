import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { Link, Avatar as MuiAvatar, SxProps, Tooltip, Typography, css, styled, useTheme } from "@mui/material";
import { Links } from "../constants";
import { IUser } from "../models";

const StyledMuiAvatar = styled(MuiAvatar)<{ $border: boolean | undefined }>`
  box-sizing: content-box;
  height: 32px;
  width: 32px;

  ${({ $border }) =>
    $border &&
    css`
      background: ${({ theme }) =>
        `linear-gradient(white, white) padding-box, linear-gradient(315deg, ${theme.palette.wine.main}, ${theme.palette.error.main}) border-box`};

      border-color: transparent;
      border-style: solid;
      border-width: 2px;

      &:before {
        border-color: #fff;
        border-radius: 50%;
        border-style: solid;
        border-width: 2px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
      }
    `}
`;

interface IProps {
  user?: IUser;
  tooltip?: boolean;
  border?: boolean;
  sx?: SxProps;
}

const Avatar = (props: IProps) => {
  const theme = useTheme();

  function stringAvatar(name: string) {
    const firstName = name.split(" ")[0][0];
    const lastName = name.split(" ")[1]?.[0] ?? "";

    return {
      sx: {
        ...props.sx,
        backgroundColor: props.user!.enabled ? theme.palette.wine.main : theme.palette.grey[500],
      } as SxProps,
      children: (
        <Typography>
          {firstName}
          {lastName}
        </Typography>
      ),
    };
  }

  return props.user ? (
    props.tooltip ? (
      <Tooltip title={<Link href={Links.User(props.user.userId)}>{props.user.displayName}</Link>}>
        {props.user.profilePhoto ? (
          <StyledMuiAvatar
            $border={props.border}
            sx={{ ...props.sx }}
            style={!props.user.enabled ? { filter: "grayscale(100%)" } : {}}
            alt={props.user.displayName}
            src={`data:image/jpeg;base64, ${props.user.profilePhoto}`}
          />
        ) : (
          <MuiAvatar {...stringAvatar(props.user.displayName)} />
        )}
      </Tooltip>
    ) : (
      <>
        {props.user.profilePhoto ? (
          <StyledMuiAvatar
            $border={props.border}
            sx={{ ...props.sx }}
            style={!props.user.enabled ? { filter: "grayscale(100%)" } : {}}
            alt={props.user.displayName}
            src={`data:image/jpeg;base64, ${props.user.profilePhoto}`}
          />
        ) : (
          <MuiAvatar {...stringAvatar(props.user.displayName)} />
        )}
      </>
    )
  ) : (
    <MuiAvatar sx={{ ...props.sx }}>
      <PersonRoundedIcon fontSize="large" />
    </MuiAvatar>
  );
};

export { Avatar };
