import { ManageAccountsOutlined } from "@mui/icons-material";
import { Chip, Link, Skeleton, Stack, SxProps, Tooltip } from "@mui/material";
import { Typography } from "../../../../components";
import { Links } from "../../../../constants";
import { useTeamPageLayoutContext } from "../TeamPageLayout";
import { useBreakpoints } from "../../../../hooks";

interface IProps {
  showAdministratorsTooltip?: boolean;
  sx?: SxProps;
}

const TeamTitle = (props: IProps) => {
  const { isLoadingTeam, team } = useTeamPageLayoutContext();
  const { isMdUp } = useBreakpoints();

  if (isLoadingTeam) {
    return (
      <Stack spacing={0.25} sx={props.sx}>
        <Skeleton variant="rectangular" height="25px" width="60px" />
        <Skeleton variant="rectangular" height="40px" width="300px" />
      </Stack>
    );
  }

  if (team) {
    return (
      <Stack direction="row" spacing={0.5} sx={{ alignItems: "end", ...props.sx }}>
        <Stack spacing={0.25} sx={{ pb: isLoadingTeam ? 1 : 0, overflow: "hidden" }}>
          {!isLoadingTeam && team?.parentTeam && (
            <Chip
              label={team.parentTeam.name}
              color="primary"
              size="small"
              component="a"
              href={Links.Team(team.parentTeam.slug)}
              clickable
            />
          )}
          <Typography variant="h3" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {team.name}
          </Typography>
        </Stack>

        {props.showAdministratorsTooltip && isMdUp && (
          <Tooltip
            title={
              <>
                <Typography sx={{ fontSize: 13, fontWeight: 700 }}>Administrators</Typography>
                {team.administrators
                  .sort((a, b) => a.displayName.localeCompare(b.displayName))
                  .map((u, i) => (
                    <Link key={i} href={Links.User(u.userId)}>
                      <Typography sx={{ fontSize: 13 }}>{u.displayName}</Typography>
                    </Link>
                  ))}
              </>
            }
            placement="bottom-start"
            disableTouchListener={team.administrators.length === 0}
            disableHoverListener={team.administrators.length === 0}
          >
            <Chip icon={<ManageAccountsOutlined />} label="Admins" sx={{ mb: "5px !important" }} />
          </Tooltip>
        )}
      </Stack>
    );
  }

  return null;
};

export { TeamTitle };
