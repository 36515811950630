import { ControllerFieldState, ControllerRenderProps, FieldValues } from "react-hook-form";
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { QuestionType } from "../../../../constants";
import { TextViewer } from "../../../../components";
import { QuestionDto } from "../../../../http";

interface GeneratedFormFieldProps {
  question: QuestionDto;
  field: ControllerRenderProps<FieldValues, string>;
  fieldState: ControllerFieldState;
  handler: (e: any) => void;
  theme: Theme;
}

const GeneratedFormField = ({ question, field, fieldState, handler, theme }: GeneratedFormFieldProps) => {
  const isText = question.questionTypeId === QuestionType.Text;
  const isLinearScale = question.questionTypeId === QuestionType.LinearScale;
  const isTitleAndDescription = question.questionTypeId === QuestionType.TitleAndDescription;

  return (
    <Stack spacing={0.5}>
      <Typography variant={isTitleAndDescription ? "h5" : "body2"}>
        {question.name} {question.isRequired && <span style={{ color: theme.palette.error.main }}>*</span>}
      </Typography>

      {isTitleAndDescription ? (
        <TextViewer value={question.description} />
      ) : (
        <Typography variant="caption">{question.description}</Typography>
      )}
      <Box>
        {isLinearScale && (
          <RadioGroup row value={field.value} sx={{ gap: 5, justifyContent: "space-between" }}>
            <Stack flexGrow={1}>
              <Stack flexDirection="row" justifyContent="space-between">
                {[...Array(question.scalePoints)].map((_, index) => {
                  return (
                    <FormControlLabel
                      sx={{ mx: -0.5 }}
                      label=""
                      key={index}
                      value={index + 1}
                      onChange={(e) => {
                        field.onChange(e);
                        handler({
                          target: e.target,
                          questionGuid: question.questionGuid,
                          answerGuid: question.answer ? question.answer.answerGuid : null,
                        });
                      }}
                      control={<Radio color="default" />}
                    />
                  );
                })}
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between">
                <Typography variant="caption">{question.minLabel}</Typography>
                <Typography variant="caption">{question.maxLabel}</Typography>
              </Stack>
            </Stack>

            {question.includeNotApplicableOption && (
              <Stack alignItems="center">
                <FormControlLabel
                  sx={{ m: 0 }}
                  label=""
                  value={0}
                  checked={question.answer ? Number(question.answer.value) === 0 : false}
                  onChange={(e) => {
                    field.onChange(e);
                    handler({
                      target: e.target,
                      questionGuid: question.questionGuid,
                      answerGuid: question.answer ? question.answer.answerGuid : null,
                    });
                  }}
                  control={<Radio color="default" />}
                />
                <Typography variant="caption">Not Applicable</Typography>
              </Stack>
            )}
          </RadioGroup>
        )}

        {isText && (
          <TextField
            error={fieldState.error !== undefined}
            name={question.questionGuid}
            value={field.value}
            onChange={field.onChange}
            onBlur={(e) => {
              field.onBlur();
              handler({
                target: e.target,
                questionGuid: question.questionGuid,
                answerGuid: question.answer ? question.answer.answerGuid : null,
              });
            }}
            placeholder="enter answer..."
            autoComplete="off"
            multiline
            inputProps={{
              sx: {
                "&::placeholder": {
                  fontStyle: "italic",
                },
              },
            }}
          />
        )}
        <FormHelperText error={fieldState.error !== undefined}>{fieldState.error?.message}</FormHelperText>
      </Box>
    </Stack>
  );
};

export { GeneratedFormField };
