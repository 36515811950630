import { DEFAULT_TITLE } from "../constants";

const setTitlePrefix = (titlePrefix?: string) => {
  if (titlePrefix === undefined) {
    document.title = DEFAULT_TITLE;
  } else {
    document.title = `${titlePrefix} - ${DEFAULT_TITLE}`;
  }
};

export { setTitlePrefix };
