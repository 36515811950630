const Links = {
  User: (userId: string) => `/people/${userId}`,
  UserMap: (userId: string) => `/people/${userId}/map`,
  UserSettings: () => `/settings`,
  Role: (guid: string) => `/roles/${guid}`,
  Team: (slug: string) => `/teams/${slug}`,
  TeamStrategicMap: (slug: string) => `/teams/${slug}/strategic-map`,
  TeamIssues: (slug: string) => `/teams/${slug}/issues`,
  TeamPlanVersion: (slug: string, dateTime: string, offset: number) =>
    `/teams/${slug}/plan?date=${dateTime}&offset=${offset}`,
  TeamMetrics: (slug: string) => `/teams/${slug}/metrics`,
  TeamResources: (slug: string) => `/teams/${slug}/resources`,
  TeamSettings: (slug: string) => `/teams/${slug}/settings`,
  EditTeamPlan: (slug: string, planVersionId: string) => `/teams/${slug}/plan/v/${planVersionId}/edit`,
  ActionItemDetail: (guid: string) => `/action-items/${guid}`,
  IssueDetail: (guid: string) => `/issues/${guid}`,
  PlanPurposeDetail: (slug: string) => `/teams/${slug}/plan/purpose`,
  PlanValueDetail: (slug: string, guid: string) => `/teams/${slug}/plan/values/${guid}`,
  PlanMissionDetail: (slug: string) => `/teams/${slug}/plan/mission`,
  MilestoneDetail: (slug: string, guid: string) => `/teams/${slug}/plan/milestones/${guid}`,
  GoalDetail: (slug: string, guid: string) => `/teams/${slug}/plan/goals/${guid}`,
  StrategyDetail: (slug: string, guid: string) => `/teams/${slug}/plan/areas-of-focus/${guid}`,
  KeyInitiativeDetail: (slug: string, guid: string) => `/teams/${slug}/plan/priorities/${guid}`,
  MetricDetail: (guid: string) => `/metrics/${guid}`,
  RoleDetail: (guid: string) => `/roles/${guid}`,
  Surveys: () => `/surveys`,
  Survey: (guid: string) => `/surveys/${guid}`,
  SurveyResponses: (slug: string) => `/surveys/${slug}/responses`,
  SurveyActions: (slug: string) => `/surveys/${slug}/actions`,
  SurveyResponse: (slug: string) => `/surveys/${slug}/response`,
  Search: (query: string, filter?: string) => {
    let urlSearchParams = new URLSearchParams({ query: query });

    if (filter != null) {
      urlSearchParams.append("filter", filter);
    }

    return `/search?${urlSearchParams.toString()}`;
  },
};

export { Links };
