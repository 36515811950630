import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  slug: string;
  video: File;
  modifiedByUserId: string;
};

const useEditTeamVideoRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editTeamVideoRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const formData = new FormData();
    formData.append("video", variables.video);

    const response = await fetch(`${configuration.apiRootUrl}/teams/${variables.slug}/video`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "put",
      body: formData,
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editTeamVideoRequest };
};

const useEditTeamVideo = () => {
  const { editTeamVideoRequest } = useEditTeamVideoRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editTeamVideoRequest,
    onSuccess: (_data, variables) => {
      queryClient.refetchQueries(queryKeys.teams.getTeam(variables.slug, variables.modifiedByUserId));
    },
  });
};

export { useEditTeamVideo };
