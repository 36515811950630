import { SendRounded } from "@mui/icons-material";
import { Box, IconButton as MuiIconButton, Stack, styled, SxProps } from "@mui/material";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { v4 as newGuid } from "uuid";
import { CommentSubject } from "../constants";
import { useSession } from "../hooks";
import { useCreateComment } from "../http";
import { IUser } from "../models";
import { isWhitespace, TextEditor, TextEditorRef } from "./textEditor";

const IconButton = styled(MuiIconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

interface IProps {
  subjectType: CommentSubject;
  subjectGuid: string | undefined;
  disabled?: boolean;
  onCommentCreated: (
    subjectGuid: string,
    commentGuid: string,
    user: IUser,
    timestampUtc: DateTime,
    text: string
  ) => void;
  sx?: SxProps;
}

const CommentForm = (props: IProps) => {
  const { userId } = useSession();
  const { mutate: createComment, isLoading } = useCreateComment();
  const [text, setText] = useState<string>("");
  const textEditor = useRef<TextEditorRef>(null);

  async function handleSendClicked() {
    if (props.subjectGuid) {
      createComment(
        {
          subjectType: props.subjectType,
          subjectGuid: props.subjectGuid,
          commentGuid: newGuid(),
          createdByUserId: userId,
          text,
        },
        {
          onSuccess: (data, variables) => {
            textEditor.current?.setContent("");
            props.onCommentCreated(
              variables.subjectGuid,
              data.guid,
              data.createdBy,
              DateTime.fromISO(data.dateCreatedUtc, { zone: "utc" }),
              data.text
            );
          },
        }
      );
    }
  }

  return (
    <Box sx={{ pl: { xs: 0.5, sm: 1 }, pr: 0.5, py: 0.75, ...props.sx }}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <TextEditor
          ref={textEditor}
          initialValue={text}
          placeholder="Type comment..."
          readonly={isLoading || props.disabled || !props.subjectGuid}
          onChange={(value) => setText(value)}
        />
        <Box>
          <IconButton
            disabled={isWhitespace(text) || isLoading || props.disabled || !props.subjectGuid}
            onClick={handleSendClicked}
          >
            <SendRounded />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );
};

export { CommentForm };
