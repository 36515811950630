import { Skeleton, Stack } from "@mui/material";
import { useGetTeamsTree } from "../http";
import { ITreeNode } from "../models";
import { TreeView } from "./TreeView";

interface IProps {
  selectedTeamGuids: string[];
  onTeamSelected: (aggregateTeamLabel: string, selectedNodes: ITreeNode[]) => void;
}

const TeamTreeView = (props: IProps) => {
  const { data: teams, isLoading } = useGetTeamsTree();

  function handleNodeSelect(selectedNodes: ITreeNode[]) {
    let aggregateTeamName = "-";
    let shallowestNode: ITreeNode | undefined = undefined;

    if (selectedNodes.length === 1) {
      aggregateTeamName = selectedNodes[0].label;
    } else if (selectedNodes.length > 1) {
      shallowestNode = selectedNodes.sort((a, b) => a.depth - b.depth || a.label.localeCompare(b.label))[0];

      if (shallowestNode) {
        aggregateTeamName = `${shallowestNode.label}... (${selectedNodes.length} teams)`;
      }
    }

    props.onTeamSelected(aggregateTeamName, selectedNodes);
  }

  return (
    <>
      {isLoading && (
        <Stack>
          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} width={240} />
          ))}
        </Stack>
      )}

      {!isLoading && teams && (
        <TreeView nodes={teams} selectedNodeIds={props.selectedTeamGuids} onNodeSelected={handleNodeSelect} />
      )}
    </>
  );
};

export { TeamTreeView };
