import { CancelRounded, FilterListRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import { Button, Divider, IconButton, MenuItem, Popover, Select, Stack } from "@mui/material";
import { GridColDef, GridFilterItem, GridLogicOperator, gridFilterModelSelector } from "@mui/x-data-grid-pro";
import { GridApiCommunity } from "@mui/x-data-grid-pro/internals";
import _ from "lodash";
import { useEffect, useState } from "react";
import { TextField } from "..";

interface IProps {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  columns: GridColDef[];
  hiddenColumns: string[];
}

const FILTER_PREFIX = "columnFilter";

const emptyFilter: GridFilterItem = {
  field: "",
  operator: "contains",
  value: "",
};

const ColumnFiltersButton = (props: IProps) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [filters, setFilters] = useState<GridFilterItem[]>([]);

  // Sync filter model to local column filter state
  useEffect(() => {
    return props.apiRef.current.subscribeEvent("filterModelChange", (model) => {
      const columnFilters = model.items.filter((x) => x.id?.toString().includes(FILTER_PREFIX));
      setFilters(columnFilters);
    });
  }, [props.apiRef]);

  useEffect(() => {
    // Set the grid logic operator to "AND"
    props.apiRef.current.setFilterLogicOperator(GridLogicOperator.And);

    // Set a default column filter if there aren't any column filters when the grid is initialized
    return props.apiRef.current.subscribeEvent("filteredRowsSet", () => {
      const filterModel = gridFilterModelSelector(props.apiRef);
      const columnFilters = filterModel.items.filter((x) => x.id?.toString().includes(FILTER_PREFIX));

      if (columnFilters.length === 0) {
        props.apiRef.current.upsertFilterItem({
          id: _.uniqueId(FILTER_PREFIX),
          ...emptyFilter,
        });
      }
    });
  }, [props.apiRef]);

  function handleAddFilterClicked() {
    const newFilterItem = { id: _.uniqueId(FILTER_PREFIX), ...emptyFilter };
    props.apiRef.current.upsertFilterItem(newFilterItem);
  }

  function handleFieldChanged(filter: GridFilterItem, newField: string) {
    props.apiRef.current.upsertFilterItem({
      ...filter,
      field: newField,
    });
  }

  function handleOperatorChanged(filter: GridFilterItem, newOperator: string) {
    props.apiRef.current.upsertFilterItem({
      ...filter,
      operator: newOperator,
    });
  }

  function handleValueChanged(filter: GridFilterItem, newValue: string) {
    props.apiRef.current.upsertFilterItem({
      ...filter,
      value: newValue,
    });
  }

  function handleRemoveFilterClicked(filter: GridFilterItem) {
    props.apiRef.current.deleteFilterItem(filter);

    if (filters.length === 1) {
      setMenuAnchor(null);
    }
  }

  function handleRemoveAllFiltersClicked() {
    for (var filter of filters) {
      props.apiRef.current.deleteFilterItem(filter);
    }

    setMenuAnchor(null);
  }

  return (
    <>
      <Button
        variant="tertiary"
        startIcon={<FilterListRounded />}
        endIcon={<KeyboardArrowDownRounded />}
        onClick={(e) => setMenuAnchor(e.currentTarget)}
      >
        {filters[0]?.field !== "" ? `${filters.length} Filter${filters.length > 1 ? "s" : ""}` : "Column Filters"}
      </Button>

      <Popover
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "10px",
            },
          },
        }}
      >
        <Stack spacing={0.5} sx={{ m: 0.5 }}>
          {filters.map((filter, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={0.25}
              sx={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <IconButton onClick={() => handleRemoveFilterClicked(filter)}>
                <CancelRounded />
              </IconButton>

              <Select
                value={filter.field}
                onChange={(e) => handleFieldChanged(filter, e.target.value)}
                sx={{ width: 150 }}
              >
                {props.columns
                  .filter((column) => !props.hiddenColumns.includes(column.field))
                  .map((column, index) => (
                    <MenuItem key={index} value={column.field}>
                      {column.headerName}
                    </MenuItem>
                  ))}
              </Select>
              <Select
                value={filter.operator}
                onChange={(e) => handleOperatorChanged(filter, e.target.value)}
                sx={{ width: 120 }}
              >
                <MenuItem value="contains">contains</MenuItem>
                <MenuItem value="equals">equals</MenuItem>
                <MenuItem value="startsWith">starts with</MenuItem>
                <MenuItem value="endsWith">ends with</MenuItem>
                <MenuItem value="isEmpty">is empty</MenuItem>
                <MenuItem value="isNotEmpty">is not empty</MenuItem>
              </Select>

              {!["isEmpty", "isNotEmpty"].includes(filter.operator) && (
                <TextField
                  value={filter.value}
                  onChange={(e) => handleValueChanged(filter, e.target.value)}
                  sx={{ width: 190 }}
                />
              )}
            </Stack>
          ))}
          <Divider variant="dashed" sx={{ color: "#c9c9c9" }} />
          <Stack direction="row" spacing={0.5}>
            <Button variant="tertiary" onClick={handleAddFilterClicked}>
              Add Filter
            </Button>
            <Button variant="tertiary" onClick={handleRemoveAllFiltersClicked}>
              Remove All
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export { ColumnFiltersButton };
