import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditActionItemContextDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  contextActionItemHistoryId?: number;
  modifiedByUserId: string;
};

const useEditActionItemContextRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editActionItemContextRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditActionItemContextDto = {
      contextActionItemHistoryId: variables.contextActionItemHistoryId,
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/action-items/${variables.guid}/context`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editActionItemContextRequest };
};

const useEditActionItemContext = () => {
  const { editActionItemContextRequest } = useEditActionItemContextRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editActionItemContextRequest,
    onSuccess: (_, variables) => {
      queryClient.refetchQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
      queryClient.refetchQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));
      queryClient.refetchQueries(queryKeys.actionItems.getActionItemFeed(variables.guid));
    },
  });
};

export { useEditActionItemContext };
