import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import { configuration } from "../../configuration";
import { SignalRClientMethod, SignalRServerMethod } from "./constants";
import { SignalRContext } from "./SignalRContext";

interface IProps {
  children?: React.ReactNode;
}

const SignalRProvider = (props: IProps) => {
  const [connection, setConnection] = useState<HubConnection | undefined>();

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${configuration.apiRootUrl}/hub`, {})
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Debug)
      .build();

    setConnection(connection);
    start(connection);

    connection.onclose((e) => {
      console.error(`SignalR connection closed due to "${e}".`);
    });

    return () => {
      connection.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function start(connection: HubConnection) {
    // Automatic reconnects don't retry initial start failures, so we need to handle it manually.
    try {
      await connection.start();
      console.log("SignalR connected.");
    } catch (e) {
      console.log(e);
      setTimeout(() => start(connection), 5000);
    }
  }

  async function invoke(methodName: string, args: any[]) {
    connection?.invoke(methodName, ...args);
  }

  function registerCallback(methodName: string, callback: (...args: any[]) => any) {
    connection?.on(methodName, callback);
  }

  function unregisterCallback(methodName: string, callback: (...args: any[]) => any) {
    connection?.off(methodName, callback);
  }

  return (
    <SignalRContext.Provider
      value={{
        connection,
        joinGroup: (...args) => invoke(SignalRServerMethod.JoinGroup, args),
        leaveGroup: (...args) => invoke(SignalRServerMethod.LeaveGroup, args),
        sendChatMessage: (...args) => invoke(SignalRServerMethod.SendChatMessage, args),
        actionItemUpdated: (...args) => invoke(SignalRServerMethod.ActionItemUpdated, args),
        createComment: (...args) => invoke(SignalRServerMethod.CreateComment, args),
        editComment: (...args) => invoke(SignalRServerMethod.EditComment, args),
        deleteComment: (...args) => invoke(SignalRServerMethod.DeleteComment, args),
        registerReceiveChatMessage: (callback) => registerCallback(SignalRClientMethod.ReceiveChatMessage, callback),
        registerReceiveActionItemUpdated: (callback) =>
          registerCallback(SignalRClientMethod.ReceiveActionItemUpdated, callback),
        registerReceiveCreateComment: (callback) =>
          registerCallback(SignalRClientMethod.ReceiveCreateComment, callback),
        registerReceiveEditComment: (callback) => registerCallback(SignalRClientMethod.ReceiveEditComment, callback),
        registerReceiveDeleteComment: (callback) =>
          registerCallback(SignalRClientMethod.ReceiveDeleteComment, callback),
        unregisterReceiveChatMessage: (callback) =>
          unregisterCallback(SignalRClientMethod.ReceiveChatMessage, callback),
        unregisterReceiveActionItemUpdated: (callback) =>
          unregisterCallback(SignalRClientMethod.ReceiveActionItemUpdated, callback),
        unregisterReceiveCreateComment: (callback) =>
          unregisterCallback(SignalRClientMethod.ReceiveCreateComment, callback),
        unregisterReceiveEditComment: (callback) =>
          unregisterCallback(SignalRClientMethod.ReceiveEditComment, callback),
        unregisterReceiveDeleteComment: (callback) =>
          unregisterCallback(SignalRClientMethod.ReceiveDeleteComment, callback),
      }}
    >
      {props.children}
    </SignalRContext.Provider>
  );
};

export { SignalRProvider };
