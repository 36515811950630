import { Box, Button, DialogActions, DialogContent, Stack, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { v4 as newGuid } from "uuid";
import { CommentSubject } from "../constants";
import { useSession, useSignalR } from "../hooks";
import { useCreateComment } from "../http";
import { isWhitespace, TextEditor } from "./textEditor";

const AnimationContainer = styled(Box)`
  display: flex;
  height: 110px;
  width: 110px;
  padding: 5px;
  border-radius: 50%;
  background-color: white;
  align-self: center;
  position: relative;
  top: -50px;
  z-index: 2;
  border: 1px solid #cdcdcd;
`;

interface IProps {
  icon: React.ReactNode;
  title: string;
  content: React.ReactNode;
  commentSubjectType: CommentSubject;
  commentSubjectGuid: string;
  onDone: () => void;
  onCancel: () => void;
}

const StatusChangedDialogContent = (props: IProps) => {
  const { userId } = useSession();
  const [text, setText] = useState<string>("");
  const { mutate: createComment, isLoading } = useCreateComment();
  const signalR = useSignalR();

  async function handleSendClicked() {
    if (isWhitespace(text)) {
      props.onDone();
      return;
    }

    createComment(
      {
        subjectType: props.commentSubjectType,
        subjectGuid: props.commentSubjectGuid,
        commentGuid: newGuid(),
        createdByUserId: userId,
        text,
      },
      {
        onSuccess: (data) => {
          signalR.createComment(
            props.commentSubjectType,
            props.commentSubjectGuid,
            data.guid,
            data.createdBy,
            data.dateCreatedUtc,
            data.text
          );
          props.onDone();
        },
      }
    );
  }

  return (
    <>
      <AnimationContainer>{props.icon}</AnimationContainer>

      <DialogContent sx={{ mt: "-50px" }}>
        <Stack spacing={1}>
          <Typography variant="h5" alignSelf="center" sx={{ textTransform: "uppercase" }}>
            {props.title}
          </Typography>
          {props.content}
          <Stack spacing={0.5}>
            <Typography variant="overline">Comment</Typography>
            <TextEditor placeholder="Type comment..." onChange={(value) => setText(value)} initialValue={""} />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isLoading} onClick={props.onCancel}>
          Cancel
        </Button>
        <Button variant="contained" disabled={isLoading} onClick={() => handleSendClicked()}>
          Done
        </Button>
      </DialogActions>
    </>
  );
};

export { StatusChangedDialogContent };
