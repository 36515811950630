import { Box, alpha, useTheme } from "@mui/material";
import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import ForceGraph2D, { ForceGraphMethods, GraphData, NodeObject } from "react-force-graph-2d";
import { useNavigate } from "react-router";
import { CodeSpinnerIcon } from "../../../../assets/icons";
import { useSession } from "../../../../hooks";
import { useGetTeamNetworkGraph } from "../../../../http";
import { INetworkGraphLink, INetworkGraphNode, ITeam, NetworkGraphNodeType } from "../../../../models";
import { NODE_RADIUS, calculateOpacity, drawNode } from "../../../../utilities";

interface IProps {
  isLoadingTeam: boolean;
  team: ITeam | undefined;
}

const TeamNetworkGraph = (props: IProps) => {
  const { userId } = useSession();
  const { data, isLoading } = useGetTeamNetworkGraph(props.team?.slug);
  const [mutableData, setMutableData] = useState<GraphData<INetworkGraphNode, INetworkGraphLink>>({
    nodes: [],
    links: [],
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const forceGraphRef = useRef<ForceGraphMethods<INetworkGraphNode, INetworkGraphLink>>();
  const tickRef = useRef<number>(0);
  const resizeTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [graphDimensions, setGraphDimensions] = useState<{ height?: number; width?: number }>({});

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  function handleResize() {
    // Buffered to only resize every 500ms
    clearTimeout(resizeTimerRef.current);
    resizeTimerRef.current = setTimeout(() => {
      setGraphDimensions({
        height: containerRef.current?.clientHeight,
        width: containerRef.current?.clientWidth,
      });

      setTimeout(() => {
        forceGraphRef.current?.zoomToFit(1000, 80);
      }, 100);
    }, 500);
  }

  // Configure initial height and width
  useEffect(() => {
    setGraphDimensions({
      height: containerRef.current?.clientHeight,
      width: containerRef.current?.clientWidth,
    });
  }, []);

  // Configure d3 forces
  useEffect(() => {
    if (forceGraphRef.current) {
      forceGraphRef.current.d3Force("charge", d3.forceManyBody().strength(-1000));
      forceGraphRef.current.d3Force("collide", d3.forceCollide(NODE_RADIUS));
      forceGraphRef.current.d3Force("link", d3.forceLink().distance(75).strength(1));
    }
  }, []);

  // Set up data structure used by react-force-graph
  useEffect(() => {
    if (isLoading) {
      setMutableData({ nodes: [], links: [] });
    }

    if (!isLoading && data) {
      setMutableData({
        nodes: data.teamNodes.concat(data.roleNodes).concat(data.peopleNodes),
        links: data.links.map((x) => ({ ...x })),
      });

      setTimeout(() => {
        forceGraphRef.current?.zoomToFit(1000, 80);
      }, 250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, props.team?.slug]);

  function handleNodeClicked(node: NodeObject<INetworkGraphNode>, event: MouseEvent) {
    if (event.ctrlKey) {
      window.open(node.url, "_blank");
    } else if (event.shiftKey) {
      window.open(node.url, "_new");
    } else {
      navigate(node.url);
    }
  }

  return (
    <Box
      ref={containerRef}
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading && <CodeSpinnerIcon sx={{ position: "absolute" }} />}

      <ForceGraph2D
        ref={forceGraphRef}
        graphData={mutableData}
        height={graphDimensions.height}
        width={graphDimensions.width}
        nodeCanvasObject={(node, canvas, scale) =>
          drawNode(
            node,
            canvas,
            scale,
            theme,
            true,
            node.type === NetworkGraphNodeType.RootTeam
              ? NODE_RADIUS * 2
              : node.type === NetworkGraphNodeType.Member && node.id === userId
              ? NODE_RADIUS * 1.5
              : 0,
            calculateOpacity(tickRef.current, node.depth)
          )
        }
        nodeVal={20}
        linkDirectionalArrowLength={5}
        linkDirectionalArrowRelPos={1}
        linkCurvature={0.25}
        linkLineDash={[5, 3]}
        linkColor={(link) => {
          const source = link.source as NodeObject<INetworkGraphNode>;
          const opacity = calculateOpacity(tickRef.current, source.depth) ?? 1;
          return alpha("#cacaca", opacity);
        }}
        linkWidth={2}
        onEngineTick={() => tickRef.current++}
        onNodeClick={handleNodeClicked}
        warmupTicks={45}
        maxZoom={1.3}
        minZoom={0.1}
      />
    </Box>
  );
};

export { TeamNetworkGraph };
