import { Typography } from "@mui/material";
import { IssueClosedAnimation } from "../../assets/animations";
import { CommentSubject } from "../../constants";
import { StatusChangedDialogContent } from "../StatusChangedDialogContent";

interface IProps {
  issueGuid: string;
  onDone: () => void;
  onCancel: () => void;
}

const IssueClosedDialogContent = (props: IProps) => {
  return (
    <StatusChangedDialogContent
      icon={<IssueClosedAnimation />}
      title="Closed"
      content={
        <>
          <Typography>
            You've marked this issue CLOSED.
            <br />
            Please enter a comment to describe how the issue was resolved.
          </Typography>
        </>
      }
      commentSubjectType={CommentSubject.Issue}
      commentSubjectGuid={props.issueGuid}
      onDone={props.onDone}
      onCancel={props.onCancel}
    />
  );
};

export { IssueClosedDialogContent };
