import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import { Loading } from "../components";
import { Paths } from "../constants";
import { useSession } from "../hooks";

const Container = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignOutPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const { endSession } = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      endSession();

      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        postLogoutRedirectUri: Paths.SignOut,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <Loading />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Container>
          <Stack spacing={1} sx={{ alignItems: "center" }}>
            <Logo height={32} width={115} />
            <Typography>You are signed out of CODE.</Typography>
            <Button variant="contained" onClick={() => navigate(Paths.Home)}>
              Sign In
            </Button>
          </Stack>
        </Container>
      </UnauthenticatedTemplate>
    </>
  );
};

export { SignOutPage };
