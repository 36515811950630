import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IPlanV2 } from "../../../models";
import { CreateSectionDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  planGuid: string;
  guid: string;
  blockGuid: string;
  name: string;
  hintText: string;
  sortOrder: string;
  createdByUserId: string;
};

const useCreateSectionRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createSectionRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: CreateSectionDto = {
      guid: variables.guid,
      blockGuid: variables.blockGuid,
      name: variables.name,
      hintText: variables.hintText,
      sortOrder: variables.sortOrder,
      createdByUserId: variables.createdByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/sections`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { createSectionRequest };
};

const useCreateSection = () => {
  const { createSectionRequest } = useCreateSectionRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSectionRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.plans.getPlan(variables.planGuid));

      // Snapshot the current cache value
      const cachedData = queryClient.getQueryData<IPlanV2>(queryKeys.plans.getPlan(variables.planGuid));

      if (cachedData) {
        // Optimistically update the cache
        const optimisticallyUpdatedData: IPlanV2 = {
          ...cachedData,
          blocks: cachedData.blocks.map((block) => {
            if (block.guid === variables.blockGuid) {
              return {
                ...block,
                sections: [
                  ...block.sections,
                  {
                    guid: variables.guid,
                    blockGuid: block.guid,
                    name: variables.name,
                    hintText: variables.hintText,
                    sortOrder: variables.sortOrder,
                    isEditable: true,
                    createActionItemAllowed: true,
                    actionItems: [],
                  },
                ],
              };
            }

            return block;
          }),
        };

        queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), optimisticallyUpdatedData);
      }

      return { cachedData };
    },
    onError: (_error, variables, context) => {
      // On error, rollback
      queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), context?.cachedData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.plans.getPlan(variables.planGuid));
    },
  });
};

export { useCreateSection };
