import { Box, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import React from "react";
import { ConnectionPicker, SmallInputLabel, TextEditor } from "../../../../components";
import { ConnectionState } from "../../../../constants";
import { useGetPlans } from "../../../../http";
import { IConnectionPoint, IInformedBy, IStrategy } from "../../../../models";
import { IEditTeamPlanPageAction } from "../state";
import { EditTextField } from "./EditTextField";

interface IProps {
  strategyIndex: number;
  strategy: IStrategy;
  dispatch: React.Dispatch<IEditTeamPlanPageAction>;
}

const EditStrategy = (props: IProps) => {
  const { strategyIndex, strategy, dispatch } = props;
  const { data: plans, isLoading: isLoadingPlans } = useGetPlans();

  function handleDescriptionChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    dispatch({
      type: "edit_strategy",
      index: strategyIndex,
      changes: {
        description: e.target.value,
      },
    });
  }

  function handleConnectionPointChanged(value: IConnectionPoint | null) {
    const informedBy: IInformedBy | undefined =
      value == null
        ? undefined
        : {
            ...value,
            syncState: ConnectionState.InSync,
            updatedVersion: null,
          };

    dispatch({
      type: "edit_strategy",
      index: strategyIndex,
      changes: {
        informedBy,
      },
    });
  }

  function handleNotesChanged(value: string) {
    dispatch({
      type: "edit_strategy",
      index: strategyIndex,
      changes: {
        notes: value,
      },
    });
  }

  return (
    <>
      <CardHeader title={<Typography variant="h6">Area of Focus</Typography>} sx={{ pb: 0 }}></CardHeader>
      <CardContent>
        <Stack spacing={1}>
          <EditTextField
            label="Description"
            value={strategy.description}
            onChange={handleDescriptionChanged}
            inputProps={{ maxLength: 512 }}
          />
          <Box>
            <SmallInputLabel>Connected To</SmallInputLabel>
            <ConnectionPicker
              isLoadingPlans={isLoadingPlans}
              plans={plans ?? []}
              selectedConnectionPoint={strategy.informedBy}
              onConnectionPointChanged={handleConnectionPointChanged}
            />
          </Box>
          <Box>
            <SmallInputLabel>Notes</SmallInputLabel>
            <TextEditor initialValue={strategy.notes} onChange={handleNotesChanged} />
          </Box>
        </Stack>
      </CardContent>
    </>
  );
};

export { EditStrategy };
