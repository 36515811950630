import { Typography } from "@mui/material";
import { EditHintCard } from "../EditHintCard";

const PurposeHintCard = () => {
  return (
    <EditHintCard title="Purpose">
      <Typography variant="body1">
        Purpose clarifies the intention of the team and what it aspires to. The Purpose orients the action of a Role or
        Team towards a "True North", ex. "Create Great Experiences".
      </Typography>
      <Typography variant="body1">What would not happen or exist if this team didn't exist?</Typography>
    </EditHintCard>
  );
};

export { PurposeHintCard };
