import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetActionItemSummaryDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetActionItemSummaryRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getActionItemSummaryRequest({
    queryKey,
  }: QueryFunctionContext<
    ReturnType<typeof queryKeys.actionItems.getActionItemSummary>
  >): Promise<GetActionItemSummaryDto> {
    const accessToken = await getAccessToken();
    const [{ guid }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/action-items/${guid}/summary`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getActionItemSummaryRequest };
};

const useGetActionItemSummary = (guid: string) => {
  const { getActionItemSummaryRequest } = useGetActionItemSummaryRequest();

  return useQuery({
    queryKey: queryKeys.actionItems.getActionItemSummary(guid),
    queryFn: getActionItemSummaryRequest,
    refetchOnMount: false,
  });
};

export { useGetActionItemSummary };
