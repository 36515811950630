import {useMutation, useQueryClient} from "@tanstack/react-query";
import {configuration} from "../../../configuration";
import {FetchError} from "../../types";
import {useAccessToken} from "../../use-access-token";
import {queryKeys} from "../queryKeys";

type Variables = {
  guid: string;
};

const useDeleteSurveyRequest = () => {
  const {getAccessToken} = useAccessToken();

  async function deleteSurveyRequest(variables: Variables) {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/surveys/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return {deleteSurveyRequest};
};

const useDeleteSurvey = () => {
  const {deleteSurveyRequest} = useDeleteSurveyRequest();
  const queryClient = useQueryClient();

  return useMutation(({
    mutationFn: deleteSurveyRequest,
    onSuccess: async (_, variables) => {
      await queryClient.removeQueries({queryKey: queryKeys.surveys.getSurvey(variables.guid)});
      await queryClient.invalidateQueries({queryKey: queryKeys.surveys.getSurveys()});
    },
  }))
};

export {useDeleteSurvey};
