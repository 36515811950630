import { Box, Skeleton, Stack } from "@mui/material";
import { useParams } from "react-router";
import { useGetResourcesForTeam } from "../../../../http";
import { TeamResource } from "./TeamResource";

interface IParams {
  slug: string;
}

interface IProps {
  isEditable: boolean;
}

const TeamResources = (props: IProps) => {
  const { slug } = useParams<keyof IParams>() as IParams;
  const { data, isLoading } = useGetResourcesForTeam(slug);

  return (
    <>
      {isLoading && (
        <Box sx={{ px: 1, pt: 1 }}>
          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} />
          ))}
        </Box>
      )}
      {!isLoading && data && (
        <Stack spacing={{ xs: 0.75, md: 1 }} sx={{ p: { xs: 0.5, md: 1 } }}>
          {data
            .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
            .map((resource) => (
              <TeamResource key={resource.guid} resource={resource} isEditable={props.isEditable} />
            ))}
        </Stack>
      )}
    </>
  );
};

export { TeamResources };
