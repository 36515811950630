import {
  Box,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Popper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Autocomplete, ConnectionPicker, SmallInputLabel, TextEditor } from "../../../../components";
import { ConnectionState } from "../../../../constants";
import { useError, useUsers } from "../../../../hooks";
import { useGetPlans } from "../../../../http";
import { IConnectionPoint, IInformedBy, IKeyInitiative, IUser } from "../../../../models";
import { IEditTeamPlanPageAction } from "../state";
import { EditTextField } from "./EditTextField";

interface IOwnershipUser {
  groupName: string;
  displayName: string;
  email: string | undefined;
  userId: string;
}

interface IProps {
  slug: string;
  strategyIndex: number;
  keyInitiativeIndex: number;
  keyInitiative: IKeyInitiative;
  members: IUser[];
  dispatch: React.Dispatch<IEditTeamPlanPageAction>;
}

const EditKeyInitiative = (props: IProps) => {
  const { strategyIndex, keyInitiativeIndex, keyInitiative, members, dispatch } = props;
  const { data: plans, isLoading: isLoadingPlans } = useGetPlans();
  const { getUsers } = useUsers();
  const { setError } = useError();
  const [autocompleteContainer, setAutocompleteContainer] = useState<HTMLElement | null>(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [users, setUsers] = useState<IOwnershipUser[]>([]);
  const theme = useTheme();

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadUsers() {
    setIsLoadingUsers(true);

    (await getUsers()).match(
      (users) => {
        setUsers(
          users.map((u) => ({
            groupName: getGroupName(u.userId),
            displayName: u.displayName,
            email: u.email,
            userId: u.userId,
          }))
        );
      },
      (error) => setError(error)
    );

    setIsLoadingUsers(false);
  }

  function getGroupName(userId: string) {
    return members.find((m) => m.userId === userId) === undefined ? "Others" : "Members";
  }

  function handleDescriptionChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    dispatch({
      type: "edit_key_initiative",
      strategyIndex,
      keyInitiativeIndex,
      changes: {
        description: e.target.value,
      },
    });
  }

  function handleConnectionPointChanged(value: IConnectionPoint | null) {
    const informedBy: IInformedBy | undefined =
      value == null
        ? undefined
        : {
            ...value,
            syncState: ConnectionState.InSync,
            updatedVersion: null,
          };

    dispatch({
      type: "edit_key_initiative",
      strategyIndex,
      keyInitiativeIndex,
      changes: {
        informedBy,
      },
    });
  }

  function handleNotesChanged(value: string) {
    dispatch({
      type: "edit_key_initiative",
      strategyIndex,
      keyInitiativeIndex,
      changes: {
        notes: value,
      },
    });
  }

  function handleOwnershipChanged(e: React.SyntheticEvent, value: IOwnershipUser[] | null) {
    dispatch({
      type: "edit_key_initiative",
      strategyIndex,
      keyInitiativeIndex,
      changes: {
        users:
          value != null
            ? value.map((user) => ({
                displayName: user.displayName,
                profilePhoto: undefined,
                userId: user.userId,
                enabled: true,
              }))
            : [],
      },
    });
  }

  return (
    <>
      <CardHeader title={<Typography variant="h6">Priority</Typography>} sx={{ pb: 0 }}></CardHeader>
      <CardContent>
        <Stack spacing={1}>
          <EditTextField
            label="Description"
            value={keyInitiative.description}
            onChange={handleDescriptionChanged}
            inputProps={{ maxLength: 512 }}
          />
          <Box>
            <SmallInputLabel>Connected To</SmallInputLabel>
            <ConnectionPicker
              isLoadingPlans={isLoadingPlans}
              plans={plans ?? []}
              selectedConnectionPoint={keyInitiative.informedBy}
              onConnectionPointChanged={handleConnectionPointChanged}
            />
          </Box>
          <FormControl>
            <SmallInputLabel>Status</SmallInputLabel>
          </FormControl>
          <Box ref={setAutocompleteContainer}>
            <SmallInputLabel>Ownership</SmallInputLabel>
            <Autocomplete
              isLoading={isLoadingUsers}
              disabled={isLoadingUsers || users.length <= 0}
              options={users.sort(
                (a, b) => a.groupName.localeCompare(b.groupName) || a.displayName.localeCompare(b.displayName)
              )}
              groupBy={(option) => option.groupName}
              renderGroup={(group) => (
                <Box key={group.key} sx={{ pb: 0.25 }}>
                  <Stack
                    spacing={0.2}
                    sx={{ px: 0.5, pt: 0.75, position: "sticky", top: 0, backgroundColor: theme.palette.common.white }}
                  >
                    <Typography variant="caption">{group.group.toLocaleUpperCase()}</Typography>
                    <Divider />
                  </Stack>
                  {group.children}
                </Box>
              )}
              getOptionLabel={(option) => option.displayName}
              renderOption={(props, option) => (
                <Box sx={{ pl: "10px !important" }} component="li" {...props} key={option.userId}>
                  <Stack>
                    <Typography>{option.displayName}</Typography>
                    <Typography variant="caption">{option.email}</Typography>
                  </Stack>
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Ownership" inputProps={{ ...params.inputProps }} />
              )}
              onChange={handleOwnershipChanged}
              value={props.keyInitiative.users.map((u) => ({
                displayName: u.displayName,
                email: u.email,
                userId: u.userId,
                groupName: getGroupName(u.userId),
              }))}
              isOptionEqualToValue={(option, value) => option.userId === value?.userId}
              PopperComponent={(props) => <Popper {...props} container={autocompleteContainer} />}
              multiple
              sx={{ mt: 0.5 }}
            />
          </Box>
          <Box>
            <SmallInputLabel>Notes</SmallInputLabel>
            <TextEditor initialValue={keyInitiative.notes} onChange={handleNotesChanged} />
          </Box>
        </Stack>
      </CardContent>
    </>
  );
};

export { EditKeyInitiative };
