import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IUser } from "../../../models";
import {
  EditActionItemOwnersDto,
  FetchError,
  GetActionItemDto,
  GetActionItemSummaryDto,
  GetPlanDto,
} from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  owners: IUser[];
  modifiedByUserId: string;
  planGuidToClearItemFrom?: string;
  onSuccess?: () => void;
};

const useEditActionItemOwnersRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editActionItemOwnersRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditActionItemOwnersDto = {
      ownerUserIds: variables.owners.map((x) => x.userId),
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/action-items/${variables.guid}/owners`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editActionItemOwnersRequest };
};

const useEditActionItemOwners = () => {
  const { editActionItemOwnersRequest } = useEditActionItemOwnersRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editActionItemOwnersRequest,
    onMutate: async (variables) => {
      const cachedPlanData = variables.planGuidToClearItemFrom
        ? optimisticallyUpdatePlanData(queryClient, variables)
        : null;

      const cachedActionItemSummaryData = optimisticallyUpdateActionItemSummaryData(queryClient, variables);
      const cachedActionItemDetailsData = optimisticallyUpdateActionItemDetailsData(queryClient, variables);

      return { cachedPlanData, cachedActionItemSummaryData, cachedActionItemDetailsData };
    },
    onSuccess: (_, variables) => {
      variables.onSuccess?.();
    },
    onError: (_error, variables, context) => {
      if (variables.planGuidToClearItemFrom && context?.cachedPlanData) {
        queryClient.setQueryData(
          queryKeys.plans.getPlan(variables.planGuidToClearItemFrom),
          context?.cachedActionItemSummaryData
        );
      }

      queryClient.setQueryData(
        queryKeys.actionItems.getActionItemSummary(variables.guid),
        context?.cachedActionItemSummaryData
      );
      queryClient.setQueryData(
        queryKeys.actionItems.getActionItemDetail(variables.guid),
        context?.cachedActionItemDetailsData
      );
    },
    onSettled: (_data, _error, variables) => {
      if (variables.planGuidToClearItemFrom) {
        queryClient.invalidateQueries(queryKeys.plans.getPlan(variables.planGuidToClearItemFrom));
      }

      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemFeed(variables.guid));
    },
  });
};

async function optimisticallyUpdatePlanData(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.plans.getPlan(variables.planGuidToClearItemFrom!));
  const cachedPlanData = queryClient.getQueryData<GetPlanDto>(
    queryKeys.plans.getPlan(variables.planGuidToClearItemFrom!)
  );

  if (cachedPlanData) {
    const optimisticallyUpdatedPlanData: GetPlanDto = {
      ...cachedPlanData,
      blocks: cachedPlanData.blocks.map((block) => ({
        ...block,
        sections: block.sections.map((section) => ({
          ...section,
          actionItems: section.actionItems.filter((x) => x.guid !== variables.guid),
        })),
      })),
    };

    queryClient.setQueryData(
      queryKeys.plans.getPlan(variables.planGuidToClearItemFrom!),
      optimisticallyUpdatedPlanData
    );

    return cachedPlanData;
  }
}

async function optimisticallyUpdateActionItemSummaryData(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
  const cachedSummaryData = queryClient.getQueryData<GetActionItemSummaryDto>(
    queryKeys.actionItems.getActionItemSummary(variables.guid)
  );

  if (cachedSummaryData) {
    const optimisticallyUpdatedActionItemSummaryData: GetActionItemSummaryDto = {
      ...cachedSummaryData,
      owners: variables.owners,
    };

    queryClient.setQueryData(
      queryKeys.actionItems.getActionItemSummary(variables.guid),
      optimisticallyUpdatedActionItemSummaryData
    );

    return cachedSummaryData;
  }
}

async function optimisticallyUpdateActionItemDetailsData(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));
  const cachedActionItemDetailData = queryClient.getQueryData<GetActionItemDto>(
    queryKeys.actionItems.getActionItemDetail(variables.guid)
  );

  if (cachedActionItemDetailData) {
    const optimisticallyUpdatedActionItemDetailsData: GetActionItemDto = {
      ...cachedActionItemDetailData,
      owners: variables.owners,
    };

    queryClient.setQueryData(
      queryKeys.actionItems.getActionItemDetail(variables.guid),
      optimisticallyUpdatedActionItemDetailsData
    );

    return cachedActionItemDetailData;
  }
}

export { useEditActionItemOwners };
