import { DateTime } from "luxon";
import { SystemStatus } from "../constants";
import { GetPlanValueDto } from "../http";
import { IPlanValueDetail } from "../models";

class PlanValueDetailMapper {
  static fromDto(dto: GetPlanValueDto): IPlanValueDetail {
    return {
      teamName: dto.teamName,
      teamSlug: dto.teamSlug,
      description: dto.description,
      historyLedger: dto.historyLedger.map((historyItem) => ({
        changes: historyItem.changes,
        changeType: historyItem.changeType,
        timestampUtc: DateTime.fromISO(historyItem.timestampUtc, { zone: "utc" }),
        user: historyItem.user,
      })),
      archived: dto.historyLedger[0].changeType === SystemStatus.Deleted,
    };
  }
}

export { PlanValueDetailMapper };
