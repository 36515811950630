import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "../constants";
import { ApplicationError } from "../models";
import { appInsights } from "../o11y";

/**
 * @deprecated Errors should be handled by the ErrorBoundary
 */
function useError() {
  const location = useLocation();
  const navigate = useNavigate();

  async function setError(error: ApplicationError) {
    appInsights.trackException({ error: new Error(error.statusText), severityLevel: SeverityLevel.Error });

    if (error.status === 401) {
      navigate(Paths.SignOut);
      return;
    }

    navigate(location.pathname, {
      state: {
        error: {
          status: error.status,
          statusText: error.statusText,
        },
      },
      replace: true,
    });
  }

  return {
    setError,
  };
}

export { useError };
