import { configuration } from "../configuration";
import { ConnectionType } from "../constants";
import { GetFollowedPlansResultDto, useAccessToken, useHttp } from "../http";
import {
  Accountability,
  ApplicationError,
  IFollowedPlan,
  IUser,
  KeyPerformanceIndicator,
  QuarterlyPriority,
  Result,
} from "../models";

const useUsers = () => {
  const { getAccessToken } = useAccessToken();
  const {
    getUsersRequest,
    getAccountabilitiesRequest,
    getKeyPerformanceIndicatorsRequest,
    getQuarterlyPrioritiesRequest,
    editKeyPerformanceIndicatorsRequest,
    editQuarterlyPrioritiesRequest,
  } = useHttp();

  async function getUsers(): Promise<Result<IUser[], ApplicationError>> {
    try {
      var response = await getUsersRequest();

      if (response.ok && response.data) {
        return Result.success(response.data.users);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function getAccountabilities(userId: string | undefined): Promise<Result<Accountability[], ApplicationError>> {
    try {
      var response = await getAccountabilitiesRequest(userId);

      if (response.ok && response.data) {
        return Result.success(Accountability.fromDto(response.data));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function getKeyPerformanceIndicators(
    userId: string | undefined
  ): Promise<Result<KeyPerformanceIndicator[], ApplicationError>> {
    try {
      var response = await getKeyPerformanceIndicatorsRequest(userId);

      if (response.ok && response.data) {
        return Result.success(KeyPerformanceIndicator.fromDto(response.data));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function getQuarterlyPriorities(
    userId: string | undefined
  ): Promise<Result<QuarterlyPriority[], ApplicationError>> {
    try {
      var response = await getQuarterlyPrioritiesRequest(userId);

      if (response.ok && response.data) {
        return Result.success(QuarterlyPriority.fromDto(response.data));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function editQuarterlyPriorities(
    userId: string | undefined,
    quarterlyPriorities: QuarterlyPriority[]
  ): Promise<Result<void, ApplicationError>> {
    try {
      var response = await editQuarterlyPrioritiesRequest({
        userId,
        quarterlyPriorities: quarterlyPriorities.map((x) => {
          return {
            guid: x.guid,
            description: x.description,
            completedDateUtc: x.completedDateUtc?.toString(),
            sortOrder: x.sortOrder,
            informedByStrategyHistoryId:
              x.informedBy?.type === ConnectionType.Strategy ? x.informedBy.historyId : undefined,
            informedByKeyInitiativeHistoryId:
              x.informedBy?.type === ConnectionType.KeyInitiative ? x.informedBy.historyId : undefined,
          };
        }),
      });

      if (response.ok) {
        return Result.success(undefined);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function editKeyPerformanceIndicators(
    userId: string | undefined,
    keyPerformanceIndicators: KeyPerformanceIndicator[]
  ): Promise<Result<void, ApplicationError>> {
    try {
      var response = await editKeyPerformanceIndicatorsRequest({
        userId,
        keyPerformanceIndicators,
      });

      if (response.ok) {
        return Result.success(undefined);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function getFollowedPlans(userId: string | undefined): Promise<Result<IFollowedPlan[], ApplicationError>> {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(`${configuration.apiRootUrl}/users/${userId}/followed-plans`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      var data: GetFollowedPlansResultDto = await response.json();

      if (response.ok && data) {
        return Result.success(data.plans);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  return {
    getUsers,
    getAccountabilities,
    getKeyPerformanceIndicators,
    getQuarterlyPriorities,
    editKeyPerformanceIndicators,
    editQuarterlyPriorities,
    getFollowedPlans,
  };
};

export { useUsers };
