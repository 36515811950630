import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetSurveyInstanceResponseDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetSurveyInstanceResponseRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getSurveyInstanceResponseRequest({
    queryKey,
  }: QueryFunctionContext<
    ReturnType<typeof queryKeys.surveys.getSurveyInstanceResponse>
  >): Promise<GetSurveyInstanceResponseDto | null> {
    const accessToken = await getAccessToken();
    const [{ instanceGuid }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/survey-instances/${instanceGuid}/response`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 404) {
      return null;
    } else if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getSurveyInstanceResponseRequest };
};

const useGetSurveyInstanceResponse = (instanceGuid: string | undefined) => {
  const { getSurveyInstanceResponseRequest } = useGetSurveyInstanceResponseRequest();

  return useQuery({
    queryKey: queryKeys.surveys.getSurveyInstanceResponse(instanceGuid),
    queryFn: getSurveyInstanceResponseRequest,
    enabled: instanceGuid !== undefined,
  });
};

export { useGetSurveyInstanceResponse };
