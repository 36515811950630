import { DateTime } from "luxon";
import { GetPlanTextFieldDto } from "../http";
import { IHistoryLedgerItem } from "./HistoryLedgerItem";

interface IPlanTextFieldDetail {
  teamName: string;
  teamSlug: string;
  threadGuid: string;
  value: string;
  historyLedger: IHistoryLedgerItem[];
}

class PlanTextFieldDetail implements IPlanTextFieldDetail {
  teamName: string;
  teamSlug: string;
  threadGuid: string;
  value: string;
  historyLedger: IHistoryLedgerItem[];

  constructor(
    teamName: string,
    teamSlug: string,
    threadGuid: string,
    value: string,
    historyLedger: IHistoryLedgerItem[]
  ) {
    this.teamName = teamName;
    this.teamSlug = teamSlug;
    this.threadGuid = threadGuid;
    this.value = value;
    this.historyLedger = historyLedger;
  }

  static fromDto(dto: GetPlanTextFieldDto): PlanTextFieldDetail {
    return new PlanTextFieldDetail(
      dto.teamName,
      dto.teamSlug,
      dto.threadGuid,
      dto.value,
      dto.historyLedger.map((x) => ({
        changes: x.changes,
        changeType: x.changeType,
        timestampUtc: DateTime.fromISO(x.timestampUtc, { zone: "utc" }),
        user: x.user,
      }))
    );
  }
}

export { PlanTextFieldDetail };
export type { IPlanTextFieldDetail };
