import { Button, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { ForceGraphMethods } from "react-force-graph-2d";
import { TertiarySelect } from "../../../components";
import { HomeVisualizationMode, useSettings } from "../../../hooks";
import { INetworkGraphLink, INetworkGraphNode } from "../../../models";

interface IProps {
  forceGraphRef: React.MutableRefObject<ForceGraphMethods<INetworkGraphNode, INetworkGraphLink> | undefined>;
}

const OrganizationNetworkGraphControls = (props: IProps) => {
  const { homeSettings, updateHomeSettings } = useSettings();

  function handleVisualizationModeChanged(e: SelectChangeEvent<unknown>) {
    updateHomeSettings({
      ...homeSettings,
      visualizationMode: e.target.value as HomeVisualizationMode,
    });
  }

  function handleResetClicked() {
    props.forceGraphRef.current?.zoomToFit(500, 20);
  }

  return (
    <>
      <TertiarySelect
        sx={{ backgroundColor: "grey.100", position: "absolute", top: 20, left: 20, zIndex: 3 }}
        value={homeSettings.visualizationMode}
        renderValue={(value) => (
          <Typography variant="button" sx={{ px: 0.5 }}>
            {value as string}
          </Typography>
        )}
        onChange={handleVisualizationModeChanged}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        <MenuItem value={HomeVisualizationMode.Teams.toString()}>Teams</MenuItem>
        <MenuItem value={HomeVisualizationMode.TeamsAndPeople.toString()}>Teams and People</MenuItem>
      </TertiarySelect>

      <Button
        variant="tertiary"
        onClick={handleResetClicked}
        sx={{ backgroundColor: "grey.100", position: "absolute", top: 20, right: 20, zIndex: 3 }}
      >
        Reset
      </Button>
    </>
  );
};

export { OrganizationNetworkGraphControls };
