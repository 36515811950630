import { Typography } from "@mui/material";
import { EditHintCard } from "../EditHintCard";

const StrategyHintCard = () => {
  return (
    <EditHintCard title="Area of Focus">
      <Typography variant="body1">
        Areas of Focus are the top three priority areas (or strategies) of the team that will bring them toward their
        purpose. Executing these strategies will level up the team in the pursuit of their mission.
      </Typography>
      <Typography variant="body1">What should we focus on to move us forward?</Typography>
    </EditHintCard>
  );
};

export { StrategyHintCard };
