import { QueryClient, QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditIssueDto, FetchError, GetIssuesDto, IssueDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  oldTeamSlug: string;
  newTeamSlug: string;
  newTeamName: string;
  description: string;
  createdByUserId: string;
  modifiedByUserId: string;
};

const useEditIssueRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editIssueRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditIssueDto = {
      teamSlug: variables.newTeamSlug,
      description: variables.description,
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/issues/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editIssueRequest };
};

const useEditIssue = () => {
  const { editIssueRequest } = useEditIssueRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editIssueRequest,
    onMutate: async (variables) => {
      const cachedIssueDetailsData = optimisticallyUpdateIssueDetailsData(
        queryClient,
        queryKeys.issues.getIssueDetail(variables.guid),
        variables
      );

      const cachedTeamIssuesData = optimisticallyUpdateIssuesData(
        queryClient,
        queryKeys.issues.getIssues(variables.oldTeamSlug, undefined),
        variables
      );

      const cachedUserIssuesData = optimisticallyUpdateIssuesData(
        queryClient,
        queryKeys.issues.getIssues(undefined, variables.createdByUserId),
        variables
      );

      return { cachedIssueDetailsData, cachedTeamIssuesData, cachedUserIssuesData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.issues.getIssueDetail(variables.guid), context?.cachedIssueDetailsData);
      queryClient.setQueryData(
        queryKeys.issues.getIssues(variables.oldTeamSlug, undefined),
        context?.cachedTeamIssuesData
      );
      queryClient.setQueryData(
        queryKeys.issues.getIssues(undefined, variables.createdByUserId),
        context?.cachedUserIssuesData
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.issues.getIssueDetail(variables.guid));
      queryClient.invalidateQueries(queryKeys.issues.getIssues(variables.oldTeamSlug, undefined));
      queryClient.invalidateQueries(queryKeys.issues.getIssues(undefined, variables.createdByUserId));
      queryClient.invalidateQueries(queryKeys.issues.getIssueFeed(variables.guid));
    },
  });
};

async function optimisticallyUpdateIssueDetailsData(
  queryClient: QueryClient,
  queryKey: QueryKey,
  variables: Variables
) {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<IssueDto>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: IssueDto = {
      ...cachedData,
      assignedTeam: {
        name: variables.newTeamName,
        slug: variables.newTeamSlug,
      },
      description: variables.description,
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);
    return cachedData;
  }
}

async function optimisticallyUpdateIssuesData(queryClient: QueryClient, queryKey: QueryKey, variables: Variables) {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<GetIssuesDto>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: GetIssuesDto = {
      ...cachedData,
      issues: cachedData.issues.map((issue) => {
        if (issue.guid === variables.guid) {
          return {
            ...issue,
            assignedTeam: {
              name: variables.newTeamName,
              slug: variables.newTeamSlug,
            },
            description: variables.description,
          };
        }

        return issue;
      }),
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useEditIssue };
