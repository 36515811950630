import { MoreVertRounded } from "@mui/icons-material";
import { Box, Button, IconButton, Link, Menu, MenuItem, Stack, Typography, styled } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { CommentSubject, Links } from "../../constants";
import { useSession } from "../../hooks";
import { FeedItemDto, useDeleteComment, useEditComment } from "../../http";
import { useDestructiveConfirm } from "../../utilities";
import { Avatar } from "../Avatar";
import { TextEditor, TextViewer, isWhitespace } from "../textEditor";

const EditRow = styled(Stack)<{ $isReadonly: boolean; $isHovering: boolean; $isEditing: boolean }>`
  background-color: ${({ $isReadonly, $isHovering, $isEditing, theme }) =>
    ($isHovering || $isEditing) && !$isReadonly ? theme.palette.primary.transparent5 : "transparent"};
  transition: ${({ theme }) => theme.transitions.create("background-color")};
`;

interface IProps {
  subjectType: CommentSubject;
  subjectGuid: string;
  feedItem: FeedItemDto;
  onCommentEdited: (subjectGuid: string, commentGuid: string, lastModifiedUtc: DateTime, text: string) => void;
  onCommentDeleted: (commentGuid: string) => void;
}

const FeedComment = (props: IProps) => {
  const { userId } = useSession();
  const { mutate: editComment, isLoading: isSavingComment } = useEditComment();
  const { mutate: deleteComment, isLoading: isDeletingComment } = useDeleteComment();
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [editedCommentText, setEditedCommentText] = useState("");
  const confirm = useDestructiveConfirm();
  const isReadonly = props.feedItem.user.userId !== userId;

  async function handleEditClicked() {
    setMenuAnchor(null);
    setIsEditing(true);
    setEditedCommentText(props.feedItem.comment!.text as string);
  }

  async function handleDeleteClicked() {
    setMenuAnchor(null);

    try {
      await confirm({
        title: "Delete Comment",
        content: "Are you sure you want to delete this comment?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteComment(
      {
        subjectType: props.subjectType,
        subjectGuid: props.subjectGuid,
        commentGuid: props.feedItem.comment!.guid,
      },
      {
        onSuccess: () => {
          props.onCommentDeleted(props.feedItem.comment!.guid);
        },
      }
    );
  }

  function handleCancelEditClicked() {
    setIsEditing(false);
    setEditedCommentText("");
  }

  async function handleSaveEditClicked() {
    editComment(
      {
        subjectType: props.subjectType,
        subjectGuid: props.subjectGuid,
        commentGuid: props.feedItem.comment!.guid,
        text: editedCommentText,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
          setEditedCommentText("");
          props.onCommentEdited(props.subjectGuid, props.feedItem.comment!.guid, DateTime.utc(), editedCommentText);
        },
      }
    );
  }

  return (
    <EditRow
      $isReadonly={isReadonly}
      $isHovering={isHovering}
      $isEditing={isEditing}
      direction="row"
      spacing={1}
      sx={{
        px: { xs: 0.5, sm: 1 },
        py: isEditing ? 0.75 : 0.5,
        alignItems: "flex-start",
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Link href={Links.User(props.feedItem.user.userId)} underline="none">
        <Avatar sx={{ mt: "4px" }} user={props.feedItem.user} border />
      </Link>

      <Stack spacing={0.25} sx={{ minWidth: 0, width: "100%" }}>
        {!isEditing && (
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Stack
              direction="column"
              spacing={-0.25}
              sx={{
                minWidth: 0,
                alignItems: "baseline",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Link variant="body2" href={Links.User(props.feedItem.user.userId)} underline="none">
                {props.feedItem.user.displayName}
                {props.feedItem.user.enabled ? "" : " (deactivated)"}
              </Link>
              <Typography variant="caption" sx={{ minWidth: 0 }} noWrap>
                {DateTime.fromISO(props.feedItem.timestampUtc, { zone: "utc" })
                  .toLocal()
                  .toLocaleString(DateTime.DATETIME_FULL)}
              </Typography>
            </Stack>

            {!isReadonly && isHovering && (
              <>
                <IconButton
                  size="small"
                  sx={{ alignSelf: "center" }}
                  onClick={(e) => {
                    setMenuAnchor(e.currentTarget);
                  }}
                  disabled={isSavingComment || isDeletingComment}
                >
                  <MoreVertRounded />
                </IconButton>
                <Menu
                  anchorEl={menuAnchor}
                  open={Boolean(menuAnchor)}
                  onClose={() => {
                    setMenuAnchor(null);
                    setIsHovering(false);
                  }}
                >
                  <MenuItem onClick={handleEditClicked}>Edit</MenuItem>
                  <MenuItem onClick={handleDeleteClicked} sx={{ color: "error.main" }}>
                    Delete
                  </MenuItem>
                </Menu>
              </>
            )}
          </Stack>
        )}

        <Box>
          {isEditing ? (
            <TextEditor
              readonly={isSavingComment}
              initialValue={editedCommentText}
              onChange={(value) => setEditedCommentText(value)}
            />
          ) : (
            <TextViewer value={props.feedItem.comment!.text as string} />
          )}

          {!isEditing &&
            DateTime.fromISO(props.feedItem.timestampUtc, { zone: "utc" }).toMillis() !==
              DateTime.fromISO(props.feedItem.comment!.modifiedDateTimeUtc, { zone: "utc" }).toMillis() && (
              <Typography variant="caption">Edited</Typography>
            )}
        </Box>

        {isEditing && (
          <Stack direction="row" spacing={0.25} sx={{ justifyContent: "flex-end", pt: 0.25 }}>
            <Button variant="text" disabled={isSavingComment} onClick={handleCancelEditClicked}>
              Cancel
            </Button>
            <Button
              variant="tertiary"
              disabled={isSavingComment || isWhitespace(editedCommentText)}
              onClick={handleSaveEditClicked}
            >
              Save
            </Button>
          </Stack>
        )}
      </Stack>
    </EditRow>
  );
};

export { FeedComment };
