import { Box, FormHelperText, Stack, TextField } from "@mui/material";
import { Country, City, ICountry, ICity } from "country-state-city";
import { useEffect, useState } from "react";
import { ILocation } from "../../../../models";
import { WindowedAutocomplete } from "../../../../components";

interface IProps {
  value: ILocation | null;
  onChange: (location: ILocation | null) => void;
  error?: boolean;
  helperText?: string;
}

const LocationPicker = (props: IProps) => {
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);
  const [selectedCity, setSelectedCity] = useState<ICity | null>(null);

  useEffect(() => {
    if (props.value === null) {
      return;
    }

    if (props.value.countryCode) {
      setSelectedCountry(Country.getCountryByCode(props.value.countryCode) ?? null);
      if (props.value.cityName && props.value.stateCode) {
        var cities = City.getCitiesOfCountry(props.value.countryCode);
        setSelectedCity(
          cities?.find((x) => x.name === props.value!.cityName && x.stateCode === props.value!.stateCode) ?? null
        );
      }
    }
  }, [props.value]);

  function handleCountryChanged(e: React.SyntheticEvent, value: ICountry | null) {
    setSelectedCountry(value);
    setSelectedCity(null);

    if (value) {
      props.onChange({ countryCode: value.isoCode ?? null, stateCode: null, cityName: null });
    } else {
      props.onChange(null);
    }
  }

  function handleCityChanged(e: React.SyntheticEvent, value: ICity | null) {
    setSelectedCity(value);
    props.onChange({
      countryCode: selectedCountry?.isoCode ?? null,
      stateCode: value?.stateCode ?? null,
      cityName: value?.name ?? null,
    });
  }

  return (
    <Box>
      <Stack direction={{ xs: "column", lg: "row" }} spacing={1} width="100%">
        <WindowedAutocomplete
          options={Country.getAllCountries()}
          fullWidth
          autoHighlight
          renderInput={(params) => <TextField {...params} label="Country" error={props.error} />}
          value={selectedCountry}
          getOptionLabel={(option) => {
            return `${option.name}`;
          }}
          onChange={handleCountryChanged}
        />
        <WindowedAutocomplete
          options={City.getCitiesOfCountry(selectedCountry?.isoCode ?? "") ?? []}
          fullWidth
          autoHighlight
          disabled={selectedCountry === null}
          renderInput={(params) => <TextField {...params} label="City" error={props.error} />}
          getOptionLabel={(option) => {
            return `${option.name}, ${option.stateCode}`;
          }}
          onChange={handleCityChanged}
          value={selectedCity}
        />
      </Stack>
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </Box>
  );
};

export { LocationPicker };
