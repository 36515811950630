import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditActionItemSectionDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  fromPlanGuid?: string;
  toPlanGuid?: string;
  fromSectionGuid?: string;
  toSectionGuid?: string;
  fromActionsToDoTeamSlug?: string;
  toActionsToDoTeamSlug?: string;
  sortOrder?: string;
};

const useEditActionItemSectionRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editActionItemSectionRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditActionItemSectionDto = {
      fromSectionGuid: variables.fromSectionGuid,
      toSectionGuid: variables.toSectionGuid,
      fromActionsToDoTeamSlug: variables.fromActionsToDoTeamSlug,
      toActionsToDoTeamSlug: variables.toActionsToDoTeamSlug,
      sortOrder: variables.sortOrder,
    };

    const response = await fetch(`${configuration.apiRootUrl}/action-items/${variables.guid}/section`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editActionItemSectionRequest };
};

const useEditActionItemSection = () => {
  const { editActionItemSectionRequest } = useEditActionItemSectionRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editActionItemSectionRequest,
    onSuccess: async (_, variables) => {
      if (variables.fromPlanGuid) {
        await queryClient.refetchQueries({ queryKey: queryKeys.plans.getPlan(variables.fromPlanGuid) });
      }

      if (variables.toPlanGuid && variables.fromPlanGuid !== variables.toPlanGuid) {
        await queryClient.invalidateQueries({ queryKey: queryKeys.plans.getPlan(variables.toPlanGuid) });
      }

      if (variables.fromActionsToDoTeamSlug) {
        await queryClient.refetchQueries({
          queryKey: queryKeys.teams.getTeamActionItems(variables.fromActionsToDoTeamSlug),
        });
      }

      if (variables.toActionsToDoTeamSlug) {
        await queryClient.refetchQueries({
          queryKey: queryKeys.teams.getTeamActionItems(variables.toActionsToDoTeamSlug),
        });
      }

      await queryClient.refetchQueries({ queryKey: queryKeys.actionItems.getActionItemSummary(variables.guid) });
      await queryClient.refetchQueries({ queryKey: queryKeys.actionItems.getActionItemDetail(variables.guid) });
    },
  });
};

export { useEditActionItemSection };
