import { Links, Paths } from "../../constants";
import { NetworkGraphNodeType } from "../../models";
import { UserDto } from "./UserDto";

interface NodeDto {
  id: string;
  label: string;
  type: number;
  depth: number;
}

interface PeopleNodeDto extends NodeDto {
  image: string | null;
  email: string;
  roles: {
    parent:
      | {
          name: string;
          slug: string;
        }
      | undefined;
    guid: string;
    name: string;
    isPrimaryRole: boolean;
  }[];
}

interface TeamNodeDto extends NodeDto {
  parent:
    | {
        name: string;
        slug: string;
      }
    | undefined;
  description: string;
  members: UserDto[];
}

const getNodeUrl = (node: NodeDto) => {
  switch (node.type) {
    case NetworkGraphNodeType.RootTeam:
    case NetworkGraphNodeType.ParentTeam:
    case NetworkGraphNodeType.ChildTeam:
      return Links.Team(node.id);
    case NetworkGraphNodeType.Member:
      return Links.User(node.id);
    case NetworkGraphNodeType.Role:
      return Links.Role(node.id);
    default:
      return Paths.NotFound;
  }
};

const getNodeImage = (node: PeopleNodeDto) => {
  let image: HTMLImageElement | null = null;

  if (node.image != null) {
    image = new Image();
    image.src = `data:image/jpeg;base64,${node.image}`;
  }

  return image;
};

export { getNodeImage, getNodeUrl };
export type { NodeDto, PeopleNodeDto, TeamNodeDto };
