import { FormControlLabel, Link, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { Control, Controller, FormState } from "react-hook-form";
import { SmallInputLabel } from "../../../../components";
import { InfoRounded } from "@mui/icons-material";

interface ICreateEditResourceFormState {
  title: string;
  url: string;
  isEmbedded: boolean;
  sortOrder: string;
}

interface IProps {
  formState: FormState<ICreateEditResourceFormState>;
  control: Control<ICreateEditResourceFormState, any>;
}

const CreateEditResourceForm = (props: IProps) => {
  const {
    formState: { errors },
    control,
  } = props;

  return (
    <Stack spacing={1}>
      <Stack spacing={0.25}>
        <SmallInputLabel>Title *</SmallInputLabel>
        <Controller
          name="title"
          control={control}
          rules={{ required: { message: "Title is required", value: true } }}
          render={({ field }) => (
            <TextField
              {...field}
              error={errors["title"] !== undefined}
              helperText={errors["title"]?.message}
              inputProps={{ maxLength: 128 }}
            />
          )}
        />
      </Stack>
      <Stack spacing={0.25}>
        <SmallInputLabel>Embed Code or URL *</SmallInputLabel>
        <Controller
          name="url"
          control={control}
          rules={{ required: "Embed Code or URL is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              error={errors["url"] !== undefined}
              helperText={errors["url"]?.message}
              onFocus={(e) => e.target.setSelectionRange(0, e.target.value.length)}
              inputProps={{ maxLength: 512 }}
            />
          )}
        />
        <Stack direction="row" spacing={0.25} sx={{ alignItems: "center" }}>
          <InfoRounded fontSize="small" />
          <Typography variant="caption">
            Need help embedding a{" "}
            <Link
              href="https://docs.microsoft.com/en-us/power-bi/collaborate-share/service-embed-secure"
              target="_blank"
            >
              Power BI report
            </Link>
            {" or "}
            <Link
              href="https://support.microsoft.com/en-us/office/share-it-embed-an-excel-workbook-on-your-web-page-or-blog-from-onedrive-804e1845-5662-487e-9b38-f96307144081"
              target="_blank"
            >
              Excel workbook
            </Link>
            ?
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={0.25}>
        <Controller
          name="isEmbedded"
          control={control}
          render={({ field }) => (
            <RadioGroup value={field.value}>
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Display as link"
                onChange={() => field.onChange(false)}
              />
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Embed on page"
                onChange={() => field.onChange(true)}
              />
            </RadioGroup>
          )}
        />
      </Stack>
    </Stack>
  );
};

export { CreateEditResourceForm };
export type { ICreateEditResourceFormState };
