import { EditOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  Typography,
  TypographyVariant,
  alpha,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

interface IProps {
  isLoading: boolean;
  isEditable: boolean;
  value?: string;
  onChange?: (value: string) => void;
  variant?: TypographyVariant;
  sx?: SxProps;
}

const ItemDescriptionField = (props: IProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>();

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  function handleDescriptionChanged(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setIsEditing(false);
    if (props.onChange) props.onChange(e.target.value);
  }

  return (
    <>
      {props.isLoading ? (
        <Box sx={{ ...props.sx }}>
          <Skeleton height={47} />
        </Box>
      ) : (
        <Stack direction="row" sx={{ alignItems: "center", ...props.sx }} spacing={0.25}>
          <Box sx={{ flexGrow: 1 }}>
            {!isEditing && (
              <>
                {props.value == null || props.value.trim() === "" ? (
                  <Typography variant={props.variant ?? "h4"} sx={{ color: theme.palette.grey[300] }}>
                    Nothing here yet.
                  </Typography>
                ) : (
                  <Typography variant={props.variant ?? "h4"}>{props.value}</Typography>
                )}
              </>
            )}

            {isEditing && (
              <TextField
                inputRef={inputRef}
                placeholder="Enter item..."
                defaultValue={props.value}
                onBlur={handleDescriptionChanged}
                inputProps={{ maxLength: 512 }}
                multiline
              />
            )}
          </Box>

          {props.isEditable && !isEditing && (
            <IconButton
              size="small"
              sx={{ color: alpha(theme.palette.common.black, 0.3) }}
              onClick={() => setIsEditing(true)}
            >
              <EditOutlined />
            </IconButton>
          )}
        </Stack>
      )}
    </>
  );
};

export { ItemDescriptionField };
