import ErrorIcon from "@mui/icons-material/Error";
import {
  Box,
  CardHeader,
  Divider,
  Fade,
  keyframes,
  Link,
  Stack,
  styled,
  SxProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import { Links } from "../../constants";
import { IContextActionItemVersion } from "../../models";

const CardTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
  },
}));

const breathe = keyframes`
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% {opacity: 0.2; }
`;

const AnimatedIcon = styled(ErrorIcon)`
  animation: ${breathe} 3s ease-in-out infinite;
`;

interface IProps {
  previousDescription: string;
  updatedContext: IContextActionItemVersion;
  footer?: React.ReactNode;
  sx?: SxProps;
}

const ContextSyncAlert = (props: IProps) => {
  const theme = useTheme();

  return (
    <>
      <CardTooltip
        sx={{ background: "transparent" }}
        TransitionComponent={Fade}
        placement="bottom-start"
        leaveDelay={200}
        PopperProps={{
          onClick: (e) => {
            e.stopPropagation();
          },
        }}
        title={
          <>
            <CardHeader
              title={
                <Typography variant="h6">
                  {props.updatedContext.isDeleted ? "Context Item Deleted" : "Context Item Updated"}
                </Typography>
              }
            />
            <Divider />
            <Stack spacing={1} sx={{ p: 1 }}>
              <Stack spacing={0.25} sx={{ minWidth: 0, width: "100%" }}>
                <Stack
                  direction={{ xs: "column", lg: "row" }}
                  spacing={0.25}
                  sx={{
                    minWidth: 0,
                    alignItems: "baseline",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Link variant="body2" href={Links.User(props.updatedContext.modifiedByUser.userId)} underline="none">
                    {props.updatedContext.modifiedByUser.displayName}
                  </Link>
                  <Typography variant="caption" sx={{ minWidth: 0 }} noWrap>
                    {DateTime.fromISO(props.updatedContext.modifiedDateTimeUtc)
                      .toLocal()
                      .toLocaleString(DateTime.DATETIME_FULL)}
                  </Typography>
                </Stack>

                <Box>
                  {props.updatedContext.isDeleted ? (
                    <Typography>
                      {`Action item `}
                      <Box component="span" fontWeight={700}>
                        {props.updatedContext.description}
                      </Box>
                      {` has been removed.`}
                    </Typography>
                  ) : (
                    <Typography>
                      {`Action item has been updated to `}
                      <Box component="span" fontWeight={700}>
                        {props.updatedContext.description}
                      </Box>
                      {`.`}
                    </Typography>
                  )}
                </Box>
              </Stack>
              <Stack spacing={1}>
                <Typography>
                  {props.updatedContext.isDeleted
                    ? "Remove the context from this action item to ensure alignment."
                    : "Update the context to ensure alignment."}
                </Typography>
              </Stack>
            </Stack>
          </>
        }
      >
        <AnimatedIcon
          sx={{
            ...props.sx,
            color: props.updatedContext.isDeleted ? theme.palette.error.main : theme.palette.orange.main,
          }}
        />
      </CardTooltip>
    </>
  );
};

export { ContextSyncAlert };
