import { Grid, useTheme } from "@mui/material";
import { GetSurveyCoherenceReportResultDto } from "../../../../http";
import { useCoherenceReportContext } from "../CoherenceReportPage";
import { CoherenceScore } from "./CoherenceScore";

interface IProps {
  isLoading: boolean;
  report: GetSurveyCoherenceReportResultDto | undefined;
}

const CoherenceScores = (props: IProps) => {
  const theme = useTheme();
  const { selectedTeamLabel, comparisonTeamLabel } = useCoherenceReportContext();

  return (
    <Grid container spacing={{ xs: 0.5, md: 1 }} sx={{ width: "100%" }}>
      <Grid item xs={12} md={6}>
        <CoherenceScore
          label="Selected"
          color={theme.palette.primary.main}
          teamLabel={selectedTeamLabel ?? "Me"}
          isLoading={props.isLoading}
          coherenceScore={props.report?.selectedTeamsCoherenceSummary.coherenceScore}
          responseCount={props.report?.selectedTeamsCoherenceSummary.responseCount}
          responseRate={props.report?.selectedTeamsCoherenceSummary.responseRate}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {comparisonTeamLabel ? (
          <CoherenceScore
            label="Compare To"
            color={theme.palette.orange.main}
            teamLabel={comparisonTeamLabel ?? "-"}
            isLoading={props.isLoading}
            coherenceScore={props.report?.comparisonTeamsCoherenceSummary?.coherenceScore}
            responseCount={props.report?.comparisonTeamsCoherenceSummary?.responseCount}
            responseRate={props.report?.comparisonTeamsCoherenceSummary?.responseRate}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export { CoherenceScores };
