import { forwardRef, Ref } from "react";
import { BufferedTextField } from ".";

interface IProps {
  index: number;
  value: string;
  onFocus: () => void;
  onChange: (description: string, index: number) => void;
}

const EditListItem = forwardRef((props: IProps, ref: Ref<HTMLTextAreaElement>) => {
  return (
    <BufferedTextField
      inputRef={props.index === 0 ? ref : null}
      value={props.value}
      onFocus={props.onFocus}
      onChange={(e) => props.onChange(e.target.value, props.index)}
      fullWidth
      inputProps={{ maxLength: 512 }}
    />
  );
});

export { EditListItem };
