import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as HomeIconSvg } from "../../assets/svg/home-icon.svg";

const HomeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <HomeIconSvg fill="currentColor" />
    </SvgIcon>
  );
};

export { HomeIcon };
