import { Controller, FieldErrors, useForm } from "react-hook-form";
import { useParams } from "react-router";
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import {
  useGetSurvey,
  useCreateSurveyInstanceQuestionAnswer,
  useEditSurveyInstanceQuestionAnswer,
  useSubmitSurveyInstanceResponse,
  GetSurveyInstanceResponseDto,
} from "../../../../http";
import { QuestionType } from "../../../../constants";
import { GeneratedFormField } from "./GeneratedFormField";
import { ISurveyInstance } from "../../../../models";
import { v4 as newGuid } from "uuid";
import { useSession } from "../../../../hooks";
import { useRef } from "react";

interface IParams {
  guid: string;
}

interface AnswerProps {
  target: { value: string };
  answerGuid: string;
  questionGuid: string;
}

interface FormDataProps {
  [key: string]: string;
}

interface GetFormStateProps {
  response?: GetSurveyInstanceResponseDto | null;
}

interface IProps {
  instance: ISurveyInstance;
  response: GetSurveyInstanceResponseDto;
}

const PreviewStep = (props: IProps) => {
  const theme = useTheme();
  const fieldRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const { userId } = useSession();
  const { guid } = useParams<keyof IParams>() as IParams;
  const { data: survey, isLoading: isLoadingSurvey } = useGetSurvey(guid);
  const { mutate: createSurveyInstanceQuestionAnswer } = useCreateSurveyInstanceQuestionAnswer();
  const { mutate: editSurveyInstanceQuestionAnswer } = useEditSurveyInstanceQuestionAnswer();
  const { mutate: submitSurveyInstanceResponse } = useSubmitSurveyInstanceResponse();

  const unansweredRequiredQuestions = props.response.questions.filter(
    (x) => x.questionTypeId !== QuestionType.TitleAndDescription && x.isRequired && x.answer == null
  );

  const { control, handleSubmit, formState } = useForm({
    defaultValues: getFormState({ response: props.response }),
  });

  function getFormState({ response }: GetFormStateProps) {
    return response?.questions
      .filter((question) => question.answer && question.answer.value !== null)
      .reduce((acc: FormDataProps, question) => {
        acc[question.questionGuid] = question.answer.value;
        return acc;
      }, {});
  }

  function questionHasAnswer(questionGuid: string) {
    const question = props.response.questions.find((q) => q.questionGuid === questionGuid);
    if (question && question.answer != null) {
      return true;
    } else {
      return false;
    }
  }

  function handleSaveAnswer({ target, questionGuid, answerGuid }: AnswerProps) {
    if (questionHasAnswer(questionGuid)) {
      handleEditSurveyInstanceQuestionAnswer(target.value, answerGuid);
    } else {
      handleCreateSurveyInstanceQuestionAnswer(target.value, questionGuid);
    }
  }

  function handleCreateSurveyInstanceQuestionAnswer(value: string, questionGuid: string) {
    createSurveyInstanceQuestionAnswer({
      answerGuid: newGuid(),
      answerValue: value,
      questionGuid: questionGuid,
      responseGuid: props.response.responseGuid,
      instanceGuid: props.instance.guid,
    });
  }

  function handleEditSurveyInstanceQuestionAnswer(value: string, answerGuid: string) {
    editSurveyInstanceQuestionAnswer({
      answer: value,
      answerGuid: answerGuid,
      instanceGuid: props.instance.guid,
    });
  }

  function onValid(_data: any) {
    submitSurveyInstanceResponse({
      responseGuid: props.response.responseGuid,
      instanceGuid: props.instance.guid,
      shouldInvalidate: survey != null && !survey.administrators.some((admin) => admin.userId === userId),
    });
  }

  function onInvalid(errors: FieldErrors<FormData>) {
    scrollToFirstError(errors);
  }

  function scrollToFirstError(errors: FieldErrors<FormData>) {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField && fieldRefs.current[firstErrorField]) {
      fieldRefs.current[firstErrorField]?.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <>
      <CardHeader
        title={isLoadingSurvey ? <Skeleton width={250} /> : <Typography variant="h6">{survey?.name}</Typography>}
      />
      <Divider />
      <CardContent sx={{ flex: 1, overflow: "auto", p: 0 }}>
        {props.response.questions.length <= 0 ? (
          <Stack spacing={0.5} divider={<Divider />}>
            {[...Array(3)].map((_, i) => (
              <Stack key={i} spacing={0.5} sx={{ p: 1 }}>
                <Skeleton width={250} />
                <Skeleton width={"100%"} />
              </Stack>
            ))}
          </Stack>
        ) : props.response.submittedAtUtc != null ? (
          <Stack sx={{ px: 1, pt: 1 }}>
            <Typography>{props.instance.postSubmissionMessage}</Typography>
            <Divider variant="dashed" sx={{ my: 0.5 }} />
            <Typography variant="caption">You can close this browser window now.</Typography>
          </Stack>
        ) : (
          <Stack component="form" id="dynamicForm" onSubmit={handleSubmit(onValid, onInvalid)} divider={<Divider />}>
            {props.response.questions
              .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
              .map((element, index) => (
                <Stack
                  key={element.questionGuid}
                  ref={(el) => (fieldRefs.current[element.questionGuid] = el)}
                  sx={{
                    py: 1,
                    px: 1.5,
                    backgroundColor:
                      formState.errors[element.questionGuid] !== undefined
                        ? alpha(theme.palette.error.main, 0.1)
                        : index % 2
                        ? "unset"
                        : theme.palette.primary.opacity5,
                  }}
                >
                  <Controller
                    name={element.questionGuid}
                    control={control}
                    defaultValue=""
                    rules={{
                      required: { message: "* This question is required.", value: element.isRequired },
                    }}
                    render={({ field, fieldState }) => (
                      <GeneratedFormField
                        question={element}
                        handler={handleSaveAnswer}
                        field={field}
                        fieldState={fieldState}
                        theme={theme}
                      />
                    )}
                  />
                </Stack>
              ))}
          </Stack>
        )}
      </CardContent>
      {props.response.submittedAtUtc == null && (
        <>
          <Divider />
          <CardActions>
            <Stack direction="row" alignItems="baseline" justifyContent="space-between" sx={{ width: "100%" }}>
              {Object.keys(formState.errors).length > 0 && (
                <Typography color="error">
                  <span style={{ fontWeight: 700 }}>{unansweredRequiredQuestions.length}</span> required questions
                  remaining
                </Typography>
              )}

              <Box sx={{ flexGrow: 1 }}></Box>

              <Button variant="contained" type="submit" form="dynamicForm">
                Submit answers
              </Button>
            </Stack>
          </CardActions>
        </>
      )}
    </>
  );
};

export { PreviewStep };
