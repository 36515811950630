import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";

interface Props {
  readonly?: boolean;
}

const ReadonlyPlugin = (props: Props) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      editor.setEditable(!props.readonly ?? true);
    });
  }, [editor, props.readonly]);

  return null;
};

export { ReadonlyPlugin };
