import { Skeleton, Stack } from "@mui/material";

const LinearScaleResultSkeleton = () => {
  return (
    <Stack spacing={0.5}>
      <Skeleton variant="rectangular" height={25} />
      <Skeleton variant="rectangular" height={25} width="50%" />
      <Skeleton variant="rectangular" height={150} />
    </Stack>
  );
};

export { LinearScaleResultSkeleton };
