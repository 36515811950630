import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetSurveyCoherenceScoreOverTimeDto, GetSurveyCoherenceScoreOverTimeResultDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetCoherenceScoreOverTimeRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getCoherenceScoreOverTimeRequest({
    queryKey,
  }: QueryFunctionContext<
    ReturnType<typeof queryKeys.surveys.getCoherenceScoreOverTime>
  >): Promise<GetSurveyCoherenceScoreOverTimeResultDto> {
    const accessToken = await getAccessToken();
    const [{ selectedTeamGuids, comparisonTeamGuids }] = queryKey;
    const url = new URL(
      `${configuration.apiRootUrl}/surveys/${configuration.coherenceSurveyGuid}/coherence-report/graph`
    );

    const body: GetSurveyCoherenceScoreOverTimeDto = {
      selectedTeamGuids: selectedTeamGuids ?? [],
      comparisonTeamGuids: comparisonTeamGuids ?? [],
    };

    const response = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getCoherenceScoreOverTimeRequest };
};

const useGetCoherenceScoreOverTime = (
  selectedTeamGuids: string[] | undefined,
  comparisonTeamGuids: string[] | undefined
) => {
  const { getCoherenceScoreOverTimeRequest } = useGetCoherenceScoreOverTimeRequest();

  return useQuery({
    queryKey: queryKeys.surveys.getCoherenceScoreOverTime(selectedTeamGuids, comparisonTeamGuids),
    queryFn: getCoherenceScoreOverTimeRequest,
  });
};

export { useGetCoherenceScoreOverTime };
