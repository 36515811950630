import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { DeleteResourceDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  teamSlug: string;
  modifiedByUserId: string;
};

const useDeleteResourceRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteResourceRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: DeleteResourceDto = {
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/resources/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteResourceRequest };
};

const useDeleteResource = () => {
  const { deleteResourceRequest } = useDeleteResourceRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteResourceRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeam(variables.teamSlug) });
      await queryClient.refetchQueries({ queryKey: queryKeys.resources.getResourcesForTeam(variables.teamSlug) });
    },
  });
};

export { useDeleteResource };
