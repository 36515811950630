import { configuration } from "../configuration";
import {
  CreatePlanVersionResultDto,
  EditKeyPerformanceIndicatorsDto,
  EditPlanVersionDto,
  EditQuarterlyPrioritiesDto,
  ErrorsDto,
  GetAccountabilitiesDto,
  GetConnectionPointsDto,
  GetGoalDto,
  GetKeyPerformanceIndicatorsDto,
  GetMilestoneDto,
  GetPlansDto,
  GetPlanTextFieldDto,
  GetPlanValueDto,
  GetPlanVersionDto,
  GetPlanVersionsDto,
  GetQuarterlyPrioritiesDto,
  GetStrategyDto,
  GetUsersDto,
  GetUserSessionDto,
  HttpResponse,
} from "./types";
import { useAccessToken } from "./use-access-token";

const useHttp = () => {
  const { getAccessToken } = useAccessToken();

  async function parseResponse<T>(response: Response): Promise<HttpResponse<T>> {
    let result: HttpResponse<T> = {
      ok: response.ok,
      status: response.status,
      statusText: response.statusText,
    };

    try {
      result.data = await response.json();
    } catch {
      result.data = undefined;
    }

    return result;
  }

  async function getPlansRequest(): Promise<HttpResponse<GetPlansDto>> {
    const accessToken = await getAccessToken();

    var response = await fetch(`${configuration.apiRootUrl}/plans`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getPlanRequest(slug: string): Promise<HttpResponse<GetPlanVersionDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getPlanVersionRequest(slug: string, planVersionId: string): Promise<HttpResponse<GetPlanVersionDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/versions/${planVersionId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getPlanDiffRequest(slug: string): Promise<HttpResponse<GetPlanVersionDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/diff`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getPlanVersionDiffRequest(
    slug: string,
    planVersionId: string
  ): Promise<HttpResponse<GetPlanVersionDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/versions/${planVersionId}/diff`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getPlanVersionsRequest(slug: string): Promise<HttpResponse<GetPlanVersionsDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/versions`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function createPlanVersionRequest(
    userId: string | undefined,
    slug: string
  ): Promise<HttpResponse<CreatePlanVersionResultDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/versions`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        createdByUserId: userId,
      }),
    });

    return parseResponse(response);
  }

  async function editPlanVersionRequest(
    slug: string,
    planVersionId: string,
    plan: EditPlanVersionDto
  ): Promise<HttpResponse<ErrorsDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/versions/${planVersionId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(plan),
    });

    return parseResponse(response);
  }

  async function editPlanVersionNotesRequest(slug: string, planVersionId: string, notes: string): Promise<Response> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/versions/${planVersionId}/notes`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({
        notes,
      }),
    });

    return response;
  }

  async function deletePlanVersionRequest(
    slug: string,
    planVersionId: string,
    userId: string | undefined
  ): Promise<Response> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/versions/${planVersionId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
      body: JSON.stringify({
        userId,
      }),
    });

    return response;
  }

  async function publishPlanVersionRequest(slug: string, planVersionId: string, notes: string): Promise<Response> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/versions/${planVersionId}/publish`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ notes }),
    });

    return response;
  }

  async function getUsersRequest(): Promise<HttpResponse<GetUsersDto>> {
    const accessToken = await getAccessToken();

    var response = await fetch(`${configuration.apiRootUrl}/users`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getUserSessionRequest(azureAdUserId: string): Promise<HttpResponse<GetUserSessionDto>> {
    const accessToken = await getAccessToken();

    var response = await fetch(`${configuration.apiRootUrl}/sessions/${azureAdUserId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getQuarterlyPrioritiesRequest(
    userId: string | undefined
  ): Promise<HttpResponse<GetQuarterlyPrioritiesDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/users/${userId}/quarterly-priorities`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getAccountabilitiesRequest(userId: string | undefined): Promise<HttpResponse<GetAccountabilitiesDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/users/${userId}/accountabilities`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getKeyPerformanceIndicatorsRequest(
    userId: string | undefined
  ): Promise<HttpResponse<GetKeyPerformanceIndicatorsDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/users/${userId}/key-performance-indicators`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function editQuarterlyPrioritiesRequest(quarterlyPriorities: EditQuarterlyPrioritiesDto): Promise<Response> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/quarterly-priorities`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(quarterlyPriorities),
    });

    return response;
  }

  async function editKeyPerformanceIndicatorsRequest(
    keyPerformanceIndicators: EditKeyPerformanceIndicatorsDto
  ): Promise<Response> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/key-performance-indicators`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(keyPerformanceIndicators),
    });

    return response;
  }

  async function getPlanPurposeRequest(slug: string): Promise<HttpResponse<GetPlanTextFieldDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/purpose`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getPlanValueRequest(slug: string, guid: string): Promise<HttpResponse<GetPlanValueDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/values/${guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getPlanMissionRequest(slug: string): Promise<HttpResponse<GetPlanTextFieldDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/mission`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getMilestoneRequest(slug: string, milestoneGuid: string): Promise<HttpResponse<GetMilestoneDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/milestones/${milestoneGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getGoalRequest(slug: string, goalGuid: string): Promise<HttpResponse<GetGoalDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/goals/${goalGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getConnectionPointsRequest(slug: string): Promise<HttpResponse<GetConnectionPointsDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/connection-points`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  async function getStrategyRequest(slug: string, strategyGuid: string): Promise<HttpResponse<GetStrategyDto>> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/strategies/${strategyGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  return {
    getPlansRequest,
    getPlanRequest,
    getPlanDiffRequest,
    getPlanVersionRequest,
    getPlanVersionDiffRequest,
    getPlanVersionsRequest,
    createPlanVersionRequest,
    editPlanVersionRequest,
    deletePlanVersionRequest,
    editPlanVersionNotesRequest,
    publishPlanVersionRequest,
    getUsersRequest,
    getUserSessionRequest,
    getAccountabilitiesRequest,
    getKeyPerformanceIndicatorsRequest,
    editQuarterlyPrioritiesRequest,
    editKeyPerformanceIndicatorsRequest,
    getPlanPurposeRequest,
    getPlanValueRequest,
    getPlanMissionRequest,
    getMilestoneRequest,
    getGoalRequest,
    getQuarterlyPrioritiesRequest,
    getConnectionPointsRequest,
    getStrategyRequest,
  };
};

export { useHttp };
