import { SettingsRounded } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router";
import { Links } from "../../../../constants";
import { useBreakpoints, useSession } from "../../../../hooks";
import { useUserPageLayoutContext } from "../UserPageLayout";

const UserActions = () => {
  const { user } = useUserPageLayoutContext();
  const { userId } = useSession();
  const { isMdUp } = useBreakpoints();
  const navigate = useNavigate();

  if (user === undefined) {
    return null;
  }

  return (
    <>
      {user.userId === userId &&
        (isMdUp ? (
          <Button startIcon={<SettingsRounded />} variant="outlined" onClick={(e) => navigate(Links.UserSettings())}>
            Settings
          </Button>
        ) : (
          <IconButton color="primary" onClick={(e) => navigate(Links.UserSettings())}>
            <SettingsRounded />
          </IconButton>
        ))}
    </>
  );
};

export { UserActions };
