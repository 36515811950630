class Result<TSuccess, TError> {
  private isSuccessResult: boolean;
  private successResult?: TSuccess;
  private errorResult?: TError;

  private constructor(isSuccessResult: boolean, successResult?: TSuccess, errorResult?: TError) {
    this.isSuccessResult = isSuccessResult;
    this.successResult = successResult;
    this.errorResult = errorResult;
  }

  static success<TSuccess, TError>(successResult: TSuccess) {
    return new Result<TSuccess, TError>(true, successResult, undefined);
  }

  static error<TSuccess, TError>(errorResult: TError) {
    return new Result<TSuccess, TError>(false, undefined, errorResult);
  }

  isSuccess() {
    return this.isSuccessResult;
  }

  match(onSuccess: (result: TSuccess) => void, onError: (result: TError) => void) {
    return this.isSuccessResult ? onSuccess(this.successResult!) : onError(this.errorResult!);
  }
}

export { Result };
