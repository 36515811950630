export * from "./links";
export * from "./paths";

export const DEFAULT_TITLE = "CODE";
export const DEBOUNCE_TIMEOUT_MS = 2000;
export const DEFAULT_DRAWER_WIDTH = 400;

export enum SearchFilter {
  All = "all",
  Teams = "teams",
  Roles = "roles",
  People = "people",
}

export enum SearchResultType {
  Team,
  Role,
  User,
}

export enum SearchResultChipType {
  Team,
  Role,
  User,
}

export enum PlanParentType {
  Template,
  Team,
  User,
}

export enum ConnectObjectType {
  Team = "Team",
  Role = "Role",
  PrimaryRole = "Primary Role",
  Person = "Person",
  QuarterlyPriority = "Personal Priority",
  Metric = "Critical Metric",
}

export enum PlanFieldType {
  Priority = "Priority",
  Purpose = "Purpose",
  Mission = "Mission",
  Value = "Value",
  Milestone = "Milestone",
  Goal = "Goal",
  Strategy = "Area of Focus",
}

export enum TeamTab {
  Overview = "Overview",
  StrategicMap = "Strategic Map",
  Issues = "Issues",
  Metrics = "Metrics",
  Resources = "Resources",
}

export enum UserTab {
  Overview = "Overview",
  Map = "Map",
}

export enum SurveyTab {
  Questions = "Questions",
  Responses = "Responses",
  Actions = "Follow up actions",
}

export const GRAPH_COLORS = [
  "#124143",
  "#1E6131",
  "#198590",
  "#33C1E0",
  "#DADEE0",
  "#F4D172",
  "#F98E15",
  "#7D1911",
  "#4A0F0A",
];

export enum SystemStatus {
  "Created" = 1,
  "Edited" = 2,
  "Deleted" = 3,
  "Archived" = 4,
}

export enum DiffStatus {
  "Deleted" = 1,
  "Edited" = 2,
  "Added" = 3,
}

export enum ActionItemStatus {
  "OnTrack" = 1,
  "AtRisk" = 2,
  "OffTrack" = 3,
  "Closed" = 4,
  "Archived" = 5,
  "Open" = 6,
}

export enum ActionItemPropertyType {
  Status = "status",
  Ownership = "ownership",
  Notes = "notes",
  SupportingItems = "supportingItems",
}

export enum SupportingItemType {
  ActionItem = "actionItem",
  Issue = "issue",
}

export enum ConnectionType {
  "Strategy" = 0,
  "KeyInitiative" = 1,
}

export enum ConnectionState {
  "InSync" = 0,
  "OutOfSync" = 1,
  "Deleted" = 2,
}

export interface ISystemStatus {
  id: number;
  name: string;
}

export interface IStatus {
  id: number;
  name: string;
  color: string;
}

export enum SurveyStatus {
  Active = "Active",
  NotActive = "Not Active",
  Completed = "Completed",
}

export enum CommentType {
  "HistoryLedgerItem" = 0,
  "Comment" = 1,
}

export enum CommentSubject {
  "Comment" = 1,
  "Team" = 2,
  "User" = 3,
  "Role" = 4,
  "PlanField" = 5,
  "PlanPurpose" = 6,
  "PlanValue" = 7,
  "PlanMission" = 8,
  "PlanMilestone" = 9,
  "PlanGoal" = 10,
  "PlanStrategy" = 11,
  "PlanKeyInitiative" = 12,
  "PlanMetric" = 13,
  "ActionItem" = 14,
  "Issue" = 15,
}

export enum TagType {
  Role = "role",
  SurveyQuestion = "surveyQuestion",
}

export const SYSTEM_STATUSES: ISystemStatus[] = [
  { id: SystemStatus.Created, name: "Added" },
  { id: SystemStatus.Edited, name: "Updated" },
  { id: SystemStatus.Deleted, name: "Removed" },
];

export const ACTION_ITEM_STATUSES: IStatus[] = [
  { id: ActionItemStatus.Open, name: "Open", color: "#C9C9C9" },
  { id: ActionItemStatus.OnTrack, name: "On Track", color: "#45A324" },
  { id: ActionItemStatus.AtRisk, name: "At Risk", color: "#F2994A" },
  { id: ActionItemStatus.OffTrack, name: "Off Track", color: "#E02626" },
  { id: ActionItemStatus.Closed, name: "Closed", color: "#45A324" },
];

export enum QuestionType {
  Text = 1,
  LinearScale = 2,
  TitleAndDescription = 3,
}

export const DEFAULT_ACTION_ITEM_STATUS = ActionItemStatus.Open;

export enum FeedItemType {
  PropertyChange = 1,
  Comment = 2,
}

export enum FeedItemProperty {
  Description = "description",
  ActionItemStatus = "actionItemStatus",
  IssueStatus = "issueStatus",
  ActionItemOwnership = "actionItemOwnership",
  ActionItemNote = "actionItemNote",
  Context = "context",
}
