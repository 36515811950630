import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { IOpenRole } from "../../../models";
import { FetchError, GetOpenRolesDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetOpenRolesRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getOpenRolesRequest(): Promise<GetOpenRolesDto> {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/open-roles`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getOpenRolesRequest };
};

const useGetOpenRoles = () => {
  const { getOpenRolesRequest } = useGetOpenRolesRequest();

  return useQuery({
    queryKey: queryKeys.roles.getOpenRoles(),
    queryFn: getOpenRolesRequest,
    select: (data): IOpenRole[] => {
      return data.roles
        .map((x) => ({
          guid: x.guid,
          parentTeam: x.parentTeam,
          name: x.name,
          description: x.description,
          isOpenLastModifiedUtc: DateTime.fromISO(x.isOpenLastModifiedUtc, { zone: "utc" }),
        }))
        .sort((a, b) => b.isOpenLastModifiedUtc.toUnixInteger() - a.isOpenLastModifiedUtc.toUnixInteger());
    },
  });
};

export { useGetOpenRoles };
