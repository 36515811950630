import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { CreateTeamDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  name: string;
  slug: string;
  parentTeamSlug: string | undefined;
  description: string;
  createdByUserId: string;
};

const useCreateTeamRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createTeamRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: CreateTeamDto = {
      name: variables.name,
      slug: variables.slug,
      parentTeamSlug: variables.parentTeamSlug,
      description: variables.description,
      createdByUserId: variables.createdByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/teams`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response;
  }

  return { createTeamRequest };
};

const useCreateTeam = () => {
  const { createTeamRequest } = useCreateTeamRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createTeamRequest,
    onSuccess: async (_, variables) => {
      if (variables.parentTeamSlug !== undefined) {
        await queryClient.removeQueries({ queryKey: queryKeys.teams.getTeamNetworkGraph(variables.parentTeamSlug) });
        await queryClient.removeQueries({ queryKey: queryKeys.teams.getTeamNetworkList(variables.parentTeamSlug) });
      }

      await queryClient.removeQueries({ queryKey: queryKeys.teams.getTeams() });
    },
  });
};

export { useCreateTeam };
