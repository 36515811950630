import { $generateHtmlFromNodes } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW } from "lexical";
import { useEffect } from "react";

interface Props {
  enabled?: boolean;
  onBlur: (value: string) => void;
}

const HtmlOnBlurPlugin = (props: Props) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          if (props.enabled ?? true) {
            props.onBlur($generateHtmlFromNodes(editor));
          }

          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, props]);

  return null;
};

export { HtmlOnBlurPlugin };
