import { BarChartRounded, CheckRounded, CloseRounded, FilterListOffRounded, IosShare } from "@mui/icons-material";
import { Button, Card, CardContent, Link, Stack } from "@mui/material";
import { DataGridPro, GridColDef, GridColumnVisibilityModel, useGridApiRef } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import {
  ColumnFiltersButton,
  ColumnVisibilityButton,
  IconLink,
  TeamFilterButton,
  ToolbarLayout,
  Typography,
} from "../../../components";
import { Links, Paths } from "../../../constants";
import { useGetOrganizationReport } from "../../../http";
import { OrganizationReportRow } from "../../../models";
import { setTitlePrefix } from "../../../utilities";
import { OrganizationReportResultsSummary } from "./components";
import { useBreakpoints } from "../../../hooks";

const hiddenColumns = ["teamId", "teamSlug"];
const alwaysVisibleColumns = ["teamName", "roleName", "personName", "personEmail"];

const defaultColumnVisibilityModel: GridColumnVisibilityModel = {
  teamId: false,
  teamName: true,
  teamSlug: false,
  connectedToTeam: false,
  roleName: true,
  roleTags: false,
  personName: true,
  personEmail: true,
  personPrimaryRole: false,
  personLocation: false,
  personDateJoined: false,
};

const OrganizationReportPage = () => {
  setTitlePrefix("Organization Report");
  const { isMdUp } = useBreakpoints();

  const columns: GridColDef[] = [
    { field: "teamId", headerName: "Team ID" },
    {
      field: "teamName",
      headerName: "Team Name",
      flex: isMdUp ? 1 : 0,
      renderCell: (params) => {
        const row = params.row as OrganizationReportRow;
        return (
          <Link href={Links.Team(row.teamSlug!)} target="_blank">
            {params.value}
          </Link>
        );
      },
    },
    { field: "teamSlug", headerName: "Team Slug" },
    { field: "connectedToTeam", headerName: "Connected to Team", flex: isMdUp ? 1 : 0 },
    {
      field: "roleName",
      headerName: "Role Name",
      flex: isMdUp ? 1 : 0,
      renderCell: (params) => {
        const row = params.row as OrganizationReportRow;
        return (
          <Link href={Links.RoleDetail(row.roleId!)} target="_blank">
            {params.value}
          </Link>
        );
      },
    },
    { field: "roleTags", headerName: "Role Tags", flex: isMdUp ? 1 : 0 },
    {
      field: "personName",
      headerName: "Person Name",
      flex: isMdUp ? 1 : 0,
      renderCell: (params) => {
        const row = params.row as OrganizationReportRow;
        return (
          <Link href={Links.User(row.personId)} target="_blank">
            {params.value}
          </Link>
        );
      },
    },
    { field: "personEmail", headerName: "Person Email", flex: isMdUp ? 1 : 0 },
    {
      field: "personPrimaryRole",
      headerName: "Person Primary Role",
      flex: isMdUp ? 1 : 0,
      renderCell: (params) => {
        const row = params.row as OrganizationReportRow;
        return row.personPrimaryRole ? <CheckRounded /> : <CloseRounded />;
      },
      headerAlign: "center",
      align: "center",
    },
    { field: "personLocation", headerName: "Person Location", flex: isMdUp ? 1 : 0 },
    {
      field: "personDateJoined",
      headerName: "Person Date Joined",
      flex: isMdUp ? 1 : 0,
      valueFormatter: ({ value }) => (value == null ? "" : DateTime.fromISO(value).toLocaleString(DateTime.DATE_FULL)),
    },
  ];

  const { isLoading, data: rows } = useGetOrganizationReport(true, true, true, true);
  const apiRef = useGridApiRef();

  function handleClearFiltersClicked() {
    // Clear team and column filters
    apiRef.current.setFilterModel({
      items: [],
    });
  }

  function handleExportClicked() {
    apiRef.current.exportDataAsCsv({
      fileName: `organization-report-${DateTime.now().toISODate()}`,
    });
  }

  return (
    <ToolbarLayout
      title={
        <Stack>
          <IconLink href={Paths.Reports} startIcon={<BarChartRounded fontSize="small" />}>
            Reports
          </IconLink>
          <Typography variant="h3">Organization Report</Typography>
          <Typography>
            Report on organizational structure, including teams, roles, people and all associated data.
          </Typography>
        </Stack>
      }
      toolbar={
        <Stack spacing={0.5} direction={{ xs: "column", md: "row" }} justifyContent="space-between" width="100%">
          <Stack direction={{ xs: "column", sm: "row" }} spacing={0.5}>
            <TeamFilterButton apiRef={apiRef} />
            <ColumnVisibilityButton
              apiRef={apiRef}
              columns={columns}
              hiddenColumns={hiddenColumns}
              alwaysVisibleColumns={alwaysVisibleColumns}
            />
            <ColumnFiltersButton apiRef={apiRef} columns={columns} hiddenColumns={hiddenColumns} />
          </Stack>
          <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }}>
            <Button
              variant="tertiary"
              startIcon={<FilterListOffRounded />}
              onClick={handleClearFiltersClicked}
              sx={{ width: { xs: "100%", sm: "inherit" } }}
            >
              Clear Filters
            </Button>
            <Button
              variant="tertiary"
              startIcon={<IosShare />}
              onClick={handleExportClicked}
              sx={{ width: { xs: "100%", sm: "inherit" } }}
            >
              Export CSV
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Stack spacing={{ xs: 0.5, sm: 1 }}>
        <Card>
          <CardContent>
            <OrganizationReportResultsSummary isLoading={isLoading} apiRef={apiRef} rows={rows} />
          </CardContent>
        </Card>
        <Card>
          {/* 359px offsets the height of the content above the data grid */}
          <CardContent sx={{ height: "calc(100vh - 359px)" }}>
            <DataGridPro
              apiRef={apiRef}
              columns={columns}
              rows={rows ?? []}
              loading={isLoading}
              disableColumnMenu
              disableColumnFilter
              disableRowSelectionOnClick
              initialState={{
                columns: {
                  columnVisibilityModel: defaultColumnVisibilityModel,
                },
              }}
              sx={{
                ".MuiDataGrid-cell": {
                  fontWeight: "normal",
                },
              }}
            />
          </CardContent>
        </Card>
      </Stack>
    </ToolbarLayout>
  );
};

export { OrganizationReportPage };
