import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { DeleteActionItemDto, FetchError, GetActionItemDto, GetPlanDto, GetTeamActionItemsDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  modifiedByUserId: string;
  planGuid?: string;
  teamSlug?: string;
};

const useDeleteActionItemRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteActionItemRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: DeleteActionItemDto = {
      modifiedByUserId: variables.modifiedByUserId,
    };
    const response = await fetch(`${configuration.apiRootUrl}/action-items/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteActionItemRequest };
};

const useDeleteActionItem = () => {
  const { deleteActionItemRequest } = useDeleteActionItemRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteActionItemRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));

      // Snapshot the current cached values
      const cachedPlanData = variables.planGuid ? optimisticallyUpdatePlanData(queryClient, variables) : null;
      const cachedTeamData = variables.teamSlug ? optimisticallyUpdateTeamData(queryClient, variables) : null;
      const cachedActionItemDetailData = queryClient.getQueryData<GetActionItemDto>(
        queryKeys.actionItems.getActionItemDetail(variables.guid)
      );

      queryClient.removeQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));

      return { cachedPlanData, cachedTeamData, cachedActionItemDetailData };
    },
    onError: (_error, variables, context) => {
      if (variables.planGuid && context?.cachedPlanData) {
        queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), context?.cachedPlanData);
      }

      if (variables.teamSlug && context?.cachedTeamData) {
        queryClient.setQueryData(queryKeys.teams.getTeamActionItems(variables.teamSlug), context?.cachedTeamData);
      }

      queryClient.setQueryData(
        queryKeys.actionItems.getActionItemDetail(variables.guid),
        context?.cachedActionItemDetailData
      );
    },
    onSettled: (_data, _error, variables) => {
      if (variables.planGuid) {
        queryClient.invalidateQueries(queryKeys.plans.getPlan(variables.planGuid));
      }

      if (variables.teamSlug) {
        queryClient.invalidateQueries(queryKeys.teams.getTeamActionItems(variables.teamSlug));
      }
    },
  });
};

async function optimisticallyUpdatePlanData(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.plans.getPlan(variables.planGuid!));
  const cachedData = queryClient.getQueryData<GetPlanDto>(queryKeys.plans.getPlan(variables.planGuid!));

  if (cachedData) {
    const optimisticallyUpdatedData: GetPlanDto = {
      ...cachedData,
      blocks: cachedData.blocks.map((block) => ({
        ...block,
        sections: block.sections.map((section) => ({
          ...section,
          actionItems: section.actionItems.filter((actionItem) => actionItem.guid !== variables.guid),
        })),
      })),
    };

    queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid!), optimisticallyUpdatedData);
    return cachedData;
  }
}

async function optimisticallyUpdateTeamData(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.teams.getTeamActionItems(variables.teamSlug));
  const cachedData = queryClient.getQueryData<GetTeamActionItemsDto>(
    queryKeys.teams.getTeamActionItems(variables.teamSlug)
  );

  if (cachedData) {
    const optimisticallyUpdatedData: GetTeamActionItemsDto = {
      ...cachedData,
      actionItems: cachedData.actionItems.filter((actionItem) => actionItem.guid !== variables.guid),
    };

    queryClient.setQueryData(queryKeys.teams.getTeamActionItems(variables.teamSlug), optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useDeleteActionItem };
