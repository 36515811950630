import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { ITeam } from "../../../models";
import { FetchError, GetTeamDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetTeamRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getTeamRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.teams.getTeam>>): Promise<GetTeamDto> {
    const accessToken = await getAccessToken();
    const [{ slug, userId }] = queryKey;
    const url = new URL(`${configuration.apiRootUrl}/teams/${slug}`);

    if (userId) {
      url.searchParams.set("userId", userId);
    }

    const response = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getTeamRequest };
};

const useGetTeam = (slug: string | undefined, userId?: string) => {
  const { getTeamRequest } = useGetTeamRequest();

  return useQuery({
    queryKey: queryKeys.teams.getTeam(slug, userId),
    queryFn: getTeamRequest,
    select: (data): ITeam => {
      return {
        ...data,
        historyLedger: data.historyLedger.map((x) => ({
          ...x,
          timestampUtc: DateTime.fromISO(x.timestampUtc, { zone: "utc" }),
        })),
      };
    },
    enabled: slug !== undefined,
  });
};

export { useGetTeam };
