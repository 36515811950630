import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditTeamDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  name: string;
  oldSlug: string;
  newSlug: string;
  oldParentTeamSlug?: string;
  newParentTeamSlug?: string;
  description: string;
  accountabilities: string;
  boundaries: string;
  communicationChannels: string;
  otherInformation: string;
  administratorUserIds: string[];
  modifiedByUserId: string;
};

const useEditTeamRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editTeamRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditTeamDto = {
      name: variables.name,
      newSlug: variables.newSlug,
      parentTeamSlug: variables.newParentTeamSlug,
      description: variables.description,
      accountabilities: variables.accountabilities,
      boundaries: variables.boundaries,
      communicationChannels: variables.communicationChannels,
      otherInformation: variables.otherInformation,
      administratorUserIds: variables.administratorUserIds,
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/teams/${variables.oldSlug}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editTeamRequest };
};

const useEditTeam = () => {
  const { editTeamRequest } = useEditTeamRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editTeamRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeams(variables.modifiedByUserId) });
      await queryClient.removeQueries({ queryKey: queryKeys.teams.getTeam(variables.oldSlug) });
      await queryClient.removeQueries({
        queryKey: queryKeys.teams.getTeam(variables.oldSlug, variables.modifiedByUserId),
      });
      await queryClient.removeQueries({ queryKey: queryKeys.teams.getTeamNetworkList(variables.oldSlug) });
      await queryClient.removeQueries({ queryKey: queryKeys.teams.getTeamNetworkGraph(variables.oldSlug) });

      if (variables.newParentTeamSlug !== undefined) {
        await queryClient.removeQueries({ queryKey: queryKeys.teams.getTeamNetworkList(variables.newParentTeamSlug) });
        await queryClient.removeQueries({
          queryKey: queryKeys.teams.getTeamNetworkGraph(variables.newParentTeamSlug),
        });
      }

      if (variables.oldParentTeamSlug !== undefined) {
        await queryClient.removeQueries({ queryKey: queryKeys.teams.getTeamNetworkList(variables.oldParentTeamSlug) });
        await queryClient.removeQueries({
          queryKey: queryKeys.teams.getTeamNetworkGraph(variables.oldParentTeamSlug),
        });
      }
    },
  });
};

export { useEditTeam };
