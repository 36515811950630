import { Stack, TextField } from "@mui/material";
import { Control, Controller, FormState } from "react-hook-form";
import { SmallInputLabel } from "..";

interface ICreateEditBlockFormState {
  name: string;
  hintText: string;
}

interface IProps {
  formState: FormState<ICreateEditBlockFormState>;
  control: Control<ICreateEditBlockFormState, any>;
}

const CreateEditBlockForm = (props: IProps) => {
  const {
    formState: { errors },
    control,
  } = props;

  return (
    <Stack spacing={1}>
      <Stack spacing={0.25}>
        <SmallInputLabel>Name *</SmallInputLabel>
        <Controller
          name="name"
          control={control}
          rules={{ required: { message: "Block name is required", value: true } }}
          render={({ field }) => (
            <TextField
              {...field}
              error={errors["name"] !== undefined}
              helperText={errors["name"]?.message}
              inputProps={{ maxLength: 64 }}
            />
          )}
        />
      </Stack>
      <Stack spacing={0.25}>
        <SmallInputLabel>Hint Text</SmallInputLabel>
        <Controller
          name="hintText"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onFocus={(e) => e.target.setSelectionRange(0, e.target.value.length)}
              multiline
              inputProps={{ maxLength: 1024 }}
            />
          )}
        />
      </Stack>
    </Stack>
  );
};

export { CreateEditBlockForm };
export type { ICreateEditBlockFormState };
