import { SxProps } from "@mui/material";
import { IUser } from "../models";
import { Avatar } from "./Avatar";

interface IProps {
  user?: IUser;
  tooltip?: boolean;
  border?: boolean;
  sx?: SxProps;
}

const LargeAvatar = (props: IProps) => {
  return (
    <Avatar
      user={props.user}
      border
      sx={{
        height: "54px",
        width: "54px",
        borderWidth: "3px",
        "&:before": {
          borderWidth: "3px",
        },
      }}
    />
  );
};

export { LargeAvatar };
