import { useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { ITreeNode } from "../../../models";
import { FetchError, GetTeamsTreeDto, GetTeamsTreeNodeDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetTeamsTreeRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getTeamsTreeRequest(): Promise<GetTeamsTreeDto> {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/teams/tree`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getTeamsTreeRequest };
};

const useGetTeamsTree = () => {
  const { getTeamsTreeRequest } = useGetTeamsTreeRequest();

  return useQuery({
    queryKey: queryKeys.teams.getTeamsTree(),
    queryFn: getTeamsTreeRequest,
    select: (data): ITreeNode[] => flattenTeamTree(null, data.teams),
  });
};

function flattenTeamTree(parentGuid: string | null, nodes: GetTeamsTreeNodeDto[]): ITreeNode[] {
  return nodes.flatMap((x): ITreeNode[] => [
    {
      nodeId: x.guid,
      label: x.name,
      depth: x.depth,
      parentNodeId: parentGuid,
      childrenNodeIds: x.connectedTeams.map((y) => y.guid),
    },
    ...flattenTeamTree(x.guid, x.connectedTeams),
  ]);
}

export { useGetTeamsTree };
