import { Typography } from "@mui/material";
import { EditHintCard } from "../EditHintCard";

const ValueHintCard = () => {
  return (
    <EditHintCard title="Value">
      <Typography variant="body1">
        A person's principles or standards of behavior; one's judgment of what is important in life. Values serve as
        broad guidelines in all situations.
      </Typography>
      <Typography variant="body1">What beliefs drive our behavior and decisions?</Typography>
    </EditHintCard>
  );
};

export { ValueHintCard };
