interface Configuration {
  environment: "local" | "int" | "prod";
  apiRootUrl: string;
  appInsightsInstrumentationKey: string;
  googleAnalyticsMeasurementId: string;
  webRootUrl: string;
  coherenceSurveyGuid: string;
}

const defaultConfiguration: Configuration = {
  environment: "local",
  apiRootUrl: "https://localhost:7071",
  appInsightsInstrumentationKey: "9c9b4e31-b656-48b1-ab32-6438ad4d0e13",
  googleAnalyticsMeasurementId: "G-DN492CM8TM",
  webRootUrl: "http://localhost:3000",
  coherenceSurveyGuid: "BE2604FC-2D2A-47DD-9A28-B1538C6A73D9",
};

const configuration: Configuration = {
  ...defaultConfiguration,
  ...(window as any).CHRYSALIS_CONFIG,
};

export { configuration };
