import React, { ReactNode, useContext, useState } from "react";
import { Dialog, DialogTitle } from "@mui/material";

interface IProps {
  children: React.ReactNode;
}

interface IDialogOptions {
  title?: string | React.ReactNode;
  content: React.ReactNode;
}
interface IDialogContext {
  showDialog: (options: IDialogOptions) => void;
  closeDialog: () => void;
}

const DialogContext = React.createContext<IDialogContext | undefined>(undefined);

const DialogProvider = (props: IProps) => {
  const [dialog, setDialog] = useState<ReactNode>(undefined);

  function closeDialog() {
    setDialog(undefined);
  }

  function showDialog(options: IDialogOptions) {
    if (dialog !== undefined) {
      throw new Error("showDialog can not be called when another dialog is being displayed.");
    }

    setDialog(
      <Dialog open={true} onClose={() => closeDialog()}>
        {options.title && <DialogTitle>{options.title}</DialogTitle>}
        {options.content}
      </Dialog>
    );
  }

  return (
    <DialogContext.Provider
      value={{
        showDialog,
        closeDialog,
      }}
    >
      {dialog}
      {props.children}
    </DialogContext.Provider>
  );
};

const useDialog = () => {
  const context = useContext(DialogContext);

  if (context === undefined) {
    throw new Error("DialogContext is not initialized.");
  }

  return context;
};

export { DialogContext, DialogProvider, useDialog };
export type { IDialogContext };
