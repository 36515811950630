import { $generateHtmlFromNodes } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";

interface Props {
  onChange: (value: string) => void;
}

const HtmlOnChangePlugin = (props: Props) => {
  const [editor] = useLexicalComposerContext();

  return (
    <OnChangePlugin
      onChange={(editorState) => {
        editorState.read(() => {
          props.onChange($generateHtmlFromNodes(editor));
        });
      }}
    />
  );
};

export { HtmlOnChangePlugin };
