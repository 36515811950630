import { configuration } from "../configuration";
import { useAccessToken } from "../http";
import { GetHeadersDto } from "../http/types/GetHeadersDto";
import { ApplicationError, Result } from "../models";

interface IGetHeadersResult {
  statusCode: number;
  xFrameOptions: string;
}

const useProxy = () => {
  const { getAccessToken } = useAccessToken();

  async function getHeaders(url: string): Promise<Result<IGetHeadersResult, ApplicationError>> {
    try {
      const accessToken = await getAccessToken();
      const response = await fetch(`${configuration.apiRootUrl}/proxy/headers?url=${url}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      });

      const data: GetHeadersDto = await response.json();

      if (response.ok && data) {
        return Result.success(data);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  return {
    getHeaders,
  };
};

export { useProxy };
