import { Stack } from "@mui/material";
import { Outlet } from "react-router";
import { SideMenu } from "./SideMenu";

const SideMenuLayout = () => {

  return (
    <Stack direction="row">
      <SideMenu />
      <Outlet />
    </Stack>
  );
};

export { SideMenuLayout };
