import { Box, Divider, Skeleton, Stack, SxProps } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { CommentSubject, CommentType } from "../constants";
import { useSignalR } from "../hooks";
import { useGetUserComments } from "../http";
import { IComment, IUser } from "../models";
import { Comment } from "./Comment";
import { CommentForm } from "./CommentForm";
import { Typography } from "./Typography";

interface IProps {
  subjectType: CommentSubject;
  subjectGuid: string;
  additionalComments?: IComment[];
  autoFocus?: boolean;
  sx?: SxProps;
}

const Comments = (props: IProps) => {
  const { data: userComments, isFetching: isFetchingUserComments } = useGetUserComments(props.subjectGuid);
  const [comments, setComments] = useState<IComment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const signalR = useSignalR();

  useEffect(() => {
    setIsLoading(true);
  }, [props.subjectGuid]);

  useEffect(() => {
    if (userComments && !isFetchingUserComments) {
      setComments(userComments.concat(props.additionalComments ?? []));
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingUserComments, props.additionalComments]);

  useEffect(() => {
    const handleReceiveCreateComment = (commentGuid: string, author: IUser, lastModifiedUtc: string, text: string) => {
      let comment: IComment = {
        type: CommentType.Comment,
        commentGuid: commentGuid,
        user: author,
        timestampUtc: DateTime.fromISO(lastModifiedUtc, { zone: "utc" }),
        lastModifiedUtc: DateTime.fromISO(lastModifiedUtc, { zone: "utc" }),
        text: text,
      };
      setComments((comments) => [comment, ...(comments ?? [])]);
    };

    const handleReceiveEditComment = (commentGuid: string, lastModifiedUtc: string, updatedText: string) => {
      setComments((comments) =>
        (comments ?? []).map((comment) => {
          if (comment.commentGuid === commentGuid) {
            return {
              ...comment,
              text: updatedText,
              lastModifiedUtc: DateTime.fromISO(lastModifiedUtc, { zone: "utc" }),
            };
          }
          return comment;
        })
      );
    };
    const handleReceiveDeleteComment = (commentGuid: string) => {
      setComments((comments) => (comments ?? []).filter((x) => x.commentGuid !== commentGuid));
    };

    signalR.registerReceiveCreateComment(handleReceiveCreateComment);
    signalR.registerReceiveEditComment(handleReceiveEditComment);
    signalR.registerReceiveDeleteComment(handleReceiveDeleteComment);

    return () => {
      signalR.unregisterReceiveCreateComment(handleReceiveCreateComment);
      signalR.unregisterReceiveEditComment(handleReceiveEditComment);
      signalR.unregisterReceiveDeleteComment(handleReceiveDeleteComment);
    };
  }, [signalR]);

  useEffect(() => {
    signalR.joinGroup(props.subjectGuid);
    return () => signalR.leaveGroup(props.subjectGuid);
  }, [props.subjectGuid, signalR]);

  function handleCommentCreated(
    subjectGuid: string,
    commentGuid: string,
    user: IUser,
    timestampUtc: DateTime,
    text: string
  ) {
    signalR.createComment(props.subjectType, subjectGuid, commentGuid, user, timestampUtc.toISO(), text);
    setComments([
      {
        commentGuid,
        type: CommentType.Comment,
        user,
        timestampUtc,
        lastModifiedUtc: timestampUtc,
        text,
      },
      ...comments,
    ]);
  }

  function handleCommentEdited(commentGuid: string, lastModifiedUtc: string, text: string) {
    signalR.editComment(props.subjectGuid!, commentGuid, lastModifiedUtc, text);
    setComments(
      comments.map((comment) => {
        if (comment.commentGuid === commentGuid) {
          return {
            ...comment,
            lastModifiedUtc: DateTime.fromISO(lastModifiedUtc, { zone: "utc" }),
            text,
          };
        }

        return comment;
      })
    );
  }

  function handleCommentDeleted(commentGuid: string) {
    signalR.deleteComment(props.subjectType, props.subjectGuid, commentGuid);
    setComments(comments.filter((x) => x.commentGuid !== commentGuid));
  }

  return (
    <Stack sx={{ overflow: "hidden", ...props.sx }}>
      <CommentForm
        subjectType={props.subjectType}
        subjectGuid={props.subjectGuid}
        disabled={isLoading}
        onCommentCreated={handleCommentCreated}
      />

      <Divider />

      {isLoading && (
        <Stack spacing={1} sx={{ mx: 1, mt: 0.5 }}>
          {[...new Array(3)].map((_, i) => (
            <Stack key={i} direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <Skeleton variant="circular" width={36} height={36} />
              <Box>
                <Skeleton width={100} height={24} />
                <Skeleton width={50} height={24} />
              </Box>
            </Stack>
          ))}
        </Stack>
      )}

      {!isLoading && (
        <Stack sx={{ overflowY: "auto" }}>
          {comments.length === 0 ? (
            <Typography sx={{ p: 1 }}>Nothing here yet.</Typography>
          ) : (
            <>
              {comments
                .sort((a, b) => {
                  // Sort by timestampUtc
                  if (a.timestampUtc < b.timestampUtc) {
                    return 1;
                  } else if (a.timestampUtc > b.timestampUtc) {
                    return -1;
                  }

                  // Then by sortOrder
                  if ((a.sortOrder ?? 0) < (b.sortOrder ?? 0)) {
                    return 1;
                  } else {
                    return -1;
                  }
                })
                .map((comment, i) => (
                  <Comment
                    key={i}
                    comment={comment}
                    onCommentEdited={handleCommentEdited}
                    onCommentDeleted={handleCommentDeleted}
                  />
                ))}
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export { Comments };
