import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetSurveyCoherenceReportDto, GetSurveyCoherenceReportResultDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetSurveyCoherenceReportRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getSurveyCoherenceReportRequest({
    queryKey,
  }: QueryFunctionContext<
    ReturnType<typeof queryKeys.surveys.getSurveyCoherenceReport>
  >): Promise<GetSurveyCoherenceReportResultDto> {
    const accessToken = await getAccessToken();
    const [{ selectedSurveyInstanceGuid, comparisonSurveyInstanceGuid, selectedTeamGuids, comparisonTeamGuids }] =
      queryKey;
    const url = new URL(`${configuration.apiRootUrl}/surveys/${configuration.coherenceSurveyGuid}/coherence-report`);

    // The selectedSurveyInstanceGuid and comparisonSurveyInstanceGuid variables are empty strings when "All" is selected.
    const body: GetSurveyCoherenceReportDto = {
      selectedSurveyInstanceGuid: selectedSurveyInstanceGuid === "" ? undefined : selectedSurveyInstanceGuid,
      comparisonSurveyInstanceGuid: comparisonSurveyInstanceGuid === "" ? undefined : comparisonSurveyInstanceGuid,
      selectedTeamGuids: selectedTeamGuids ?? [],
      comparisonTeamGuids: comparisonTeamGuids ?? [],
    };

    const response = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getSurveyCoherenceReportRequest };
};

const useGetSurveyCoherenceReport = (
  selectedSurveyInstanceGuid: string | undefined,
  comparisonSurveyInstanceGuid: string | undefined,
  selectedTeamGuids: string[] | undefined,
  comparisonTeamGuids: string[] | undefined
) => {
  const { getSurveyCoherenceReportRequest } = useGetSurveyCoherenceReportRequest();

  return useQuery({
    queryKey: queryKeys.surveys.getSurveyCoherenceReport(
      selectedSurveyInstanceGuid,
      comparisonSurveyInstanceGuid,
      selectedTeamGuids,
      comparisonTeamGuids
    ),
    queryFn: getSurveyCoherenceReportRequest,
  });
};

export { useGetSurveyCoherenceReport };
