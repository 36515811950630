import { styled, Typography as MuiTypography, TypographyProps } from "@mui/material";

const BaseTypography = styled(MuiTypography)<{ $faded?: boolean }>`
  color: ${({ theme, $faded }) => ($faded ? theme.palette.grey[300] : undefined)};
`;

interface IProps {
  faded?: boolean;
}

const Typography = (props: IProps & TypographyProps) => {
  const { faded, ...other } = props;

  return <BaseTypography $faded={faded} {...other} />;
};

export { Typography };
