import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { Box, Button, Divider, IconButton, Menu, MenuItem, Stack, styled, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";
import { InformedByTag, SmallInputLabel } from "../../../../components";
import { ConnectionState } from "../../../../constants";
import { useDomParser } from "../../../../hooks";
import { IStrategy } from "../../../../models";
import { IEditTeamPlanPageAction, IEditTeamPlanPageState } from "../state";
import { EditKeyInitiatives } from "./EditKeyInitiatives";

const StrategyDescription = styled(Box)<{ $isHovering: boolean }>`
  background-color: ${({ $isHovering, theme }) => ($isHovering ? theme.palette.primary.opacity5 : "transparent")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: ${({ theme }) => theme.spacing(0.5)};
`;

interface IProps {
  state: IEditTeamPlanPageState;
  dispatch: React.Dispatch<IEditTeamPlanPageAction>;
  onStrategySelected: (index: number) => void;
  onStrategyDeleted: (index: number) => void;
  onKeyInitiativeSelected: (strategyIndex: number, keyInitiativeIndex: number) => void;
  onKeyInitiativeDeleted: (strategyIndex: number, keyInitiativeIndex: number) => void;
}

const EditStrategies = (props: IProps) => {
  const { state, dispatch } = props;
  const [hoverIndex, setHoverIndex] = useState<number | undefined>();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [menuIndex, setMenuIndex] = useState<number | null>(null);
  const { stripHtml } = useDomParser();
  const confirm = useConfirm();

  function handleAddClicked() {
    dispatch({ type: "add_strategy" });
    props.onStrategySelected(props.state.plan.strategies.length);
  }

  function handleMoveUpClicked(e: React.MouseEvent, index: number) {
    e.stopPropagation();
    setMenuAnchor(null);
    setMenuIndex(null);

    dispatch({
      type: "reorder_strategy",
      sourceIndex: index,
      destinationIndex: index - 1,
    });
  }

  function handleMoveDownClicked(e: React.MouseEvent, index: number) {
    e.stopPropagation();
    setMenuAnchor(null);
    setMenuIndex(null);

    dispatch({
      type: "reorder_strategy",
      sourceIndex: index,
      destinationIndex: index + 1,
    });
  }

  async function handleDeleteClicked(e: React.MouseEvent, index: number) {
    e.stopPropagation();
    setMenuAnchor(null);

    try {
      await confirm({
        title: "Delete Area of Focus",
        description: (
          <Stack spacing={1}>
            <Typography>This will delete the area of focus along with associated priorities.</Typography>
            <Typography>Are you sure you want to delete the area of focus?</Typography>
          </Stack>
        ),
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    setMenuIndex(null);

    dispatch({
      type: "delete_strategy",
      index,
    });
  }

  function handleRemoveInformedBy(index: number) {
    dispatch({
      type: "edit_strategy",
      index,
      changes: {
        informedBy: undefined,
      },
    });
  }

  function handleUpdateInformedBy(strategy: IStrategy, index: number) {
    if (strategy.informedBy?.updatedVersion) {
      dispatch({
        type: "edit_strategy",
        index,
        changes: {
          informedBy: {
            ...strategy.informedBy,
            historyId: strategy.informedBy.updatedVersion.historyId,
            description: strategy.informedBy.updatedVersion.description,
            syncState: ConnectionState.InSync,
            updatedVersion: null,
          },
        },
      });
    }
  }

  return (
    <Box>
      <Stack spacing={1} sx={{ mb: 1 }}>
        {state.plan.strategies
          .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
          .map((item: IStrategy, index) => (
            <Box key={item.guid}>
              <Divider />
              <SmallInputLabel sx={{ px: 1, pt: 1, pb: 0.25 }}>Area of Focus</SmallInputLabel>
              <Box key={item.guid} onClick={() => props.onStrategySelected(index)} sx={{ cursor: "pointer" }}>
                <StrategyDescription
                  $isHovering={hoverIndex === index}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(undefined)}
                >
                  <Stack minWidth={0} sx={{ px: 1, py: 0.5 }}>
                    {item.informedBy && (
                      <InformedByTag
                        informedBy={item.informedBy}
                        SyncStateAlertProps={{
                          footer: (
                            <Stack spacing={0.5} direction="row" alignSelf="end">
                              <Button
                                variant={
                                  item.informedBy.syncState === ConnectionState.OutOfSync ? "outlined" : "contained"
                                }
                                onClick={() => handleRemoveInformedBy(index)}
                              >
                                Remove
                              </Button>
                              {item.informedBy.syncState === ConnectionState.OutOfSync && (
                                <Button variant="contained" onClick={() => handleUpdateInformedBy(item, index)}>
                                  Update
                                </Button>
                              )}
                            </Stack>
                          ),
                        }}
                      />
                    )}
                    {item.description === "" ? (
                      <Typography variant="h5" sx={{ fontStyle: "italic" }}>
                        Placeholder
                      </Typography>
                    ) : (
                      <Typography variant="h5">{item.description}</Typography>
                    )}
                    {item.notes && (
                      <Typography noWrap variant="caption">
                        {stripHtml(item.notes)}
                      </Typography>
                    )}
                  </Stack>
                  <IconButton
                    size="medium"
                    onClick={(e) => {
                      e.stopPropagation();
                      setMenuAnchor(e.currentTarget);
                      setMenuIndex(index);
                    }}
                    sx={{ height: "40px", width: "40px" }}
                  >
                    <MoreVertRoundedIcon />
                  </IconButton>
                  <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor) && menuIndex === index}
                    onClose={(e: any) => {
                      e.stopPropagation();
                      setMenuAnchor(null);
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    {index !== 0 && <MenuItem onClick={(e) => handleMoveUpClicked(e, index)}>Move Up</MenuItem>}
                    {index !== state.plan.strategies.length - 1 && (
                      <MenuItem onClick={(e) => handleMoveDownClicked(e, index)}>Move Down</MenuItem>
                    )}
                    <MenuItem onClick={(e) => handleDeleteClicked(e, index)} sx={{ color: "error.main" }}>
                      Delete
                    </MenuItem>
                  </Menu>
                </StrategyDescription>
                <EditKeyInitiatives
                  state={state}
                  dispatch={dispatch}
                  strategyIndex={index}
                  onSelected={props.onKeyInitiativeSelected}
                  onDeleted={props.onKeyInitiativeDeleted}
                />
              </Box>
            </Box>
          ))}
      </Stack>
      <Divider />
      <Box sx={{ p: 1 }}>
        <Button variant="tertiary" onClick={handleAddClicked}>
          Add Area of Focus
        </Button>
      </Box>
    </Box>
  );
};

export { EditStrategies };
