import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { ITeamNetworkGraph } from "../../../models";
import { FetchError, GetTeamNetworkGraphDto, getNodeImage, getNodeUrl } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetTeamNetworkGraphRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getTeamNetworkGraphRequest({
    queryKey,
    signal,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.teams.getTeamNetworkGraph>>): Promise<GetTeamNetworkGraphDto> {
    const [{ slug }] = queryKey;
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/graphs/teams/${slug}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal,
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getTeamNetworkGraphRequest };
};

const useGetTeamNetworkGraph = (slug: string | undefined) => {
  const { getTeamNetworkGraphRequest } = useGetTeamNetworkGraphRequest();

  return useQuery({
    queryKey: queryKeys.teams.getTeamNetworkGraph(slug),
    queryFn: getTeamNetworkGraphRequest,
    select: (data): ITeamNetworkGraph => ({
      teamNodes: data.teamNodes.map((node) => ({
        ...node,
        url: getNodeUrl(node),
      })),
      roleNodes: data.roleNodes.map((node) => ({
        ...node,
        url: getNodeUrl(node),
      })),
      peopleNodes: data.peopleNodes.map((node) => ({
        ...node,
        url: getNodeUrl(node),
        image: getNodeImage(node),
        imageSrc: node.image,
      })),
      links: data.links,
    }),
    enabled: slug !== undefined,
  });
};

export { useGetTeamNetworkGraph };
