import { DeleteOutlineRounded, ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Button, IconButton, Input, Stack, TextField, styled } from "@mui/material";
import {
  GridColDef,
  GridRenderEditCellParams,
  GridRowModel,
  GridRowParams,
  GridValueFormatterParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { v4 as newGuid } from "uuid";
import SingleClickDataGrid from "../SingleClickDataGrid";
import { ICreateEditMetricFormState } from "./CreateEditMetricForm";

const StyledDataGrid = styled(SingleClickDataGrid)`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  border: none;

  & .MuiDataGrid-columnHeader {
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.typography.overline.fontSize};
    font-weight: ${({ theme }) => theme.typography.overline.fontWeight};
  }

  & .MuiDataGrid-cell {
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
  }

  &:focus-within .MuiDataGrid-cell.MuiDataGrid-cell--editing {
    padding: 0 10px 0 0;
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
    outline-width: 2px;
    outline-color: ${({ theme }) => theme.palette.turquoise.main};
  }
`;

const NumberInput = styled(Input)`
  font-size: 1rem;
  line-height: 1.5rem;
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    display: none;
  }

  & input {
    text-align: end;
    padding: 0px;
  }
`;

const BorderlessInput = styled(TextField)`
  & .MuiInputBase-root {
    & fieldset {
      border: none;
    }
  }
` as typeof TextField;

interface GridRowProps {
  id: string;
  date: DateTime;
  [key: string]: number | null | DateTime | string;
}

interface IProps {
  form: UseFormReturn<ICreateEditMetricFormState>;
}

const MetricDataGrid = (props: IProps) => {
  const [columns, setColumns] = useState<GridColDef[]>([{ field: "date", type: "date" }]);
  const [rows, setRows] = useState<GridRowProps[]>([]);

  useEffect(() => {
    // Initial datagrid loading.
    loadDatasets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Subscribe to any changes on the form
    const subscription = props.form.watch((_value, options) => {
      if (options.name?.includes("datasets")) {
        // If any dataset property changes reload the datagrid
        loadDatasets();
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form, props.form.watch]);

  function loadDatasets() {
    loadColumns();
    loadRows();
  }

  function CustomNumberEditComponent(props: GridRenderEditCellParams<any, number>) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
      <NumberInput
        type="number"
        disableUnderline
        value={value}
        autoFocus
        onChange={handleValueChange}
        onFocus={(e) => e.target.select()}
        onBlur={() => apiRef.current.stopCellEditMode({ id, field })}
      />
    );
  }

  function CustomDateEditComponent(props: GridRenderEditCellParams<any, DateTime>) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue?: DateTime | null) => {
      if (newValue !== null && newValue !== undefined) {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
      }
    };

    return (
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          value={value}
          onChange={(newValue) => handleValueChange(newValue)}
          format="MMMM d, yyyy"
          slots={{
            textField: BorderlessInput,
          }}
          slotProps={{
            openPickerButton: {
              size: "small",
            },
          }}
        />
      </LocalizationProvider>
    );
  }

  function loadColumns() {
    const tempColumns: GridColDef[] = [
      {
        field: "date",
        headerName: "Date",
        width: 175,
        editable: true,
        type: "date",
        renderEditCell: (params: GridRenderEditCellParams<any, DateTime>) => <CustomDateEditComponent {...params} />,
        valueFormatter: (params: GridValueFormatterParams<DateTime>) => {
          const valueFormatted = params.value.toLocaleString(DateTime.DATE_MED);
          return valueFormatted;
        },
      },
    ];

    props.form
      .getValues("datasets")
      .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
      .forEach((dataset) =>
        tempColumns.push({
          field: dataset.title,
          width: 125,
          editable: true,
          sortable: false,
          renderEditCell: (params: GridRenderEditCellParams<any, number>) => <CustomNumberEditComponent {...params} />,
          valueFormatter(params) {
            if (params.value == null) {
              return "";
            }

            return Number(params.value).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            });
          },
        })
      );

    tempColumns.push({
      field: "actions",
      type: "actions",
      flex: 1,
      align: "right",
      getActions: (params: GridRowParams) => [
        <IconButton onClick={() => handleRowDelete(params.row)} tabIndex={-1} size="small">
          <DeleteOutlineRounded fontSize="large" />
        </IconButton>,
      ],
    });

    setColumns(tempColumns);
  }

  function loadRows() {
    const tempRows: GridRowProps[] = [];

    props.form
      .getValues("datasets")
      .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
      .forEach((dataset) => {
        dataset.data?.forEach((data) => {
          if (data.date) {
            let index = tempRows.findIndex((x) => x.id === data.rowGuid);

            if (index > -1) {
              tempRows[index][dataset.title] = data.value;
            } else {
              tempRows.push({
                id: data.rowGuid,
                date: data.date,
                [dataset.title]: data.value,
              });
            }
          }
        });
      });

    setRows(tempRows);
  }

  function handleAddRow() {
    setRows((prevRows) => [{ id: newGuid(), date: DateTime.now(), value: null }, ...prevRows]);
  }

  function handleRowDelete(row: GridRowModel) {
    const updatedDatasets = props.form.getValues("datasets").map((d) => {
      return {
        ...d,
        data: d.data.filter((r) => r.rowGuid !== row.id),
      };
    });

    setRows((prevRows) => prevRows.filter((r) => r.rowGuid !== row.id));
    props.form.setValue("datasets", updatedDatasets);
  }

  function processRowUpdate(newRow: GridRowModel, oldRow: GridRowModel): GridRowModel {
    if (newRow.date === undefined) {
      return oldRow;
    }

    let newRowDateTime = newRow.date;

    if (!DateTime.isDateTime(newRow.date)) {
      newRowDateTime = DateTime.fromJSDate(newRow.date);
    }

    const updatedDatasets = props.form.getValues("datasets").map((dataset) => {
      const dataIndex = dataset.data.findIndex((data) => data.rowGuid === oldRow.id);
      const newRowData = {
        date: newRowDateTime,
        value: newRow[dataset.title],
        rowGuid: oldRow.id,
      };

      if (dataIndex > -1) {
        return {
          ...dataset,
          data: dataset.data.map((data, index) => {
            if (index === dataIndex) {
              return newRowData;
            }

            return data;
          }),
        };
      } else {
        return {
          ...dataset,
          data: [...dataset.data, newRowData],
        };
      }
    });

    props.form.setValue("datasets", updatedDatasets);

    return {
      ...newRow,
      date: !DateTime.isDateTime(newRow.date) ? DateTime.fromJSDate(newRow.date) : newRow.date,
    };
  }

  function processRowUpdateError(error: any) {
    console.error(error);
  }

  return (
    <Stack spacing={1} sx={{ height: 400, width: "100%" }}>
      <Button
        onClick={handleAddRow}
        sx={{ alignSelf: "start" }}
        disabled={props.form.getValues("datasets").length === 0}
      >
        Add Data
      </Button>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={processRowUpdateError}
        disableColumnMenu={true}
        hideFooter={true}
        initialState={{ sorting: { sortModel: [{ field: "date", sort: "desc" }] } }}
        slots={{
          columnSortedAscendingIcon: ExpandLessRounded,
          columnSortedDescendingIcon: ExpandMoreRounded,
        }}
      />
    </Stack>
  );
};

export { MetricDataGrid };
