import { useQueryClient } from "@tanstack/react-query";
import { CommentSubject, FeedItemType } from "../../constants";
import { FeedItemDto, GetFeedDto, queryKeys, useGetIssueFeed } from "../../http";
import { IUser } from "../../models";
import { Feed } from "./Feed";

interface IProps {
  issueGuid: string;
}

const IssueFeed = (props: IProps) => {
  const { isLoading, data } = useGetIssueFeed(props.issueGuid);
  const queryClient = useQueryClient();

  function handleCreateCommentReceived(commentGuid: string, user: IUser, timestampUtc: string, text: string) {
    const cachedData = queryClient.getQueryData<GetFeedDto>(queryKeys.issues.getIssueFeed(props.issueGuid));

    if (cachedData) {
      const newComment: FeedItemDto = {
        type: FeedItemType.Comment,
        timestampUtc,
        user,
        comment: {
          guid: commentGuid,
          modifiedDateTimeUtc: timestampUtc,
          text,
        },
      };

      queryClient.setQueryData(queryKeys.issues.getIssueFeed(props.issueGuid), {
        ...cachedData,
        feed: [newComment, ...cachedData.feed],
      });
    }
  }

  function handleEditCommentReceived(commentGuid: string, lastModifiedUtc: string, updatedText: string) {
    const cachedData = queryClient.getQueryData<GetFeedDto>(queryKeys.issues.getIssueFeed(props.issueGuid));

    if (cachedData) {
      queryClient.setQueryData(queryKeys.issues.getIssueFeed(props.issueGuid), {
        ...cachedData,
        feed: cachedData.feed.map((item) => {
          if (item.comment?.guid === commentGuid) {
            return {
              ...item,
              comment: {
                ...item.comment,
                modifiedDateTimeUtc: lastModifiedUtc,
                text: updatedText,
              },
            };
          }

          return item;
        }),
      });
    }
  }

  function handleDeleteCommentReceived(commentGuid: string) {
    const cachedData = queryClient.getQueryData<GetFeedDto>(queryKeys.issues.getIssueFeed(props.issueGuid));

    if (cachedData) {
      queryClient.setQueryData(queryKeys.issues.getIssueFeed(props.issueGuid), {
        ...cachedData,
        feed: cachedData?.feed.filter((item) => item.comment?.guid !== commentGuid),
      });
    }
  }

  return (
    <Feed
      subjectType={CommentSubject.Issue}
      subjectGuid={props.issueGuid}
      isLoading={isLoading}
      items={data?.feed ?? []}
      onCreateCommentReceived={handleCreateCommentReceived}
      onEditCommentReceived={handleEditCommentReceived}
      onDeleteCommentReceived={handleDeleteCommentReceived}
    />
  );
};

export { IssueFeed };
