import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditPrimaryRoleDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  userId: string;
  roleGuid: string | null;
};

const useEditPrimaryRoleRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editPrimaryRoleRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditPrimaryRoleDto = {
      roleGuid: variables.roleGuid,
    };

    const response = await fetch(`${configuration.apiRootUrl}/users/${variables.userId}/primary-role`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editPrimaryRoleRequest };
};

const useEditPrimaryRole = () => {
  const { editPrimaryRoleRequest } = useEditPrimaryRoleRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editPrimaryRoleRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.roles.getRolesForUser(variables.userId) });
    },
  });
};

export { useEditPrimaryRole };
