import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate, useParams } from "react-router";
import { Links, Paths, UserTab } from "../../../../constants";
import { useSession } from "../../../../hooks";

interface IParams {
  userId: string;
}

const UserNavBar = () => {
  const { userId: sessionUserId } = useSession();
  const { userId } = useParams<keyof IParams>() as IParams;
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState<UserTab>(UserTab.Overview);

  useEffect(() => {
    setCurrentTab(getTabForPath(location.pathname));
  }, [location.pathname]);

  function getTabForPath(path: string) {
    if (!!matchPath(Paths.UserMap, path)) {
      return UserTab.Map;
    }

    return UserTab.Overview;
  }

  function handleTabChanged(_: React.SyntheticEvent, value: UserTab) {
    setCurrentTab(value);

    switch (value) {
      case UserTab.Overview:
        navigate(Links.User(userId));
        break;
      case UserTab.Map:
        navigate(Links.UserMap(userId));
        break;
    }
  }

  return (
    <Tabs value={currentTab} variant="scrollable" onChange={handleTabChanged}>
      <Tab label={sessionUserId === userId ? "My Profile" : "Profile"} value={UserTab.Overview} disableRipple />
      <Tab label={sessionUserId === userId ? "My Map" : "Map"} value={UserTab.Map} disableRipple />
    </Tabs>
  );
};

export { UserNavBar };
