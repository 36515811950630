import { Typography } from "@mui/material";
import { EditHintCard } from "../EditHintCard";

const MissionHintCard = () => {
  return (
    <EditHintCard title="Mission">
      <Typography variant="body1">
        Mission is how a team fulfills its Purpose. It is meant to be an inspirational aim that is many years out that a
        team can attach to as the duty of their team. Together with Purpose (the Why), the Mission clarifies the
        identity of a Role or Team. The Mission is more explicit and tangible than the Purpose and helps to focus the
        work.
      </Typography>
      <Typography variant="body1">
        What Goal might we create as a team that would have us on-track for the next decade or two?
      </Typography>
    </EditHintCard>
  );
};

export { MissionHintCard };
