import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, DeleteSurveyQuestionDto } from "../../types";

import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  surveyGuid: string;
};

const useDeleteSurveyQuestionRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteSurveyQuestionRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: DeleteSurveyQuestionDto = {
      guid: variables.guid,
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-questions/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteSurveyQuestionRequest };
};

const useDeleteSurveyQuestion = () => {
  const { deleteSurveyQuestionRequest } = useDeleteSurveyQuestionRequest();
  const queryClient = useQueryClient();

  return useMutation(({
    mutationFn: deleteSurveyQuestionRequest,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({queryKey: queryKeys.surveys.getSurveyQuestions(variables.surveyGuid)});
    },
  }))
};

export { useDeleteSurveyQuestion };
