import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { ActionItemStatusIcon, EditList, InformedByTag, KeyInitiativeOwnership } from "../../../../components";
import { ConnectionState } from "../../../../constants";
import { useDomParser } from "../../../../hooks";
import { IKeyInitiative } from "../../../../models";
import { IEditTeamPlanPageAction, IEditTeamPlanPageState } from "../state";

interface IProps {
  state: IEditTeamPlanPageState;
  dispatch: React.Dispatch<IEditTeamPlanPageAction>;
  strategyIndex: number;
  onSelected: (strategyIndex: number, keyInitiatveIndex: number) => void;
  onDeleted: (strategyIndex: number, keyInitiativeIndex: number) => void;
}

const EditKeyInitiatives = (props: IProps) => {
  const { state, dispatch, strategyIndex } = props;
  const { stripHtml } = useDomParser();

  function handleSelected(e: React.MouseEvent, index: number) {
    e.stopPropagation();
    props.onSelected(strategyIndex, index);
  }

  function handleAddClicked() {
    dispatch({ type: "add_key_initiative", strategyIndex });
    props.onSelected(strategyIndex, props.state.plan.strategies[strategyIndex].keyInitiatives.length);
  }

  function handleReorder(sourceIndex: number, destinationIndex: number) {
    dispatch({ type: "reorder_key_initiative", strategyIndex, sourceIndex, destinationIndex });
  }

  function handleDeleteClicked(index: number) {
    dispatch({ type: "delete_key_initiative", strategyIndex, index });
    props.onDeleted(strategyIndex, index);
  }

  function handleRemoveInformedBy(index: number) {
    dispatch({
      type: "edit_key_initiative",
      strategyIndex,
      keyInitiativeIndex: index,
      changes: {
        informedBy: undefined,
      },
    });
  }

  function handleUpdateInformedBy(keyInitiative: IKeyInitiative, index: number) {
    if (keyInitiative.informedBy?.updatedVersion) {
      dispatch({
        type: "edit_key_initiative",
        strategyIndex,
        keyInitiativeIndex: index,
        changes: {
          informedBy: {
            ...keyInitiative.informedBy,
            historyId: keyInitiative.informedBy.updatedVersion.historyId,
            description: keyInitiative.informedBy.updatedVersion.description,
            syncState: ConnectionState.InSync,
            updatedVersion: null,
          },
        },
      });
    }
  }

  return (
    <EditList
      id="keyInitiatives"
      values={state.plan.strategies[strategyIndex].keyInitiatives.sort((a, b) =>
        a.sortOrder.localeCompare(b.sortOrder)
      )}
      buttonText="Add Priority"
      buttonProps={{ sx: { mt: 0.25 } }}
      renderItem={(item: IKeyInitiative, index: number) => (
        <Box key={item.guid} minWidth={0} onClick={(e) => handleSelected(e, index)} sx={{ cursor: "pointer", py: 0.5 }}>
          <Box sx={{ pl: 1.25, pr: 1 }}>
            {item.informedBy && (
              <InformedByTag
                informedBy={item.informedBy}
                SyncStateAlertProps={{
                  footer: (
                    <Stack spacing={0.5} direction="row" alignSelf="end">
                      <Button
                        variant={item.informedBy.syncState === ConnectionState.OutOfSync ? "outlined" : "contained"}
                        onClick={() => handleRemoveInformedBy(index)}
                      >
                        Remove
                      </Button>
                      {item.informedBy.syncState === ConnectionState.OutOfSync && (
                        <Button variant="contained" onClick={() => handleUpdateInformedBy(item, index)}>
                          Update
                        </Button>
                      )}
                    </Stack>
                  ),
                }}
              />
            )}
          </Box>
          <Stack direction="row" minWidth={0} spacing={0.5}>
            <Box sx={{ pt: "2px" }}>
              <ActionItemStatusIcon status={item.status} />
            </Box>
            <Stack minWidth={0}>
              {item.description === "" ? (
                <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                  Placeholder
                </Typography>
              ) : (
                <Typography variant="body1">{item.description}</Typography>
              )}
              <Stack
                direction="row"
                spacing={0.25}
                minWidth={0}
                sx={{ alignItems: "center" }}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <KeyInitiativeOwnership owners={item.users} sx={{ flexShrink: 0 }} />

                {item.notes && stripHtml(item.notes) !== "" && (
                  <Typography noWrap variant="caption">
                    {stripHtml(item.notes)}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      )}
      renderEmptyPlaceholder={() => (
        <Box sx={{ px: 1, pt: 0.5 }}>
          <Typography variant="body1">No priorities entered yet.</Typography>
        </Box>
      )}
      onAdd={handleAddClicked}
      onReorder={handleReorder}
      onDelete={handleDeleteClicked}
    />
  );
};

export { EditKeyInitiatives };
