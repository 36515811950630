import { QueryFunctionContext, useQuery, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetTeamActionItemsDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetTeamActionItemsRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getTeamActionItemsRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.teams.getTeamActionItems>>): Promise<GetTeamActionItemsDto> {
    const accessToken = await getAccessToken();
    const [{ slug }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/action-items`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getTeamActionItemsRequest };
};

const useGetTeamActionItems = (teamSlug: string | undefined) => {
  const { getTeamActionItemsRequest } = useGetTeamActionItemsRequest();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: queryKeys.teams.getTeamActionItems(teamSlug),
    queryFn: getTeamActionItemsRequest,
    enabled: teamSlug !== undefined,
    onSuccess: (data) => {
      data.actionItems.forEach((actionItem) => {
        queryClient.setQueryData(queryKeys.actionItems.getActionItemSummary(actionItem.guid), actionItem);
      });
    },
  });
};

export { useGetTeamActionItems };
