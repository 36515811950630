import { CheckBoxOutlineBlankRounded, CheckBoxRounded, IndeterminateCheckBoxRounded } from "@mui/icons-material";
import { Stack, styled } from "@mui/material";
import { TreeItem as MuiTreeItem, TreeItemProps } from "@mui/x-tree-view";
import React from "react";
import { Typography } from "../components";
import { ITreeNode } from "../models";

const TreeItem = styled(MuiTreeItem)`
  background-color: transparent !important;

  .MuiTreeItem-content {
    background-color: transparent !important;
  }
`;

interface IProps extends TreeItemProps {
  nodes: ITreeNode[];
  selectedNodeIds: string[];
  onNodeSelected: (nodeId: string) => void;
}

const TreeViewItem = React.forwardRef((props: IProps, ref: React.Ref<HTMLLIElement>) => {
  const { nodes, selectedNodeIds, onNodeSelected, ...other } = props;

  function getNodeIcon() {
    if (props.selectedNodeIds.includes(props.nodeId)) {
      return <CheckBoxRounded />;
    } else if (isDescendantSelected(props.nodeId, props.nodes, props.selectedNodeIds)) {
      return <IndeterminateCheckBoxRounded />;
    } else {
      return <CheckBoxOutlineBlankRounded />;
    }
  }

  function isDescendantSelected(parentNodeId: string, nodes: ITreeNode[], selectedNodeIds: string[]): boolean {
    const descendantNodes = nodes.filter((x) => x.parentNodeId === parentNodeId);
    let result = false;

    for (var i = 0; i < descendantNodes.length; i++) {
      result =
        result ||
        selectedNodeIds.includes(descendantNodes[i].nodeId) ||
        isDescendantSelected(descendantNodes[i].nodeId, nodes, selectedNodeIds);

      if (result) {
        break;
      }
    }

    return result;
  }

  return (
    <TreeItem
      ref={ref}
      {...other}
      label={
        <Stack
          direction="row"
          spacing={0.25}
          sx={{ alignItems: "center" }}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            onNodeSelected(props.nodeId);
          }}
        >
          {getNodeIcon()}
          <Typography>{props.label}</Typography>
        </Stack>
      }
    />
  );
});

export { TreeViewItem };
