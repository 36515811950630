import { Typography } from "@mui/material";
import React from "react";

interface IProps {
  title: React.ReactNode;
  content?: React.ReactNode;
}

const SnackbarContent = (props: IProps) => {
  return (
    <>
      <Typography variant="body2" component="span" sx={{ mr: props.content ? 1 : 0 }}>
        {props.title}
      </Typography>
      {props.content && (
        <Typography variant="body1" component="span">
          {props.content}
        </Typography>
      )}
    </>
  );
};

export { SnackbarContent };
