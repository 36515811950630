import { AutorenewRounded, CloudUploadRounded, DeleteRounded } from "@mui/icons-material";
import { Button, Stack, Typography, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CodeSpinnerIcon } from "../../../assets/icons";
import { useSession } from "../../../hooks";
import { useDeleteTeamVideo, useEditTeamVideo } from "../../../http";
import { ITeam } from "../../../models";
import { useDestructiveConfirm } from "../../../utilities";

const VideoContainer = styled(Stack)<{ $error: boolean }>`
  border-radius: 8px;
  border: ${({ theme, $error }) => ($error ? `2px solid ${theme.palette.error.main}` : "1px solid #c9c9c9")};
  height: 160px;
  width: 285px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
`;

const UploadButton = styled(Button)`
  border-radius: 0;
  height: 100%;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
` as typeof Button;

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: insert(50%);
  height: 1;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1;
`;

interface Props {
  isFetchingTeam: boolean;
  team: ITeam;
}

const TeamVideoUploader = (props: Props) => {
  const { userId } = useSession();
  const { mutate: editTeamVideo, isLoading: isUpdatingTeamVideo } = useEditTeamVideo();
  const { mutate: deleteTeamVideo, isLoading: isDeletingTeamVideo } = useDeleteTeamVideo();
  const [hasVideo, setHasVideo] = useState<boolean>(props.team.hasVideo);
  const [videoFileName, setVideoFileName] = useState<string | null>(props.team.videoFileName);
  const [videoHelperText, setVideoHelperText] = useState<string | null>(null);
  const destructiveConfirm = useDestructiveConfirm();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    setHasVideo(props.team.hasVideo);
  }, [props.team.hasVideo]);

  useEffect(() => {
    setVideoFileName(props.team.videoFileName);
  }, [props.team.videoFileName]);

  useEffect(() => {
    videoRef.current?.load();
  }, [props.team.videoUrl]);

  async function handleTeamVideoChanged(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files == null) {
      return;
    }

    const video = e.target.files[0];

    if (video.size > 300000000) {
      setVideoHelperText("Max size is 300 MB.");
      return;
    }

    if (!["video/mp4", "video/webm"].includes(video.type)) {
      setVideoHelperText("Unsupported file type. Accepted file types are mp4 and webm.");
      return;
    }

    setHasVideo(true);
    setVideoFileName(video.name);

    editTeamVideo({
      slug: props.team.slug,
      video: video,
      modifiedByUserId: userId,
    });

    setVideoHelperText(null);
  }

  async function handleTeamVideoDeleteClicked() {
    setVideoHelperText(null);

    try {
      await destructiveConfirm({
        title: "Delete Video",
        content: "Are you sure you want to delete this video?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteTeamVideo({
      slug: props.team.slug,
      modifiedByUserId: userId,
    });
  }

  // Updating the video will cause the team to be refetched, and we want to show a loading
  // spinner while that happens to prevent showing the previous team video after it's replaced.
  const isLoading = props.isFetchingTeam || isUpdatingTeamVideo || isDeletingTeamVideo;

  return (
    <Stack spacing={0.25}>
      <Stack direction="row" spacing={1}>
        <VideoContainer $error={Boolean(videoHelperText)}>
          {isLoading ? (
            <CodeSpinnerIcon />
          ) : props.team.hasVideo ? (
            <video ref={videoRef} controls height={160} width={285}>
              <source src={props.team.videoUrl} />
            </video>
          ) : (
            <UploadButton disabled={isLoading} component="label">
              <Stack
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CloudUploadRounded fontSize="large" />
                <Typography variant="caption" sx={{ textDecoration: "!important none" }}>
                  Upload Video (mp4 or webm)
                </Typography>
                <VisuallyHiddenInput type="file" accept="video/mp4, video/webm" onChange={handleTeamVideoChanged} />
              </Stack>
            </UploadButton>
          )}
        </VideoContainer>

        {hasVideo && (
          <Stack spacing={0.5} sx={{ flexShrink: 1, overflow: "hidden" }}>
            <Typography sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {videoFileName}
            </Typography>
            <Stack direction="row" alignItems="start" spacing={0.5}>
              <Button variant="tertiary" component="label" startIcon={<AutorenewRounded />} disabled={isLoading}>
                Replace
                <VisuallyHiddenInput type="file" accept="video/mp4, video/webm" onChange={handleTeamVideoChanged} />
              </Button>
              <Button
                variant="tertiary"
                startIcon={<DeleteRounded />}
                color="error"
                onClick={handleTeamVideoDeleteClicked}
                disabled={isLoading}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>

      {videoHelperText && (
        <Typography variant="caption" sx={{ color: "error.main" }}>
          {videoHelperText}
        </Typography>
      )}
    </Stack>
  );
};

export { TeamVideoUploader };
