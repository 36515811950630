import { useMediaQuery, useTheme } from "@mui/material";

const useBreakpoints = () => {
  const theme = useTheme();

  const isXlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isXsUp = useMediaQuery(theme.breakpoints.up("xs"));

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return {
    isXlUp,
    isLgUp,
    isMdUp,
    isSmUp,
    isXsUp,
    isMdDown,
  };
};

export { useBreakpoints };
