import { ActionItemStatus } from "../constants";
import { GetAccountabilitiesDto } from "../http";

class Accountability {
  guid: string;
  description: string;
  teamName: string;
  teamSlug: string;
  statusId: ActionItemStatus;

  constructor(guid: string, description: string, teamName: string, teamSlug: string, statusId: ActionItemStatus) {
    this.guid = guid;
    this.description = description;
    this.teamName = teamName;
    this.teamSlug = teamSlug;
    this.statusId = statusId;
  }

  static fromDto(dto: GetAccountabilitiesDto): Accountability[] {
    return dto.accountabilities.map(
      (x) => new Accountability(x.guid, x.description, x.teamName, x.teamSlug, x.statusId)
    );
  }
}
export { Accountability };
