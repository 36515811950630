import { Button, Card, CardContent, Stack } from "@mui/material";
import { CreateMetricModal, Typography } from "../../../components";
import { useTeamPageLayoutContext } from "../TeamPageLayout";
import { ViewMetricsGrid } from "./components";
import { useState } from "react";

const TeamMetricsPage = () => {
  const { isLoadingTeam, team } = useTeamPageLayoutContext();
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Stack spacing={{ xs: 0.75, md: 1 }}>
        <Card>
          <CardContent>
            <Stack spacing={1} direction="row" alignItems="center">
              <Stack spacing={0.25} sx={{ flexGrow: 1 }}>
                <Typography variant="h5">Metrics</Typography>
                <Typography variant="body1">Metrics relevant to the team.</Typography>
              </Stack>

              {(team?.isAdministrator || team?.isMember) && (
                <Button variant="contained" onClick={() => setIsModalVisible(true)}>
                  Add Metric
                </Button>
              )}
            </Stack>
          </CardContent>
        </Card>

        {!isLoadingTeam && team && team.hasMetrics && (
          <Card>
            <ViewMetricsGrid />
          </Card>
        )}
      </Stack>

      <CreateMetricModal
        visible={isModalVisible}
        onAdded={() => setIsModalVisible(false)}
        onCancelled={() => setIsModalVisible(false)}
      />
    </>
  );
};

export { TeamMetricsPage };
