import { useEffect, useRef } from "react";

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Create interval
  useEffect(() => {
    const intervalId = setInterval(() => {
      savedCallback.current?.();
    }, delay);
    return () => clearInterval(intervalId);
  }, [delay]);
}

export { useInterval };
