import { GetKeyPerformanceIndicatorsDto } from "../http";

class KeyPerformanceIndicator {
  guid: string;
  description: string;
  sortOrder: number;

  constructor(guid: string, description: string, sortOrder: number) {
    this.guid = guid;
    this.description = description;
    this.sortOrder = sortOrder;
  }

  static fromDto(dto: GetKeyPerformanceIndicatorsDto): KeyPerformanceIndicator[] {
    return dto.keyPerformanceIndicators.map(
      (x) => new KeyPerformanceIndicator(x.guid, x.description, x.sortOrder)
    );
  }
}

export { KeyPerformanceIndicator };
