import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { GetTagsDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";
import { TagType } from "../../../constants";

const useGetTagsRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getTagsRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.tags.getTags>>): Promise<GetTagsDto> {
    const accessToken = await getAccessToken();
    const [{ type }] = queryKey;
    const url = new URL(`${configuration.apiRootUrl}/tags`);

    if (type) {
      url.searchParams.set("type", type.toString());
    }

    const response = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json();
  }

  return { getTagsRequest };
};

const useGetTags = (type: TagType) => {
  const { getTagsRequest } = useGetTagsRequest();

  return useQuery({
    queryKey: queryKeys.tags.getTags(type),
    queryFn: getTagsRequest,
    select: (data): string[] => {
      return data.tags;
    },
  });
};

export { useGetTags };
