import { Typography } from "@mui/material";
import { EditHintCard } from "../EditHintCard";

const GoalHintCard = () => {
  return (
    <EditHintCard title="Goal">
      <Typography variant="body1">
        Goals are the most important short-term steps (achievable in the next 12months) towards achieving the
        milestone(s) and executing strategies which are longer term. They are either achieved or not. Once achieved we
        can check them off. If the goal represents a status we want to maintain, we can consider turning it into a
        critical metric with a target value. It's most important that goals are Specific, measurable, attainable, and
        realistic. Timely is optional.
      </Typography>
      <Typography variant="body1">
        What small steps can be measured and checked off as they are accomplished that will move us toward the next
        milestone?
      </Typography>
    </EditHintCard>
  );
};

export { GoalHintCard };
