import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IUserDetail } from "../../../models";
import { FetchError, GetUserDetailsDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";
import { DateTime } from "luxon";

const useGetUserRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getUserRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.users.getUser>>): Promise<GetUserDetailsDto> {
    const accessToken = await getAccessToken();
    const [{ userId }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getUserRequest };
};

const useGetUser = (guid: string) => {
  const { getUserRequest } = useGetUserRequest();

  return useQuery({
    queryKey: queryKeys.users.getUser(guid),
    queryFn: getUserRequest,
    select: (data): IUserDetail => {
      return {
        ...data,
        joinedDate: data.joinedDate ? DateTime.fromISO(data.joinedDate, { zone: "utc" }) : null
      };
    },
  });
};

export { useGetUser };
