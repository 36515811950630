import { FormatListBulletedRounded, StickyNote2Outlined, CommentOutlined } from "@mui/icons-material";
import { Divider, Stack, SxProps, Tooltip, Typography, useTheme } from "@mui/material";
import { Avatar, AvatarGroup, isWhitespace } from "..";
import { IActionItemSummary } from "../../models";

interface IProps {
  actionItem: IActionItemSummary;
  sx?: SxProps;
}

const ActionItemIcons = (props: IProps) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      spacing={0.25}
      divider={<Divider orientation="vertical" sx={{ height: "12px" }} />}
      sx={props.sx}
      alignItems="center"
    >
      {props.actionItem.owners.length > 0 && (
        <AvatarGroup
          max={2}
          sx={{
            "& .MuiAvatar-root": { width: 20, height: 20, fontSize: 12 },
          }}
          users={props.actionItem.owners}
          avatarComponent={(user) => <Avatar user={user} tooltip />}
        />
      )}

      {props.actionItem.note !== null && !isWhitespace(props.actionItem.note) && (
        <Tooltip title="Notes">
          <StickyNote2Outlined sx={{ color: theme.palette.grey[300] }} />
        </Tooltip>
      )}

      {props.actionItem.propertiesSummary.supportingItemsCount > 0 && (
        <Tooltip title="Supporting Items">
          <Stack direction="row" spacing={0.1} sx={{ alignItems: "center" }}>
            <FormatListBulletedRounded sx={{ color: theme.palette.grey[300] }} />
            <Typography variant="caption">{props.actionItem.propertiesSummary.supportingItemsCount}</Typography>
          </Stack>
        </Tooltip>
      )}

      {props.actionItem.propertiesSummary.commentCount > 0 && (
        <Tooltip title="Comments">
          <Stack direction="row" spacing={0.1} sx={{ alignItems: "center" }}>
            <CommentOutlined sx={{ color: theme.palette.grey[300] }} />
            <Typography variant="caption">{props.actionItem.propertiesSummary.commentCount}</Typography>
          </Stack>
        </Tooltip>
      )}
    </Stack>
  );
};

export { ActionItemIcons };
