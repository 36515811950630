import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { IMetric } from "../../../models";
import { FetchError, GetMetricsDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetMetricsForTeamRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getMetricsForTeamRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.metrics.getMetricsForTeam>>): Promise<GetMetricsDto> {
    const accessToken = await getAccessToken();
    const [{ slug }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/metrics`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getMetricsForTeamRequest };
};

const useGetMetricsForTeam = (slug: string, enabled: boolean = true) => {
  const { getMetricsForTeamRequest } = useGetMetricsForTeamRequest();

  return useQuery({
    queryKey: queryKeys.metrics.getMetricsForTeam(slug),
    queryFn: getMetricsForTeamRequest,
    select: (data): IMetric[] => {
      return data.metrics
        .map((metric) => ({
          guid: metric.guid,
          teamSlug: metric.teamSlug,
          title: metric.title,
          description: metric.description,
          datasets: metric.datasets.map((dataset) => ({
            guid: dataset.guid,
            title: dataset.title,
            color: dataset.color,
            dotted: dataset.dotted,
            data: dataset.data.map((dataPoint) => ({
              date: DateTime.fromISO(dataPoint.date, { zone: "utc" }),
              rowGuid: dataPoint.rowGuid,
              value: dataPoint.value,
            })),
            sortOrder: dataset.sortOrder,
          })),
          sortOrder: metric.sortOrder,
          dataLastModifiedByDisplayName: metric.dataLastModifiedByDisplayName,
          dataLastModifiedDateTimeUtc: DateTime.fromISO(metric.dataLastModifiedDateTimeUtc, { zone: "utc" }),
        }))
        .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder));
    },
    enabled: enabled,
  });
};

export { useGetMetricsForTeam };
