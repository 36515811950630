import React from "react";
import { LinkProps, NavLink, To } from "react-router-dom";

const LinkBehavior = React.forwardRef<any, Omit<LinkProps, "to"> & { href: LinkProps["to"] }>((props, ref) => {
  const { href, children, ...other } = props;

  function isInternalUrl(to: To) {
    try {
      const url = new URL(to.toString(), window.location.origin);
      return url.hostname === window.location.hostname;
    } catch {
      return false;
    }
  }

  return isInternalUrl(href) ? (
    <NavLink ref={ref} to={href} {...other}>
      {children}
    </NavLink>
  ) : (
    <a ref={ref} href={href.toString()} {...other}>
      {children}
    </a>
  );
});

export { LinkBehavior };
