import { QueryClient, QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { FetchError, GetIssuesDto, IssueDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  teamSlug: string;
  closed: boolean;
  createdByUserId: string;
  modifiedByUserId: string;
};

const useEditIssueStatusRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editIssueStatusRequest(variables: Variables) {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/issues/${variables.guid}/status`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({
        closed: variables.closed,
        modifiedByUserId: variables.modifiedByUserId,
      }),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editIssueStatusRequest };
};

const useEditIssueStatus = () => {
  const { editIssueStatusRequest } = useEditIssueStatusRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editIssueStatusRequest,
    onMutate: async (variables) => {
      const cachedIssueDetailsData = await optimisticallyUpdateIssueDetailsData(
        queryClient,
        queryKeys.issues.getIssueDetail(variables.guid),
        variables
      );

      const cachedTeamIssuesData = optimisticallyUpdateIssuesData(
        queryClient,
        queryKeys.issues.getIssues(variables.teamSlug, undefined),
        variables
      );

      const cachedUserIssuesData = optimisticallyUpdateIssuesData(
        queryClient,
        queryKeys.issues.getIssues(undefined, variables.createdByUserId),
        variables
      );

      return { cachedIssueDetailsData, cachedTeamIssuesData, cachedUserIssuesData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.issues.getIssueDetail(variables.guid), context?.cachedIssueDetailsData);
      queryClient.setQueryData(
        queryKeys.issues.getIssues(variables.teamSlug, undefined),
        context?.cachedTeamIssuesData
      );
      queryClient.setQueryData(
        queryKeys.issues.getIssues(undefined, variables.createdByUserId),
        context?.cachedUserIssuesData
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.issues.getIssueDetail(variables.guid));
      queryClient.invalidateQueries(queryKeys.issues.getIssues(variables.teamSlug, undefined));
      queryClient.invalidateQueries(queryKeys.issues.getIssues(undefined, variables.createdByUserId));
      queryClient.invalidateQueries(queryKeys.issues.getIssueFeed(variables.guid));
    },
  });
};

async function optimisticallyUpdateIssuesData(queryClient: QueryClient, queryKey: QueryKey, variables: Variables) {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<GetIssuesDto>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: GetIssuesDto = {
      ...cachedData,
      issues: cachedData.issues.map((issue) => {
        if (issue.guid === variables.guid) {
          return {
            ...issue,
            closedOnDateTimeUtc: variables.closed ? DateTime.now().toUTC().toString() : null,
          };
        }

        return issue;
      }),
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);

    return cachedData;
  }
}

async function optimisticallyUpdateIssueDetailsData(
  queryClient: QueryClient,
  queryKey: QueryKey,
  variables: Variables
) {
  await queryClient.cancelQueries();
  const cachedData = queryClient.getQueryData<IssueDto>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: IssueDto = {
      ...cachedData,
      closedOnDateTimeUtc: variables.closed ? DateTime.now().toUTC().toString() : null,
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);

    return cachedData;
  }
}

export { useEditIssueStatus };
