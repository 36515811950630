import { DragIndicatorRounded } from "@mui/icons-material";
import { Box, Divider, Skeleton, Stack, styled } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { useGetActionItemSummary } from "../../http";
import { ActionItem } from "./ActionItem";

const StyledStack = styled(Stack)<{ $isDraggable: boolean; $isDragging: boolean; $isPastView: boolean }>`
  padding-left: ${({ $isDraggable, theme }) => ($isDraggable ? "0px" : theme.spacing(1))};
  transition: ${({ theme }) => theme.transitions.create("background-color")};
  background-color: ${({ $isDragging, $isPastView, theme }) =>
    $isDragging ? theme.palette.primary.opacity10 : $isPastView ? "transparent" : undefined};

  .dragHandle {
    opacity: 1;
    @media (pointer: fine) {
      opacity: ${({ $isDragging }) => ($isDragging ? 1 : 0)};
    }
  }

  &:hover {
    background-color: ${({ $isPastView, theme }) => ($isPastView ? "transparent" : theme.palette.primary.opacity5)};

    .dragHandle {
      opacity: 1;
    }
  }
`;

const DragHandle = styled(Box)<{ $isDraggable: boolean; $isDisabled: boolean }>`
  color: ${({ theme, $isDisabled }) => ($isDisabled ? "transparent" : theme.palette.grey[200])};
  background-color: "transparent";
  @media (pointer: fine) {
    color: ${({ theme, $isDisabled }) => ($isDisabled ? "transparent" : theme.palette.primary.main)};
    background-color: ${({ theme, $isDisabled }) => ($isDisabled ? "transparent" : theme.palette.primary.transparent5)};
  }
  display: ${({ $isDraggable }) => ($isDraggable ? "flex" : "none")};
  align-self: stretch;
  align-items: center;
  justify-content: center;
  transition: ${({ theme }) => theme.transitions.create(["opacity"])};
  cursor: ${({ $isDisabled }) => ($isDisabled ? "default" : "pointer")};
  min-width: 20px;
`;

interface IProps {
  index: number;
  actionItemGuid: string;
  planGuid?: string;
  canEditPlan: boolean;
  canEdit: boolean;
  isPastView: boolean;
  isFocused: boolean;
  onBlur?: () => void;
}

const DraggableActionItem = (props: IProps) => {
  const { data: actionItem, isLoading } = useGetActionItemSummary(props.actionItemGuid);

  return isLoading || !actionItem ? (
    <Skeleton />
  ) : (
    <Draggable
      key={props.actionItemGuid}
      draggableId={props.actionItemGuid}
      index={props.index}
      isDragDisabled={!props.canEditPlan || props.isFocused}
    >
      {(provided, snapshot) => (
        <Box ref={provided.innerRef} {...provided.draggableProps}>
          <StyledStack
            direction="row"
            sx={{ alignItems: "center", py: 0, pr: { xs: 0.5, sm: 1 }, width: "100%" }}
            $isDraggable={props.canEditPlan}
            $isDragging={snapshot.isDragging}
            $isPastView={props.isPastView}
          >
            <DragHandle
              className="dragHandle"
              $isDraggable={props.canEditPlan}
              $isDisabled={props.isFocused}
              {...provided.dragHandleProps}
            >
              <DragIndicatorRounded />
            </DragHandle>
            <ActionItem
              actionItemGuid={props.actionItemGuid}
              planGuid={props.planGuid}
              canEditPlan={props.canEditPlan}
              canEdit={props.canEdit}
              isPastView={props.isPastView}
              isFocused={props.isFocused}
              onBlur={props.onBlur}
            />
          </StyledStack>
          <Divider variant="dashed" orientation="horizontal" sx={{ mx: 1 }} />
        </Box>
      )}
    </Draggable>
  );
};

export { DraggableActionItem };
