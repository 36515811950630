import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { ApplicationError } from "../models";

interface ILocationState {
  error: ApplicationError;
}

interface IProps {
  children?: React.ReactNode;
}

const Layout = (props: IProps) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const locationState = location.state as ILocationState;

    if (locationState?.error != null) {
      enqueueSnackbar("Oops! Something went wrong.", {
        variant: "error",
      });
      window.history.replaceState({}, document.title);
    }
  }, [enqueueSnackbar, location.state]);

  return (
    <Box width="100%" minWidth={"0px"}>
      {props.children}
    </Box>
  );
};

export { Layout };
