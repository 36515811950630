import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { CreateSurveyInstanceResponseDto, FetchError, GetSurveyInstanceResponseDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  userId?: string;
  cityName?: string;
  stateCode?: string;
  countryCode?: string;
  joinedDate?: string;
  surveyInstanceGuid: string;
  teamSlug?: string;
};

const useCreateSurveyInstanceResponseRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createSurveyInstanceResponseRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: CreateSurveyInstanceResponseDto = {
      userId: variables.userId,
      cityName: variables.cityName,
      stateCode: variables.stateCode,
      countryCode: variables.countryCode,
      joinedDate: variables.joinedDate,
      surveyInstanceGuid: variables.surveyInstanceGuid,
      teamSlug: variables.teamSlug,
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-response`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response;
  }

  return { createSurveyInstanceResponseRequest };
};

const useCreateSurveyInstanceResponse = () => {
  const { createSurveyInstanceResponseRequest } = useCreateSurveyInstanceResponseRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSurveyInstanceResponseRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.surveys.getSurveyInstanceResponse(variables.surveyInstanceGuid));

      let response: GetSurveyInstanceResponseDto = {
        responseGuid: variables.guid,
        cityName: variables.cityName,
        stateCode: variables.stateCode,
        countryCode: variables.countryCode,
        joinedDate: variables.joinedDate,
        teamSlug: variables.teamSlug,
        submittedAtUtc: undefined,
        questions: [],
      };

      // Snapshot the current cache value
      const cachedData = queryClient.getQueryData<GetSurveyInstanceResponseDto>(
        queryKeys.surveys.getSurveyInstanceResponse(variables.surveyInstanceGuid)
      );

      queryClient.setQueryData(queryKeys.surveys.getSurveyInstanceResponse(variables.surveyInstanceGuid), response);

      return { cachedData };
    },
    onError: (_error, variables, context) => {
      // On error, rollback
      queryClient.setQueryData(
        queryKeys.surveys.getSurveyInstanceResponse(variables.surveyInstanceGuid),
        context?.cachedData
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.surveys.getSurveyInstanceResponse(variables.surveyInstanceGuid));
    },
  });
};

export { useCreateSurveyInstanceResponse };
