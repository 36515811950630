import { useQueryClient } from "@tanstack/react-query";
import { configuration } from "../configuration";
import { ConnectionType } from "../constants";
import { CreatePlanVersionResultDto, ErrorsDto, queryKeys, useAccessToken, useHttp } from "../http";
import {
  GoalDetailMapper,
  MilestoneDetailMapper,
  PlanValueDetailMapper,
  PlanVersionMapper,
  StrategyDetailMapper,
} from "../mapping";
import {
  ApplicationError,
  IConnectionPoint,
  IGoalDetail,
  IMilestoneDetail,
  IPlan,
  IPlanValueDetail,
  IPlanVersionSummary,
  IStrategyDetail,
  PlanTextFieldDetail,
  PlanVersionSummary,
  Result,
} from "../models";

const usePlan = () => {
  const { getAccessToken } = useAccessToken();
  const {
    getPlanRequest,
    getPlanVersionRequest,
    getPlanVersionsRequest,
    createPlanVersionRequest,
    editPlanVersionRequest,
    editPlanVersionNotesRequest,
    deletePlanVersionRequest,
    publishPlanVersionRequest,
    getPlanPurposeRequest,
    getPlanValueRequest,
    getPlanMissionRequest,
    getMilestoneRequest,
    getGoalRequest,
    getConnectionPointsRequest,
    getStrategyRequest,
  } = useHttp();

  const queryClient = useQueryClient();

  async function getPlanVersion(slug: string, planVersionId: string | null): Promise<Result<IPlan, ApplicationError>> {
    try {
      var response;

      if (planVersionId === null) {
        response = await getPlanRequest(slug);
      } else {
        response = await getPlanVersionRequest(slug, planVersionId);
      }

      if (response.ok && response.data) {
        return Result.success(PlanVersionMapper.fromGetPlanVersionDto(response.data));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function getPlanVersions(slug: string): Promise<Result<IPlanVersionSummary[], ApplicationError>> {
    try {
      var response = await getPlanVersionsRequest(slug);

      if (response.ok && response.data) {
        return Result.success(PlanVersionSummary.fromDto(response.data));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function createPlan(userId: string | undefined, slug: string): Promise<Result<void, ApplicationError>> {
    const accessToken = await getAccessToken();

    try {
      var response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          createdByUserId: userId,
        }),
      });

      if (response.ok) {
        queryClient.invalidateQueries(queryKeys.plans.getPlans());
        return Result.success(undefined);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function createPlanVersion(
    userId: string | undefined,
    slug: string
  ): Promise<Result<CreatePlanVersionResultDto, ApplicationError>> {
    try {
      var response = await createPlanVersionRequest(userId, slug);

      if (response.ok && response.data) {
        return Result.success({ slug: response.data.slug, planVersionId: response.data.planVersionId });
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function editPlanVersion(
    userId: string,
    slug: string,
    planVersionId: string,
    plan: IPlan
  ): Promise<Result<void, ErrorsDto | ApplicationError>> {
    try {
      var response = await editPlanVersionRequest(slug, planVersionId, {
        modifiedByUserId: userId,
        purpose: plan.purpose,
        values: plan.values.map((value) => ({
          description: value.description,
          guid: value.guid,
          sortOrder: value.sortOrder,
        })),
        mission: plan.mission,
        goals: plan.goals.map((goal) => ({
          description: goal.description,
          guid: goal.guid,
          sortOrder: goal.sortOrder,
        })),
        milestones: plan.milestones.map((milestone) => ({
          description: milestone.description,
          guid: milestone.guid,
          sortOrder: milestone.sortOrder,
        })),
        strategies: plan.strategies.map((strategy) => ({
          description: strategy.description,
          guid: strategy.guid,
          informedByStrategyHistoryId:
            strategy.informedBy?.type === ConnectionType.Strategy ? strategy.informedBy.historyId : undefined,
          informedByKeyInitiativeHistoryId:
            strategy.informedBy?.type === ConnectionType.KeyInitiative ? strategy.informedBy.historyId : undefined,
          keyInitiatives: strategy.keyInitiatives.map((keyInitiative) => ({
            description: keyInitiative.description,
            guid: keyInitiative.guid,
            informedByStrategyHistoryId:
              keyInitiative.informedBy?.type === ConnectionType.Strategy
                ? keyInitiative.informedBy.historyId
                : undefined,
            informedByKeyInitiativeHistoryId:
              keyInitiative.informedBy?.type === ConnectionType.KeyInitiative
                ? keyInitiative.informedBy.historyId
                : undefined,
            notes: keyInitiative.notes,
            sortOrder: keyInitiative.sortOrder,
            userIds: keyInitiative.users.map((user) => user.userId),
          })),
          notes: strategy.notes,
          sortOrder: strategy.sortOrder,
        })),
      });

      if (response.ok) {
        return Result.success(undefined);
      } else if (response.data) {
        return Result.error(response.data);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function editPlanVersionNotes(
    slug: string,
    planVersionId: string,
    notes: string
  ): Promise<Result<void, ApplicationError>> {
    try {
      var response = await editPlanVersionNotesRequest(slug, planVersionId, notes);

      if (response.ok) {
        return Result.success(undefined);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function deletePlanVersion(
    slug: string,
    planVersionId: string,
    userId: string | undefined
  ): Promise<Result<void, ApplicationError>> {
    try {
      var response = await deletePlanVersionRequest(slug, planVersionId, userId);

      if (response.ok) {
        return Result.success(undefined);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function publishPlanVersion(
    slug: string,
    planVersionId: string,
    notes: string
  ): Promise<Result<void, ApplicationError>> {
    try {
      var response = await publishPlanVersionRequest(slug, planVersionId, notes);

      if (response.ok) {
        return Result.success(undefined);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function getPlanPurposeDetail(slug: string): Promise<Result<PlanTextFieldDetail, ApplicationError>> {
    try {
      var response = await getPlanPurposeRequest(slug);

      if (response.ok && response.data) {
        return Result.success(PlanTextFieldDetail.fromDto(response.data));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function getPlanValueDetail(slug: string, guid: string): Promise<Result<IPlanValueDetail, ApplicationError>> {
    try {
      var response = await getPlanValueRequest(slug, guid);

      if (response.ok && response.data) {
        return Result.success(PlanValueDetailMapper.fromDto(response.data));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function getPlanMissionDetail(slug: string): Promise<Result<PlanTextFieldDetail, ApplicationError>> {
    try {
      var response = await getPlanMissionRequest(slug);

      if (response.ok && response.data) {
        return Result.success(PlanTextFieldDetail.fromDto(response.data));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function getGoalDetail(slug: string, goalGuid: string): Promise<Result<IGoalDetail, ApplicationError>> {
    try {
      var response = await getGoalRequest(slug, goalGuid);

      if (response.ok && response.data) {
        return Result.success(GoalDetailMapper.fromDto(response.data));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function getMilestoneDetail(
    slug: string,
    milestoneGuid: string
  ): Promise<Result<IMilestoneDetail, ApplicationError>> {
    try {
      var response = await getMilestoneRequest(slug, milestoneGuid);

      if (response.ok && response.data) {
        return Result.success(MilestoneDetailMapper.fromDto(response.data));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function getConnectionPoints(slug: string): Promise<Result<IConnectionPoint[], ApplicationError>> {
    try {
      var response = await getConnectionPointsRequest(slug);

      if (response.ok && response.data) {
        return Result.success(response.data.connectionPoints);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function getStrategyDetail(
    slug: string,
    strategyGuid: string
  ): Promise<Result<IStrategyDetail, ApplicationError>> {
    try {
      var response = await getStrategyRequest(slug, strategyGuid);

      if (response.ok && response.data) {
        return Result.success(StrategyDetailMapper.fromDto(response.data));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  return {
    getPlanVersion,
    getPlanVersions,
    createPlan,
    createPlanVersion,
    editPlanVersion,
    editPlanVersionNotes,
    deletePlanVersion,
    publishPlanVersion,
    getPlanPurposeDetail,
    getPlanValueDetail,
    getPlanMissionDetail,
    getMilestoneDetail,
    getGoalDetail,
    getConnectionPoints,
    getStrategyDetail,
  };
};

export { usePlan };
