import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { GraphQLClient, gql } from "graphql-request";
import { configuration } from "../../../configuration";
import { OrganizationReportRow } from "../../../models";
import { GetOrganizationReportDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetOrganizationReportRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getOrganizationReportRequest({
    queryKey,
  }: QueryFunctionContext<
    ReturnType<typeof queryKeys.reports.getOrganizationReport>
  >): Promise<GetOrganizationReportDto> {
    const accessToken = await getAccessToken();
    const [{ includeRoles, includeRoleTags, includeTeams, includeParentTeams }] = queryKey;

    const graphQLClient = new GraphQLClient(`${configuration.apiRootUrl}/graphql/`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const query = gql`
      query (
        $includeRoles: Boolean!
        $includeRoleTags: Boolean!
        $includeTeams: Boolean!
        $includeParentTeams: Boolean!
      ) {
        organizationReport {
          userId
          name
          email
          startDate
          location
          roles @include(if: $includeRoles) {
            guid
            name
            isPrimaryRole
            tags @include(if: $includeRoleTags)
            team @include(if: $includeTeams) {
              guid
              name
              slug
              parentTeam @include(if: $includeParentTeams) {
                guid
                name
                slug
              }
            }
          }
        }
      }
    `;

    const variables = {
      includeRoles: includeRoles ?? false,
      includeRoleTags: includeRoleTags ?? false,
      includeTeams: includeTeams ?? false,
      includeParentTeams: includeParentTeams ?? false,
    };

    const data = await graphQLClient.request<GetOrganizationReportDto>(query, variables);

    return data;
  }

  return { getOrganizationReportRequest };
};

const useGetOrganizationReport = (
  includeRoles?: boolean,
  includeRoleTags?: boolean,
  includeTeams?: boolean,
  includeParentTeams?: boolean
) => {
  const { getOrganizationReportRequest } = useGetOrganizationReportRequest();

  return useQuery({
    queryKey: queryKeys.reports.getOrganizationReport(includeRoles, includeRoleTags, includeTeams, includeParentTeams),
    queryFn: getOrganizationReportRequest,
    select: (data): OrganizationReportRow[] => {
      return data.organizationReport.reduce((rows, person) => {
        if (person.roles === undefined || person.roles.length === 0) {
          return [
            ...rows,
            {
              id: `${person.userId}`,
              personId: person.userId,
              personName: person.name,
              personEmail: person.email,
              personLocation: person.location,
              personDateJoined: person.startDate,
            } as OrganizationReportRow,
          ];
        }

        return [
          ...rows,
          ...(person.roles?.map(
            (role): OrganizationReportRow => ({
              id: `${person.userId}${role.guid}`,
              teamId: role.team?.guid,
              teamName: role.team?.name,
              teamSlug: role.team?.slug,
              connectedToTeam: role.team?.parentTeam?.name,
              roleId: role.guid,
              roleName: role.name,
              roleTags: role.tags?.join(", "),
              personId: person.userId,
              personName: person.name,
              personEmail: person.email,
              personPrimaryRole: role.isPrimaryRole,
              personLocation: person.location,
              personDateJoined: person.startDate,
            })
          ) ?? []),
        ];
      }, [] as OrganizationReportRow[]);
    },
  });
};

export { useGetOrganizationReport };
