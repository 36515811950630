import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError } from "../../types";
import { EditSurveyQuestionNameDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  surveyGuid: string;
  name: string;
  modifiedByUserId: string;
};

const useEditSurveyQuestionNameRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editSurveyQuestionNameRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();

    const body: EditSurveyQuestionNameDto = {
      name: variables.name,
      modifiedByUserId: variables.modifiedByUserId,
      surveyGuid: variables?.surveyGuid
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-questions/${variables.guid}/name`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editSurveyQuestionNameRequest };
};

const useEditSurveyQuestionName = () => {
  const { editSurveyQuestionNameRequest } = useEditSurveyQuestionNameRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editSurveyQuestionNameRequest,
    onSuccess: async (_data, variables) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurveyQuestion(variables.guid) });
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurveyQuestions(variables.surveyGuid) });
    },
  });
};

export { useEditSurveyQuestionName };
