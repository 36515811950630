import { HubConnection } from "@microsoft/signalr";
import React from "react";
import { CommentSubject } from "../../constants";
import { IUser } from "../../models";

interface ISignalRContext {
  connection: HubConnection | undefined;
  joinGroup: (groupGuid: string) => void;
  leaveGroup: (groupGuid: string) => void;
  sendChatMessage: (authorName: string, authorUserId: string, authorProfilePhoto: string, message: string) => void;
  actionItemUpdated: (actionItemGuid: string) => void;
  createComment: (
    subjectType: CommentSubject,
    groupGuid: string,
    commentGuid: string,
    author: IUser,
    lastModifiedUtc: string,
    text: string
  ) => void;
  editComment: (subjectGuid: string, commentGuid: string, lastModifiedUtc: string, updatedText: string) => void;
  deleteComment: (subjectType: CommentSubject, subjectGuid: string, commentGuid: string) => void;
  registerReceiveChatMessage: (
    callback: (authorName: string, authorUserId: string, authorProfilePhoto: string, message: string) => void
  ) => void;
  registerReceiveActionItemUpdated: (callback: (actionItemGuid: string) => void) => void;
  registerReceiveCreateComment: (
    callback: (commentGuid: string, author: IUser, lastModifiedUtc: string, text: string) => void
  ) => void;
  registerReceiveEditComment: (
    callback: (commentGuid: string, lastModifiedUtc: string, updatedText: string) => void
  ) => void;
  registerReceiveDeleteComment: (callback: (commentGuid: string) => void) => void;
  unregisterReceiveChatMessage: (
    callback: (authorName: string, authorUserId: string, authorProfilePhoto: string, message: string) => void
  ) => void;
  unregisterReceiveActionItemUpdated: (callback: (actionItemGuid: string) => void) => void;
  unregisterReceiveCreateComment: (
    callback: (commentGuid: string, author: IUser, lastModifiedUtc: string, text: string) => void
  ) => void;
  unregisterReceiveEditComment: (
    callback: (commentGuid: string, lastModifiedUtc: string, updatedText: string) => void
  ) => void;
  unregisterReceiveDeleteComment: (callback: (commentGuid: string) => void) => void;
}

const SignalRContext = React.createContext<ISignalRContext>({
  connection: undefined,
  joinGroup: () => null,
  leaveGroup: () => null,
  sendChatMessage: () => null,
  actionItemUpdated: () => null,
  createComment: () => null,
  editComment: () => null,
  deleteComment: () => null,
  registerReceiveChatMessage: () => null,
  registerReceiveActionItemUpdated: () => null,
  registerReceiveCreateComment: () => null,
  registerReceiveEditComment: () => null,
  registerReceiveDeleteComment: () => null,
  unregisterReceiveChatMessage: () => null,
  unregisterReceiveActionItemUpdated: () => null,
  unregisterReceiveCreateComment: () => null,
  unregisterReceiveEditComment: () => null,
  unregisterReceiveDeleteComment: () => null,
});

export { SignalRContext };
export type { ISignalRContext };
