import { MoreVertRounded } from "@mui/icons-material";
import { Button, IconButton, Menu, MenuItem as MuiMenuItem, Skeleton, Stack, styled } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { SettingsContext } from "../../../../components";
import { Links } from "../../../../constants";
import { useError, usePlan, useSession } from "../../../../hooks";
import { queryKeys } from "../../../../http";
import { IEditTeamPlanPageAction, IEditTeamPlanPageState } from "../state";
import { ConfirmPublishModal } from "./ConfirmPublishModal";

const MenuItem = styled(MuiMenuItem)`
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  font-size: 1rem;
  margin: 0 ${({ theme }) => theme.spacing(0.25)};
`;

interface IProps {
  state: IEditTeamPlanPageState;
  dispatch: React.Dispatch<IEditTeamPlanPageAction>;
  onRefreshPlanHistory: () => Promise<void>;
  onError: () => void;
}

const EditTeamPlanActions = (props: IProps) => {
  const { state, dispatch } = props;
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const previewUrl = undefined; // useHref(Links.TeamPlanVersion(state.plan.teamSlug, state.plan.planVersionId));
  const { userId } = useSession();
  const { editOpspSettings, updateEditOpspSettings } = useContext(SettingsContext);
  const { deletePlanVersion, editPlanVersion, publishPlanVersion } = usePlan();
  const { setError } = useError();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmPublishModalVisible, setIsConfirmPublishModalVisible] = useState(false);
  const [isConfirmPublishModalLoading, setIsConfirmPublishModalLoading] = useState(false);
  const queryClient = useQueryClient();

  async function handlePublishClicked(notes: string) {
    if (userId) {
      setIsConfirmPublishModalLoading(true);

      dispatch({ type: "saving" });
      (await editPlanVersion(userId, state.plan.teamSlug, state.plan.planVersionId, state.plan)).match(
        async () => {
          (await publishPlanVersion(state.plan.teamSlug, state.plan.planVersionId, notes)).match(
            async () => {
              await queryClient.removeQueries({ queryKey: [{ key: "planVersion", slug: state.plan.teamSlug }] });
              await queryClient.removeQueries({ queryKey: queryKeys.plans.getPlanVersions(state.plan.teamSlug) });

              navigate(Links.TeamStrategicMap(state.plan.teamSlug));
            },
            (error) => setError(error)
          );
        },
        (error) => {
          enqueueSnackbar("There was an error saving your most recent change. Please refresh the page and try again.", {
            variant: "error",
          });
          console.error(error);
        }
      );
    }
  }

  async function handleSaveClicked() {
    setMenuAnchor(null);
    if (userId) {
      dispatch({ type: "saving" });
      (await editPlanVersion(userId, state.plan.teamSlug, state.plan.planVersionId, state.plan)).match(
        async () => {
          dispatch({ type: "saved", lastModifiedUtc: DateTime.utc().minus(1000) });
          await props.onRefreshPlanHistory();
        },
        (error) => {
          props.onError();
          console.error(error);
          dispatch({ type: "saved", lastModifiedUtc: state.plan.lastModifiedUtc });
        }
      );
    }
  }

  async function handleDiscardClicked(slug: string, planVersionIdToDelete: string) {
    setMenuAnchor(null);

    try {
      await confirm({
        title: "Delete Draft",
        description: "Are you sure you want to delete this draft?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    (await deletePlanVersion(slug, planVersionIdToDelete, userId)).match(
      async () => {
        await queryClient.removeQueries({
          queryKey: [{ key: "planVersion", slug }],
        });

        // If we're viewing the plan that has just been deleted, redirect to the published version
        if (planVersionIdToDelete === state.plan.planVersionId) {
          navigate(Links.TeamStrategicMap(slug));
        } else {
          await props.onRefreshPlanHistory();
        }
      },
      (error) => setError(error)
    );
  }

  function handleShowHintsClicked() {
    setMenuAnchor(null);
    updateEditOpspSettings({
      showHints: true,
    });
  }

  return (
    <>
      {state.isLoadingPlan ? (
        <Skeleton variant="rectangular" height="40px" width="300px" />
      ) : (
        <>
          <Stack direction="row" spacing={0.5}>
            <Button
              variant="outlined"
              sx={{ whiteSpace: "nowrap", minWidth: "max-content" }}
              disabled={state.isSaving}
              onClick={() => window.open(previewUrl, "_blank")}
            >
              Preview
            </Button>
            <Button
              variant="contained"
              sx={{ whiteSpace: "nowrap", minWidth: "max-content" }}
              disabled={state.isSaving}
              onClick={() => setIsConfirmPublishModalVisible(true)}
            >
              Save and publish
            </Button>
            <IconButton disabled={state.isSaving} onClick={(e) => setMenuAnchor(e.currentTarget)}>
              <MoreVertRounded fontSize="large" />
            </IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => setMenuAnchor(null)}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem onClick={handleSaveClicked}>Save draft</MenuItem>
              <MenuItem onClick={() => handleDiscardClicked(state.plan.teamSlug, state.plan.planVersionId)}>
                Discard draft
              </MenuItem>
              {!editOpspSettings.showHints && <MenuItem onClick={handleShowHintsClicked}>Show hints</MenuItem>}
            </Menu>
          </Stack>

          <ConfirmPublishModal
            visible={isConfirmPublishModalVisible}
            loading={isConfirmPublishModalLoading}
            onPublish={handlePublishClicked}
            onClose={() => setIsConfirmPublishModalVisible(false)}
          />
        </>
      )}
    </>
  );
};

export { EditTeamPlanActions };
