import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { DeleteRoleDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  teamSlug: string;
  guid: string;
  modifiedByUserId: string;
};

const useDeleteRoleRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteRoleRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: DeleteRoleDto = {
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/roles/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteRoleRequest };
};

const useDeleteRole = () => {
  const { deleteRoleRequest } = useDeleteRoleRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteRoleRequest,
    onSuccess: async (_, variables) => {
      await queryClient.removeQueries({ queryKey: queryKeys.roles.getRole(variables.guid)});
      await queryClient.invalidateQueries({ queryKey: queryKeys.roles.getRolesForUser() });
      await queryClient.invalidateQueries({ queryKey: queryKeys.roles.getOpenRoles()});
      await queryClient.refetchQueries({queryKey: queryKeys.teams.getTeams(variables.modifiedByUserId)});
      await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeamNetworkGraph(variables.teamSlug)});
      await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeamNetworkList(variables.teamSlug)});
    },
  });
};

export { useDeleteRole };
