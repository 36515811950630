import { Stack, styled, Typography, TypographyProps } from "@mui/material";
import { useState } from "react";
import { IPlan } from "../../../../models";
import { ViewCard } from "./ViewCard";
import { ViewNow } from "./ViewNow";
import { ViewWhere } from "./ViewWhere";
import { ViewWhy } from "./ViewWhy";

const TitleLink = styled((props: { $isActive: boolean } & TypographyProps) => (
  <Typography variant="h6" {...props}>
    {props.children}
  </Typography>
))`
  color: ${({ $isActive, theme }) => ($isActive ? theme.palette.primary.main : "inherit")};
  cursor: pointer;
  text-transform: uppercase;
  transition: ${({ theme }) => theme.transitions.create("color")};

  &:hover {
    color: ${({ $isActive, theme }) => ($isActive ? theme.palette.primary.main : theme.palette.grey[500])};
  }
`;

enum Tab {
  Why,
  Where,
  Now,
}

interface IProps {
  isLoading: boolean;
  disabled?: boolean;
  faded?: boolean;
  plan: IPlan | undefined;
}

const ViewCondensedPlan = (props: IProps) => {
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.Now);

  return (
    <ViewCard
      title={
        <Stack direction="row" spacing={0.5}>
          <TitleLink $isActive={currentTab === Tab.Why} onClick={() => setCurrentTab(Tab.Why)}>
            Why
          </TitleLink>
          <TitleLink $isActive={currentTab === Tab.Where} onClick={() => setCurrentTab(Tab.Where)}>
            Where
          </TitleLink>
          <TitleLink $isActive={currentTab === Tab.Now} onClick={() => setCurrentTab(Tab.Now)}>
            Now
          </TitleLink>
        </Stack>
      }
    >
      {currentTab === Tab.Why && (
        <ViewWhy isLoading={props.isLoading} disabled={props.disabled} faded={props.faded} plan={props.plan} />
      )}
      {currentTab === Tab.Where && (
        <ViewWhere isLoading={props.isLoading} disabled={props.disabled} faded={props.faded} plan={props.plan} />
      )}
      {currentTab === Tab.Now && (
        <ViewNow isLoading={props.isLoading} disabled={props.disabled} faded={props.faded} plan={props.plan} />
      )}
    </ViewCard>
  );
};

export { ViewCondensedPlan };
