import { Stack } from "@mui/material";
import { createContext, useContext, useEffect } from "react";
import { Navigate, Outlet, useParams } from "react-router";
import { HeaderLayout } from "../../../components";
import { Paths } from "../../../constants";
import { useSession } from "../../../hooks";
import { useGetSurvey } from "../../../http";
import { ISurvey } from "../../../models";
import { setTitlePrefix } from "../../../utilities";
import { SurveyActions, SurveyNavBar, SurveyTitle } from "./components";

interface IParams {
  guid: string;
}

interface ISurveyPageLayoutContext {
  isLoadingSurvey: boolean;
  survey: ISurvey | undefined;
}

const SurveyPageLayoutContext = createContext<ISurveyPageLayoutContext | undefined>(undefined);

const SurveyPageLayout = () => {
  const { guid } = useParams<keyof IParams>() as IParams;
  const { userId } = useSession();
  const { data: survey, isLoading } = useGetSurvey(guid);

  useEffect(() => {
    setTitlePrefix(survey?.name);
  }, [survey?.name]);

  if (survey && userId && !survey.administrators.some((admin) => admin.userId === userId)) {
    return <Navigate to={Paths.Forbidden} replace />;
  }

  return (
    <SurveyPageLayoutContext.Provider
      value={{
        isLoadingSurvey: isLoading,
        survey,
      }}
    >
      <HeaderLayout
        title={
          <Stack pb={0} spacing={0.45}>
            <SurveyTitle />
            <SurveyNavBar />
          </Stack>
        }
        actions={<SurveyActions />}
        headerContainerProps={{
          sx: {
            alignItems: "flex-end",
            pb: 0,
          },
        }}
      >
        <Outlet />
      </HeaderLayout>
    </SurveyPageLayoutContext.Provider>
  );
};

const useSurveyPageLayoutContext = () => {
  const context = useContext(SurveyPageLayoutContext);

  if (context === undefined) {
    throw new Error("SurveyRouteContext is not initialized.");
  }

  return context;
};

export { SurveyPageLayout, SurveyPageLayoutContext, useSurveyPageLayoutContext };
