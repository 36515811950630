import { useEffect, useState } from "react";
import { Typography } from "../../../../components";
import { useInterval } from "../../../../hooks";
import { IEditTeamPlanPageState } from "../state";

interface IProps {
  state: IEditTeamPlanPageState;
}

const AutosaveStatus = (props: IProps) => {
  const { state } = props;
  const [lastModifiedUtc, setLastModifiedUtc] = useState(state.lastModifiedUtc);

  useEffect(() => {
    setLastModifiedUtc(state.lastModifiedUtc);
  }, [state.lastModifiedUtc]);

  useInterval(() => {
    setLastModifiedUtc(state.lastModifiedUtc);
  }, 1000 * 60);

  if (state.isSaving) {
    return <Typography variant="body1">Saving...</Typography>;
  } else {
    return <Typography variant="body1">Last saved {lastModifiedUtc.toLocal().toRelative()}</Typography>;
  }
};

export { AutosaveStatus };
