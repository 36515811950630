import { CheckCircle, Circle, CircleOutlined } from "@mui/icons-material";
import { SxProps, useTheme } from "@mui/material";
import { ACTION_ITEM_STATUSES, ActionItemStatus, DEFAULT_ACTION_ITEM_STATUS } from "../constants";

interface IProps {
  status: ActionItemStatus | undefined;
  faded?: boolean;
  sx?: SxProps;
}

const ActionItemStatusIcon = (props: IProps) => {
  const theme = useTheme();
  const status = props.status ?? DEFAULT_ACTION_ITEM_STATUS;

  switch (status) {
    case ActionItemStatus.Open:
      return (
        <CircleOutlined
          sx={{
            color: props.faded ? theme.palette.grey[300] : ACTION_ITEM_STATUSES.find((x) => x.id === status)!.color,
            ...props.sx,
          }}
        />
      );
    case ActionItemStatus.Closed:
      return (
        <CheckCircle
          sx={{
            color: props.faded ? theme.palette.grey[300] : ACTION_ITEM_STATUSES.find((x) => x.id === status)!.color,
            ...props.sx,
          }}
        />
      );
    default:
      return (
        <Circle
          sx={{
            color: props.faded ? theme.palette.grey[300] : ACTION_ITEM_STATUSES.find((x) => x.id === status)!.color,
            ...props.sx,
          }}
        />
      );
  }
};

export { ActionItemStatusIcon };
