import { DateTime } from "luxon";
import { Reducer } from "react";
import { v4 as newGuid } from "uuid";
import { ActionItemStatus } from "../../../../constants";
import { lexorank } from "../../../../utilities";
import { IEditTeamPlanPageAction } from "./actions";
import { IEditTeamPlanPageState } from "./types";

const initialState: IEditTeamPlanPageState = {
  plan: {
    teamName: "",
    teamSlug: "",
    parentTeamSlug: "",
    planVersionId: "",
    draftPlanVersionId: undefined,
    draftCreatedBy: undefined,
    purpose: "",
    previousPurpose: undefined,
    values: [],
    mission: "",
    previousMission: undefined,
    lastModifiedByDisplayName: "",
    lastModifiedUtc: DateTime.utc(),
    isDraft: false,
    isPublished: false,
    notes: "",
    goals: [],
    milestones: [],
    strategies: [],
  },
  planHistory: [],
  isLoadingPlan: true,
  isLoadingPlanHistory: true,
  hasUnsavedChanges: false,
  isSaving: false,
  lastModifiedUtc: DateTime.utc(),
};

const reducer: Reducer<IEditTeamPlanPageState, IEditTeamPlanPageAction> = (
  state: IEditTeamPlanPageState,
  action: IEditTeamPlanPageAction
): IEditTeamPlanPageState => {
  switch (action.type) {
    case "loading_plan": {
      return {
        ...state,
        isLoadingPlan: true,
      };
    }
    case "loaded_plan": {
      return {
        ...state,
        isLoadingPlan: false,
        plan: action.plan,
        lastModifiedUtc: action.plan.lastModifiedUtc,
      };
    }
    case "loading_plan_history": {
      return {
        ...state,
        isLoadingPlanHistory: true,
      };
    }
    case "loaded_plan_history": {
      return {
        ...state,
        isLoadingPlanHistory: false,
        planHistory: action.planHistory,
      };
    }
    case "saving": {
      return {
        ...state,
        hasUnsavedChanges: false,
        isSaving: true,
      };
    }
    case "saved": {
      return {
        ...state,
        isSaving: false,
        lastModifiedUtc: action.lastModifiedUtc,
      };
    }
    case "edit_purpose": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          purpose: action.purpose,
        },
      };
    }
    case "add_value": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          values: [
            ...state.plan.values,
            {
              guid: newGuid(),
              description: "",
              sortOrder: lexorank.getNextRank(state.plan.values),
              diffStatus: undefined,
            },
          ],
        },
      };
    }
    case "edit_value": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          values: state.plan.values.map((value, index) => {
            if (index === action.index) {
              return {
                ...value,
                description: action.description,
              };
            }

            return value;
          }),
        },
      };
    }
    case "delete_value": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          values: state.plan.values.filter((_, index) => index !== action.index),
        },
      };
    }
    case "reorder_value": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          values: state.plan.values.map((value, index) => {
            if (index === action.sourceIndex) {
              return {
                ...value,
                sortOrder: lexorank.getRank(state.plan.values, action.sourceIndex, action.destinationIndex),
              };
            }

            return value;
          }),
        },
      };
    }
    case "edit_mission": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          mission: action.mission,
        },
      };
    }
    case "add_milestone": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          milestones: [
            ...state.plan.milestones,
            {
              guid: newGuid(),
              description: "",
              sortOrder: lexorank.getNextRank(state.plan.milestones),
              diffStatus: undefined,
            },
          ],
        },
      };
    }
    case "edit_milestone": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          milestones: state.plan.milestones.map((milestone, index) => {
            if (index === action.index) {
              return {
                ...milestone,
                description: action.description,
              };
            }

            return milestone;
          }),
        },
      };
    }
    case "delete_milestone": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          milestones: state.plan.milestones.filter((_, index) => index !== action.index),
        },
      };
    }
    case "reorder_milestone": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          milestones: state.plan.milestones.map((milestone, index) => {
            if (index === action.sourceIndex) {
              return {
                ...milestone,
                sortOrder: lexorank.getRank(state.plan.milestones, action.sourceIndex, action.destinationIndex),
              };
            }

            return milestone;
          }),
        },
      };
    }
    case "add_goal": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          goals: [
            ...state.plan.goals,
            {
              guid: newGuid(),
              description: "",
              sortOrder: lexorank.getNextRank(state.plan.goals),
              diffStatus: undefined,
            },
          ],
        },
      };
    }
    case "edit_goal": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          goals: state.plan.goals.map((goal, index) => {
            if (index === action.index) {
              return {
                ...goal,
                description: action.description,
              };
            }

            return goal;
          }),
        },
      };
    }
    case "delete_goal": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          goals: state.plan.goals.filter((_, index) => index !== action.index),
        },
      };
    }
    case "reorder_goal": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          goals: state.plan.goals.map((goal, index) => {
            if (index === action.sourceIndex) {
              return {
                ...goal,
                sortOrder: lexorank.getRank(state.plan.goals, action.sourceIndex, action.destinationIndex),
              };
            }

            return goal;
          }),
        },
      };
    }
    case "add_strategy": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          strategies: [
            ...state.plan.strategies,
            {
              guid: newGuid(),
              strategyHistoryId: undefined,
              description: "",
              notes: "",
              sortOrder: lexorank.getNextRank(state.plan.strategies),
              diffStatus: undefined,
              informedBy: undefined,
              keyInitiatives: [],
            },
          ],
        },
      };
    }
    case "edit_strategy": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          strategies: state.plan.strategies.map((strategy, index) => {
            if (index === action.index) {
              return {
                ...strategy,
                ...action.changes,
              };
            }

            return strategy;
          }),
        },
      };
    }
    case "delete_strategy": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          strategies: state.plan.strategies.filter((_, index) => index !== action.index),
        },
      };
    }
    case "reorder_strategy": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          strategies: state.plan.strategies.map((strategy, index) => {
            if (index === action.sourceIndex) {
              return {
                ...strategy,
                sortOrder: lexorank.getRank(state.plan.strategies, action.sourceIndex, action.destinationIndex),
              };
            }

            return strategy;
          }),
        },
      };
    }
    case "add_key_initiative": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          strategies: state.plan.strategies.map((strategy, strategyIndex) => {
            if (strategyIndex === action.strategyIndex) {
              return {
                ...strategy,
                keyInitiatives: [
                  ...strategy.keyInitiatives,
                  {
                    guid: newGuid(),
                    keyInitiativeHistoryId: undefined,
                    description: "",
                    notes: "",
                    sortOrder: lexorank.getNextRank(state.plan.strategies[action.strategyIndex].keyInitiatives),
                    diffStatus: undefined,
                    users: [],
                    status: ActionItemStatus.Open,
                    informedBy: undefined,
                  },
                ],
              };
            }

            return strategy;
          }),
        },
      };
    }
    case "edit_key_initiative": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          strategies: state.plan.strategies.map((strategy, strategyIndex) => {
            if (strategyIndex === action.strategyIndex) {
              return {
                ...strategy,
                keyInitiatives: strategy.keyInitiatives.map((keyInitiative, keyInitiativeIndex) => {
                  if (keyInitiativeIndex === action.keyInitiativeIndex) {
                    return {
                      ...keyInitiative,
                      ...action.changes,
                    };
                  }

                  return keyInitiative;
                }),
              };
            }

            return strategy;
          }),
        },
      };
    }
    case "delete_key_initiative": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          strategies: state.plan.strategies.map((strategy, strategyIndex) => {
            if (strategyIndex === action.strategyIndex) {
              return {
                ...strategy,
                keyInitiatives: strategy.keyInitiatives.filter((_, index) => index !== action.index),
              };
            }

            return strategy;
          }),
        },
      };
    }
    case "reorder_key_initiative": {
      return {
        ...state,
        hasUnsavedChanges: true,
        plan: {
          ...state.plan,
          strategies: state.plan.strategies.map((strategy, strategyIndex) => {
            if (strategyIndex === action.strategyIndex) {
              return {
                ...strategy,
                keyInitiatives: strategy.keyInitiatives.map((keyInitiative, keyInitiativeIndex) => {
                  if (keyInitiativeIndex === action.sourceIndex) {
                    return {
                      ...keyInitiative,
                      sortOrder: lexorank.getRank(
                        state.plan.strategies[strategyIndex].keyInitiatives,
                        action.sourceIndex,
                        action.destinationIndex
                      ),
                    };
                  }

                  return keyInitiative;
                }),
              };
            }

            return strategy;
          }),
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export { initialState, reducer };
