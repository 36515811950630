import { ExpandLessRounded, ExpandMoreRounded, MoreVertRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
} from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { TextEditor, TextViewer, Typography } from "../../../../components";
import { useDomParser, useError, usePlan, useSession } from "../../../../hooks";
import { IPlanVersionSummary } from "../../../../models";

const Container = styled(Box)<{ $isSelected: boolean }>`
  & .MuiAccordionSummary-root {
    color: ${({ $isSelected, theme }) => ($isSelected ? theme.palette.primary.main : undefined)};
    cursor: ${({ $isSelected }) => ($isSelected ? "default" : "pointer")};
  }
`;

interface IProps {
  planVersion: IPlanVersionSummary;
  selected: boolean;
  onClick: (planVersion: IPlanVersionSummary) => void;
  onNotesUpdated: (planVersion: IPlanVersionSummary) => void;
}

const PlanVersionHistoryItem = (props: IProps) => {
  const { userId } = useSession();
  const { editPlanVersionNotes } = usePlan();
  const { setError } = useError();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editedNotes, setEditedNotes] = useState("");
  const { stripHtml } = useDomParser();

  function handleEditClicked(e: React.MouseEvent) {
    e.stopPropagation();
    setMenuAnchor(null);
    setIsEditing(true);
    setEditedNotes(props.planVersion.notes);
  }

  function handleCancelEditClicked() {
    setIsEditing(false);
    setEditedNotes("");
  }

  async function handleSaveEditClicked() {
    setIsSaving(true);

    (await editPlanVersionNotes(props.planVersion.teamSlug, props.planVersion.planVersionId, editedNotes)).match(
      () =>
        props.onNotesUpdated({
          ...props.planVersion,
          notes: editedNotes,
        }),
      (error) => setError(error)
    );

    setIsSaving(false);
    setIsEditing(false);
    setEditedNotes("");
  }

  return (
    <Container $isSelected={props.selected}>
      <Accordion disableGutters sx={{ backgroundColor: "transparent" }} expanded={isExpanded}>
        <AccordionSummary
          sx={{ p: 0, "> .MuiAccordionSummary-content": { m: 0 } }}
          onClick={() => props.onClick(props.planVersion)}
        >
          <Stack sx={{ width: "100%" }}>
            {props.planVersion.isDraft && <Chip sx={{ mb: 0.5 }} label="Draft" color="default" />}
            {props.planVersion.isPublished && <Chip sx={{ mb: 0.5 }} label="Published" color="primary" />}
            <Typography variant="body2">
              {props.planVersion.lastModifiedUtc.toLocal().toLocaleString(DateTime.DATETIME_FULL)}
            </Typography>
            <Typography variant="body1">{`by ${props.planVersion.createdByUser.displayName}`}</Typography>
          </Stack>
          {!props.planVersion.isDraft && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(!isExpanded);
                }}
              >
                {isExpanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
              </IconButton>
            </Box>
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 1, pb: 0 }}>
          <Box sx={{ flexGrow: 1, minWidth: 0 }}>
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "0.875rem", width: "100%" }}>CHANGE LOG</Typography>
              {userId === props.planVersion.createdByUser.userId && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    marginRight: "5px",
                  }}
                >
                  <IconButton
                    onClick={(e) => {
                      setMenuAnchor(e.currentTarget);
                    }}
                    size="small"
                  >
                    <MoreVertRounded />
                  </IconButton>
                  <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={(e) => {
                      (e as React.MouseEvent)?.stopPropagation();
                      setMenuAnchor(null);
                    }}
                  >
                    <MenuItem onClick={handleEditClicked}>Edit</MenuItem>
                  </Menu>
                </Box>
              )}
            </Stack>
            {isEditing ? (
              <>
                <TextEditor
                  initialValue={editedNotes}
                  onChange={(value) => setEditedNotes(value)}
                  readonly={isSaving}
                />
                <Stack direction="row" spacing={0.25} sx={{ justifyContent: "flex-end", mt: 0.5 }}>
                  <Button variant="tertiary" disabled={isSaving} onClick={handleCancelEditClicked}>
                    Cancel
                  </Button>
                  <Button variant="tertiary" disabled={isSaving} onClick={handleSaveEditClicked}>
                    Save
                  </Button>
                </Stack>
              </>
            ) : (
              <>
                {props.planVersion.notes != null && stripHtml(props.planVersion.notes) !== "" ? (
                  <TextViewer value={props.planVersion.notes} />
                ) : (
                  <Typography sx={{ fontSize: "0.875rem", fontStyle: "italic" }}>No notes entered.</Typography>
                )}
              </>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export { PlanVersionHistoryItem };
