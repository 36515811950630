import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { CommentSubject, FeedItemType } from "../../constants";
import { useSignalR } from "../../hooks";
import { FeedItemDto } from "../../http";
import { IUser } from "../../models";
import { CommentForm } from "./../CommentForm";
import { FeedComment } from "./FeedComment";
import { FeedPropertyChange } from "./FeedPropertyChange";

interface IProps {
  subjectType: CommentSubject;
  subjectGuid: string;
  isLoading: boolean;
  items: FeedItemDto[];
  onCreateCommentReceived: (commentGuid: string, author: IUser, timestampUtc: string, text: string) => void;
  onEditCommentReceived: (commentGuid: string, lastModifiedUtc: string, updatedText: string) => void;
  onDeleteCommentReceived: (commentGuid: string) => void;
}

const Feed = (props: IProps) => {
  const signalR = useSignalR();

  useEffect(() => {
    signalR.registerReceiveCreateComment(props.onCreateCommentReceived);
    signalR.registerReceiveEditComment(props.onEditCommentReceived);
    signalR.registerReceiveDeleteComment(props.onDeleteCommentReceived);

    return () => {
      signalR.unregisterReceiveCreateComment(props.onCreateCommentReceived);
      signalR.unregisterReceiveEditComment(props.onEditCommentReceived);
      signalR.unregisterReceiveDeleteComment(props.onDeleteCommentReceived);
    };
  }, [props.onCreateCommentReceived, props.onDeleteCommentReceived, props.onEditCommentReceived, signalR]);

  useEffect(() => {
    signalR.joinGroup(props.subjectGuid);
    return () => signalR.leaveGroup(props.subjectGuid);
  }, [props.subjectGuid, signalR]);

  function handleCommentCreated(
    subjectGuid: string,
    commentGuid: string,
    user: IUser,
    timestampUtc: DateTime,
    text: string
  ) {
    signalR.createComment(props.subjectType, subjectGuid, commentGuid, user, timestampUtc.toISO(), text);
  }

  function handleCommentEdited(subjectGuid: string, commentGuid: string, lastModifiedUtc: DateTime, text: string) {
    signalR.editComment(subjectGuid, commentGuid, lastModifiedUtc.toISO(), text);
  }

  function handleCommentDeleted(commentGuid: string) {
    signalR.deleteComment(props.subjectType, props.subjectGuid, commentGuid);
  }

  return (
    <Stack>
      <CommentForm
        subjectType={props.subjectType}
        subjectGuid={props.subjectGuid}
        disabled={props.isLoading}
        onCommentCreated={handleCommentCreated}
      />

      <Divider />

      {props.isLoading && (
        <Stack divider={<Divider variant="dashed" sx={{ mx: 1 }} />}>
          {[...new Array(2)].map((_, i) => (
            <Stack key={i} direction="row" spacing={1} sx={{ alignItems: "center", p: 1 }}>
              <Skeleton variant="circular" width={36} height={36} />
              <Box>
                <Skeleton width={220} height={24} />
                <Skeleton width={50} height={24} />
              </Box>
            </Stack>
          ))}

          <Stack sx={{ backgroundColor: "rgba(0, 0, 0, 0.03)", p: 1 }}>
            <Skeleton width={50} height={24} />
            <Skeleton width={220} height={24} />
          </Stack>
        </Stack>
      )}

      {!props.isLoading && (
        <>
          {props.items.length === 0 && <Typography>Nothing here yet.</Typography>}
          {props.items.length > 0 && (
            <Stack divider={<Divider variant="dashed" sx={{ mx: { xs: 0.5, sm: 1 } }} />}>
              {props.items.map((feedItem, i) => {
                switch (feedItem.type) {
                  case FeedItemType.Comment:
                    return (
                      <FeedComment
                        key={i}
                        subjectType={props.subjectType}
                        subjectGuid={props.subjectGuid}
                        feedItem={feedItem}
                        onCommentEdited={handleCommentEdited}
                        onCommentDeleted={handleCommentDeleted}
                      />
                    );
                  case FeedItemType.PropertyChange:
                    return <FeedPropertyChange key={i} feedItem={feedItem} />;
                  default:
                    return null;
                }
              })}
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};

export { Feed };
