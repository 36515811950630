import { Box, Button, Menu, MenuItem, Select, Stack, SxProps, Tooltip, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { ActionItemStatusChangedDialogContent } from ".";
import { ACTION_ITEM_STATUSES, ActionItemStatus, DEFAULT_ACTION_ITEM_STATUS, IStatus } from "../constants";
import { useSession } from "../hooks";
import { useEditActionItemStatus } from "../http";
import { ActionItemStatusIcon } from "./ActionItemStatusIcon";
import { useDialog } from "./DialogProvider";

const SelectOptionBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & .MuiListItemIcon-root {
    min-width: 25px;
  }
`;

interface IProps {
  actionItemGuid: string;
  status: ActionItemStatus;
  planGuid?: string;
  teamSlug?: string;
  onChange?: (statusId: ActionItemStatus) => void;
  sx?: SxProps;
  variant: "button" | "select";
  disabled?: boolean;
  faded?: boolean;
}

const StatusPicker = (props: IProps) => {
  const { userId } = useSession();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<ActionItemStatus>(props.status ?? DEFAULT_ACTION_ITEM_STATUS);
  const { mutate: editActionItemStatus } = useEditActionItemStatus();
  const { showDialog, closeDialog } = useDialog();

  useEffect(() => {
    setSelectedStatus(props.status ?? DEFAULT_ACTION_ITEM_STATUS);
  }, [props.status]);

  async function handleStatusUpdate(selectedStatus: ActionItemStatus) {
    setSelectedStatus(selectedStatus);

    if (selectedStatus === ActionItemStatus.Closed) {
      showDialog({
        content: (
          <ActionItemStatusChangedDialogContent
            actionItemGuid={props.actionItemGuid}
            onDone={closeDialog}
            onCancel={closeDialog}
          />
        ),
      });
    }

    await editActionItemStatus({
      planGuid:
        selectedStatus === ActionItemStatus.Closed || props.status === ActionItemStatus.Closed
          ? props.planGuid
          : undefined,
      guid: props.actionItemGuid,
      teamSlug: props.teamSlug,
      statusId: selectedStatus,
      userId: userId,
    });
  }

  function renderMenuItem(status: IStatus) {
    return (
      <Stack direction="row" spacing={0.5} alignItems="baseline">
        <ActionItemStatusIcon status={status.id} sx={{ alignSelf: "center" }} />
        <Typography variant="body1">{status.name}</Typography>
      </Stack>
    );
  }

  if (props.variant === "button") {
    return (
      <>
        <Tooltip title={ACTION_ITEM_STATUSES.find((x) => x.id === selectedStatus)!.name}>
          <span>
            <Button
              disabled={props.disabled}
              variant="text"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setMenuAnchor(event.currentTarget);
                event.preventDefault();
              }}
              sx={{ minWidth: 26, mr: 0.25, ...props.sx }}
              disableRipple
            >
              <ActionItemStatusIcon
                sx={{ height: "16px", width: "16px", opacity: props.faded ? 0.5 : 1 }}
                status={selectedStatus}
              />
            </Button>
          </span>
        </Tooltip>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={(event: React.MouseEvent) => {
            event.stopPropagation();
            setMenuAnchor(null);
          }}
        >
          {ACTION_ITEM_STATUSES.map((status) => (
            <MenuItem
              key={status.id}
              onClick={(event) => {
                handleStatusUpdate(status.id);
                setMenuAnchor(null);
                event.stopPropagation();
              }}
            >
              {renderMenuItem(status)}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }

  if (props.variant === "select") {
    return (
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          disableScrollLock: true,
        }}
        variant="filled"
        disableUnderline
        disabled={props.disabled}
        value={selectedStatus}
        onChange={(e) => handleStatusUpdate(e.target.value as number)}
        renderValue={(selectValue) => (
          <SelectOptionBox>{renderMenuItem(ACTION_ITEM_STATUSES.find((x) => x.id === selectValue)!)}</SelectOptionBox>
        )}
        sx={{ ...props.sx }}
        fullWidth
      >
        {ACTION_ITEM_STATUSES.map((status) => (
          <MenuItem key={status.id} value={status.id}>
            {renderMenuItem(status)}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return null;
};

export { StatusPicker };
