import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { FetchError, GetPlanHistoryDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { configuration } from "../../../configuration";

const useGetPlanHistoryRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getPlanHistoryRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.plans.getPlanHistory>>): Promise<GetPlanHistoryDto> {
    const accessToken = await getAccessToken();
    const [{ guid, offsetInMinutes }] = queryKey;

    const response = await fetch(
      `${configuration.apiRootUrl}/plans/${guid}/history?offsetInMinutes=${offsetInMinutes}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getPlanHistoryRequest };
};

const useGetPlanHistory = (guid: string, offsetInMinutes: number) => {
  const { getPlanHistoryRequest } = useGetPlanHistoryRequest();

  return useQuery({
    queryKey: queryKeys.plans.getPlanHistory(guid, offsetInMinutes),
    queryFn: getPlanHistoryRequest,
    enabled: guid !== undefined && guid !== null,
  });
};

export { useGetPlanHistory };
