import { DateTime } from "luxon";
import { GetQuarterlyPrioritiesDto } from "../http";
import { InformedByMapper } from "../mapping/InformedByMapper";
import { IInformedBy } from "./InformedBy";

class QuarterlyPriority {
  guid: string;
  description: string;
  completedDateUtc: DateTime | null;
  sortOrder: number;
  informedBy: IInformedBy | undefined;

  constructor(
    guid: string,
    description: string,
    completedDateUtc: DateTime | null,
    sortOrder: number,
    informedBy: IInformedBy | undefined
  ) {
    this.guid = guid;
    this.description = description;
    this.completedDateUtc = completedDateUtc;
    this.sortOrder = sortOrder;
    this.informedBy = informedBy;
  }

  static fromDto(dto: GetQuarterlyPrioritiesDto): QuarterlyPriority[] {
    return dto.quarterlyPriorities.map(
      (x) =>
        new QuarterlyPriority(
          x.guid,
          x.description,
          x.completedDateUtc ? DateTime.fromISO(x.completedDateUtc, { zone: "utc" }) : null,
          x.sortOrder,
          x.informedBy ? InformedByMapper.fromDto(x.informedBy) : undefined
        )
    );
  }
}

export { QuarterlyPriority };
