import { EditList, EditListItem } from "../../../../components";
import { IPlanValue } from "../../../../models";
import { IEditTeamPlanPageAction, IEditTeamPlanPageState } from "../state";

interface IProps {
  state: IEditTeamPlanPageState;
  dispatch: React.Dispatch<IEditTeamPlanPageAction>;
  onFocus: () => void;
}

const EditValues = (props: IProps) => {
  const { state, dispatch } = props;

  function handleAddClicked() {
    dispatch({ type: "add_value" });
  }

  function handleChange(description: string, index: number) {
    dispatch({ type: "edit_value", index, description });
  }

  function handleReorder(sourceIndex: number, destinationIndex: number) {
    dispatch({ type: "reorder_value", sourceIndex, destinationIndex });
  }

  function handleDeleteClicked(index: number) {
    dispatch({ type: "delete_value", index });
  }

  return (
    <EditList
      id="values"
      label="Values"
      values={state.plan.values.sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))}
      buttonText="Add Value"
      renderItem={(item: IPlanValue, index: number) => (
        <EditListItem index={index} value={item.description} onFocus={props.onFocus} onChange={handleChange} />
      )}
      onAdd={handleAddClicked}
      onReorder={handleReorder}
      onDelete={handleDeleteClicked}
    />
  );
};

export { EditValues };
