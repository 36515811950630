import { LexoRank } from "lexorank";

function getRank(list: { sortOrder: string }[], oldIndex: number, newIndex: number): string {
  const orderedList = list.sort((a, b) => a.sortOrder.localeCompare(b.sortOrder));
  if (newIndex === 0) {
    // Generate an rank before the first item in the list
    return getPrevRank(orderedList);
  } else if (newIndex === orderedList.length - 1) {
    // Generate a rank after the last item in the orderedList
    return getNextRank(orderedList);
  } else {
    // Generate a rank between the new and old indices
    if (orderedList === undefined || orderedList.length === 0) {
      return LexoRank.middle().toString();
    }

    const firstRank = LexoRank.parse(orderedList[newIndex].sortOrder);
    const secondRank =
      newIndex < oldIndex
        ? LexoRank.parse(orderedList[newIndex - 1].sortOrder)
        : LexoRank.parse(orderedList[newIndex + 1].sortOrder);

    return firstRank.between(secondRank).toString();
  }
}

function getRankAtIndex(list: { sortOrder: string }[], newIndex: number): string {
  const orderedList = list.sort((a, b) => a.sortOrder.localeCompare(b.sortOrder));
  if (newIndex === 0) {
    return getPrevRank(orderedList);
  } else if (newIndex === orderedList.length) {
    return getNextRank(orderedList);
  } else {
    const firstRank = LexoRank.parse(orderedList[newIndex - 1].sortOrder);
    const secondRank = LexoRank.parse(orderedList[newIndex].sortOrder);

    return firstRank.between(secondRank).toString();
  }
}

function getPrevRank(list?: { sortOrder: string }[]) {
  if (list === undefined || list.length === 0) {
    return LexoRank.middle().toString();
  }

  const orderedList = list.sort((a, b) => a.sortOrder.localeCompare(b.sortOrder));
  return LexoRank.parse(orderedList[0].sortOrder).genPrev().toString();
}

function getNextRank(list?: { sortOrder: string }[]) {
  if (list === undefined || list.length === 0) {
    return LexoRank.middle().toString();
  }

  const orderedList = list.sort((a, b) => a.sortOrder.localeCompare(b.sortOrder));
  return LexoRank.parse(orderedList[orderedList.length - 1].sortOrder)
    .genNext()
    .toString();
}

const lexorank = {
  getRank,
  getRankAtIndex,
  getPrevRank,
  getNextRank,
};

export { lexorank };
