import { HubRounded, TableRowsOutlined } from "@mui/icons-material";
import { Box, Card, CardHeader, Divider, IconButton as MuiIconButton, Stack, Typography, styled } from "@mui/material";
import { useSettings } from "../../../../hooks";
import { useTeamPageLayoutContext } from "../../TeamPageLayout";
import { TeamNetworkGraph } from "./TeamNetworkGraph";
import { TeamNetworkList } from "./TeamNetworkList";

const IconButton = styled(MuiIconButton)<{ $isActive: boolean }>`
  color: ${({ $isActive, theme }) => ($isActive ? theme.palette.primary.main : "#cacaca")};
  padding: 5px;
`;

const TeamNetwork = () => {
  const { teamSettings, updateTeamSettings } = useSettings();
  const { team, isLoadingTeam } = useTeamPageLayoutContext();

  return (
    <Card sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography variant="h6">Network</Typography>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" sx={{ height: 24 }} />}
              spacing={0.25}
              sx={{ alignItems: "center" }}
            >
              <IconButton
                $isActive={!teamSettings.networkListView}
                onClick={() => updateTeamSettings({ networkListView: false })}
              >
                <HubRounded fontSize="small" />
              </IconButton>
              <IconButton
                $isActive={teamSettings.networkListView ?? false}
                onClick={() => updateTeamSettings({ networkListView: true })}
              >
                <TableRowsOutlined fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        }
      />
      <Divider />
      {!teamSettings.networkListView && (
        <Box
          sx={{
            height: { xs: "100vw", md: 500 },
            width: "100%",
          }}
        >
          <TeamNetworkGraph isLoadingTeam={isLoadingTeam} team={team} />
        </Box>
      )}

      {teamSettings.networkListView && <TeamNetworkList isLoadingTeam={isLoadingTeam} team={team} />}
    </Card>
  );
};

export { TeamNetwork };
