import { Box, Button, ButtonProps, Typography } from "@mui/material";
import { DraggableList, SmallInputLabel } from ".";

interface IProps {
  id: string;
  label?: string;
  buttonText: string;
  buttonProps?: ButtonProps;
  values: any[];
  renderItem: (item: any, index: number) => React.ReactNode;
  renderEmptyPlaceholder?: () => React.ReactNode;
  onAdd: () => void;
  onReorder: (sourceIndex: number, destinationIndex: number) => void;
  onDelete: (index: number) => void;
}

const EditList = (props: IProps) => {
  return (
    <Box>
      {props.label && <SmallInputLabel sx={{ px: 1, mb: 0.25 }}>{props.label}</SmallInputLabel>}

      <DraggableList
        id={props.id}
        dataSource={props.values}
        renderItem={props.renderItem}
        renderEmptyPlaceholder={() => (
          <>
            {props.renderEmptyPlaceholder ? (
              props.renderEmptyPlaceholder()
            ) : (
              <Box sx={{ px: 1 }}>
                <Typography variant="body1">No {props.label?.toLocaleLowerCase() ?? "data"} entered yet.</Typography>
              </Box>
            )}
          </>
        )}
        onReorder={props.onReorder}
        onDelete={props.onDelete}
        showDeleteOnHover={false}
      />

      <Box sx={{ px: 1, pt: 1 }}>
        <Button
          variant="tertiary"
          onClick={(event) => {
            event.stopPropagation();
            props.onAdd();
          }}
          {...props.buttonProps}
        >
          {props.buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export { EditList };
