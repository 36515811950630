import { Box, Link, Typography } from "@mui/material";
import { ACTION_ITEM_STATUSES, Links } from "../../constants";
import { FeedItemDto } from "../../http";
import { ActionItemStatusIcon } from "../ActionItemStatusIcon";

interface IProps {
  feedItem: FeedItemDto;
}

const ActionItemStatusPropertyChange = (props: IProps) => {
  const status = ACTION_ITEM_STATUSES.find((x) => x.id === props.feedItem.propertyChange!.value);

  return (
    <Typography variant="caption" sx={{ alignItems: "center" }}>
      <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link> updated status to
      <Box component="span" sx={{ fontWeight: "bold", ml: "2px" }}>
        <ActionItemStatusIcon status={status!.id} sx={{ position: "relative", top: "2px" }} />
        <span> {status!.name}</span>
      </Box>
    </Typography>
  );
};

export { ActionItemStatusPropertyChange };
