import { BookmarkBorderRounded, BookmarkRounded } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useBreakpoints, useError, useSession, useTeams } from "../../../../hooks";
import { queryKeys } from "../../../../http";
import { useTeamPageLayoutContext } from "../TeamPageLayout";

const FollowButton = () => {
  const { isLoadingTeam, team } = useTeamPageLayoutContext();
  const { followTeam, unfollowTeam } = useTeams();
  const { setError } = useError();
  const { userId } = useSession();
  const { isMdUp } = useBreakpoints();
  const [isSaving, setIsSaving] = useState(false);
  const queryClient = useQueryClient();

  async function handleFollow() {
    if (team === undefined || userId === undefined) {
      return;
    }

    setIsSaving(true);

    (await followTeam(team.slug, userId)).match(
      async () => {
        await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeam(team.slug, userId) });
        setIsSaving(false);
      },
      (error) => {
        setIsSaving(false);
        setError(error);
      }
    );
  }

  async function handleUnfollow() {
    if (team === undefined || userId === undefined) {
      return;
    }

    setIsSaving(true);

    (await unfollowTeam(team.slug, userId)).match(
      async () => {
        await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeam(team.slug, userId) });
        setIsSaving(false);
      },
      (error) => {
        setIsSaving(false);
        setError(error);
      }
    );
  }

  let isButtonDisabled = isSaving || isLoadingTeam || team == null;
  let isFollowing = team != null && team.isFollowing;

  return isMdUp ? (
    <Button
      variant="outlined"
      disabled={isButtonDisabled}
      onClick={isFollowing ? handleUnfollow : handleFollow}
      startIcon={isFollowing ? <BookmarkRounded /> : <BookmarkBorderRounded />}
    >
      {isFollowing ? "Following" : "Follow"}
    </Button>
  ) : (
    <IconButton
      color="primary"
      sx={{ border: 1, borderRadius: "8px" }}
      disabled={isButtonDisabled}
      onClick={isFollowing ? handleUnfollow : handleFollow}
    >
      {isFollowing ? <BookmarkRounded /> : <BookmarkBorderRounded />}
    </IconButton>
  );
};

export { FollowButton };
