import { Stack } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Outlet, useParams } from "react-router";
import { HeaderLayout } from "../../../components";
import { useGetUser } from "../../../http";
import { IUserDetail } from "../../../models";
import { setTitlePrefix } from "../../../utilities";
import { UserActions, UserNavBar, UserTitle } from "./components";

interface IParams {
  userId: string;
}

interface IUserPageLayoutContext {
  isLoadingUser: boolean;
  user: IUserDetail | undefined;
}

const UserPageLayoutContext = React.createContext<IUserPageLayoutContext | undefined>(undefined);

const UserPageLayout = () => {
  const { userId } = useParams<keyof IParams>() as IParams;
  const { data: user, isFetching } = useGetUser(userId);

  useEffect(() => {
    setTitlePrefix(user?.displayName);
  }, [user?.displayName]);

  return (
    <UserPageLayoutContext.Provider
      value={{
        isLoadingUser: isFetching,
        user,
      }}
    >
      <HeaderLayout
        title={
          <Stack spacing={0.25} sx={{ pb: isFetching ? 1 : 0 }}>
            <UserTitle />
          </Stack>
        }
        actions={!isFetching && <UserActions />}
        navbar={!isFetching && <UserNavBar />}
        headerContainerProps={{
          sx: {
            alignItems: "flex-end",
            pb: 0,
          },
        }}
      >
        <Outlet />
      </HeaderLayout>
    </UserPageLayoutContext.Provider>
  );
};

const useUserPageLayoutContext = () => {
  const context = useContext(UserPageLayoutContext);

  if (context === undefined) {
    throw new Error("UserRouteContext is not initialized.");
  }

  return context;
};

export { UserPageLayout, UserPageLayoutContext, useUserPageLayoutContext };
