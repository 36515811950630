import { Card, CardHeader, Divider } from "@mui/material";
import { Comments, HistoryLedgerMapper, Typography } from "../../../../components";
import { CommentSubject } from "../../../../constants";
import { useTeamPageLayoutContext } from "../../TeamPageLayout";
import { TeamHistoryLedgerMappingStrategy } from "./TeamHistoryLedgerMappingStrategy";

const TeamFeed = () => {
  const { team } = useTeamPageLayoutContext();

  return (
    <Card
      sx={{
        width: "100%",
        flexDirection: "column",
      }}
    >
      <CardHeader title={<Typography variant="h6">Feed</Typography>} />
      <Divider />
      {team && (
        <Comments
          subjectType={CommentSubject.Team}
          subjectGuid={team.guid}
          additionalComments={HistoryLedgerMapper.toComments(
            team.historyLedger ?? [],
            new TeamHistoryLedgerMappingStrategy()
          )}
        />
      )}
    </Card>
  );
};

export { TeamFeed };
