import { IMetricDataset } from "../models";

function toChartData(dataset: IMetricDataset) {
  return dataset.data
    .sort((a, b) => (a.date < b.date ? -1 : 1))
    .map((x) => {
      return {
        x: x.date.toFormat("M/d/yyyy"),
        y: x.value,
      };
    });
}

const MetricMapper = {
  toChartData,
};

export { MetricMapper };
