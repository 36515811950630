import { Card, CardContent, Divider, Grid, Link, Skeleton, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { v4 as newGuid } from "uuid";
import { Links } from "../../../constants";
import { useCreateSurveyInstanceResponse, useGetSurvey, useGetSurveyInstanceResponse } from "../../../http";
import { ISurveyInstance, IUser } from "../../../models";
import { setTitlePrefix } from "../../../utilities";
import { PreviewStep, SurveyProfileVerificationForm } from "./components";

interface IParams {
  guid: string;
}

interface NotAcceptingResponsesStateProps {
  administrators: IUser[];
}

const getCurrentInstance = (instances: ISurveyInstance[]): ISurveyInstance | undefined => {
  return instances.find(
    (instance) =>
      DateTime.utc() >= instance.startDateUtc &&
      (instance.endDateUtc == null || DateTime.utc() < instance.endDateUtc.plus({ days: 1 }))
  );
};

const NotAcceptingResponsesState = ({ administrators }: NotAcceptingResponsesStateProps) => {
  const administratorsList = administrators.map((admin, index) => (
    <React.Fragment key={admin.userId}>
      <Link key={admin.userId} href={Links.User(admin.userId)}>
        {admin.displayName}
      </Link>
      {index !== administrators.length - 1 && ", "}
    </React.Fragment>
  ));

  return (
    <>
      <CardContent>
        <Typography>Requested survey is currently not accepting responses.</Typography>
        <Divider variant="dashed" sx={{ my: 0.5 }} />
        <Typography variant="caption">Contact survey administrators {administratorsList} for more info.</Typography>
      </CardContent>
    </>
  );
};

const SurveyPreviewPage = () => {
  const [currentInstance, setCurrentInstance] = useState<ISurveyInstance>();
  const { guid } = useParams<keyof IParams>() as IParams;
  const { data: survey, isLoading: isLoadingSurvey } = useGetSurvey(guid);
  const { data: instanceResponse, isLoading: isLoadingInstanceResponse } = useGetSurveyInstanceResponse(
    currentInstance?.guid
  );
  const { mutate: createSurveyInstanceResponse } = useCreateSurveyInstanceResponse();

  useEffect(() => {
    setTitlePrefix(survey?.name);
  }, [survey?.name]);

  useEffect(() => {
    if (!isLoadingSurvey && survey) {
      setCurrentInstance(getCurrentInstance(survey.surveyInstances));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSurvey]);

  useEffect(() => {
    if (instanceResponse == null && currentInstance != null && !survey?.requestVerificationOfPPI) {
      createSurveyInstanceResponse({
        guid: newGuid(),
        surveyInstanceGuid: currentInstance.guid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInstanceResponse]);

  return (
    <Grid container justifyContent="center" sx={{ py: 1, height: "100vh" }}>
      <Grid item sm={12} md={8} sx={{ height: "100%" }}>
        <Card sx={{ display: "flex", flexDirection: "column", maxHeight: "100%" }}>
          {isLoadingSurvey || isLoadingInstanceResponse || survey == null ? (
            <Stack sx={{ p: 1 }}>
              {[...Array(3)].map((_, i) => (
                <Skeleton key={i} />
              ))}
            </Stack>
          ) : currentInstance == null ? (
            <NotAcceptingResponsesState administrators={survey.administrators} />
          ) : instanceResponse != null ? (
            <PreviewStep instance={currentInstance} response={instanceResponse!} />
          ) : (
            survey.requestVerificationOfPPI &&
            instanceResponse == null && <SurveyProfileVerificationForm surveyName={survey.name} instanceGuid={currentInstance.guid} />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export { SurveyPreviewPage };
