import { Accordion, AccordionDetails, AccordionSummary, Stack, TextField, Typography } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { ExpandMoreRounded } from "@mui/icons-material";
import { SmallInputLabel } from "../SmallInputLabel";
import { EditMetricDatasets } from "./EditMetricDatasets";
import { ConnectObjectType } from "../../constants";
import { MetricDataGrid } from "./MetricDataGrid";
import { DateTime } from "luxon";

interface ICreateEditMetricFormState {
  title: string;
  description: string;
  sortOrder: string;
  datasets: {
    guid: string;
    title: string;
    dotted: boolean;
    color: string;
    data: { rowGuid: string; date: DateTime; value: number | null }[];
    sortOrder: string;
  }[];
}

interface IProps {
  form: UseFormReturn<ICreateEditMetricFormState>;
}

const CreateEditMetricForm = (props: IProps) => {
  const { formState, control } = props.form;

  return (
    <Stack spacing={1} width={"100%"}>
      <Stack spacing={0.25}>
        <SmallInputLabel>Title *</SmallInputLabel>
        <Controller
          name="title"
          control={control}
          rules={{ required: { message: "Title is required", value: true } }}
          render={({ field }) => (
            <TextField
              {...field}
              error={formState.errors["title"] !== undefined}
              helperText={formState.errors["title"]?.message}
            />
          )}
        />
      </Stack>
      <Stack spacing={0.25}>
        <SmallInputLabel>Description</SmallInputLabel>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              helperText={formState.errors["description"]?.message}
              onFocus={(e) => e.target.setSelectionRange(0, e.target.value.length)}
            />
          )}
        />
      </Stack>
      <Accordion disableGutters={true} sx={{ width: "100%" }}>
        <AccordionSummary expandIcon={<ExpandMoreRounded fontSize="large" />}>
          <Typography>{ConnectObjectType.Metric} Setup</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EditMetricDatasets form={props.form} />
        </AccordionDetails>
      </Accordion>

      <MetricDataGrid form={props.form} />
    </Stack>
  );
};

export { CreateEditMetricForm };
export type { ICreateEditMetricFormState };
