import { DeleteOutlineRounded, EditOutlined } from "@mui/icons-material";
import { Box, Button, Chip, Divider, Link, Skeleton, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { AvatarGroup, Comments, EditRoleModal, HistoryLedgerMapper, Typography } from "../../components";
import { CommentSubject, ConnectObjectType, Links, SearchFilter } from "../../constants";
import { useSession } from "../../hooks";
import { useDeleteRole, useGetRole, useGetTeam } from "../../http";
import { setTitlePrefix, useDestructiveConfirm } from "../../utilities";
import { DetailPageLayout } from "./DetailPageLayout";
import { RoleHistoryLedgerMappingStrategy } from "./components";

interface UrlParams {
  roleGuid: string;
}

const RoleDetailsPage = () => {
  const { roleGuid } = useParams<keyof UrlParams>() as UrlParams;
  const { userId } = useSession();
  const navigate = useNavigate();
  const { data: role, isLoading: isLoadingRole } = useGetRole(roleGuid);
  const { data: team, isLoading: isLoadingTeam } = useGetTeam(role?.parentTeam.slug, userId);
  const { mutate: deleteRole } = useDeleteRole();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useDestructiveConfirm();

  useEffect(() => {
    setTitlePrefix(role?.name);
  }, [role?.name]);

  async function handleDeleteRoleClicked() {
    try {
      await confirm({
        title: "Delete Role",
        content: "Are you sure you want to delete this role?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteRole(
      {
        teamSlug: team!.slug,
        guid: roleGuid,
        modifiedByUserId: userId!,
      },
      {
        onSuccess: () => {
          if (role !== undefined) {
            navigate(Links.Team(role.parentTeam.slug));
            enqueueSnackbar(`Role "${role.name}" has been deleted`);
          }
        },
      }
    );
  }

  return (
    <DetailPageLayout
      actions={
        !isLoadingTeam &&
        (team?.isMember || team?.isAdministrator) && (
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              startIcon={<EditOutlined />}
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteOutlineRounded />}
              onClick={handleDeleteRoleClicked}
            >
              Delete
            </Button>
          </Stack>
        )
      }
    >
      {isLoadingRole && (
        <Stack>
          <Skeleton sx={{ mx: 1, my: 0.5 }} width={"10%"} />
          <Divider />
          <Skeleton sx={{ mx: 1 }} height={96} />
        </Stack>
      )}

      {!isLoadingRole && role && (
        <>
          <Stack spacing={{ xs: 0.5, sm: 1 }} sx={{ mt: { xs: 0.5, sm: 1 } }}>
            <Stack direction="row" spacing={0.5} flexWrap="wrap" sx={{ px: { xs: 0.5, sm: 1 } }}>
              {role.parentTeam && (
                <Chip
                  color="primary"
                  label={role.parentTeam.name}
                  component="a"
                  href={Links.Team(role.parentTeam.slug)}
                  clickable
                />
              )}

              <Chip label={ConnectObjectType.Role} color="orange" />

              {role.isOpen && (
                <Chip
                  label={`OPEN as of ${role.isOpenLastModifiedUtc
                    .toLocal()
                    .toLocaleString(DateTime.DATE_MED)
                    .toLocaleUpperCase()}`}
                  color="orange"
                  variant="outlined"
                  sx={{
                    ".MuiChip-label": {
                      textTransform: "none",
                    },
                  }}
                />
              )}
            </Stack>
            <Divider />
            <Stack spacing={{ xs: 0.5, sm: 1 }} sx={{ px: { xs: 0.5, sm: 1 } }}>
              <Stack spacing={0.5}>
                <Typography variant="h3" sx={{ whiteSpace: "pre-line" }}>
                  {role.name}
                </Typography>
                <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                  {role.description}
                </Typography>
              </Stack>
              {role.accountabilities.length > 0 && (
                <Box>
                  <Typography variant="overline">Accountabilities</Typography>
                  <Divider />
                  <Stack sx={{ pl: 1, mt: 0.5 }}>
                    {role.accountabilities.map((accountability, index) => (
                      <Typography key={index} display="list-item">
                        {accountability.description}
                      </Typography>
                    ))}
                  </Stack>
                </Box>
              )}
              {role.tags.length > 0 && (
                <Box>
                  <Typography variant="overline">Tags</Typography>
                  <Divider />
                  <Stack direction="row" divider={<Typography>,&nbsp;</Typography>} sx={{ mt: 0.5 }}>
                    {role.tags.map((tag, i) => (
                      <Link key={i} href={Links.Search(tag, SearchFilter.Roles)}>
                        {tag}
                      </Link>
                    ))}
                  </Stack>
                </Box>
              )}
              {role.members.length > 0 && (
                <Box>
                  <Typography variant="overline">People</Typography>
                  <Divider />
                  <AvatarGroup users={role.members} max={10} sx={{ mt: 0.75 }} />
                </Box>
              )}
            </Stack>
            <Box>
              <Divider />
              <Comments
                subjectType={CommentSubject.Role}
                subjectGuid={roleGuid}
                additionalComments={HistoryLedgerMapper.toComments(
                  role.historyLedger,
                  new RoleHistoryLedgerMappingStrategy()
                )}
              />
            </Box>
          </Stack>

          <EditRoleModal
            visible={isModalVisible}
            role={role}
            onUpdated={() => setIsModalVisible(false)}
            onCancelled={() => setIsModalVisible(false)}
          />
        </>
      )}
    </DetailPageLayout>
  );
};

export { RoleDetailsPage };
