import { CheckCircleRounded, CloseRounded, ErrorRounded, InfoRounded, WarningRounded } from "@mui/icons-material";
import { alpha, Button, darken, lighten, styled } from "@mui/material";
import { SnackbarKey, SnackbarProvider as UnstyledSnackbarProvider, useSnackbar } from "notistack";

interface SnackbarCloseButtonProps {
  id: SnackbarKey;
}

const SnackbarCloseButton = (props: SnackbarCloseButtonProps) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <Button onClick={() => closeSnackbar(props.id)} sx={{ minWidth: "24px" }}>
      <CloseRounded />
    </Button>
  );
};

const SnackbarProvider = styled(UnstyledSnackbarProvider)`
  .MuiSvgIcon-root {
    background-color: transparent;
    font-size: 24px;
  }

  &.SnackbarContent-root {
    align-items: flex-start;
    box-shadow: none;
    border-radius: 15px;
    flex-direction: row;
    padding: ${({ theme }) => theme.spacing(0.5)};
    min-width: min(800px, 75vw);

    & .SnackbarItem-message {
      align-items: flex-start;
      flex: 1;
      padding: 6px 0;
      margin-left: ${({ theme }) => theme.spacing(0.25)};

      & .MuiSvgIcon-root {
        margin-right: ${({ theme }) => theme.spacing(0.5)};
      }
    }

    & .SnackbarItem-action {
      flex: 0;
      margin-right: 0;

      > .MuiButtonBase-root {
        white-space: nowrap;
      }
    }
  }

  &.SnackbarItem-variantError {
    color: ${({ theme }) => darken(theme.palette.error.light, 0.6)};
    background-color: ${({ theme }) => lighten(theme.palette.error.light, 0.8)};

    & .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.error.light};
    }

    & .MuiButton-root:hover {
      background-color: ${({ theme }) => alpha(theme.palette.error.light, 0.04)};
    }

    & .MuiTouchRipple-root {
      color: ${({ theme }) => alpha(theme.palette.error.light, 0.3)};
    }
  }

  &.SnackbarItem-variantWarning {
    color: ${({ theme }) => darken(theme.palette.warning.light, 0.6)};
    background-color: ${({ theme }) => lighten(theme.palette.warning.light, 0.8)};

    & .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.warning.light};
    }

    & .MuiButton-root:hover {
      background-color: ${({ theme }) => alpha(theme.palette.warning.light, 0.04)};
    }

    & .MuiTouchRipple-root {
      color: ${({ theme }) => alpha(theme.palette.warning.light, 0.3)};
    }
  }

  &.SnackbarItem-variantInfo {
    color: ${({ theme }) => darken(theme.palette.info.light, 0.6)};
    background-color: ${({ theme }) => lighten(theme.palette.info.light, 0.8)};

    & .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.info.light};
    }

    & .MuiButton-root:hover {
      background-color: ${({ theme }) => alpha(theme.palette.info.light, 0.04)};
    }

    & .MuiTouchRipple-root {
      color: ${({ theme }) => alpha(theme.palette.info.light, 0.3)};
    }
  }

  &.SnackbarItem-variantSuccess {
    color: ${({ theme }) => darken(theme.palette.success.light, 0.6)};
    background-color: ${({ theme }) => lighten(theme.palette.success.light, 0.8)};

    & .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.success.light};
    }

    & .MuiButton-root:hover {
      background-color: ${({ theme }) => alpha(theme.palette.success.light, 0.04)};
    }

    & .MuiTouchRipple-root {
      color: ${({ theme }) => alpha(theme.palette.success.light, 0.3)};
    }
  }
`;

SnackbarProvider.defaultProps = {
  action: (id: SnackbarKey) => <SnackbarCloseButton id={id} />,
  anchorOrigin: { vertical: "top", horizontal: "center" },
  iconVariant: {
    error: <ErrorRounded />,
    warning: <WarningRounded />,
    info: <InfoRounded />,
    success: <CheckCircleRounded />,
  },
  autoHideDuration: 3000,
  variant: "info",
  preventDuplicate: true,
};

export { SnackbarProvider };
