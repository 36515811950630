import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  KeyboardArrowDownRounded,
  ManageSearchRounded,
} from "@mui/icons-material";
import { Button, Divider, Popover, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ITreeNode } from "../models";
import { TeamTreeView } from "./TeamTreeView";

const BUTTON_TITLE_PLACEHOLDER = "Select Team";

interface IProps {
  isMeVisible: boolean;
  selectedTeamsLabel: string | undefined;
  selectedTeams: ITreeNode[];
  onMeSelected: () => void;
  onTeamSelected: (aggregateTeamLabel: string, selectedTeams: ITreeNode[]) => void;
  onClearClicked: () => void;
}

const TeamPicker = (props: IProps) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [buttonTitle, setButtonTitle] = useState(BUTTON_TITLE_PLACEHOLDER);
  const [tooltipTitle, setTooltipTitle] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    if (props.isMeVisible && props.selectedTeams.length === 0) {
      setButtonTitle("Me");
      setTooltipTitle(null);
    } else if (props.selectedTeams.length === 1) {
      setButtonTitle(props.selectedTeams[0].label);
      setTooltipTitle(null);
    } else if (props.selectedTeams.length > 1) {
      setButtonTitle(props.selectedTeamsLabel ?? "-");
      setTooltipTitle(
        <>
          <Typography sx={{ fontSize: 13, fontWeight: 700 }}>Selected</Typography>
          {props.selectedTeams
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((x, i) => (
              <Typography key={i} sx={{ display: "inline-block", fontSize: 13, marginRight: "2px" }}>
                {x.label}
                {i < props.selectedTeams.length - 1 && ", "}
              </Typography>
            ))}
        </>
      );
    } else {
      setButtonTitle(BUTTON_TITLE_PLACEHOLDER);
      setTooltipTitle(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTeamsLabel, props.selectedTeams]);

  function handleClearClicked() {
    setButtonTitle(BUTTON_TITLE_PLACEHOLDER);
    setTooltipTitle(null);

    props.onClearClicked();
  }

  return (
    <>
      <Tooltip
        title={tooltipTitle}
        placement="bottom-start"
        slotProps={{
          tooltip: {
            sx: {
              maxHeight: "400px",
              overflowY: "auto",
            },
          },
        }}
      >
        <Button
          variant="tertiary"
          startIcon={<ManageSearchRounded />}
          endIcon={<KeyboardArrowDownRounded />}
          onClick={(e) => setMenuAnchor(e.currentTarget)}
        >
          {buttonTitle}
        </Button>
      </Tooltip>

      <Popover
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack spacing={0.5} sx={{ m: 0.5, mt: 1, justifyContent: "center" }}>
          {props.isMeVisible && (
            <>
              <Stack
                direction="row"
                spacing={0.25}
                sx={{ alignItems: "center", "&&": { ml: 1.5 }, cursor: "pointer" }}
                onClick={props.onMeSelected}
              >
                {props.selectedTeams.length === 0 ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}
                <Typography>Me</Typography>
              </Stack>
              <Divider variant="dashed" sx={{ color: "#c9c9c9", "&&": { mx: 0.5 } }} />
            </>
          )}

          <TeamTreeView
            selectedTeamGuids={props.selectedTeams.map((x) => x.nodeId)}
            onTeamSelected={props.onTeamSelected}
          />
          <Divider variant="dashed" sx={{ color: "#c9c9c9" }} />
          <Button variant="tertiary" sx={{ alignSelf: "start" }} onClick={handleClearClicked}>
            Clear
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export { TeamPicker };
