import { Typography } from "@mui/material";
import { ReactNode } from "react";
import {
  DefaultHistoryLedgerMappingStrategy,
  HistoryLedgerItemValue,
  IHistoryLedgerMappingStrategy,
} from "../../../components";
import { ConnectObjectType } from "../../../constants";
import { IHistoryLedgerItem, IHistoryLedgerItemChange } from "../../../models";

class MetricHistoryLedgerMappingStrategy implements IHistoryLedgerMappingStrategy {
  defaultStrategy: IHistoryLedgerMappingStrategy;

  constructor() {
    this.defaultStrategy = new DefaultHistoryLedgerMappingStrategy(ConnectObjectType.Metric);
  }

  getCreatedText(historyLedgerItem: IHistoryLedgerItem): ReactNode {
    const titles = historyLedgerItem.changes.filter((x) => x.propertyName === "title");

    if (titles.length > 0) {
      return (
        <Typography>
          {`Added critical metric `}
          <HistoryLedgerItemValue value={titles[0].newValue} />
        </Typography>
      );
    }
  }

  getDeletedText(historyLedgerItem: IHistoryLedgerItem): ReactNode {
    return this.defaultStrategy.getDeletedText(historyLedgerItem);
  }

  getEditedText(change: IHistoryLedgerItemChange): ReactNode {
    switch (change.propertyName) {
      case "dataset name": {
        if (change.oldValue == null) {
          return (
            <Typography>
              {`Added dataset `}
              <HistoryLedgerItemValue value={change.newValue} />
            </Typography>
          );
        }

        if (change.newValue == null) {
          return (
            <Typography>
              {`Removed dataset `}
              <HistoryLedgerItemValue value={change.oldValue} />
            </Typography>
          );
        }

        return (
          <Typography>
            {`Updated dataset name from `}
            <HistoryLedgerItemValue value={change.oldValue} />
            {` to `}
            <HistoryLedgerItemValue value={change.newValue} />
          </Typography>
        );
      }
      default: {
        return this.defaultStrategy.getEditedText(change);
      }
    }
  }
}

export { MetricHistoryLedgerMappingStrategy };
