import { Chip, IconButton, Menu, MenuItem, Stack, SxProps, Typography, styled, useTheme } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { Links } from "../../../constants";
import { useSnackbar } from "notistack";
import { DateTime } from "luxon";
import { ISurveyInstance } from "../../../models";
import { useDestructiveConfirm } from "../../../utilities";
import { useDeleteSurveyInstance } from "../../../http";

const StyledStack = styled(Stack)`
  transition: ${({ theme }) => theme.transitions.create("background-color")};
  cursor: pointer;

  .editMenuIcon {
    opacity: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.opacity5};

    .editMenuIcon {
      opacity: 1;
    }
  }
`;

interface IProps {
  instance: ISurveyInstance;
  surveyGuid: string;
  onClick?: (e: React.MouseEvent) => void;
  sx?: SxProps;
  disabled?: boolean;
  canDelete?: boolean;
}

const SurveyInstance = (props: IProps) => {
  const { instance, surveyGuid } = props;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const { mutate: deleteSurveyInstance } = useDeleteSurveyInstance();
  const confirm = useDestructiveConfirm();
  const isCurrent =
    instance.startDateUtc <= DateTime.utc() &&
    (instance.endDateUtc == null || DateTime.utc() < instance.endDateUtc.plus({ days: 1 }));

  async function handleDeleteClicked(e: React.MouseEvent) {
    setMenuAnchor(null);
    e.stopPropagation();

    try {
      await confirm({
        title: "Delete Survey Instance",
        content: "Are you sure you want to delete this survey instance?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteSurveyInstance({
      instanceGuid: instance.guid,
      surveyGuid: surveyGuid,
    });
  }

  return (
    <StyledStack
      direction="row"
      onClick={(e) => {
        if (!props.disabled && props.onClick) {
          props.onClick(e);
        }
      }}
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "100%", minWidth: 0, ...props.sx }}
    >
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Typography>
          {`${instance.startDateUtc.toLocaleString(DateTime.DATE_MED)} - ${
            instance.endDateUtc ? instance.endDateUtc.toLocaleString(DateTime.DATE_MED) : "End Date Not Set"
          }`}
        </Typography>
        {isCurrent && <Chip label="current" size="small" />}
      </Stack>
      <IconButton
        className="editMenuIcon"
        onClick={(event) => {
          setMenuAnchor(event.currentTarget);
          event.stopPropagation();
        }}
        aria-label="settings"
        size="small"
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={(e: React.PointerEvent) => {
          setMenuAnchor(null);
          e.stopPropagation();
        }}
        disableScrollLock
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={(e) => {
            setMenuAnchor(null);
            props.onClick && props.onClick(e);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuAnchor(null);
            navigator.clipboard.writeText(window.origin + Links.SurveyResponse(props.surveyGuid)).then(() => {
              enqueueSnackbar(`Share the link with your target audience.`, {
                variant: "success",
              });
            });
          }}
        >
          Copy Share Link
        </MenuItem>
        {props.canDelete && !isCurrent && (
          <MenuItem onClick={handleDeleteClicked} sx={{ color: theme.palette.error.main }}>
            Delete
          </MenuItem>
        )}
      </Menu>
    </StyledStack>
  );
};

export { SurveyInstance };
