import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError } from "../../types";
import { CreateSurveyInstanceDto } from "../../types/surveys";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  surveyGuid: string;
  startDateUtc: string;
  endDateUtc: string | null;
  postSubmissionMessage: string;
  notificationMessage: string | null;
  notificationDateTimesUtc: string[];
};

const useCreateSurveyInstanceRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createSurveyInstanceRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: CreateSurveyInstanceDto = {
      surveyGuid: variables.surveyGuid,
      startDateUtc: variables.startDateUtc,
      endDateUtc: variables.endDateUtc,
      postSubmissionMessage: variables.postSubmissionMessage,
      notificationMessage: variables.notificationMessage,
      notificationDateTimesUtc: variables.notificationDateTimesUtc
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-instances`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { createSurveyInstanceRequest };
};

const useCreateSurveyInstance = () => {
  const { createSurveyInstanceRequest } = useCreateSurveyInstanceRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSurveyInstanceRequest,
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurvey(variables.surveyGuid) });
    },
  });
}

export { useCreateSurveyInstance };
