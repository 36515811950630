import { FormControl, InputProps } from "@mui/material";
import { BufferedTextField, SmallInputLabel } from "../../../../components";

interface IProps {
  label: string;
}

const EditTextField = (props: IProps & InputProps) => {
  const { label, ...rest } = props;

  return (
    <FormControl fullWidth>
      <SmallInputLabel>{label}</SmallInputLabel>
      <BufferedTextField {...rest} />
    </FormControl>
  );
};

export { EditTextField };
