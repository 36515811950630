import { AddRounded, InfoOutlined, MoreVertRounded } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Menu, MenuItem, Stack, Tooltip, styled, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { useConfirm } from "material-ui-confirm";
import React, { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { v4 as newGuid } from "uuid";
import { Accordion, ActionItem, Typography } from "..";
import { ActionItemStatus } from "../../constants";
import { useSession } from "../../hooks";
import { useCreatePlanActionItem, useDeleteSection, useEditSection } from "../../http";
import { IBlock, ISection } from "../../models";
import { lexorank } from "../../utilities";
import { DraggableActionItem } from "./DraggableActionItem";
import { EditSectionModel } from "./EditSectionModal";
import { usePlanContext } from "./Plan";

const DroppableZone = styled(Stack)<{ $isDraggingOver: boolean; $isPastView: boolean }>`
  transition: ${({ theme }) => theme.transitions.create("background-color")};

  background-color: ${({ $isDraggingOver, $isPastView, theme }) =>
    $isDraggingOver ? theme.palette.primary.opacity5 : $isPastView ? "transparent" : theme.palette.common.white};
  pointer-events: ${({ $isDraggingOver }) => ($isDraggingOver ? "none" : "auto")};
`;

interface IProps {
  section: ISection;
  sections: ISection[];
  block: IBlock;
  blocks: IBlock[];
  isPastView: boolean;
  canEditPlan: boolean;
}

const Section = (props: IProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [showClosed, setShowClosed] = useState(false);
  const confirm = useConfirm();
  const { mutate: editSection } = useEditSection();
  const { mutate: deleteSection } = useDeleteSection();
  const { mutate: createPlanActionItem } = useCreatePlanActionItem();
  const user = useSession();
  const { planGuid } = usePlanContext();
  const [isEditSectionModalVisible, setIsEditSectionModalVisible] = useState(false);
  const [focusedActionItemGuid, setFocusedActionItemGuid] = useState<string | undefined>(undefined);
  const theme = useTheme();

  const isFirstSection = props.sections.indexOf(props.section) === 0;
  const isLastSection = props.sections.indexOf(props.section) === props.sections.length - 1;

  const closedItems = props.section.actionItems.filter((item) => item.currentStatus === ActionItemStatus.Closed);
  const activeItems = props.section.actionItems.filter((item) => item.currentStatus !== ActionItemStatus.Closed);

  function handleMenuItemClicked(e: React.MouseEvent) {
    setMenuAnchor(null);
    e.stopPropagation();
  }

  async function handleAddActionItemClicked(e: React.MouseEvent) {
    e.stopPropagation();

    if (!isExpanded) {
      setIsExpanded(true);
    }

    const transitionDuration = !isExpanded ? theme.transitions.duration.standard : 0;

    // Wait for the accordion expansion transition to complete
    setTimeout(() => {
      const newActionItemGuid = newGuid();
      const sortOrder = lexorank.getPrevRank(activeItems);

      const isCreatingOnUserPlan = planGuid === user.planGuid;

      createPlanActionItem({
        planGuid: planGuid,
        sectionGuid: props.section.guid,
        owners: isCreatingOnUserPlan
          ? [{ userId: user.userId, profilePhoto: user.profilePhoto, displayName: user.displayName, enabled: true }]
          : [],
        guid: newActionItemGuid,
        sortOrder: sortOrder,
        createdByUserId: user.userId,
      });

      setFocusedActionItemGuid(newActionItemGuid);
    }, transitionDuration);
  }

  async function handleEditClicked(e: React.MouseEvent) {
    handleMenuItemClicked(e);
    setIsEditSectionModalVisible(true);
  }

  async function handleMoveUpClicked(e: React.MouseEvent) {
    handleMenuItemClicked(e);

    const oldIndex = props.sections.indexOf(props.section);
    const newSortOrder = lexorank.getRank(props.sections, oldIndex, oldIndex - 1);

    editSection({
      planGuid: planGuid,
      guid: props.section.guid,
      blockGuid: props.block.guid,
      name: props.section.name,
      hintText: props.section.hintText,
      sortOrder: newSortOrder,
      modifiedByUserId: user.userId,
    });
  }

  async function handleMoveDownClicked(e: React.MouseEvent) {
    handleMenuItemClicked(e);

    const oldIndex = props.sections.indexOf(props.section);
    const newSortOrder = lexorank.getRank(props.sections, oldIndex, oldIndex + 1);

    editSection({
      planGuid: planGuid,
      guid: props.section.guid,
      blockGuid: props.block.guid,
      name: props.section.name,
      hintText: props.section.hintText,
      sortOrder: newSortOrder,
      modifiedByUserId: user.userId,
    });
  }

  async function handleMoveToBlock(e: React.MouseEvent, blockGuid: string) {
    handleMenuItemClicked(e);

    const newBlock = props.blocks.find((block) => block.guid === blockGuid);

    if (newBlock === undefined) {
      throw new Error("Invalid block");
    }

    const newSortOrder = lexorank.getNextRank(newBlock.sections);

    editSection({
      planGuid: planGuid,
      guid: props.section.guid,
      blockGuid: newBlock.guid,
      name: props.section.name,
      hintText: props.section.hintText,
      sortOrder: newSortOrder,
      modifiedByUserId: user.userId,
    });
  }

  async function handleDeleteClicked(e: React.MouseEvent) {
    handleMenuItemClicked(e);

    try {
      await confirm({
        dialogActionsProps: {
          sx: {
            justifyContent: "space-between",
          },
        },
        title: "Delete Section",
        titleProps: {
          sx: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
          },
        },
        content: (
          <Stack spacing={1}>
            <Typography>Deleting this section will delete all of the section's items as well.</Typography>
            <Typography sx={{ fontWeight: "bold" }}>This action is undoable.</Typography>
          </Stack>
        ),
        confirmationText: "Delete Section",
        confirmationButtonProps: {
          color: "error",
        },
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteSection({
      planGuid: planGuid,
      guid: props.section.guid,
      modifiedByUserId: user.userId,
    });
  }

  return (
    <Stack>
      <Droppable key={props.section.guid} droppableId={props.section.guid}>
        {(provided, snapshot) => (
          <DroppableZone
            $isPastView={props.isPastView}
            $isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
            ref={provided.innerRef}
            onMouseEnter={() => {
              setIsHovering(true);
            }}
            onMouseLeave={() => {
              setIsHovering(false);
            }}
          >
            <Accordion
              title={
                <Stack direction="row" sx={{ alignItems: "center", minHeight: "35px", width: "100%" }}>
                  <Stack direction="row" spacing={0.5} flexGrow={1} alignItems="center">
                    <Typography variant="caption" sx={{ color: "grey.400" }}>
                      {props.section.name}
                      <span style={{ textTransform: "none" }}>
                        {!isExpanded ? ` (${activeItems.length} Open, ${closedItems.length} Closed)` : ""}
                      </span>
                    </Typography>
                    {props.section.hintText && (
                      <Tooltip title={props.section.hintText}>
                        <InfoOutlined sx={{ color: "grey.300" }} />
                      </Tooltip>
                    )}
                  </Stack>

                  {(isHovering || !window.matchMedia("(pointer: fine)").matches) &&
                    !snapshot.isDraggingOver &&
                    props.canEditPlan &&
                    (props.section.isEditable || props.section.createActionItemAllowed) && (
                      <>
                        {props.section.createActionItemAllowed && (
                          <IconButton size="small" onClick={handleAddActionItemClicked}>
                            <AddRounded />
                          </IconButton>
                        )}

                        {props.section.isEditable && (
                          <>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                setMenuAnchor(e.currentTarget);
                              }}
                            >
                              <MoreVertRounded />
                            </IconButton>
                            <Menu
                              anchorEl={menuAnchor}
                              open={Boolean(menuAnchor)}
                              onClose={(e: React.PointerEvent) => {
                                setMenuAnchor(null);
                                e.stopPropagation();
                              }}
                            >
                              <MenuItem onClick={handleEditClicked}>Edit Section</MenuItem>
                              <MenuItem disabled={isFirstSection} onClick={handleMoveUpClicked}>
                                Move Section Up
                              </MenuItem>
                              <MenuItem disabled={isLastSection} onClick={handleMoveDownClicked}>
                                Move Section Down
                              </MenuItem>
                              {props.blocks
                                .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
                                .map((block, index) => (
                                  <MenuItem
                                    disabled={block.sections.includes(props.section)}
                                    key={index}
                                    onClick={(e) => handleMoveToBlock(e, block.guid)}
                                  >
                                    Move Section to "{block.name}"
                                  </MenuItem>
                                ))}
                              <MenuItem onClick={handleDeleteClicked} sx={{ color: theme.palette.error.main }}>
                                Delete Section
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </>
                    )}
                </Stack>
              }
              expanded={isExpanded}
              onExpandClicked={() => {
                if (showClosed && isExpanded) {
                  setShowClosed(false);
                }

                setIsExpanded(!isExpanded);
              }}
              backgroundColorOnHover={props.isPastView ? "transparent" : undefined}
            >
              {props.section.actionItems
                .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
                .map(
                  (actionItem, index) =>
                    actionItem.currentStatus !== ActionItemStatus.Closed && (
                      <DraggableActionItem
                        key={actionItem.guid}
                        index={index}
                        actionItemGuid={actionItem.guid}
                        planGuid={planGuid}
                        canEdit={actionItem.userCanEdit && !props.isPastView}
                        canEditPlan={props.canEditPlan}
                        isPastView={props.isPastView}
                        isFocused={actionItem.guid === focusedActionItemGuid}
                        onBlur={() => setFocusedActionItemGuid(undefined)}
                      />
                    )
                )}
            </Accordion>
            {provided.placeholder}
          </DroppableZone>
        )}
      </Droppable>

      {closedItems.length > 0 && isExpanded && (
        <Box sx={{ px: 1, my: 0.5 }}>
          <Button variant="tertiary" size="small" onClick={() => setShowClosed(!showClosed)}>
            {!showClosed ? `Show Closed (${closedItems.length})` : `Hide Closed`}
          </Button>
        </Box>
      )}

      {closedItems.length > 0 && showClosed && (
        <>
          <Divider variant="dashed" orientation="horizontal" sx={{ mx: 1 }} />
          <Stack divider={<Divider variant="dashed" orientation="horizontal" sx={{ mx: 1 }} />}>
            {closedItems
              .sort((a, b) =>
                DateTime.fromISO(a.statusLastModifiedDateTimeUtc, { zone: "utc" }) <
                DateTime.fromISO(b.statusLastModifiedDateTimeUtc, { zone: "utc" })
                  ? 1
                  : -1
              )
              .map((actionItem, _) => (
                <ActionItem
                  key={actionItem.guid}
                  actionItemGuid={actionItem.guid}
                  planGuid={planGuid}
                  canEdit={actionItem.userCanEdit && !props.isPastView}
                  canEditPlan={props.canEditPlan}
                  isPastView={props.isPastView}
                  isFocused={actionItem.guid === focusedActionItemGuid}
                  onBlur={() => setFocusedActionItemGuid(undefined)}
                  sx={{ px: 1 }}
                />
              ))}
          </Stack>
          <Divider variant="dashed" orientation="horizontal" sx={{ mx: 1 }} />
        </>
      )}

      <EditSectionModel
        visible={isEditSectionModalVisible}
        section={props.section}
        blockGuid={props.block.guid}
        onSaved={() => setIsEditSectionModalVisible(false)}
        onCancelled={() => setIsEditSectionModalVisible(false)}
      />
    </Stack>
  );
};

export { Section };
