import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { useGetSession } from "../../http";
import { Loading } from "../Loading";
import { ISessionContext, SessionContext } from "./SessionContext";

interface IProps {
  children?: React.ReactNode;
}

const SessionProvider = (props: IProps) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [azureAdUserId, setAzureAdUserId] = useState<string | undefined>(undefined);
  const { data, isLoading } = useGetSession(azureAdUserId, isAuthenticated);
  const [sessionContext, setSessionContext] = useState<ISessionContext>({
    isSessionLoaded: false,
    endSession: () => null,
  });

  useEffect(() => {
    setAzureAdUserId(instance.getActiveAccount()?.localAccountId);
  }, [instance, isAuthenticated]);

  useEffect(() => {
    if (data) {
      setSessionContext({
        isSessionLoaded: true,
        user: {
          displayName: data.user.displayName,
          planGuid: data.user.planGuid,
          profilePhoto: data.user.profilePhoto,
          userId: data.user.userId,
        },
        endSession,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  async function endSession() {
    setSessionContext({
      isSessionLoaded: false,
      user: undefined,
      endSession,
    });
  }

  return (
    <SessionContext.Provider value={sessionContext}>
      {sessionContext.isSessionLoaded ? props.children : <Loading />}
    </SessionContext.Provider>
  );
};

export { SessionProvider };
