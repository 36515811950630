import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Link,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";
import { Autocomplete, HeaderLayout, SmallInputLabel, TextEditor, TextEditorRef } from "../../../components";
import { Links, Paths } from "../../../constants";
import { useSession, useSlugify } from "../../../hooks";
import { useDeleteTeam, useEditTeam, useGetTeam, useGetTeams, useGetUsers } from "../../../http";
import { IUser } from "../../../models";
import { getTop, useDestructiveConfirm } from "../../../utilities";
import { TeamVideoUploader } from "./TeamVideoUploader";

const StickySideMenuCard = styled(Card)`
  position: sticky;
  top: 100px;
  overflow: visible;
  z-index: 2;
`;

interface IEditTeamFormState {
  name: string;
  slug: string;
  parentTeam: { slug: string; name: string } | null;
  description: string;
  accountabilities: string;
  boundaries: string;
  communicationChannels: string;
  otherInformation: string;
  administrators: IUser[];
}

interface UrlParams {
  slug: string;
}

const TeamSettingsPage = () => {
  const { slug } = useParams<keyof UrlParams>() as UrlParams;
  const { userId } = useSession();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate: editTeam, isLoading: isUpdatingTeam } = useEditTeam();
  const { mutate: deleteTeam, isLoading: isDeletingTeam } = useDeleteTeam();
  const { slugify } = useSlugify();
  const theme = useTheme();
  const destructiveConfirm = useDestructiveConfirm();

  const { isLoading: isLoadingTeam, isFetching: isFetchingTeam, data: team } = useGetTeam(slug, userId);
  const { isLoading: isLoadingTeams, data: teams } = useGetTeams();
  const { isLoading: isLoadingUsers, data: users } = useGetUsers();

  const aboutRef = useRef<HTMLParagraphElement>(null);
  const adminRef = useRef<HTMLParagraphElement>(null);
  const deleteRef = useRef<HTMLParagraphElement>(null);
  const accountabilitiesRef = useRef<TextEditorRef>(null);
  const boundariesRef = useRef<TextEditorRef>(null);
  const communicationChannelsRef = useRef<TextEditorRef>(null);
  const otherInformationRef = useRef<TextEditorRef>(null);

  var isButtonDisabled = isUpdatingTeam || isLoadingTeam || isDeletingTeam;

  const { formState, control, handleSubmit, reset, setValue } = useForm<IEditTeamFormState>({
    defaultValues: {
      name: "",
      slug: "",
      parentTeam: null,
      description: "",
      accountabilities: "",
      boundaries: "",
      communicationChannels: "",
      otherInformation: "",
      administrators: [],
    },
  });

  useEffect(() => {
    if (!isLoadingTeam && team) {
      reset({
        name: team.name,
        slug: team.slug,
        parentTeam: team.parentTeam,
        description: team.description,
        accountabilities: team.accountabilities,
        boundaries: team.boundaries,
        communicationChannels: team.communicationChannels,
        otherInformation: team.otherInformation,
        administrators: team.administrators,
      });

      // Update TextEditor state
      accountabilitiesRef.current?.setContent(team.accountabilities);
      boundariesRef.current?.setContent(team.boundaries);
      communicationChannelsRef.current?.setContent(team.communicationChannels);
      otherInformationRef.current?.setContent(team.otherInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingTeam, reset]);

  useEffect(() => {
    scrollToHash(location.hash);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  async function handleUpdateClicked() {
    handleSubmit((data) => {
      editTeam(
        {
          oldSlug: team!.slug,
          newSlug: data.slug,
          name: data.name,
          oldParentTeamSlug: team!.parentTeam?.slug,
          newParentTeamSlug: data.parentTeam?.slug,
          description: data.description,
          accountabilities: data.accountabilities,
          boundaries: data.boundaries,
          communicationChannels: data.communicationChannels,
          otherInformation: data.otherInformation,
          administratorUserIds: data.administrators.map((x) => x.userId),
          modifiedByUserId: userId!,
        },
        {
          onSuccess: () => {
            navigate(Links.Team(data.slug));
            enqueueSnackbar("Settings saved successfully.", { variant: "info" });
          },
        }
      );
    })();
  }

  async function handleDeleteClicked() {
    try {
      await destructiveConfirm({
        title: "Delete Team",
        content: "Are you sure you want to delete this team?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteTeam(
      {
        slug: team!.slug,
        parentSlug: team!.parentTeam?.slug,
        modifiedByUserId: userId!,
      },
      {
        onSuccess: () => {
          navigate(Paths.Home);
          enqueueSnackbar(`Team ${team?.name} has been deleted.`, { variant: "info" });
        },
      }
    );
  }

  function scrollToHash(hash: string) {
    let top = 0;

    switch (hash) {
      case "#about":
        top = getTop(aboutRef, 80);
        break;
      case "#admin":
        top = getTop(adminRef, 80);
        break;
      case "#delete":
        top = getTop(deleteRef, 80);
        break;
    }

    window.scrollTo({ top, behavior: "smooth" });
  }

  if (team && !team?.isAdministrator) {
    return <Navigate to={Paths.Forbidden} replace />;
  }

  return (
    <HeaderLayout
      title={
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h3">Team Settings</Typography>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" disabled={isButtonDisabled} onClick={() => navigate(Links.Team(team!.slug))}>
              Cancel
            </Button>
            <Button variant="contained" disabled={isButtonDisabled} onClick={handleUpdateClicked}>
              Save
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Grid container spacing={1} sx={{ justifyContent: "center" }}>
        <Grid item xs={3} sx={{ display: { xs: "none", md: "block" } }}>
          <StickySideMenuCard sx={{ p: 1 }}>
            <Stack spacing={1}>
              <Link
                href="#about"
                sx={{ color: theme.palette.text.primary, textDecoration: "none", textTransform: "uppercase" }}
              >
                About
              </Link>
              <Link
                href="#admin"
                sx={{ color: theme.palette.text.primary, textDecoration: "none", textTransform: "uppercase" }}
              >
                Administrators
              </Link>
              <Link
                href="#delete"
                sx={{ color: theme.palette.text.primary, textDecoration: "none", textTransform: "uppercase" }}
              >
                Delete
              </Link>
            </Stack>
          </StickySideMenuCard>
        </Grid>
        <Grid sx={{ width: "100%" }} item md={9} sm={12}>
          {isLoadingTeam ? (
            <Card>
              <CardHeader title={<Skeleton />} />
              <Divider />
              <Stack spacing={0.25} sx={{ m: 1 }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Stack>
            </Card>
          ) : (
            <Stack spacing={1}>
              <Card ref={aboutRef}>
                <CardHeader title={<Typography variant="h6">About</Typography>} />
                <Divider />
                <Stack spacing={1} sx={{ m: 1 }}>
                  <Typography variant="body1">
                    Set the team name, purpose, and the connected team providing wider context.
                  </Typography>
                  <Stack spacing={0.25}>
                    <SmallInputLabel>Team Name *</SmallInputLabel>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: { message: "Team Name is required", value: true } }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onChange={(e) => {
                            setValue("name", e.target.value);
                            setValue("slug", slugify(e.target.value));
                          }}
                          error={formState.errors["name"] !== undefined}
                          helperText={formState.errors["name"]?.message}
                          placeholder="Enter the team name..."
                          inputProps={{ maxLength: 64 }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack spacing={0.25}>
                    <SmallInputLabel>URL Slug *</SmallInputLabel>
                    <Controller
                      name="slug"
                      control={control}
                      rules={{ required: { message: "URL Slug is required", value: true } }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onBlur={(e) => {
                            setValue("slug", slugify(e.target.value));
                          }}
                          error={formState.errors["slug"] !== undefined}
                          helperText={formState.errors["slug"]?.message}
                          placeholder="Enter the slug that will be used in the url for the team page..."
                          inputProps={{ maxLength: 64 }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack spacing={0.25}>
                    <SmallInputLabel>Connected Team</SmallInputLabel>
                    <Controller
                      name="parentTeam"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          isLoading={isLoadingTeams}
                          disabled={isLoadingTeams}
                          options={teams ? teams.filter((x) => x.slug !== team?.slug) : []}
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <MenuItem key={option.slug} component="li" {...props}>
                              <Typography>{option.name}</Typography>
                            </MenuItem>
                          )}
                          onChange={(_, option) => field.onChange(option)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select a team..."
                              inputProps={{ ...params.inputProps }}
                              error={formState.errors["parentTeam"] !== undefined}
                              helperText={formState.errors["parentTeam"]?.message}
                            />
                          )}
                          value={field.value}
                          isOptionEqualToValue={(option, value) => option.slug === value.slug}
                        />
                      )}
                    />
                  </Stack>

                  <Stack spacing={0.25}>
                    <SmallInputLabel>Purpose *</SmallInputLabel>
                    <Controller
                      name="description"
                      control={control}
                      rules={{ required: { message: "Purpose is required", value: true } }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={formState.errors["description"] !== undefined}
                          helperText={formState.errors["description"]?.message}
                          placeholder="Enter the purpose of the team that is easily understandable by people outside of your team..."
                          multiline
                          inputProps={{ maxLength: 512 }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack spacing={0.25}>
                    <Stack direction="row" spacing={0.25} alignItems="center">
                      <SmallInputLabel>Team Video</SmallInputLabel>
                      <Tooltip
                        title={
                          <Box sx={{ p: 0.5 }}>
                            <Stack spacing={0.25}>
                              <Stack direction="row" spacing={0.25} alignItems="center">
                                <InfoOutlined fontSize="medium" />
                                <Typography variant="body2">Upload Team Video</Typography>
                              </Stack>
                              <Typography>
                                Select any resolution mp4 or webm video from your device's file manager. Maximum video
                                size is 300 MB.
                              </Typography>
                            </Stack>
                          </Box>
                        }
                      >
                        <InfoOutlined fontSize="small" />
                      </Tooltip>
                    </Stack>
                    <TeamVideoUploader isFetchingTeam={isFetchingTeam} team={team!} />
                  </Stack>

                  <Stack spacing={0.25}>
                    <SmallInputLabel>Accountabilities</SmallInputLabel>
                    <Controller
                      name="accountabilities"
                      control={control}
                      render={({ field }) => (
                        <TextEditor
                          ref={accountabilitiesRef}
                          initialValue={field.value}
                          onChange={field.onChange}
                          placeholder="Enter areas of on-going accountabilities for your team..."
                        />
                      )}
                    />
                  </Stack>

                  <Stack spacing={0.25}>
                    <SmallInputLabel>Boundaries</SmallInputLabel>
                    <Controller
                      name="boundaries"
                      control={control}
                      render={({ field }) => (
                        <TextEditor
                          ref={boundariesRef}
                          initialValue={field.value}
                          onChange={field.onChange}
                          placeholder="Enter team boundaries..."
                        />
                      )}
                    />
                  </Stack>

                  <Stack spacing={0.25}>
                    <SmallInputLabel>Communication Channels</SmallInputLabel>
                    <Controller
                      name="communicationChannels"
                      control={control}
                      render={({ field }) => (
                        <TextEditor
                          ref={communicationChannelsRef}
                          initialValue={field.value}
                          onChange={field.onChange}
                          placeholder="Enter preferred channels of communication with the team......"
                        />
                      )}
                    />
                  </Stack>

                  <Stack spacing={0.25}>
                    <SmallInputLabel>Other Information</SmallInputLabel>
                    <Controller
                      name="otherInformation"
                      control={control}
                      render={({ field }) => (
                        <TextEditor
                          ref={otherInformationRef}
                          initialValue={field.value}
                          onChange={field.onChange}
                          placeholder="Enter other resources relevant to the team..."
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Card>
              <Card ref={adminRef}>
                <CardHeader title={<Typography variant="h6">Administrators</Typography>} />
                <Divider />
                <Stack spacing={0.25} sx={{ m: 1 }}>
                  <Typography variant="body1">Administrators can update team details and delete the team.</Typography>
                  <Controller
                    name="administrators"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        isLoading={isLoadingUsers}
                        disabled={isLoadingUsers}
                        options={users ?? []}
                        getOptionLabel={(option) => option.displayName}
                        renderOption={(props, option) => (
                          <MenuItem component="li" {...props} key={option.userId}>
                            <Stack>
                              <Typography>{option.displayName}</Typography>
                              <Typography variant="caption">{option.email}</Typography>
                            </Stack>
                          </MenuItem>
                        )}
                        onChange={(_, option) => field.onChange(option)}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select people..." inputProps={{ ...params.inputProps }} />
                        )}
                        value={field.value.sort((a, b) => a.displayName.localeCompare(b.displayName))}
                        isOptionEqualToValue={(option, value) => option.userId === value.userId}
                        multiple
                      />
                    )}
                  />
                </Stack>
              </Card>
              <Card ref={deleteRef}>
                <CardHeader title={<Typography variant="h6">Delete</Typography>} />
                <Divider />
                <Stack spacing={1} sx={{ m: 1, alignItems: "start" }}>
                  <Typography variant="body1">
                    Deleting the team will delete all the associated content: roles, connections, strategic plan,
                    metrics, and resources with respective updates and comments.
                  </Typography>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteClicked}
                    endIcon={
                      isDeletingTeam ? <CircularProgress size={20} sx={{ color: theme.palette.common.white }} /> : null
                    }
                    disabled={isButtonDisabled}
                  >
                    Delete
                  </Button>
                </Stack>
              </Card>
            </Stack>
          )}
        </Grid>
      </Grid>
    </HeaderLayout>
  );
};

export { TeamSettingsPage };
