import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { ITeamNetwork } from "../../../models";
import { FetchError, GetTeamNetworkDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetTeamNetworkRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getTeamNetworkRequest({
    queryKey,
    signal,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.teams.getTeamNetworkList>>): Promise<GetTeamNetworkDto> {
    const [{ slug }] = queryKey;
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/network`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal,
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getTeamNetworkRequest };
};

const useGetTeamNetwork = (slug: string | undefined) => {
  const { getTeamNetworkRequest } = useGetTeamNetworkRequest();

  return useQuery({
    queryKey: queryKeys.teams.getTeamNetworkList(slug),
    queryFn: getTeamNetworkRequest,
    select: (data): ITeamNetwork => ({
      childTeams: data.childTeams,
      roles: data.roles.map((x) => ({
        guid: x.guid,
        name: x.name,
        description: x.description,
        isOpen: x.isOpen,
        isOpenLastModifiedUtc: DateTime.fromISO(x.isOpenLastModifiedUtc, { zone: "utc" }),
        members: x.members,
      })),
      members: data.members,
    }),
    enabled: slug !== undefined,
  });
};

export { useGetTeamNetwork };
