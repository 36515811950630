import { DateTime } from "luxon";
import { CommentType } from "../constants";
import { CreateCommentResultDto, GetCommentsDto } from "../http";
import { IComment } from "../models";

function fromGetCommentsDto(dto: GetCommentsDto): IComment[] {
  return dto.comments.map(
    (comment) =>
      ({
        commentGuid: comment.guid,
        type: CommentType.Comment,
        timestampUtc: DateTime.fromISO(comment.dateCreatedUtc, { zone: "utc" }),
        lastModifiedUtc: DateTime.fromISO(comment.lastModifiedUtc, { zone: "utc" }),
        user: comment.createdBy,
        text: comment.text,
      } as IComment)
  );
}

function fromCreateCommentResultDto(dto: CreateCommentResultDto): IComment {
  return {
    commentGuid: dto.guid,
    type: CommentType.Comment,
    timestampUtc: DateTime.fromISO(dto.dateCreatedUtc, { zone: "utc" }),
    lastModifiedUtc: DateTime.fromISO(dto.lastModifiedUtc, { zone: "utc" }),
    user: dto.createdBy,
    text: dto.text,
  };
}

const CommentMapper = {
  fromGetCommentsDto,
  fromCreateCommentResultDto,
};

export { CommentMapper };
