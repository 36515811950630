import { Box, Card, CardContent, CardHeader, Divider, Drawer, Stack, styled, Switch, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useBreakpoints } from "../../../../hooks";
import { useUpdatePlanVersionNotes } from "../../../../http";
import { IPlanVersionSummary, PlanVersionSummary } from "../../../../models";
import { PlanVersionHistoryItems } from "./PlanVersionHistoryItems";

const SWITCH_ANIMATION_MS = 150;

const VersionHistoryDrawer = styled(Card)<{ $isMdUp: boolean }>`
  border-radius: 15px;
  margin: ${({ theme }) => theme.spacing(1)};
  margin-bottom: 120px;
  height: 100%;
  width: ${({ $isMdUp }) => ($isMdUp ? "400px" : "300px")};
  overflow-y: auto;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

const VersionHistoryDrawerHeader = styled(Box)`
  position: sticky;
  top: 0;
  overflow: visible;
  background-color: ${({ theme }) => theme.palette.common.white};
  z-index: 1;
`;

interface IProps {
  currentTeamSlug: string;
  isLoading: boolean;
  planVersionSummaries: IPlanVersionSummary[];
  visible: boolean;
  onClose: () => void;
}

const PlanVersionHistory = (props: IProps) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showChanges, setShowChanges] = useState(false);
  const [planVersionId, setPlanVersionId] = useState(searchParams.get("version") ?? undefined);
  const { isMdUp } = useBreakpoints();
  const { mutate } = useUpdatePlanVersionNotes(props.currentTeamSlug);

  useEffect(() => {
    setShowChanges(searchParams.get("showDiff") !== null);
    setPlanVersionId(searchParams.get("version") ?? undefined);
  }, [searchParams]);

  const handleShowChangesChanged = (newValue: boolean) => {
    setShowChanges(newValue);
    if (newValue) {
      searchParams.append("showDiff", newValue.toString());
    } else {
      searchParams.delete("showDiff");
    }
    setTimeout(() => {
      setSearchParams(searchParams);
    }, SWITCH_ANIMATION_MS);
  };

  const handleVersionClicked = useCallback(
    (planVersion: PlanVersionSummary) => {
      // If the planVersionId is null, we're already viewing the published version
      if (planVersionId === null && planVersion.isPublished) {
        return;
      }

      // Don't change versions to the version we're already viewing
      if (planVersionId === planVersion.planVersionId) {
        return;
      }

      if (planVersion.isPublished) {
        searchParams.delete("version");
      } else {
        searchParams.set("version", planVersion.planVersionId);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, planVersionId]
  );

  const handleNotesUpdated = useCallback(
    (planVersion: PlanVersionSummary) => {
      mutate({ slug: planVersion.teamSlug, planVersionId: planVersion.planVersionId, notes: planVersion.notes });
    },
    [mutate]
  );

  return (
    <Drawer
      open={props.visible}
      onClose={props.onClose}
      PaperProps={{ style: { top: isMdUp ? "105px" : "10px", border: "none" } }}
      BackdropProps={{ style: { background: "transparent", zIndex: 5 } }}
      anchor="right"
      sx={{
        position: "inherit",
        [`& .MuiDrawer-paper`]: { background: "transparent", boxShadow: "none" },
      }}
      keepMounted
    >
      <VersionHistoryDrawer ref={drawerRef} $isMdUp={isMdUp}>
        <VersionHistoryDrawerHeader>
          <CardHeader
            title={
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Versions</Typography>
              </Stack>
            }
          />
          <Divider />
          <Stack direction="row" sx={{ px: 1, py: 0.5, justifyContent: "space-between" }}>
            <Typography>Show changes on plan</Typography>
            <Switch
              onChange={(e) => {
                handleShowChangesChanged(e.target.checked);
              }}
              checked={showChanges}
            />
          </Stack>
          <Divider />
        </VersionHistoryDrawerHeader>
        <CardContent>
          {!props.isLoading && (
            <PlanVersionHistoryItems
              planVersions={props.planVersionSummaries}
              currentPlanVersionId={planVersionId}
              onClick={handleVersionClicked}
              onNotesUpdated={handleNotesUpdated}
            />
          )}
        </CardContent>
      </VersionHistoryDrawer>
    </Drawer>
  );
};

export { PlanVersionHistory };
