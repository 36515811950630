import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { DateTime } from "luxon";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import { SmallInputLabel, TextEditor } from "../../../components";
import { DateRange, DateRangePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useCreateSurveyInstance, useEditSurveyInstance, useGetSurveyInstance } from "../../../http";
import { AddRounded, DeleteOutlineRounded, InfoOutlined } from "@mui/icons-material";
import { ISurveyInstance } from "../../../models";
import { Links } from "../../../constants";
import { useSnackbar } from "notistack";

interface IParams {
  guid: string;
}

interface SurveyInstanceModalProps {
  surveyInstances: ISurveyInstance[];
  visible: boolean;
  selectedInstanceGuid?: string;
  onClose: () => void;
}

interface SurveyInstanceFormState {
  dateRange: DateRange<unknown> | any;
  notificationMessage: string;
  postSubmissionMessage: string;
}

function getTextFromHtml(htmlString: string): string {
  const div = document.createElement("div");
  div.innerHTML = htmlString;
  return div.innerText.trim();
}

const SurveyInstanceModal = (props: SurveyInstanceModalProps) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { guid: surveyGuid } = useParams<keyof IParams>() as IParams;
  const { data: instance, isLoading: isLoadingInstance } = useGetSurveyInstance(props.selectedInstanceGuid);
  const { mutate: createSurveyInstance } = useCreateSurveyInstance();
  const { mutate: editSurveyInstance } = useEditSurveyInstance();
  const [isSendNotificationMessage, setIsSendNotificationMessage] = useState(false);
  const [notifications, setNotifications] = useState<(string | null)[]>([null]);
  const [notificationsError, setNotificationsError] = useState<boolean>(false);
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const {
    formState: { errors },
    watch,
    getValues,
    control,
    handleSubmit,
    reset,
  } = useForm<SurveyInstanceFormState>({
    defaultValues: {
      dateRange: [null, null],
      notificationMessage: "Please fill out the survey.",
      postSubmissionMessage: "Thank you for filling out the survey.",
    },
  });
  const isEmptyStartDate = watch("dateRange")[0] === null;

  useEffect(() => {
    if (instance) {
      setNotifications(instance.notificationDateTimesUtc);
      reset(
        {
          dateRange: [instance.startDateUtc, instance.endDateUtc],
          notificationMessage: instance.notificationMessage,
          postSubmissionMessage: instance.postSubmissionMessage,
        },
        { keepDefaultValues: true }
      );
    } else {
      setNotifications([null]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInstance]);

  useEffect(() => {
    let actualNotifications = notifications;

    if (isSendNotificationMessage && !notifications.length) {
      actualNotifications = [null];
      setNotifications(actualNotifications);
    }

    setNotificationsError(
      isSendNotificationMessage ? !actualNotifications.length || actualNotifications.some((i) => i === null) : false
    );
  }, [notifications, isSendNotificationMessage]);

  const shouldDisableDate = (date: DateTime): any => {
    const isDateInRange = props.surveyInstances
      .filter((i) => i.guid !== instance?.guid)
      .some((range) => {
        const startDate = range.startDateUtc ? range.startDateUtc : null;
        const endDate = range.endDateUtc ? range.endDateUtc : null;
        return startDate && endDate && date >= startDate && date <= endDate;
      });

    const isCurrentlySelectedDateInRange =
      instance &&
      instance.startDateUtc &&
      instance.endDateUtc &&
      date >= instance.startDateUtc &&
      date <= instance.endDateUtc;

    const selectedStartDate = getValues("dateRange")?.[0];
    const selectedEndDate = getValues("dateRange")?.[1];

    if (selectedStartDate && !selectedEndDate) {
      return isDateInRange || (isCurrentlySelectedDateInRange && date < selectedStartDate);
    }

    if (selectedStartDate && selectedEndDate) {
      return isDateInRange || (isCurrentlySelectedDateInRange && (date < selectedStartDate || date > selectedEndDate));
    }

    return isDateInRange || (isCurrentlySelectedDateInRange && date > selectedStartDate);
  };

  function handleClose(_: object, reason: string) {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    setNotifications([]);
  }

  function handleSaveClicked() {
    setStartDateError(isEmptyStartDate);

    !notificationsError &&
      !isEmptyStartDate &&
      handleSubmit((data) => {
        if (!props.selectedInstanceGuid) {
          createSurveyInstance({
            surveyGuid: surveyGuid,
            startDateUtc: data.dateRange[0].toISODate(),
            endDateUtc: data.dateRange[1] ? data.dateRange[1].toISODate() : null,
            postSubmissionMessage: data.postSubmissionMessage,
            notificationMessage: isSendNotificationMessage ? data.notificationMessage : "",
            notificationDateTimesUtc: isSendNotificationMessage ? (notifications as string[]) : [],
          });
        } else {
          editSurveyInstance({
            surveyGuid: surveyGuid,
            instanceGuid: props.selectedInstanceGuid,
            startDateUtc: data.dateRange[0].toISODate(),
            endDateUtc: data.dateRange[1] ? data.dateRange[1].toISODate() : null,
            postSubmissionMessage: data.postSubmissionMessage,
            notificationMessage: isSendNotificationMessage ? data.notificationMessage : "",
            notificationDateTimesUtc: isSendNotificationMessage ? (notifications as string[]) : [],
          });
        }
        setIsSaved(true);
      })();
  }

  function handleDateTimeChange(index: number, value: DateTime | null) {
    const updatedNotifications = [...notifications];
    updatedNotifications[index] = value ? value.toISO() : null;
    setNotifications(updatedNotifications);
  }

  function handleDeleteNotificationTime(index: number) {
    const updatedNotifications = [...notifications];
    updatedNotifications.splice(index, 1);
    setNotifications(updatedNotifications);
  }

  function handleAddNotificationTime() {
    setNotifications([...notifications, null]);
  }

  function handleCloseClicked() {
    reset(undefined, { keepDefaultValues: false });
    props.onClose();
    setIsSaved(false);
  }

  if (props.selectedInstanceGuid && isLoadingInstance) {
    return <Skeleton variant="rectangular" height="250px" />;
  }

  return (
    <Dialog open={props.visible} onClose={handleClose}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DialogTitle>Share Survey</DialogTitle>
        <DialogContent>
          {isSaved ? (
            <Stack spacing={1}>
              <Typography>
                Responses are collected through the posted link from
                {getValues("dateRange")[0] ? ` ${getValues("dateRange")[0]?.toLocaleString(DateTime.DATE_MED)}` : ""}
                {getValues("dateRange")[1] ? ` to ${getValues("dateRange")[1]?.toLocaleString(DateTime.DATE_MED)}` : ""}
                .
              </Typography>
              {isSendNotificationMessage && (
                <Typography>
                  Your target audience will be notified about the new survey on{" "}
                  {notifications
                    .map((date) =>
                      date
                        ? DateTime.fromISO(date, { zone: "utc" }).toLocal().toLocaleString(DateTime.DATETIME_MED)
                        : ""
                    )
                    .join(", ")}
                  .
                </Typography>
              )}
              <Link href={Links.SurveyResponse(surveyGuid)}>{window.origin + Links.SurveyResponse(surveyGuid)}</Link>
            </Stack>
          ) : (
            <Stack divider={<Divider variant="dashed" />} spacing={1}>
              <Stack>
                <Typography mb={1}>Define how you want to share survey with with your target audience.</Typography>
                <SmallInputLabel>Start and end date</SmallInputLabel>
                <Controller
                  control={control}
                  name="dateRange"
                  render={({ field }) => (
                    <DateRangePicker
                      format="MM/dd/yyyy"
                      calendars={2}
                      value={field.value}
                      onChange={(e) => {
                        setStartDateError(isEmptyStartDate);
                        field.onChange(e);
                      }}
                      //todo: date validation need to be reimplemented
                      shouldDisableDate={shouldDisableDate}
                      minDate={instance ? instance.startDateUtc : undefined}
                      slotProps={{
                        textField: () => ({
                          label: "",
                          placeholder: "select date",
                        }),
                        actionBar: {
                          actions: ["today"],
                        },
                      }}
                    />
                  )}
                />
                {startDateError && (
                  <Typography variant="caption" color={theme.palette.error.main}>
                    Start date is required
                  </Typography>
                )}
              </Stack>
              <Stack spacing={1}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Switch
                    name="send-notification"
                    defaultChecked={isSendNotificationMessage}
                    onClick={() => {
                      setIsSendNotificationMessage(!isSendNotificationMessage);
                    }}
                  />
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography>Send Notification Message</Typography>
                    <Tooltip
                      title={
                        <Stack direction="row" spacing={0.5} sx={{ alignItems: "baseline" }}>
                          <InfoOutlined sx={{ position: "relative", top: "2px" }} />
                          <Typography>
                            {`When set to ON, the notification message will automatically be sent to your target audience
                            at the set notification time. When set to OFF, notification message and notification time
                            are reset to default values.`}
                          </Typography>
                        </Stack>
                      }
                    >
                      <InfoOutlined />
                    </Tooltip>
                  </Stack>
                </Stack>
                {isSendNotificationMessage && (
                  <>
                    <Stack>
                      <SmallInputLabel>Notifications message</SmallInputLabel>
                      <Controller
                        name="notificationMessage"
                        control={control}
                        rules={{
                          validate: {
                            isNotEmpty: (value) => {
                              if (isSendNotificationMessage) {
                                return getTextFromHtml(value) ? true : "Notification message is required";
                              } else {
                                return true;
                              }
                            },
                          },
                        }}
                        render={({ field }) => <TextEditor initialValue={field.value} onChange={field.onChange} />}
                      />
                      <Typography variant="caption" color={theme.palette.error.main}>
                        {errors["notificationMessage"]?.message}
                      </Typography>
                    </Stack>
                    <Stack divider={<Divider />}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="overline" color={theme.palette.text.primary}>
                          Notification Times
                        </Typography>
                        <IconButton size="small" onClick={handleAddNotificationTime}>
                          <AddRounded />
                        </IconButton>
                      </Stack>
                      <Stack component="ul" spacing={1} sx={{ mx: 0, mt: 1, mb: 0, p: 0 }}>
                        {notifications.map((notification, i) => (
                          <Stack key={i} component="li" direction="row" alignItems="center">
                            <Typography>Send notification message on</Typography>
                            <DateTimePicker
                              value={notification ? DateTime.fromISO(notification) : null}
                              onChange={(value) => handleDateTimeChange(i, value)}
                              sx={{ width: 220, ml: 0.5 }}
                              slotProps={{
                                textField: () => ({
                                  placeholder: "select date and time",
                                }),
                              }}
                            />
                            {i > 0 && (
                              <IconButton
                                onClick={() => handleDeleteNotificationTime(i)}
                                size="small"
                                sx={{ ml: "auto" }}
                              >
                                <DeleteOutlineRounded color="error" />
                              </IconButton>
                            )}
                          </Stack>
                        ))}
                        {notificationsError && (
                          <Stack sx={{ pl: 11.25 }}>
                            <Typography variant="caption" color={theme.palette.error.main}>
                              All notifications times must be filled
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  </>
                )}
              </Stack>
              <Stack>
                <SmallInputLabel>Post Submission Message</SmallInputLabel>
                <Controller
                  name="postSubmissionMessage"
                  control={control}
                  rules={{
                    validate: {
                      isNotEmpty: (value) => {
                        return getTextFromHtml(value) ? true : "Post Submission Message is required";
                      },
                    },
                  }}
                  render={({ field }) => <TextEditor initialValue={field.value} onChange={field.onChange} />}
                />
                <Typography variant="caption" color={theme.palette.error.main}>
                  {errors["postSubmissionMessage"]?.message}
                </Typography>
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          {isSaved ? (
            <>
              <Button variant="outlined" onClick={handleCloseClicked} sx={{ mr: "auto" }}>
                Close
              </Button>
              <Button variant="contained" onClick={() => setIsSaved(false)}>
                Edit instance
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  navigator.clipboard.writeText(window.origin + Links.SurveyResponse(surveyGuid)).then(() => {
                    enqueueSnackbar(`Share the link with your target audience.`, {
                      variant: "success",
                    });
                  });
                  handleCloseClicked();
                  setIsSaved(false);
                }}
              >
                Copy link
              </Button>
            </>
          ) : (
            <>
              <Button variant="outlined" onClick={handleCloseClicked}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSaveClicked}>
                Save
              </Button>
            </>
          )}
        </DialogActions>
      </LocalizationProvider>
    </Dialog>
  );
};

export { SurveyInstanceModal };
