import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetSurveyResponsesReportResultDto, GetSurveyResponsesReportDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetSurveyAnswersReportRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getSurveyAnswersReportRequest({
    queryKey,
  }: QueryFunctionContext<
    ReturnType<typeof queryKeys.surveys.getSurveyAnswersReport>
  >): Promise<GetSurveyResponsesReportResultDto> {
    const accessToken = await getAccessToken();
    const [{ surveyGuid, surveyInstanceGuid }] = queryKey;

    const body: GetSurveyResponsesReportDto = {
      instanceGuid: surveyInstanceGuid === "" ? undefined : surveyInstanceGuid,
    };

    const response = await fetch(`${configuration.apiRootUrl}/surveys/${surveyGuid}/report`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getSurveyAnswersReportRequest };
};

const useGetSurveyAnswersReport = (surveyGuid: string, surveyInstanceGuid?: string) => {
  const { getSurveyAnswersReportRequest } = useGetSurveyAnswersReportRequest();

  return useQuery({
    queryKey: queryKeys.surveys.getSurveyAnswersReport(surveyGuid, surveyInstanceGuid),
    queryFn: getSurveyAnswersReportRequest,
  });
};

export { useGetSurveyAnswersReport };
