import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { useParams } from "react-router";
import { useSession } from "../../../../hooks";
import { useCreatePlan } from "../../../../http";
import { useTeamPageLayoutContext } from "../../TeamPageLayout";

interface IParams {
  slug: string;
}

const TeamStrategicMapPlaceholder = () => {
  const { slug } = useParams<keyof IParams>() as IParams;
  const { userId } = useSession();
  const { team } = useTeamPageLayoutContext();
  const { mutate: createPlan, isLoading: isCreatingPlan } = useCreatePlan();

  function handleCreatePlanClicked() {
    if (team === undefined) {
      throw new Error("Team is undefined");
    }

    createPlan({
      slug: slug,
      createdByUserId: userId,
    });
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={1} direction="row" alignItems="center">
          <Stack spacing={0.25} sx={{ flexGrow: 1 }}>
            <Typography variant="h5">Strategic Map</Typography>
            <Typography variant="body1">Document team strategy and/or implementation plans</Typography>
          </Stack>

          {(team?.isAdministrator || team?.isMember) && (
            <Button variant="contained" disabled={isCreatingPlan} onClick={handleCreatePlanClicked}>
              Create Strategic Map
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export { TeamStrategicMapPlaceholder };
