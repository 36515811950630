import { ClearRounded, ExpandMoreRounded } from "@mui/icons-material";
import {
  Autocomplete as MuiAutocomplete,
  CircularProgress,
  styled,
  AutocompleteProps,
  Typography,
} from "@mui/material";
import { createContext, forwardRef, useContext } from "react";
import { FixedSizeList } from "react-window";

const StyledMuiAutocomplete = styled(MuiAutocomplete)`
  .MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.grey[400]};

    &.Mui-disabled {
      color: ${({ theme }) => theme.palette.text.disabled};
    }
  }

  .MuiChip-root {
    height: 32px;
    background-color: ${({ theme }) => theme.palette.primary.transparent10};
    border-radius: 32px;
    color: ${({ theme }) => theme.palette.primary.main};

    .MuiChip-label {
      font-size: ${({ theme }) => theme.typography.body1.fontSize};
      text-transform: none;
    }
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
` as typeof MuiAutocomplete;

const LISTBOX_PADDING = 10; // px

interface IProps<T> {
  getOptionLabel: (item: T) => string;
  isLoading?: boolean;
}

const WindowedAutocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: IProps<T> & AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const { isLoading, ...other } = props;

  const renderRow = ({ index, data, style }: any): JSX.Element => {
    const dataSet = data[index];
    const displayOption: string = props.getOptionLabel(dataSet[1]);
    const inlineStyle = {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    };

    return (
      <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
        {displayOption}
      </Typography>
    );
  };

  const OuterElementContext = createContext({});

  const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  const ListBoxComponent = forwardRef((props: any, ref: any) => {
    const { children, ...other } = props;
    const itemData: any = [];
    children.forEach((item: any) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;
    const itemSize: number = 36;

    const getChildSize = (child: React.ReactChild) => {
      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a: number, b: number) => a + b, 0);
    };

    return (
      <div ref={ref} {...other}>
        <OuterElementContext.Provider value={other}>
          <FixedSizeList
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            itemData={itemData}
            itemCount={itemCount}
            itemSize={itemSize}
            outerElementType={OuterElementType}
            innerElementType="ul"
            overscanCount={5}
          >
            {renderRow}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  return (
    <StyledMuiAutocomplete
      blurOnSelect
      handleHomeEndKeys
      disableListWrap
      clearIcon={<ClearRounded sx={{ fontSize: "20px" }} />}
      ListboxComponent={ListBoxComponent}
      renderOption={(props, option) => [props, option]}
      popupIcon={
        isLoading ? (
          <CircularProgress size={20} sx={{ color: "text.disabled" }} />
        ) : props.disabled ? null : (
          <ExpandMoreRounded sx={{ color: "primary.main", fontSize: "24px" }} />
        )
      }
      {...other}
    />
  );
};

export { WindowedAutocomplete };
