import React from "react";
import { Location, NavigateFunction, Params, useLocation, useNavigate, useParams } from "react-router";

export interface IRouterProps {
  router?: {
    location: Location;
    navigate: NavigateFunction;
    params: Readonly<Params<string>>;
  };
}

export function withRouter<IProps>(Component: typeof React.Component<IProps>) {
  function ComponentWithRouterProps(props: IProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProps;
}
