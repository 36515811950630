import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetFeedDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetIssueFeedRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getIssueFeedRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.issues.getIssueFeed>>): Promise<GetFeedDto> {
    const accessToken = await getAccessToken();
    const [{ guid }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/issues/${guid}/feed`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getIssueFeedRequest };
};

const useGetIssueFeed = (guid: string) => {
  const { getIssueFeedRequest } = useGetIssueFeedRequest();

  return useQuery({
    queryKey: queryKeys.issues.getIssueFeed(guid),
    queryFn: getIssueFeedRequest,
  });
};

export { useGetIssueFeed };
