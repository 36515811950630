import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError } from "../../types";
import { EditSurveyAdministratorsDto } from "../../types/surveys";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  userIds: string[];
  modifiedByUserId: string;
};

const useEditSurveyAdministratorsRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editSurveyAdministratorsRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();

    const body: EditSurveyAdministratorsDto = {
      userIds: variables.userIds,
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/surveys/${variables.guid}/administrators`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editSurveyAdministratorsRequest };
};

const useEditSurveyAdministrators = () => {
  const { editSurveyAdministratorsRequest } = useEditSurveyAdministratorsRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editSurveyAdministratorsRequest,
    onSuccess: async (_data, variables) => {
      await queryClient.refetchQueries(queryKeys.surveys.getSurvey(variables.guid));
    },
  });
};

export { useEditSurveyAdministrators };
