import { alpha, Button as MuiButton, ButtonProps, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const Button = styled(MuiButton)<{ $isPressing: boolean; $fillColour: string }>`
  color: ${({ theme }) => theme.palette.common.white};
  background: ${({ theme, $isPressing, $fillColour }) =>
    $isPressing ? `linear-gradient(to right, ${$fillColour} 50%, ${theme.palette.grey[300]} 50%)` : $fillColour};
  background-size: 201% 100%;
  background-position: ${({ $isPressing }) => ($isPressing ? "left bottom" : "right bottom")};
  transition: ${({ $isPressing }) => ($isPressing ? "background-position ease-out 2s" : undefined)};

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }

  &.Mui-disabled {
    color: ${({ theme }) => alpha(theme.palette.common.black, 0.26)};
    background-color: ${({ theme }) => alpha(theme.palette.common.black, 0.12)};
  }
`;

Button.defaultProps = {
  disableRipple: true,
};

interface IProps {
  onClick: () => void;
  fillColour: string;
}

const LongPressButton = (props: IProps & ButtonProps) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [isPressing, setIsPressing] = useState(false);
  const { fillColour, onClick, ...rest } = props;

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  function handlePressStart() {
    setIsPressing(true);

    timerRef.current = setTimeout(() => {
      onClick();
      timerRef.current = null;
      setIsPressing(false);
    }, 2000);
  }

  function handlePressEnd() {
    setIsPressing(false);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }

  return (
    <Button
      variant="contained"
      $isPressing={isPressing}
      $fillColour={props.fillColour}
      onMouseDown={handlePressStart}
      onTouchStart={handlePressStart}
      onMouseUp={handlePressEnd}
      onTouchEnd={handlePressEnd}
      onMouseLeave={handlePressEnd}
      {...rest}
    >
      {props.children}
    </Button>
  );
};

export { LongPressButton };
