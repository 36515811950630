import { DateTime } from "luxon";
import { IUser } from "./User";

interface IHistoryLedgerItem {
  user: IUser;
  timestampUtc: DateTime;
  changeType: number;
  changes: IHistoryLedgerItemChange[];
}

interface IHistoryLedgerItemChange {
  propertyName: string;
  oldValue: any;
  newValue: any;
}

interface IHistoryLedgerItemConnectionValue {
  teamName: string;
  teamSlug: string;
  guid: string;
  type: HistoryLedgerItemConnectionValueType;
  description: string;
}

interface IHistoryLedgerItemAccountabilityValue {
  displayName: string;
  profilePhoto: string;
  userId: string;
}

enum HistoryLedgerItemConnectionValueType {
  Strategy,
  KeyInitiative,
}

interface IHistoryLedgerItemTeamValue {
  name: string;
  slug: string;
}

interface IHistoryLedgerItemUserValue {
  displayName: string;
  profilePhoto: string;
  userId: string;
}

export { HistoryLedgerItemConnectionValueType };
export type {
  IHistoryLedgerItem,
  IHistoryLedgerItemChange,
  IHistoryLedgerItemConnectionValue,
  IHistoryLedgerItemAccountabilityValue,
  IHistoryLedgerItemTeamValue,
  IHistoryLedgerItemUserValue,
};
