import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, ReorderSurveyQuestionDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  surveyGuid: string;
  guid: string;
  sortOrder: string;
};

const useReorderSurveyQuestionRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function reorderSurveyQuestionRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: ReorderSurveyQuestionDto = {
      guid: variables.guid,
      sortOrder: variables.sortOrder,
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-questions/sort-order`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response;
  }

  return { reorderSurveyQuestionRequest };
};

const useReorderSurveyQuestion = () => {
  const { reorderSurveyQuestionRequest } = useReorderSurveyQuestionRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: reorderSurveyQuestionRequest,
    onSuccess: async (_data, variables) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurveyQuestions(variables.surveyGuid) });
    },
  });
};

export { useReorderSurveyQuestion };
