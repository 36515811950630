import { EditOutlined } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";

interface IProps {
  labelIcon: React.ReactNode;
  label: string;
  rowLayoutOnSmallScreens?: boolean;
  canEdit?: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  onEditClicked?: () => void;
}

const ItemProperty = (props: IProps) => {
  return (
    <Stack direction="row" spacing={1} alignItems="start">
      <Stack
        direction={{ xs: props.rowLayoutOnSmallScreens ? "row" : "column", sm: "row" }}
        spacing={0}
        sx={{ flexGrow: 1, minWidth: "0px" }}
        alignItems="baseline"
      >
        <Stack direction="row" spacing={0.5} sx={{ minWidth: "112px", maxWidth: "112px" }} alignItems="baseline">
          <Box position="relative" top="4px">
            {props.labelIcon}
          </Box>
          <Typography variant={"caption"} sx={{ lineHeight: "0.875rem", textTransform: "uppercase" }}>
            {props.label}
          </Typography>
        </Stack>
        <Stack sx={{ width: "100%" }} direction="row">
          <Box sx={{ flexGrow: 1, minWidth: "0px" }}>{props.children}</Box>
          {!props.isLoading && props.canEdit && !props.isEditing && (
            <IconButton size="small" sx={{ color: "rgba(0, 0, 0, 0.3)" }} onClick={props.onEditClicked}>
              <EditOutlined />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export { ItemProperty };
