import { AddTaskRounded, CircleOutlined, ThumbUp, ThumbUpOutlined, Verified } from "@mui/icons-material";
import { Button, Link, Stack, SxProps, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { Links } from "../../constants";
import { useSession } from "../../hooks";
import { IssueDto } from "../../http";
import { CreateActionToDoModal } from "../actionItems";

interface IProps {
  issue?: IssueDto;
  isLoading?: boolean;
  onToggleClosed: () => void;
  onUpvoteClicked: () => void;
  sx?: SxProps;
}

const IssueActions = (props: IProps) => {
  const { userId } = useSession();
  const [isCreateTeamActionItemModalOpen, setIsCreateTeamActionItemModelOpen] = useState(false);
  const buttonsDisabled = !props.issue && props.isLoading;

  return (
    <>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={0.5} sx={{ ...props.sx }}>
        <Tooltip
          title={
            props.issue?.closedOnDateTimeUtc
              ? `Closed on ${DateTime.fromISO(props.issue.closedOnDateTimeUtc, { zone: "utc" })
                  .toLocal()
                  .toLocaleString(DateTime.DATETIME_MED)}`
              : null
          }
        >
          <Button
            variant="tertiary"
            startIcon={props.issue?.closedOnDateTimeUtc != null ? <Verified /> : <CircleOutlined />}
            disabled={buttonsDisabled || !props.issue?.userCanEdit}
            onClick={props.onToggleClosed}
          >
            {props.issue?.closedOnDateTimeUtc != null ? `Closed` : `Close`}
          </Button>
        </Tooltip>

        <Tooltip
          title={
            props.issue && props.issue.upvotes.length > 0
              ? props.issue.upvotes
                  .sort((a, b) => a.displayName.localeCompare(b.displayName))
                  .map((u, i) => [
                    <Typography key={i} sx={{ display: "inline-block", fontSize: 13, marginRight: "2px" }}>
                      <Link href={Links.User(u.userId)}>{u.displayName}</Link>
                      {i < props.issue!.upvotes.length - 1 && ", "}
                    </Typography>,
                  ])
              : null
          }
        >
          <Button
            variant="tertiary"
            startIcon={
              props.issue?.upvotes.findIndex((x) => x.userId === userId) !== -1 ? <ThumbUp /> : <ThumbUpOutlined />
            }
            disabled={buttonsDisabled}
            onClick={props.onUpvoteClicked}
          >
            {`Upvote (${props.issue?.upvotes.length ?? 0})`}
          </Button>
        </Tooltip>

        <Button
          variant="tertiary"
          startIcon={<AddTaskRounded />}
          disabled={buttonsDisabled}
          onClick={() => setIsCreateTeamActionItemModelOpen(true)}
        >
          Create Action
        </Button>
      </Stack>

      <CreateActionToDoModal
        visible={isCreateTeamActionItemModalOpen}
        onAdded={() => setIsCreateTeamActionItemModelOpen(false)}
        onCancelled={() => setIsCreateTeamActionItemModelOpen(false)}
      />
    </>
  );
};

export { IssueActions };
