import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { IUserRole } from "../../../models";
import { FetchError, GetRolesForUserDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetRolesForUserRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getRolesForUserRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.roles.getRolesForUser>>): Promise<GetRolesForUserDto> {
    const accessToken = await getAccessToken();
    const [{ userId }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/users/${userId}/roles`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getRolesForUserRequest };
};

const useGetRolesForUser = (userId: string) => {
  const { getRolesForUserRequest } = useGetRolesForUserRequest();

  return useQuery({
    queryKey: queryKeys.roles.getRolesForUser(userId),
    queryFn: getRolesForUserRequest,
    select: (data): IUserRole[] => {
      return data.roles
        .sort((a, b) => a.parentTeam.name.localeCompare(b.parentTeam.name) || a.name.localeCompare(b.name))
        .map((role) => ({
          guid: role.guid,
          parentTeam: role.parentTeam,
          name: role.name,
          isPrimaryRole: role.isPrimaryRole,
          description: role.description,
          isOpen: role.isOpen,
          isOpenLastModifiedUtc: DateTime.fromISO(role.isOpenLastModifiedUtc, { zone: "utc" }),
          accountabilities: role.accountabilities,
          tags: role.tags,
          members: role.members,
        }));
    },
  });
};

export { useGetRolesForUser };
