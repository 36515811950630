import { Link, Skeleton, Stack, Tooltip } from "@mui/material";
import { Typography } from "../../../../components";
import { Links } from "../../../../constants";
import { useTeamPageLayoutContext } from "../TeamPageLayout";
import { FollowButton } from "./FollowButton";

const Followers = () => {
  const { isLoadingTeam, team } = useTeamPageLayoutContext();

  return (
    <>
      {isLoadingTeam ? (
        <Skeleton variant="rectangular" height={35} width={110} />
      ) : (
        team && (
          <>
            <Tooltip
              placement="bottom-end"
              title={team.followers
                .sort((a, b) => a.displayName.localeCompare(b.displayName))
                .map((u, i) => [
                  <Typography key={i} sx={{ display: "inline-block", fontSize: 13, marginRight: "2px" }}>
                    <Link href={Links.User(u.userId)}>{u.displayName}</Link>
                    {i < team.followers.length - 1 && ", "}
                  </Typography>,
                ])}
              disableTouchListener={team.followers.length === 0}
              disableHoverListener={team.followers.length === 0}
            >
              <Stack direction="row" spacing={0.2} sx={{ cursor: "pointer" }}>
                <Typography variant="body2" sx={{ color: "primary.main" }}>
                  {team.followers.length}
                </Typography>
                <Typography variant="body1" sx={{ color: "primary.main" }}>
                  {team.followers.length === 1 ? "Follower" : "Followers"}
                </Typography>
              </Stack>
            </Tooltip>

            {!team.isMember && <FollowButton />}
          </>
        )
      )}
    </>
  );
};

export { Followers };
