import { useAccessToken } from "../../use-access-token";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { FetchError } from "../../types";
import { GetSurveyInstanceDto } from "../../types/surveys";
import { configuration } from "../../../configuration";
import { ISurveyInstance } from "../../../models";
import { DateTime } from "luxon";

const useGetSurveyInstancesRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getSurveyInstancesRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.surveys.getSurveyInstance>>): Promise<GetSurveyInstanceDto> {
    const accessToken = await getAccessToken();
    const [{ guid }] = queryKey;
    const url = new URL(`${configuration.apiRootUrl}/survey-instances/${guid}`);

    const response = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getSurveyInstancesRequest };
};

const useGetSurveyInstance = (guid: string | undefined | null) => {
  const { getSurveyInstancesRequest } = useGetSurveyInstancesRequest();

  return useQuery({
    queryKey: queryKeys.surveys.getSurveyInstance(guid),
    queryFn: getSurveyInstancesRequest,
    select: (data): ISurveyInstance => {
      return {
        ...data,
        startDateUtc: DateTime.fromISO(data.startDateUtc, { zone: "utc" }),
        endDateUtc: data.endDateUtc ? DateTime.fromISO(data.endDateUtc, { zone: "utc" }) : null,
      };
    },
    enabled: guid !== undefined,
  });
};

export { useGetSurveyInstance };
