import { useSurveyPageLayoutContext } from "../SurveyPageLayout";
import { Button, Stack, Typography } from "@mui/material";
import { Tune, VisibilityOutlined } from "@mui/icons-material";
import { Links } from "../../../../constants";
import { useDrawer } from "../../../../components";
import { SurveySettingsDrawerContent } from ".";

const SurveyActions = () => {
  const { survey, isLoadingSurvey } = useSurveyPageLayoutContext();
  const { showDrawer } = useDrawer();

  return (
    <>
      <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={0.5} direction="row" justifyContent="space-between" alignItems="center">
          <Button
            onClick={() =>
              showDrawer({
                title: <Typography variant="h6">Settings</Typography>,
                content: <SurveySettingsDrawerContent survey={survey!} />,
              })
            }
            disabled={isLoadingSurvey || !survey}
            variant="outlined"
            startIcon={<Tune sx={{ transform: "rotate(90deg)" }} />}
          >
            Settings
          </Button>
          <Button
            variant="outlined"
            href={survey ? Links.SurveyResponse(survey.guid) : ""}
            target="_blank"
            disabled={isLoadingSurvey || !survey}
            startIcon={<VisibilityOutlined />}
          >
            Preview
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export { SurveyActions };
