import React, { useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate, useParams } from "react-router";
import { Links, Paths, SurveyTab } from "../../../../constants";
import { Tabs, Tab } from "@mui/material";

interface IParams {
  guid: string;
}

const SurveyNavBar = () => {
  const { guid} = useParams<keyof IParams>() as IParams;
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState<SurveyTab>(SurveyTab.Questions);

  useEffect(() => {
    setCurrentTab(getTabForPath(location.pathname));
  }, [location.pathname]);

  function getTabForPath(path: string) {
    if (!!matchPath(Paths.SurveyResponses, path)) {
      return SurveyTab.Responses;
    }

    if (!!matchPath(Paths.SurveyActions, path)) {
      return SurveyTab.Actions;
    }

    return SurveyTab.Questions;
  }

  function handleTabChanged(_: React.SyntheticEvent, value: SurveyTab) {
    setCurrentTab(value);

    switch (value) {
      case SurveyTab.Questions:
        navigate(Links.Survey(guid));
        break;
      case SurveyTab.Responses:
        navigate(Links.SurveyResponses(guid));
        break;
      case SurveyTab.Actions:
        navigate(Links.SurveyActions(guid));
        break;
    }
  }

  return (
    <Tabs value={currentTab} onChange={handleTabChanged}>
      <Tab label="Questions" value={SurveyTab.Questions} disableRipple />
      <Tab label="Responses" value={SurveyTab.Responses} disableRipple />
      {/*<Tab label="Actions" value={SurveyTab.Actions} disableRipple />*/}
    </Tabs>
  );
};

export { SurveyNavBar };
