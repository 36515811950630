import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {SmallInputLabel} from "../../../components";
import {useCreateSurvey} from "../../../http";
import {useSession} from "../../../hooks";

interface ICreateSurveyFormState {
  name: string;
  userId: string;
  startDateUtc: string;
}

interface Props {
  visible: boolean;
  onCreated: (guid: string) => void;
  onCancel: () => void;
}

const CreateSurveyModal = (props: Props) => {
  const {userId} = useSession();
  const {mutate, isLoading: isCreatingSurvey} = useCreateSurvey();
  const {
    formState: {errors},
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm<ICreateSurveyFormState>({
    defaultValues: {
      name: "",
    },
  });

  function handleClose(_: object, reason: string) {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    props.onCancel();
  }

  async function handleCreateClicked() {
    await handleSubmit((data) => {
      mutate(
        {
          name: data.name,
          userId: userId,
          startDateUtc: new Date().toISOString(),
        },
        {
          onSuccess: (data: any) => {
            reset(undefined, {keepDefaultValues: true});
            props.onCreated(data.guid);
          },
        }
      );
    })();
  }

  function handleCancelClicked() {
    reset(undefined, {keepDefaultValues: true});
    props.onCancel();
  }

  return (
    <Dialog open={props.visible} onClose={handleClose}>
      <DialogTitle>Create New Survey</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Stack spacing={0.25}>
            <SmallInputLabel>Survey Name *</SmallInputLabel>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Survey Name is required",
              }}
              render={({field}) => (
                <TextField
                  {...field}
                  autoFocus
                  onChange={(e) => {
                    setValue("name", e.target.value);
                  }}
                  error={errors["name"] !== undefined}
                  helperText={errors["name"]?.message}
                  placeholder="Enter the survey name..."
                  inputProps={{maxLength: 64}}
                />
              )}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isCreatingSurvey}
          endIcon={isCreatingSurvey ? <CircularProgress size={20} sx={{color: "grey.400"}}/> : null}
          onClick={handleCreateClicked}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export {CreateSurveyModal};
