import { FlagOutlined, OpenInBrowserRounded } from "@mui/icons-material";
import { Box, Button, alpha, styled, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { v4 as newGuid } from "uuid";
import { AccordionViewList, Issue, IssueDrawerContent, useDrawer } from "../../../../components";
import { Links } from "../../../../constants";
import { useSession } from "../../../../hooks";
import { IssueDto, useCreateIssue, useGetIssues } from "../../../../http";

const Container = styled(Box)`
  background-color: ${({ theme }) => alpha(theme.palette.orange.main, 0.1)};
  border: solid;
  border-color: ${({ theme }) => alpha(theme.palette.orange.main, 0.3)};
  border-width: 1px;
  border-radius: 8px;
`;

interface IProps {
  teamSlug: string | undefined;
  teamName: string | undefined;
}

const TeamIssuesList = (props: IProps) => {
  const { data, isLoading } = useGetIssues(props.teamSlug, undefined);
  const { showDrawer } = useDrawer();
  const [focusedIssueGuid, setFocusedIssueGuid] = useState<string | undefined>();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { mutate: createIssue } = useCreateIssue();
  const user = useSession();
  const navigate = useNavigate();
  const theme = useTheme();

  async function handleAddClicked(e: React.MouseEvent) {
    e.stopPropagation();

    if (!isExpanded) {
      setIsExpanded(true);
    }

    const transitionDuration = !isExpanded ? theme.transitions.duration.standard : 0;

    // Wait for the accordion expansion transition to complete
    setTimeout(() => {
      if (props.teamSlug && props.teamName) {
        const newIssueGuid = newGuid();

        createIssue({
          guid: newIssueGuid,
          teamSlug: props.teamSlug,
          teamName: props.teamName,
          description: "",
          createdByUser: {
            userId: user.userId,
            profilePhoto: user.profilePhoto,
            displayName: user.displayName,
            enabled: true,
          },
        });

        setFocusedIssueGuid(newIssueGuid);
      }
    }, transitionDuration);
  }

  async function handleIssueClicked(e: React.MouseEvent, issue: IssueDto) {
    e.stopPropagation();

    showDrawer({
      title: (
        <Button
          variant="tertiary"
          endIcon={<OpenInBrowserRounded />}
          onClick={() => navigate(Links.IssueDetail(issue.guid))}
        >
          Open Page
        </Button>
      ),
      content: <IssueDrawerContent issueGuid={issue.guid} />,
    });
  }

  function issueSortFunction(a: IssueDto, b: IssueDto) {
    const dateA = DateTime.fromISO(a.closedOnDateTimeUtc || a.createdOnDateTimeUtc);
    const dateB = DateTime.fromISO(b.closedOnDateTimeUtc || b.createdOnDateTimeUtc);

    return dateA < dateB ? 1 : -1;
  }

  return (
    <Container>
      <AccordionViewList
        title="Issues"
        icon={<FlagOutlined />}
        dataSource={data?.issues}
        isLoading={isLoading}
        isExpanded={isExpanded}
        renderItem={(issue) => (
          <Issue
            key={issue.guid}
            issue={issue}
            isFocused={issue.guid === focusedIssueGuid}
            onClick={(e) => handleIssueClicked(e, issue)}
            onBlur={() => setFocusedIssueGuid(undefined)}
            sx={{ pl: 0.75, pr: 1 }}
          />
        )}
        sortFunction={issueSortFunction}
        closedItemFilter={(issue) => issue.closedOnDateTimeUtc == null}
        onAddClicked={handleAddClicked}
        onExpandClicked={() => setIsExpanded(!isExpanded)}
        backgroundColorOnHover={alpha(theme.palette.orange.main, 0.1)}
        sx={{ mx: { xs: 0.5, sm: 1 } }}
      />
    </Container>
  );
};

export { TeamIssuesList };
