import { Card, CardHeader, Divider, Stack, Typography } from "@mui/material";
import React from "react";

interface IProps {
  title: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

const EditCard = React.forwardRef((props: IProps, ref: any) => {
  return (
    <Card ref={ref}>
      <CardHeader
        title={
          <Stack direction="row" spacing={0.5}>
            {props.icon}
            <Typography variant="h6">{props.title}</Typography>
          </Stack>
        }
      />
      <Divider />
      {props.children}
    </Card>
  );
});

export { EditCard };
