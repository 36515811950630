import { Card, SxProps, useTheme } from "@mui/material";
import { BlockLink, IBlockLinkProps } from "./BlockLink";

interface IProps extends IBlockLinkProps {
  cardProps?: {
    sx?: SxProps;
  };
}

const CardLink = (props: IProps) => {
  const { sx, cardProps, ...others } = props;
  const theme = useTheme();

  return (
    <BlockLink
      backgroundColor={theme.palette.common.white}
      backgroundColorOnHover={theme.palette.primary.opacity5}
      sx={{ ...sx, borderRadius: "15px" }}
      {...others}
    >
      <Card sx={{ backgroundColor: "transparent", ...cardProps?.sx }}>{props.children}</Card>
    </BlockLink>
  );
};

export { CardLink };
