import { CircleOutlined, MoreVertRounded, Verified } from "@mui/icons-material";
import { Checkbox, IconButton, Menu, MenuItem, Stack, SxProps, Tooltip, alpha, styled, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { ContextProperty, InlineTextField } from "..";
import { SupportingItemType } from "../../constants";
import { useBreakpoints, useSession } from "../../hooks";
import {
  IssueDto,
  useDeleteIssue,
  useEditIssue,
  useEditIssueStatus,
  useRemoveIssueUpvote,
  useUpvoteIssue,
} from "../../http";
import { useDestructiveConfirm } from "../../utilities";
import { useDialog } from "../DialogProvider";
import { IssueClosedDialogContent } from "./IssueClosedDialogContent";
import { IssueIcons } from "./IssueIcons";

const StyledStack = styled(Stack)`
  transition: ${({ theme }) => theme.transitions.create("background-color")};
  cursor: pointer;

  .editMenuIcon {
    opacity: 0;
  }

  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.orange.main, 0.1)};

    .editMenuIcon {
      opacity: 1;
    }
  }
`;

interface IProps {
  issue: IssueDto;
  isFocused: boolean;
  onClick?: (e: React.MouseEvent) => void;
  onBlur?: () => void;
  sx?: SxProps;
}

const Issue = (props: IProps) => {
  const { issue } = props;
  const user = useSession();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const { mutate: editIssue } = useEditIssue();
  const { mutate: editIssueStatus } = useEditIssueStatus();
  const { mutate: deleteIssue } = useDeleteIssue();
  const { mutate: upvoteIssue } = useUpvoteIssue();
  const { mutate: removeIssueUpvote } = useRemoveIssueUpvote();
  const theme = useTheme();
  const confirm = useDestructiveConfirm();
  const ref = useRef<HTMLInputElement>(null);
  const { showDialog, closeDialog } = useDialog();
  const { isMdUp } = useBreakpoints();

  const isCreatedByUser = issue.createdByUser.userId === user.userId;

  useEffect(() => {
    if (props.isFocused) {
      ref.current?.focus();
    }
  }, [props.isFocused]);

  async function handleDeleteClicked(e: React.MouseEvent) {
    setMenuAnchor(null);
    e.stopPropagation();

    try {
      await confirm({
        title: "Delete Issue",
        content: "Are you sure you want to delete this issue?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteIssue({
      guid: issue.guid,
      teamSlug: issue.assignedTeam.slug,
      createdByUserId: issue.createdByUser.userId,
      modifiedByUserId: user.userId,
    });
  }

  function handleIssueBlurred(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (issue.userCanEdit) {
      editIssue({
        guid: issue.guid,
        oldTeamSlug: issue.assignedTeam.slug,
        newTeamSlug: issue.assignedTeam.slug,
        newTeamName: issue.assignedTeam.name,
        description: e.target.value,
        createdByUserId: issue.createdByUser.userId,
        modifiedByUserId: user.userId,
      });
    }

    props.onBlur?.();
  }

  async function handleCloseClicked() {
    if (issue.userCanEdit) {
      if (issue.closedOnDateTimeUtc === null) {
        showDialog({
          content: <IssueClosedDialogContent issueGuid={issue.guid} onDone={closeDialog} onCancel={closeDialog} />,
        });
      }

      editIssueStatus({
        guid: issue.guid,
        teamSlug: issue.assignedTeam.slug,
        closed: issue.closedOnDateTimeUtc === null,
        createdByUserId: issue.createdByUser.userId,
        modifiedByUserId: user.userId,
      });
    }
  }

  async function handleUpvoteClicked() {
    if (issue === undefined) {
      return;
    }

    if (issue.upvotes.some((x) => x.userId === user.userId)) {
      removeIssueUpvote({
        guid: issue.guid,
        teamSlug: issue.assignedTeam.slug,
        createdByUserId: issue.createdByUser.userId,
        userId: user.userId,
      });
    } else {
      upvoteIssue({
        guid: issue.guid,
        teamSlug: issue.assignedTeam.slug,
        createdByUserId: issue.createdByUser.userId,
        user: { userId: user.userId, displayName: user.displayName, enabled: true, profilePhoto: "" },
      });
    }
  }

  return (
    <StyledStack
      direction="row"
      onClick={props.onClick}
      sx={{ alignItems: "center", py: 0, ...props.sx, width: "100%", minWidth: 0 }}
    >
      <Stack sx={{ py: 0.25, overflow: "hidden", width: "100%" }}>
        <Stack spacing={-0.25} sx={{ overflow: "hidden", width: "100%" }}>
          {issue.context && (
            <ContextProperty
              sx={{ pl: 1.75 }}
              itemGuid={issue.guid}
              itemType={SupportingItemType.Issue}
              context={issue.context}
              condensed
            />
          )}
          <Stack direction="row" sx={{ alignItems: "center", overflow: "hidden", width: "100%" }}>
            <Tooltip
              title={
                issue.closedOnDateTimeUtc
                  ? `Closed on ${DateTime.fromISO(issue.closedOnDateTimeUtc, { zone: "utc" })
                      .toLocal()
                      .toLocaleString(DateTime.DATETIME_MED)}`
                  : null
              }
            >
              <Checkbox
                icon={<CircleOutlined sx={{ color: "#c9c9c9", height: "16px", wwidth: "16px" }} />}
                checked={issue.closedOnDateTimeUtc !== null}
                checkedIcon={<Verified sx={{ height: "16px", wwidth: "16px" }} />}
                disabled={!issue.userCanEdit}
                onClick={(event) => {
                  event.stopPropagation();
                  handleCloseClicked();
                  event.preventDefault();
                }}
              />
            </Tooltip>

            <InlineTextField
              ref={ref}
              value={issue.description}
              onClick={(e) => isCreatedByUser && e.stopPropagation()}
              onBlur={handleIssueBlurred}
              placeholder="Enter item..."
              isEditable={isCreatedByUser}
              inputProps={{ maxLength: 512 }}
            />
          </Stack>
        </Stack>

        <IssueIcons issue={issue} onUpvoteClicked={handleUpvoteClicked} sx={{ pl: 1.75, pb: 0.25 }} />
      </Stack>

      {isMdUp && (
        <IconButton
          className="editMenuIcon"
          size="small"
          onClick={(e) => {
            setMenuAnchor(e.currentTarget);
            e.stopPropagation();
          }}
          sx={{ ml: 0.25 }}
        >
          <MoreVertRounded />
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={(e: React.PointerEvent) => {
              setMenuAnchor(null);
              e.stopPropagation();
            }}
          >
            <MenuItem onClick={handleDeleteClicked} sx={{ color: theme.palette.error.main }}>
              Delete Issue
            </MenuItem>
          </Menu>
        </IconButton>
      )}
    </StyledStack>
  );
};

export { Issue };
