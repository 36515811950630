import slugifyFunc from "slugify";

const useSlugify = () => {
  function slugify(value: string) {
    return slugifyFunc(value, {
      strict: true,
      lower: true,
    });
  }

  return { slugify };
};

export { useSlugify };
