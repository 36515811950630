import { DragIndicatorRounded, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { Box, Divider, Stack as MuiStack, Skeleton, TextField, Typography, styled, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { Draggable } from "react-beautiful-dnd";
import { SmallInputLabel, TextEditor, TextViewer } from "../../../../components";
import { QuestionType } from "../../../../constants";
import { useSession } from "../../../../hooks";
import { useEditSurveyQuestionDescription, useEditSurveyQuestionName } from "../../../../http";
import { SurveyQuestion } from "../../../../models";
import { useSurveyQuestionsPageContext } from "../SurveyQuestionsPage";

const Stack = styled(MuiStack)<{ $isDragging: boolean; $isQuestion: boolean }>`
  transition: ${({ theme }) => theme.transitions.create(["background-color"])};
  background-color: ${({ $isDragging, theme }) => ($isDragging ? theme.palette.primary.opacity10 : "")};

  .dragHandle {
    opacity: ${({ $isDragging }) => ($isDragging ? 1 : 0)};
  }

  &.is-active,
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.opacity5};
    .dragHandle {
      opacity: 1;
    }
  }
`;

const DragHandle = styled(Box)<{ $isDisabled: boolean }>`
  color: ${({ theme, $isDisabled }) => ($isDisabled ? "transparent" : theme.palette.primary.main)};
  background-color: ${({ theme, $isDisabled }) => ($isDisabled ? "transparent" : theme.palette.primary.transparent5)};
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  transition: ${({ theme }) => theme.transitions.create(["opacity"])};
  cursor: ${({ $isDisabled }) => ($isDisabled ? "default" : "pointer")};
  width: 20px;
`;

const TextInput = styled(TextField)`
  input,
  textarea {
    &::placeholder {
      font-style: italic;
    }
  }
`;

const ScalePoints = styled("ul")`
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;

  span {
    position: absolute;
    bottom: 0;
    font-size: 12px;
    color: #c9c9c9;

    &:first-of-type {
      left: 0;
    }

    &:last-of-type {
      right: 0;
    }
  }

  li {
    svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${({ theme }) => theme.palette.grey[200]};
      }
    }
  }
`;

const NotApplicableOption = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  min-width: 135px;
  padding-left: 5%;
  gap: 2px;
  border-left: 1px solid ${({ theme }) => theme.palette.grey[200]};

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme }) => theme.palette.grey[200]};
    }
  }

  span {
    font-size: 12px;
    color: #c9c9c9;
  }
`;

interface IProps {
  index: number;
  question: SurveyQuestion;
  onClick?: (e: React.MouseEvent) => void;
}

const QuestionItem = (props: IProps) => {
  const { question } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = useSession();
  const { selectedQuestionItemGuid, focusedQuestionItemGuid, setFocusedQuestionItemGuid } =
    useSurveyQuestionsPageContext();
  const { mutate: editSurveyQuestionName, isLoading: isUpdatingSurveyQuestionName } = useEditSurveyQuestionName();
  const { mutate: editSurveyQuestionDescription, isLoading: isUpdatingSurveyQuestionDescription } =
    useEditSurveyQuestionDescription();
  const isText = question.questionTypeId === QuestionType.Text;
  const isLinearScale = question.questionTypeId === QuestionType.LinearScale;
  const isTitleAndDescription = question.questionTypeId === QuestionType.TitleAndDescription;
  const isSelected = selectedQuestionItemGuid === question.guid;
  const isFocused = focusedQuestionItemGuid === question.guid;
  const theme = useTheme();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusedQuestionItemGuid === question.guid) {
      ref.current?.focus();
    }
  }, [question.guid, focusedQuestionItemGuid]);

  function handleShowSavedNotification() {
    enqueueSnackbar("Question saved successfully.", { variant: "success" });
  }

  function handleQuestionItemFocused(e: any) {
    e.stopPropagation();
    setFocusedQuestionItemGuid(question.guid);
  }

  function handleNameChanged(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setFocusedQuestionItemGuid(undefined);
    editSurveyQuestionName(
      {
        guid: question.guid,
        surveyGuid: question.surveyGuid,
        name: e.target.value,
        modifiedByUserId: userId,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  function handleDescriptionChanged(value: string) {
    setFocusedQuestionItemGuid(undefined);
    editSurveyQuestionDescription(
      {
        guid: question.guid,
        surveyGuid: question.surveyGuid,
        description: value,
        modifiedByUserId: userId,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  return !question ? (
    <Skeleton />
  ) : (
    <Draggable
      key={question.guid}
      draggableId={question.guid}
      index={props.index}
      isDragDisabled={selectedQuestionItemGuid === question.guid}
    >
      {(provided, snapshot) => (
        <Box ref={provided.innerRef} {...provided.draggableProps}>
          <Stack
            direction="row"
            spacing={0.5}
            sx={{ alignItems: "center", py: 0, pr: 1 }}
            onClick={props.onClick}
            className={`${isFocused || isSelected ? "is-active" : ""}`}
            $isDragging={snapshot.isDragging}
            $isQuestion={!isTitleAndDescription}
          >
            <DragHandle
              className="dragHandle"
              $isDisabled={selectedQuestionItemGuid === question.guid}
              {...provided.dragHandleProps}
            >
              <DragIndicatorRounded />
            </DragHandle>

            <MuiStack spacing={0.5} py={0.5} width="80%">
              <MuiStack>
                {isFocused || !question.name ? (
                  <>
                    <SmallInputLabel>{isTitleAndDescription ? "Title" : "Question"}</SmallInputLabel>
                    <TextInput
                      ref={ref}
                      defaultValue={question.name}
                      disabled={isUpdatingSurveyQuestionName}
                      onClick={(e) => e.stopPropagation()}
                      onBlur={handleNameChanged}
                      placeholder="enter question"
                    />
                  </>
                ) : (
                  <MuiStack onClick={handleQuestionItemFocused}>
                    <Typography
                      variant={isTitleAndDescription ? "h5" : "body2"}
                      color={question.name ? theme.palette.text.primary : theme.palette.grey["500"]}
                      sx={{ fontStyle: question.name ? "normal" : "italic" }}
                    >
                      {question.name ?? "Enter question"}
                    </Typography>
                  </MuiStack>
                )}
              </MuiStack>

              <MuiStack>
                {isFocused || !question.description ? (
                  <>
                    <SmallInputLabel>Description</SmallInputLabel>
                    {isTitleAndDescription ? (
                      <TextEditor
                        initialValue={question.description}
                        onBlur={handleDescriptionChanged}
                        placeholder="enter description"
                        readonly={isUpdatingSurveyQuestionDescription}
                      />
                    ) : (
                      <TextInput
                        ref={ref}
                        multiline
                        defaultValue={question.description}
                        disabled={isUpdatingSurveyQuestionDescription}
                        onClick={(e) => e.stopPropagation()}
                        onBlur={(e) => handleDescriptionChanged(e.target.value)}
                        placeholder="enter description"
                      />
                    )}
                  </>
                ) : (
                  <MuiStack onClick={handleQuestionItemFocused}>
                    {isTitleAndDescription ? (
                      <TextViewer value={question.description} />
                    ) : (
                      <Typography
                        variant="caption"
                        color={question.description ? theme.palette.text.primary : theme.palette.grey["500"]}
                        sx={{ fontStyle: question.description ? "normal" : "italic" }}
                      >
                        {question.description ?? "Enter description"}
                      </Typography>
                    )}
                  </MuiStack>
                )}
              </MuiStack>

              {isLinearScale && (
                <MuiStack direction="row" spacing={1} justifyContent="space-between">
                  <ScalePoints>
                    {[...Array(question.scalePoints - 1)].map((_, index) => (
                      <li key={index}>
                        <RadioButtonUnchecked />
                      </li>
                    ))}
                    <li>
                      <RadioButtonChecked />
                    </li>
                    <span>{question.minLabel}</span>
                    <span>{question.maxLabel}</span>
                  </ScalePoints>
                  {question.includeNotApplicableOption && (
                    <NotApplicableOption>
                      <RadioButtonUnchecked />
                      <span>Not Applicable</span>
                    </NotApplicableOption>
                  )}
                </MuiStack>
              )}

              {isText && <TextInput placeholder="respondent’s answer" disabled />}
            </MuiStack>
          </Stack>
          <Divider variant="dashed" orientation="horizontal" sx={{ mx: 1 }} />
        </Box>
      )}
    </Draggable>
  );
};

export { QuestionItem };
