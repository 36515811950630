import { Box, Skeleton, Stack, SxProps, useTheme } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useEffect, useState } from "react";

ChartJS.register(LinearScale, CategoryScale, PointElement, LineElement, Title, Tooltip, Legend);

interface IProps {
  sx?: SxProps;
}

const LineGraphSkeleton = (props: IProps) => {
  const theme = useTheme();

  const generateRandomData = () => {
    return [...Array.from({ length: 10 }, () => Math.random() * 6 - 3)];
  };

  const set1InitialData = generateRandomData();
  const set2InitialData = generateRandomData();

  const [chartData, setChartData] = useState({
    labels: Array.from({ length: set1InitialData.length }, (_, i) => i + 1), // Initial labels
    datasets: [
      {
        data: set1InitialData,
        tension: 0.3,
        pointRadius: 0,
        borderColor: theme.palette.grey[200],
        borderWidth: 3,
      },
      {
        data: set2InitialData,
        tension: 0.3,
        pointRadius: 0,
        borderDash: [3, 3],
        borderColor: theme.palette.grey[200],
        borderWidth: 3,
      },
    ],
  });

  // Update the chart data every second
  useEffect(() => {
    const interval = setInterval(() => {
      setChartData((prevChartData) => {
        return {
          ...prevChartData,
          datasets: [
            {
              ...prevChartData.datasets[0],
              data: generateRandomData(),
            },
            {
              ...prevChartData.datasets[1],
              data: generateRandomData(),
            },
          ],
        };
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function getOptions(): ChartOptions<"line"> {
    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        point: {
          borderWidth: 0,
          backgroundColor: "rgba(0,0,0,0)",
        },
      },
      plugins: {
        legend: {
          display: false,
          position: "top" as const,
        },
      },
      scales: {
        xAxis: {
          display: false,
        },
        yAxis: {
          display: true,
          ticks: {
            callback: () => {
              return "";
            },
          },
          max: 4,
          min: -4,
        },
      },
    };
  }

  return (
    <Stack sx={{ overflow: "hidden", ...props.sx }}>
      <Box>
        <Line options={getOptions()} data={chartData} />
      </Box>
      <Skeleton />
    </Stack>
  );
};

export { LineGraphSkeleton };
