import { useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IUser } from "../../../models";
import { FetchError, GetUsersDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetUsersRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getUsersRequest(): Promise<GetUsersDto> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/users`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getUsersRequest };
};

const useGetUsers = (enabled: boolean = true) => {
  const { getUsersRequest } = useGetUsersRequest();

  return useQuery({
    queryKey: queryKeys.users.getUsers(),
    queryFn: getUsersRequest,
    select: (data): IUser[] => {
      return data.users
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map((user) => ({
          displayName: user.displayName,
          email: user.email,
          enabled: user.enabled,
          profilePhoto: user.profilePhoto,
          userId: user.userId,
        }));
    },
    enabled: enabled,
  });
};

export { useGetUsers };
