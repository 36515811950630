import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { ISurvey } from "../../../models";
import { FetchError, GetSurveyDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";
import { DateTime } from "luxon";

const useGetSurveyRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getSurveyRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.surveys.getSurvey>>): Promise<GetSurveyDto> {
    const accessToken = await getAccessToken();
    const [{ guid }] = queryKey;
    const url = new URL(`${configuration.apiRootUrl}/surveys/${guid}`);

    const response = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getSurveyRequest };
};

const useGetSurvey = (guid: string | undefined) => {
  const { getSurveyRequest } = useGetSurveyRequest();

  return useQuery({
    queryKey: queryKeys.surveys.getSurvey(guid),
    queryFn: getSurveyRequest,
    select: (data): ISurvey => {
      return {
        ...data,
        lastModifiedDateTimeUtc: DateTime.fromISO(data.lastModifiedDateTimeUtc, { zone: "utc" }),
        surveyInstances: data.surveyInstances.map((instance) => ({
          ...instance,
          startDateUtc: DateTime.fromISO(instance.startDateUtc, { zone: "utc" }),
          endDateUtc: instance.endDateUtc ? DateTime.fromISO(instance.endDateUtc, { zone: "utc" }) : null,
        }))
      };
    },
  });
};

export { useGetSurvey };
