import { DateTime } from "luxon";
import { GetPlanVersionDto } from "../http";
import { IGoal, IKeyInitiative, IMilestone, IPlan, IPlanValue, IStrategy } from "../models";

const fromGetPlanVersionDto = (dto: GetPlanVersionDto): IPlan => {
  return {
    teamName: dto.teamName,
    teamSlug: dto.teamSlug,
    parentTeamSlug: dto.parentTeamSlug,
    planVersionId: dto.planVersionId,
    draftPlanVersionId: dto.draftPlanVersionId,
    draftCreatedBy: dto.draftCreatedBy,
    purpose: dto.purpose,
    previousPurpose: dto.previousPurpose,
    values: getValuesFromGetPlanVersionDto(dto),
    mission: dto.mission,
    previousMission: dto.previousMission,
    lastModifiedByDisplayName: dto.lastModifiedByDisplayName,
    lastModifiedUtc: DateTime.fromISO(dto.lastModifiedUtc, { zone: "utc" }),
    isDraft: dto.isDraft,
    isPublished: dto.isPublished,
    notes: dto.notes,
    goals: getGoalsFromGetPlanVersionDto(dto),
    milestones: getMilestonesFromGetPlanVersionDto(dto),
    strategies: getStrategiesFromGetPlanVersionDto(dto),
  };
};

const getValuesFromGetPlanVersionDto = (dto: GetPlanVersionDto): IPlanValue[] => {
  return dto.values.map((value) => ({
    description: value.description,
    diffStatus: value.diffStatusId,
    guid: value.guid,
    sortOrder: value.sortOrder,
  }));
};

const getGoalsFromGetPlanVersionDto = (dto: GetPlanVersionDto): IGoal[] => {
  return dto.goals.map((goal) => ({
    description: goal.description,
    diffStatus: goal.diffStatusId,
    guid: goal.guid,
    sortOrder: goal.sortOrder,
  }));
};

const getMilestonesFromGetPlanVersionDto = (dto: GetPlanVersionDto): IMilestone[] => {
  return dto.milestones.map((milestone) => ({
    description: milestone.description,
    diffStatus: milestone.diffStatusId,
    guid: milestone.guid,
    sortOrder: milestone.sortOrder,
  }));
};

const getStrategiesFromGetPlanVersionDto = (dto: GetPlanVersionDto): IStrategy[] => {
  return dto.strategies.map((strategy) => ({
    description: strategy.description,
    diffStatus: strategy.diffStatusId,
    guid: strategy.guid,
    informedBy:
      strategy.informedBy == null
        ? undefined
        : {
            description: strategy.informedBy.description,
            guid: strategy.informedBy.guid,
            historyId: strategy.informedBy.historyId,
            syncState: strategy.informedBy.syncState,
            teamName: strategy.informedBy.teamName,
            teamSlug: strategy.informedBy.teamSlug,
            type: strategy.informedBy.type,
            updatedVersion:
              strategy.informedBy.updatedVersion == null
                ? null
                : {
                    description: strategy.informedBy.updatedVersion.description,
                    historyId: strategy.informedBy.updatedVersion.historyId,
                    lastModifiedBy: strategy.informedBy.updatedVersion.lastModifiedBy,
                    lastModifiedUtc: DateTime.fromISO(strategy.informedBy.updatedVersion.lastModifiedUtc, {
                      zone: "utc",
                    }),
                  },
          },
    keyInitiatives: strategy.keyInitiatives.map<IKeyInitiative>((keyInitiative) => ({
      currentStatus: keyInitiative.currentStatus,
      description: keyInitiative.description,
      diffStatus: keyInitiative.diffStatusId,
      guid: keyInitiative.guid,
      informedBy:
        keyInitiative.informedBy == null
          ? undefined
          : {
              description: keyInitiative.informedBy.description,
              guid: keyInitiative.informedBy.guid,
              historyId: keyInitiative.informedBy.historyId,
              syncState: keyInitiative.informedBy.syncState,
              teamName: keyInitiative.informedBy.teamName,
              teamSlug: keyInitiative.informedBy.teamSlug,
              type: keyInitiative.informedBy.type,
              updatedVersion:
                keyInitiative.informedBy.updatedVersion == null
                  ? null
                  : {
                      description: keyInitiative.informedBy.updatedVersion.description,
                      historyId: keyInitiative.informedBy.updatedVersion.historyId,
                      lastModifiedBy: keyInitiative.informedBy.updatedVersion.lastModifiedBy,
                      lastModifiedUtc: DateTime.fromISO(keyInitiative.informedBy.updatedVersion.lastModifiedUtc, {
                        zone: "utc",
                      }),
                    },
            },
      keyInitiativeHistoryId: keyInitiative.keyInitiativeHistoryId,
      notes: keyInitiative.notes,
      sortOrder: keyInitiative.sortOrder,
      status: keyInitiative.currentStatus?.statusId,
      users: keyInitiative.users,
    })),
    notes: strategy.notes,
    sortOrder: strategy.sortOrder,
    strategyHistoryId: strategy.strategyHistoryId,
  }));
};

const PlanVersionMapper = {
  fromGetPlanVersionDto,
};

export { PlanVersionMapper };
