import { Box, Card, CardContent, CardHeader, Divider, Link, Skeleton, Stack } from "@mui/material";
import { TextViewer, Typography, isWhitespace } from "../../../../components";
import { useGetUser } from "../../../../http";
import { DateTime } from "luxon";
import { Country } from "country-state-city";
import { Links } from "../../../../constants";
import { useParams } from "react-router";

interface IParams {
  userId: string;
}

const UserAbout = () => {
  const { userId } = useParams<keyof IParams>() as IParams;
  const { data: user, isFetching } = useGetUser(userId);

  function getLocationFormattedString() {
    if (user) {
      var countryName = Country.getCountryByCode(user.countryCode ?? "")?.name;
      var result = "";

      if (user.cityName) {
        result = `${user.cityName}, ${user.stateCode}, `;
      }

      if (countryName) {
        result += countryName;
      }
      return result;
    }

    return;
  }

  const formattedLocationString = getLocationFormattedString();

  return (
    <Card>
      <CardHeader title={<Typography variant="h6">About</Typography>} />
      <Divider />
      <CardContent>
        <Stack spacing={1}>
          {isFetching && (
            <>
              {[...Array(3)].map((_, i) => (
                <Box key={i}>
                  <Skeleton width={100} height={17} />
                  <Skeleton width={200} height={24} />
                </Box>
              ))}
            </>
          )}

          {!isFetching && user != null && (
            <>
              {user?.bio && !isWhitespace(user.bio) && (
                <Stack spacing={0.5}>
                  <Typography variant="overline">Bio</Typography>
                  <Divider />
                  <TextViewer value={user.bio} />
                </Stack>
              )}

              <Stack spacing={0.5}>
                <Typography variant="overline">Joined Date</Typography>
                <Divider />
                {user.joinedDate ? (
                  <Typography variant="body1">{user.joinedDate?.toLocaleString(DateTime.DATE_FULL)}</Typography>
                ) : (
                  <Typography variant="placeholder">Nothing here yet.</Typography>
                )}
              </Stack>

              <Stack spacing={0.5}>
                <Typography variant="overline">Location</Typography>
                <Divider />
                {!isWhitespace(formattedLocationString ?? "") ? (
                  <Typography>{formattedLocationString}</Typography>
                ) : (
                  <Typography variant="placeholder">Nothing here yet.</Typography>
                )}
              </Stack>

              <Stack spacing={0.5}>
                <Typography variant="overline">People Lead</Typography>
                <Divider />
                {user.peopleLead ? (
                  <Link onClick={(e) => e.stopPropagation()} href={Links.User(user.peopleLead.userId)}>
                    {`${user.peopleLead.displayName}${user.peopleLead.enabled ? "" : " (deactivated)"}`}
                  </Link>
                ) : (
                  <Typography variant="placeholder">Nothing here yet.</Typography>
                )}
              </Stack>

              {user.pronouns && (
                <Stack spacing={0.5}>
                  <Typography variant="overline">Pronouns</Typography>
                  <Divider />
                  <Typography variant="body1">{user.pronouns}</Typography>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export { UserAbout };
