import { InputProps } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DEBOUNCE_TIMEOUT_MS } from "../constants";
import { TextField } from "./TextField";

const BufferedTextField = (props: InputProps) => {
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const timerFuncRef = useRef<(() => void) | undefined>(undefined);
  const { value, ...rest } = props;
  const [internalValue, setInternalValue] = useState(value ?? "");

  useEffect(() => {
    setInternalValue(value ?? "");
  }, [value]);

  useEffect(() => {
    return () => cancelTimeout();
  }, []);

  function cancelTimeout() {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerFuncRef.current?.();
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInternalValue(e.target.value);
    clearTimeout(timerRef.current);

    timerFuncRef.current = () => {
      props.onChange?.(e);
    };

    timerRef.current = setTimeout(timerFuncRef.current, DEBOUNCE_TIMEOUT_MS);
  }

  function handleBlur() {
    cancelTimeout();
  }

  return <TextField {...rest} value={internalValue} onChange={handleChange} onBlur={handleBlur} multiline />;
};

export { BufferedTextField };
