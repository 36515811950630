import { Box } from "@mui/material";
import { IEditTeamPlanPageAction, IEditTeamPlanPageState } from "../state";
import { EditTextField } from "./EditTextField";

interface IProps {
  state: IEditTeamPlanPageState;
  dispatch: React.Dispatch<IEditTeamPlanPageAction>;
  onFocus: () => void;
}

const EditMission = (props: IProps) => {
  const { state, dispatch } = props;

  function handleChange(mission: string) {
    dispatch({ type: "edit_mission", mission });
  }

  return (
    <Box sx={{ px: 1 }}>
      <EditTextField
        label="Mission"
        value={state.plan.mission}
        onFocus={props.onFocus}
        onChange={(e) => handleChange(e.target.value)}
        inputProps={{ maxLength: 512 }}
      />
    </Box>
  );
};

export { EditMission };
