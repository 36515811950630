import { Tooltip, ToggleButton, ToggleButtonProps } from "@mui/material";

interface IProps {
  title: string;
}

const TooltipToggleButton = (props: IProps & ToggleButtonProps) => {
  const { title, ...toggleButtonProps } = props;

  return props.title ? (
    <Tooltip placement="top" disableInteractive title={props.title}>
      <ToggleButton {...toggleButtonProps} disabled={false} />
    </Tooltip>
  ) : (
    <ToggleButton {...toggleButtonProps} />
  );
};

export { TooltipToggleButton };
