import { Box, BoxProps } from "@mui/material";

const CompletedStatusAnimation = (props: BoxProps) => {
  return (
    <Box component="object" type="image/svg+xml" data="/svg/completed-status.svg" width={100} {...props}>
      Completed Status Animation
    </Box>
  );
};

export { CompletedStatusAnimation };
