import { Typography } from "@mui/material";
import { IHistoryLedgerItem, IHistoryLedgerItemChange } from "../../models";
import { HistoryLedgerItemEmptyValue } from "./HistoryLedgerItemEmptyValue";
import { HistoryLedgerItemValue } from "./HistoryLedgerItemValue";
import { IHistoryLedgerMappingStrategy } from "./HistoryLedgerMapper";

class DefaultHistoryLedgerMappingStrategy implements IHistoryLedgerMappingStrategy {
  planFieldType: string;

  constructor(planFieldType: string) {
    this.planFieldType = planFieldType.toLocaleLowerCase();
  }

  getCreatedText(historyLedgerItem: IHistoryLedgerItem): React.ReactNode {
    const descriptionOrValue = historyLedgerItem.changes.filter(
      (x) => x.propertyName === "description" || x.propertyName === "value"
    );

    if (descriptionOrValue.length > 0) {
      return (
        <Typography>
          Added {this.planFieldType} <HistoryLedgerItemValue value={descriptionOrValue[0].newValue} />
        </Typography>
      );
    }
  }

  getDeletedText(_historyLedgerItem: IHistoryLedgerItem): React.ReactNode {
    return <Typography>Deleted {this.planFieldType}</Typography>;
  }

  getEditedText(change: IHistoryLedgerItemChange): React.ReactNode {
    return (
      <Typography>
        {`Updated ${change.propertyName}`}
        {change.oldValue != null && change.oldValue !== "" && (
          <>
            {` from `}
            <HistoryLedgerItemValue
              value={typeof change.oldValue === "string" ? change.oldValue : JSON.stringify(change.oldValue)}
            />
          </>
        )}
        {` to `}
        {change.newValue == null || change.newValue === "" ? (
          <HistoryLedgerItemEmptyValue />
        ) : (
          <HistoryLedgerItemValue
            value={typeof change.newValue === "string" ? change.newValue : JSON.stringify(change.newValue)}
          />
        )}
      </Typography>
    );
  }
}

export { DefaultHistoryLedgerMappingStrategy };
