import { Box, Popper, Stack, styled, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ConnectionType } from "../constants";
import { useError, usePlan } from "../hooks";
import { IConnectionPoint, IPlanSummary } from "../models";
import { Autocomplete } from "./Autocomplete";
import { Typography } from "./Typography";

const MenuItem = styled(Box)`
  &:first-child {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }

  &:last-child {
    margin-bottom: 2px;
  }
` as typeof Box;

interface IProps {
  isLoadingPlans: boolean;
  plans: IPlanSummary[];
  selectedConnectionPoint?: IConnectionPoint;
  onConnectionPointChanged: (value: IConnectionPoint | null) => void;
}

const ConnectionPicker = (props: IProps) => {
  const { getConnectionPoints } = usePlan();
  const { setError } = useError();
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<IPlanSummary | null>(null);
  const [selectedConnectionPoint, setSelectedConnectionPoint] = useState<IConnectionPoint | null>(
    props.selectedConnectionPoint ?? null
  );
  const [isLoadingConnectionPoints, setIsLoadingConnectionPoints] = useState(false);
  const [connectionPoints, setConnectionPoints] = useState<IConnectionPoint[]>([]);

  useEffect(() => {
    if (props.selectedConnectionPoint) {
      setSelectedPlan({ name: props.selectedConnectionPoint.teamName, slug: props.selectedConnectionPoint.teamSlug });
      setSelectedConnectionPoint(props.selectedConnectionPoint);
      loadConnectionPoints(props.selectedConnectionPoint.teamSlug);
    } else {
      setSelectedPlan(null);
      setSelectedConnectionPoint(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedConnectionPoint]);

  async function loadConnectionPoints(teamSlug: string) {
    setIsLoadingConnectionPoints(true);
    (await getConnectionPoints(teamSlug)).match(
      (result) => setConnectionPoints(result),
      (error) => setError(error)
    );
    setIsLoadingConnectionPoints(false);
  }

  async function handlePlanChanged(e: React.SyntheticEvent, value: IPlanSummary | null) {
    setSelectedPlan(value);
    setSelectedConnectionPoint(null);

    if (value == null) {
      props.onConnectionPointChanged(null);
    } else {
      loadConnectionPoints(value.slug);
    }
  }

  async function handleConnectionPointChanged(e: React.SyntheticEvent, value: IConnectionPoint | null) {
    setSelectedConnectionPoint(value);
    props.onConnectionPointChanged(value);
  }

  return (
    <Stack ref={setContainer} spacing={0.25} sx={{ mt: 0.5 }}>
      <Autocomplete
        isLoading={props.isLoadingPlans}
        disabled={props.isLoadingPlans}
        options={props.plans}
        getOptionLabel={(option: IPlanSummary) => option.name}
        renderOption={(props, option) => (
          <MenuItem key={option.slug} component="li" {...props}>
            <Typography>{option.name}</Typography>
          </MenuItem>
        )}
        isOptionEqualToValue={(option, value) => option.slug === value.slug}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select a team" inputProps={{ ...params.inputProps }} />
        )}
        onChange={handlePlanChanged}
        PopperComponent={(props) => (
          <Popper
            {...props}
            container={container}
            style={{ ...props.style, margin: 0, padding: 0, minWidth: "300px" }}
          />
        )}
        value={selectedPlan}
      />

      <Autocomplete
        isLoading={isLoadingConnectionPoints}
        disabled={isLoadingConnectionPoints || selectedPlan == null}
        options={connectionPoints}
        getOptionLabel={(option: IConnectionPoint) => option.description}
        renderOption={(props, option) => (
          <MenuItem
            key={option.guid}
            component="li"
            {...props}
            sx={{ paddingLeft: option.type === ConnectionType.KeyInitiative ? "36px !important" : undefined }}
          >
            <Typography>{option.description}</Typography>
          </MenuItem>
        )}
        isOptionEqualToValue={(option, value) => option.guid === value.guid}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select a strategic plan item" inputProps={{ ...params.inputProps }} />
        )}
        onChange={handleConnectionPointChanged}
        PopperComponent={(props) => (
          <Popper
            {...props}
            container={container}
            style={{ ...props.style, margin: 0, padding: 0, minWidth: "300px" }}
          />
        )}
        value={selectedConnectionPoint}
      />
    </Stack>
  );
};

export { ConnectionPicker };
