import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { CreateMetricDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  teamSlug: string;
  title: string;
  description: string;
  sortOrder: string;
  modifiedByUserId: string;
  datasets: {
    title: string;
    color: string;
    dotted: boolean;
    data: {
      rowGuid: string;
      value: number | null;
      date: DateTime;
      modifiedByUserId: string;
    }[];
    sortOrder: string;
    modifiedByUserId: string;
  }[];
};

const useCreateMetricRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createMetricRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: CreateMetricDto = {
      teamSlug: variables.teamSlug,
      title: variables.title,
      description: variables.description,
      sortOrder: variables.sortOrder,
      createdByUserId: variables.modifiedByUserId,
      datasets: variables.datasets.map((d) => {
        return {
          color: d.color,
          title: d.title,
          dotted: d.dotted,
          sortOrder: d.sortOrder,
          createdByUserId: d.modifiedByUserId,
          data: d.data.map((p) => {
            return {
              value: p.value,
              date: p.date.toString(),
              rowGuid: p.rowGuid,
              createdByUserId: p.modifiedByUserId,
            };
          }),
        };
      }),
    };

    const response = await fetch(`${configuration.apiRootUrl}/metrics`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { createMetricRequest };
};

const useCreateMetric = () => {
  const { createMetricRequest } = useCreateMetricRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createMetricRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeam(variables.teamSlug) });
      await queryClient.refetchQueries({ queryKey: queryKeys.metrics.getMetricsForTeam(variables.teamSlug) });
    },
  });
};

export { useCreateMetric };
