import { DateTime } from "luxon";
import { SystemStatus } from "../constants";
import { GetStrategyDto } from "../http";
import { IStrategyDetail } from "../models";
import { InformedByMapper } from "./InformedByMapper";

function fromDto(dto: GetStrategyDto): IStrategyDetail {
  return {
    teamName: dto.teamName,
    teamSlug: dto.teamSlug,
    guid: dto.guid,
    description: dto.description,
    notes: dto.notes,
    informedBy: dto.informedBy ? InformedByMapper.fromDto(dto.informedBy) : undefined,
    childKeyInitiatives: dto.childKeyInitiatives.map((keyInitiative) => ({
      ...keyInitiative,
      currentStatus: {
        ...keyInitiative.currentStatus,
        modifiedDateTimeUtc: DateTime.fromISO(keyInitiative.currentStatus.modifiedDateTimeUtc, { zone: "utc" }),
      },
      lastModifiedUtc: DateTime.fromISO(keyInitiative.lastModifiedUtc, { zone: "utc" }),
    })),
    connectedStrategies: dto.connectedStrategies.map((strategy) => ({
      ...strategy,
      lastModifiedUtc: DateTime.fromISO(strategy.lastModifiedUtc, { zone: "utc" }),
    })),
    connectedKeyInitiatives: dto.connectedKeyInitiatives.map((keyInitiative) => ({
      ...keyInitiative,
      currentStatus: {
        ...keyInitiative.currentStatus,
        modifiedDateTimeUtc: DateTime.fromISO(keyInitiative.currentStatus.modifiedDateTimeUtc, { zone: "utc" }),
      },
      lastModifiedUtc: DateTime.fromISO(keyInitiative.lastModifiedUtc, { zone: "utc" }),
    })),
    connectedQuarterlyPriorities: dto.connectedQuarterlyPriorities.map((quarterlyPriority) => ({
      ...quarterlyPriority,
      completedDateUtc: quarterlyPriority.completedDateUtc
        ? DateTime.fromISO(quarterlyPriority.completedDateUtc, { zone: "utc" })
        : null,
    })),
    historyLedger: dto.historyLedger.map((historyItem) => ({
      changes: historyItem.changes,
      changeType: historyItem.changeType,
      timestampUtc: DateTime.fromISO(historyItem.timestampUtc, { zone: "utc" }),
      user: historyItem.user,
    })),
    archived: dto.historyLedger[0].changeType === SystemStatus.Deleted,
  };
}

const StrategyDetailMapper = {
  fromDto,
};

export { StrategyDetailMapper };
