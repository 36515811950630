import { Box, Popper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { WindowedAutocomplete } from ".";
import { useError, useSession, useUsers } from "../hooks";
import { IUser } from "../models";

interface IProps {
  value: IUser | null;
  excludeSessionUser?: boolean;
  onChange: (user: IUser | null) => void;
}

const UserSelect = (props: IProps) => {
  const { userId: sessionUserId } = useSession();
  const { getUsers } = useUsers();
  const { setError } = useError();
  const [autocompleteContainer, setAutocompleteContainer] = useState<HTMLElement | null>(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    (async () => {
      setIsLoadingUsers(true);

      (await getUsers()).match(
        (users) => {
          if (props.excludeSessionUser) {
            setUsers(users.filter((x) => x.userId !== sessionUserId));
          } else {
            setUsers(users);
          }

          setIsLoadingUsers(false);
        },
        (error) => {
          setError(error);
          setIsLoadingUsers(false);
        }
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box ref={setAutocompleteContainer}>
      <WindowedAutocomplete
        isLoading={isLoadingUsers}
        disabled={isLoadingUsers || users.length <= 0}
        options={users.sort((a, b) => a.displayName.localeCompare(b.displayName))}
        getOptionLabel={(option) => `${option.displayName} / ${option.email}`}
        value={props.value}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select your lead..." inputProps={{ ...params.inputProps }} />
        )}
        onChange={(e, value) => props.onChange(value)}
        isOptionEqualToValue={(option, value) => option.userId === value?.userId}
        PopperComponent={(props) => <Popper {...props} container={autocompleteContainer} />}
      />
    </Box>
  );
};

export { UserSelect };
