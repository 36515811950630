import { Box, Tooltip, styled, TooltipProps, tooltipClasses } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Instance } from "@popperjs/core";
import { INetworkGraphNode, IPeopleGraphNode, ITeamGraphNode, NetworkGraphNodeType } from "../../../models";
import { PeopleGraphNodeTooltipContent } from "./PeopleGraphNodeTooltipContent";
import { TeamGraphNodeTooltipContent } from "./TeamGraphNodeTooltipContent";

const ForceGraphTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxHeight: "45vh",
    overflow: "hidden",
    width: 250,
    padding: 0,
  },
}));

interface IProps {
  networkNode?: INetworkGraphNode;
  position?: { x: number; y: number };
  open: boolean;
}

const NetworkGraphTooltip = (props: IProps) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [networkNodeCopy, setNetworkNodeCopy] = useState<INetworkGraphNode | undefined>();
  const popperRef = React.useRef<Instance>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  useEffect(() => {
    if (props.open) {
      setIsOpen(true);
      setNetworkNodeCopy(props.networkNode);
    }
  }, [props.open, props.networkNode]);

  useEffect(() => {
    if (!props.open && !isHovering) {
      //Start closing the tooltip
      timerRef.current = setTimeout(() => {
        setIsOpen(false);
        timerRef.current = null;
      }, 300);
    } else {
      //Cancel closing sequences
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (popperRef.current != null) {
        popperRef.current.update();
      }
    }
  }, [props.open, isHovering]);

  const isPeopleNode = (node: INetworkGraphNode): node is IPeopleGraphNode => {
    return node.type === NetworkGraphNodeType.Member;
  };

  const isTeamNode = (node: INetworkGraphNode): node is ITeamGraphNode => {
    return (
      node.type === NetworkGraphNodeType.RootTeam ||
      node.type === NetworkGraphNodeType.ParentTeam ||
      node.type === NetworkGraphNodeType.ChildTeam
    );
  };

  return (
    <ForceGraphTooltip
      title={
        networkNodeCopy &&
        ((isPeopleNode(networkNodeCopy) && <PeopleGraphNodeTooltipContent personNode={networkNodeCopy} />) ||
          (isTeamNode(networkNodeCopy) && <TeamGraphNodeTooltipContent teamNode={networkNodeCopy} />))
      }
      open={isOpen}
      PopperProps={{
        popperRef,
        onMouseOver: (e) => {
          setIsHovering(true);
        },
        onMouseLeave: () => {
          setIsHovering(false);
        },
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(props.position?.x, props.position?.y, 20, 20);
          },
        },
      }}
    >
      <Box width={0}></Box>
    </ForceGraphTooltip>
  );
};

export { NetworkGraphTooltip };
