import { DateTime } from "luxon";
import { SystemStatus } from "../constants";
import { GetGoalDto } from "../http";
import { IGoalDetail } from "../models";

function fromDto(dto: GetGoalDto): IGoalDetail {
  return {
    teamName: dto.teamName,
    teamSlug: dto.teamSlug,
    description: dto.description,
    archived: dto.historyLedger[0].changeType === SystemStatus.Deleted,
    historyLedger: dto.historyLedger.map((historyLedgerItem) => ({
      ...historyLedgerItem,
      timestampUtc: DateTime.fromISO(historyLedgerItem.timestampUtc, { zone: "utc" }),
    })),
  };
}

const GoalDetailMapper = {
  fromDto,
};

export { GoalDetailMapper };
