import { Box, SxProps } from "@mui/material";

interface IProps {
  value: React.ReactNode;
  sx?: SxProps;
}

const HistoryLedgerItemValue = (props: IProps) => {
  return (
    <Box component="span" sx={{ fontWeight: "bold", ...props.sx }}>
      {props.value}
    </Box>
  );
};

export { HistoryLedgerItemValue };
