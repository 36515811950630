import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { DeleteTeamDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  slug: string;
  parentSlug?: string;
  modifiedByUserId: string;
};

const useDeleteTeamRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function DeleteTeamRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: DeleteTeamDto = {
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/teams/${variables.slug}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { DeleteTeamRequest };
};

const useDeleteTeam = () => {
  const { DeleteTeamRequest } = useDeleteTeamRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: DeleteTeamRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeams(variables.modifiedByUserId) });

      await queryClient.removeQueries({ queryKey: queryKeys.teams.getTeams() });
      await queryClient.removeQueries({ queryKey: queryKeys.teams.getConnectedTeams(variables.slug) });

      if (variables.parentSlug !== undefined) {
        await queryClient.removeQueries({ queryKey: queryKeys.teams.getTeamNetworkGraph(variables.parentSlug) });
        await queryClient.removeQueries({ queryKey: queryKeys.teams.getTeamNetworkList(variables.parentSlug) });
      }
    },
  });
};

export { useDeleteTeam };
