import { Link, Typography } from "@mui/material";
import { Links, SystemStatus } from "../../constants";
import { FeedItemContextValueDto, FeedItemDto } from "../../http";

interface IProps {
  feedItem: FeedItemDto;
}

const ContextPropertyChange = (props: IProps) => {
  if (props.feedItem.propertyChange!.changeType === SystemStatus.Deleted) {
    return (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> removed connection</span>
      </Typography>
    );
  }

  const context = props.feedItem.propertyChange!.value as FeedItemContextValueDto;
  let changeTypeString = "";

  if (props.feedItem.propertyChange!.changeType === SystemStatus.Created) {
    changeTypeString = " added connection to ";
  }

  if (props.feedItem.propertyChange!.changeType === SystemStatus.Edited) {
    changeTypeString = " updated connection to ";
  }

  return (
    <Typography variant="caption">
      <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
      <span>{changeTypeString}</span>
      <Link href={Links.Team(context.actionItemParentIdentifier)} sx={{ fontWeight: "bold" }}>
        {context.actionItemParentName}
      </Link>
      <strong>: </strong>
      <Link href={Links.ActionItemDetail(context.actionItemGuid)} sx={{ fontWeight: "bold" }}>
        {context.actionItemDescription}
      </Link>
    </Typography>
  );
};

export { ContextPropertyChange };
