import { InfoOutlined } from "@mui/icons-material";
import { Button, MenuItem, Stack, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { Control, Controller, ControllerRenderProps, FormState } from "react-hook-form";
import { v4 as newGuid } from "uuid";
import { Autocomplete, DraggableList, SmallInputLabel, TagPicker } from "../../components";
import { useGetTeams, useGetUsers } from "../../http";
import { IRoleAccountability, ITeam, IUser } from "../../models";
import { lexorank } from "../../utilities";
import { TagType } from "../../constants";

interface ICreateEditRoleFormState {
  team: ITeam | undefined;
  name: string;
  description: string;
  isOpen: boolean;
  accountabilities: IRoleAccountability[];
  tags: string[];
  members: IUser[];
}

interface IProps {
  formState: FormState<ICreateEditRoleFormState>;
  control: Control<ICreateEditRoleFormState, any>;
  loadingEnabled: boolean;
}

const CreateEditRoleForm = (props: IProps) => {
  const {
    formState: { errors },
    control,
    loadingEnabled,
  } = props;
  const { isLoading: isLoadingTeams, data: teams } = useGetTeams(undefined, loadingEnabled);
  const { isLoading: isLoadingUsers, data: users } = useGetUsers(loadingEnabled);

  function handleAccountabilityAdded(field: ControllerRenderProps<ICreateEditRoleFormState, "accountabilities">) {
    field.onChange([
      ...field.value,
      {
        guid: newGuid(),
        description: "",
        sortOrder: lexorank.getNextRank(field.value),
      },
    ]);
  }

  function handleAccountabilityChanged(
    field: ControllerRenderProps<ICreateEditRoleFormState, "accountabilities">,
    index: number,
    value: string
  ) {
    const updatedValues = field.value.map((accountability, i) => {
      if (index === i) {
        return {
          ...accountability,
          description: value,
        };
      }

      return accountability;
    });

    field.onChange(updatedValues);
  }

  function handleAccountabilityReordered(
    field: ControllerRenderProps<ICreateEditRoleFormState, "accountabilities">,
    sourceIndex: number,
    destinationIndex: number
  ) {
    var reorderedValues = field.value.map((value, index) => {
      if (index === sourceIndex) {
        return {
          ...value,
          sortOrder: lexorank.getRank(field.value, sourceIndex, destinationIndex),
        };
      }

      return value;
    });

    field.onChange(reorderedValues);
  }

  function handleAccountabilityDeleted(
    field: ControllerRenderProps<ICreateEditRoleFormState, "accountabilities">,
    index: number
  ) {
    field.onChange(field.value.filter((_, i) => i !== index));
  }

  return (
    <Stack spacing={1}>
      <Stack spacing={0.25} sx={{ px: 1 }}>
        <SmallInputLabel>Team *</SmallInputLabel>
        <Controller
          name="team"
          control={control}
          rules={{
            required: {
              message: "Team is required",
              value: true,
            },
          }}
          render={({ field }) => (
            <Autocomplete
              isLoading={isLoadingTeams}
              disabled={isLoadingTeams}
              options={teams ?? []}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <MenuItem key={option.slug} component="li" {...props}>
                  <Typography>{option.name}</Typography>
                </MenuItem>
              )}
              onChange={(_, option) => field.onChange(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a team..."
                  inputProps={{ ...params.inputProps }}
                  error={errors["team"] !== undefined}
                  helperText={errors["team"]?.message}
                />
              )}
              value={field.value}
              isOptionEqualToValue={(option, value) => option.slug === value.slug}
            />
          )}
        />
      </Stack>
      <Stack spacing={0.25} sx={{ px: 1 }}>
        <SmallInputLabel>Role Name *</SmallInputLabel>
        <Controller
          name="name"
          control={control}
          rules={{ required: { message: "Role Name is required", value: true } }}
          render={({ field }) => (
            <TextField
              {...field}
              error={errors["name"] !== undefined}
              helperText={errors["name"]?.message}
              placeholder="Enter a role name that describes the work..."
              inputProps={{ maxLength: 64 }}
            />
          )}
        />
      </Stack>
      <Stack direction="row" spacing={0.5} sx={{ alignItems: "center", px: 1 }}>
        <SmallInputLabel>Open Role</SmallInputLabel>
        <Tooltip
          title={
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "baseline" }}>
              <InfoOutlined sx={{ position: "relative", top: "2px" }} />
              <Typography variant="caption">Share with the organization that help is needed in this role.</Typography>
            </Stack>
          }
        >
          <InfoOutlined />
        </Tooltip>
        <Controller
          name="isOpen"
          control={control}
          render={({ field }) => <Switch checked={field.value} onChange={field.onChange} />}
        />
      </Stack>
      <Stack spacing={0.25} sx={{ px: 1 }}>
        <SmallInputLabel>Role Description *</SmallInputLabel>
        <Controller
          name="description"
          control={control}
          rules={{ required: { message: "Role Description is required", value: true } }}
          render={({ field }) => (
            <TextField
              {...field}
              error={errors["description"] !== undefined}
              helperText={errors["description"]?.message}
              placeholder="Describe why this role exists..."
              multiline
            />
          )}
        />
      </Stack>
      <Stack spacing={0.25} sx={{ px: 1 }}>
        <SmallInputLabel>People</SmallInputLabel>
        <Controller
          name="members"
          control={control}
          render={({ field }) => (
            <Autocomplete
              isLoading={isLoadingUsers}
              disabled={isLoadingUsers}
              options={users ?? []}
              getOptionLabel={(option) => option.displayName}
              renderOption={(props, option) => (
                <MenuItem component="li" {...props} key={option.userId}>
                  <Stack>
                    <Typography>{option.displayName}</Typography>
                    <Typography variant="caption">{option.email}</Typography>
                  </Stack>
                </MenuItem>
              )}
              onChange={(_, option) => field.onChange(option)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select people..." inputProps={{ ...params.inputProps }} />
              )}
              value={field.value}
              isOptionEqualToValue={(option, value) => option.userId === value.userId}
              multiple
            />
          )}
        />
      </Stack>
      <Stack spacing={0.25} sx={{ px: 1 }}>
        <SmallInputLabel>Tags</SmallInputLabel>
        <Controller
          name="tags"
          control={control}
          render={({ field }) => (
            <TagPicker value={field.value} onChange={(value) => field.onChange(value)} tagType={TagType.Role} />
          )}
        />
      </Stack>
      <Stack>
        <SmallInputLabel sx={{ px: 1 }}>Accountabilities</SmallInputLabel>
        <Controller
          name="accountabilities"
          control={control}
          render={({ field }) => (
            <Stack spacing={0.5} sx={{ alignItems: "start", width: "100%" }}>
              <DraggableList
                id={field.name}
                dataSource={field.value.sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))}
                confirmDeletion={false}
                renderItem={(item, index) => (
                  <TextField
                    key={index}
                    value={item.description}
                    onChange={(e) => handleAccountabilityChanged(field, index, e.target.value)}
                    placeholder="Describe an accountability starting with an -ing word, e.g. Researching..."
                    sx={{ my: 0.25 }}
                  />
                )}
                renderEmptyPlaceholder={() => (
                  <Typography variant="body1" sx={{ px: 1, pt: 0.25 }}>
                    Nothing here yet.
                  </Typography>
                )}
                onReorder={(sourceIndex, destinationIndex) =>
                  handleAccountabilityReordered(field, sourceIndex, destinationIndex)
                }
                onDelete={(index) => handleAccountabilityDeleted(field, index)}
              />
              <Button
                variant="tertiary"
                onClick={() => handleAccountabilityAdded(field)}
                sx={{ marginLeft: "20px !important" }}
              >
                Add Accountability
              </Button>
            </Stack>
          )}
        />
      </Stack>
    </Stack>
  );
};

export { CreateEditRoleForm };
export type { ICreateEditRoleFormState };
