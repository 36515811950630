import { Box, styled } from "@mui/material";
import { HeaderLayout } from "./HeaderLayout";

const Toolbar = styled(Box)`
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
`;

const ContentContainer = styled(Box)``;

interface IProps {
  title: React.ReactNode;
  toolbar: React.ReactNode;
  children: React.ReactNode;
}

const ToolbarLayout = (props: IProps) => {
  const { children, toolbar, ...headerLayoutProps } = props;

  return (
    <HeaderLayout
      {...headerLayoutProps}
      contentContainerProps={{
        sx: {
          p: 0,
        },
      }}
    >
      <Toolbar sx={{ px: { xs: 0.5, sm: 1 }, py: 0.5 }}>{toolbar}</Toolbar>
      <ContentContainer sx={{ p: { xs: 0.5, sm: 1 } }}>{children}</ContentContainer>
    </HeaderLayout>
  );
};

export { ToolbarLayout };
