function useDomParser() {
  function stripHtml(html: string) {
    if (html === "") {
      return html;
    }

    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.childNodes[0].textContent;
  }

  return {
    stripHtml,
  };
}

export { useDomParser };
