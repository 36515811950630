import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { IMetric } from "../../models";
import { ConnectObjectType } from "../../constants";
import { CreateEditMetricForm, ICreateEditMetricFormState } from ".";
import { useForm } from "react-hook-form";
import { useEditMetric } from "../../http";
import { useEffect } from "react";
import { useSession } from "../../hooks";

interface IProps {
  isVisible: boolean;
  metric: IMetric;
  onUpdated: () => void;
  onCancelled: () => void;
}

const EditMetricModal = (props: IProps) => {
  const { userId } = useSession();
  const { mutate, isLoading: isSaving } = useEditMetric();

  const form = useForm<ICreateEditMetricFormState>({
    defaultValues: {
      title: props.metric?.title ?? "",
      description: props.metric?.description ?? "",
      sortOrder: props.metric?.sortOrder ?? "",
      datasets: props.metric?.datasets
        .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
        .map((dataset) => {
          return {
            guid: dataset.guid,
            title: dataset.title,
            dotted: dataset.dotted,
            color: dataset.color,
            data: dataset.data.map((data) => {
              return {
                rowGuid: data.rowGuid,
                date: data.date,
                value: data.value,
              };
            }),
            sortOrder: dataset.sortOrder,
          };
        }),
    },
  });

  useEffect(() => {
    form.reset({
      title: props.metric?.title ?? "",
      description: props.metric?.description ?? "",
      sortOrder: props.metric?.sortOrder ?? "",
      datasets: props.metric?.datasets
        .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
        .map((dataset) => {
          return {
            guid: dataset.guid,
            title: dataset.title,
            dotted: dataset.dotted,
            color: dataset.color,
            sortOrder: dataset.sortOrder,
            data: dataset.data.map((data) => {
              return {
                rowGuid: data.rowGuid,
                date: data.date,
                value: data.value,
              };
            }),
          };
        }),
    });
  }, [props.metric, form]);

  async function handleUpdateClicked() {
    if (props.metric === undefined) {
      return;
    }

    form.handleSubmit((data) => {
      mutate(
        {
          guid: props.metric!.guid,
          teamSlug: props.metric.teamSlug,
          title: data.title,
          description: data.description,
          sortOrder: data.sortOrder,
          modifiedByUserId: userId!,
          datasets: data.datasets?.map((dataset) => {
            return {
              guid: dataset.guid,
              title: dataset.title,
              dotted: dataset.dotted,
              color: dataset.color,
              data: dataset.data.map((data) => {
                return {
                  rowGuid: data.rowGuid,
                  date: data.date,
                  value: data.value,
                  modifiedByUserId: userId!,
                };
              }),
              sortOrder: dataset.sortOrder,
              modifiedByUserId: userId!,
            };
          }),
        },
        {
          onSuccess: () => {
            props.onUpdated();
          },
        }
      );
    })();
  }

  function handleClose(_: object, reason: string) {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    handleCancelClicked();
  }

  function handleCancelClicked() {
    form.reset(undefined, { keepDefaultValues: true });
    props.onCancelled();
  }

  return (
    <Dialog open={props.isVisible} onClose={handleClose}>
      <DialogTitle>Editing {ConnectObjectType.Metric}</DialogTitle>
      <DialogContent sx={{ mb: 1 }}>
        <Stack spacing={1} sx={{ alignItems: "baseline" }}>
          <CreateEditMetricForm form={form} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isSaving} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isSaving}
          endIcon={isSaving ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleUpdateClicked}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { EditMetricModal };
