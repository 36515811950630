import { Box, Card, Chip, Divider, Link, Skeleton, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useParams } from "react-router";
import { Comments, HistoryLedgerMapper, InformedByTag, TextViewer, Typography } from "../../components";
import { CommentSubject, DEFAULT_TITLE, Links, PlanFieldType } from "../../constants";
import { useDomParser } from "../../hooks";
import { useGetKeyInitiativeDetail } from "../../http";
import { DetailPageLayout } from "./DetailPageLayout";
import { KeyInitiativeHistoryLedgerMappingStrategy } from "./components";
import { renderSortedConnections } from "./helpers";

interface UrlParams {
  slug: string;
  keyInitiativeGuid: string;
}

const KeyInitiativeDetailsPage = () => {
  const { slug, keyInitiativeGuid } = useParams<keyof UrlParams>() as UrlParams;
  const { isLoading, data: keyInitiative } = useGetKeyInitiativeDetail(slug, keyInitiativeGuid);
  const { stripHtml } = useDomParser();

  useEffect(() => {
    document.title = DEFAULT_TITLE;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && keyInitiative) {
      document.title = `${keyInitiative.teamName} Priority - ${DEFAULT_TITLE}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <DetailPageLayout
      faded={keyInitiative?.archived}
      sideBarContent={
        isLoading ? (
          <Stack spacing={1}>
            <Card sx={{ p: 1 }}>
              <Skeleton />
            </Card>
          </Stack>
        ) : (
          keyInitiative &&
          (keyInitiative.connectedStrategies.length > 0 ||
          keyInitiative.connectedQuarterlyPriorities.length > 0 ||
          keyInitiative.connectedKeyInitiatives.length > 0 ? (
            <Box>
              <Typography sx={{ textTransform: "uppercase", mb: 0.5 }}>Connecting</Typography>
              <Stack spacing={1}>
                {renderSortedConnections(
                  keyInitiative.connectedStrategies,
                  keyInitiative.connectedKeyInitiatives,
                  keyInitiative.connectedQuarterlyPriorities
                )}
              </Stack>
            </Box>
          ) : undefined)
        )
      }
    >
      {isLoading && (
        <Stack>
          <Skeleton sx={{ mx: 1, mt: 0.5 }} width={"10%"} />
          <Divider />
          <Skeleton sx={{ mx: 1 }} height={96} />
          <Divider />
          <Stack sx={{ p: 1 }}>
            <Skeleton width={"80%"} />
            <Skeleton width={"80%"} />
            <Skeleton width={"80%"} />
          </Stack>
        </Stack>
      )}

      {!isLoading && keyInitiative && (
        <Stack spacing={1} sx={{ mt: 1 }}>
          <Stack direction="row" gap={0.5} flexWrap="wrap" sx={{ px: 1 }}>
            <Chip
              color="primary"
              label={keyInitiative.teamName}
              component="a"
              href={Links.TeamStrategicMap(keyInitiative.teamSlug)}
              clickable
            />
            <Chip label={PlanFieldType.Priority} />
          </Stack>
          <Divider />
          <Stack sx={{ px: 1 }}>
            {keyInitiative.informedBy && <InformedByTag sx={{ pb: 0.25 }} informedBy={keyInitiative.informedBy} />}
            <Typography variant={"h3"} faded={keyInitiative.archived}>
              {keyInitiative.description}
            </Typography>
          </Stack>
          <Divider />
          <Stack spacing={1} sx={{ px: 1 }}>
            <Stack
              direction={{ sm: "row", xs: "column" }}
              spacing={{ sm: 1, xs: 0.25 }}
              sx={{ alignItems: "baseline" }}
            >
              <Typography
                variant={"body1"}
                sx={{ width: "132px", textTransform: "uppercase", flex: "none" }}
                faded={keyInitiative.archived}
              >
                Status
              </Typography>
              <Stack direction="row" spacing={0.5} sx={{ alignItems: "center", position: "relative" }}>
                <Typography variant="caption" faded={keyInitiative.archived}>
                  {`Set by `}
                  <Link href={Links.User(keyInitiative.status.modifiedByUser.userId)}>
                    {keyInitiative.status.modifiedByUser.displayName}
                  </Link>
                  {` on `}
                  {keyInitiative.status.modifiedDateTimeUtc.toLocal().toLocaleString(DateTime.DATE_FULL)}
                </Typography>
              </Stack>
            </Stack>

            {keyInitiative.users.length > 0 && (
              <Stack direction={{ sm: "row", xs: "column" }} spacing={{ sm: 1, xs: 0.25 }}>
                <Typography
                  variant={"body1"}
                  sx={{ width: "132px", textTransform: "uppercase", flex: "none" }}
                  faded={keyInitiative.archived}
                >
                  Ownership
                </Typography>
                <Typography variant={"body1"} faded={keyInitiative.archived}>
                  {keyInitiative.users.map((x, index) => (
                    <Box key={index} component="span">
                      {index > 0 ? ", " : ""}
                      <Link onClick={(e) => e.stopPropagation()} href={Links.User(x.userId)}>
                        {x.displayName}
                        {x.enabled ? "" : " (deactivated)"}
                      </Link>
                    </Box>
                  ))}
                </Typography>
              </Stack>
            )}

            {keyInitiative.notes && stripHtml(keyInitiative.notes) !== "" && (
              <Stack direction={{ sm: "row", xs: "column" }} spacing={{ sm: 1, xs: 0.25 }}>
                <Typography
                  variant={"body1"}
                  sx={{ width: "132px", textTransform: "uppercase", flex: "none" }}
                  faded={keyInitiative.archived}
                >
                  Notes
                </Typography>
                <TextViewer value={keyInitiative.notes} />
              </Stack>
            )}
          </Stack>
          <Box>
            <Divider />
            <Comments
              subjectType={CommentSubject.PlanKeyInitiative}
              subjectGuid={keyInitiativeGuid}
              additionalComments={HistoryLedgerMapper.toComments(
                keyInitiative.historyLedger,
                new KeyInitiativeHistoryLedgerMappingStrategy()
              )}
            />
          </Box>
        </Stack>
      )}
    </DetailPageLayout>
  );
};

export { KeyInitiativeDetailsPage };
