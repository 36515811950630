import { styled } from "@mui/material";
import { CodeSpinnerIcon } from "../assets/icons";
import { Layout } from "./Layout";

const SpinContainer = styled("div")`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = () => {
  return (
    <Layout>
      <SpinContainer>
        <CodeSpinnerIcon />
      </SpinContainer>
    </Layout>
  );
};

export { Loading };
