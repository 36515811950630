import { Link, Typography } from "@mui/material";
import { Links } from "../../constants";
import { FeedItemDto } from "../../http";

interface IProps {
  feedItem: FeedItemDto;
}

const IssueStatusPropertyChange = (props: IProps) => {
  const closed = props.feedItem.propertyChange!.value;

  return (
    <Typography variant="caption" sx={{ alignItems: "center" }}>
      <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>{" "}
      {closed ? "closed this item" : "re-opened this item"}
    </Typography>
  );
};

export { IssueStatusPropertyChange };
