import { Box, alpha, useTheme } from "@mui/material";
import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import ForceGraph2D, { ForceGraphMethods, GraphData, NodeObject } from "react-force-graph-2d";
import { useNavigate } from "react-router";
import { CodeSpinnerIcon } from "../../../assets/icons";
import { HomeVisualizationMode, useSession, useSettings } from "../../../hooks";
import { useGetOrganizationNetworkGraph } from "../../../http";
import { INetworkGraphLink, INetworkGraphNode, NetworkGraphNodeType } from "../../../models";
import { NODE_RADIUS, calculateOpacity, drawNode } from "../../../utilities";
import { NetworkGraphTooltip } from "./NetworkGraphTooltip";
import { OrganizationNetworkGraphControls } from "./OrganizationNetworkGraphControls";

const OrganizationNetworkGraph = () => {
  const { userId } = useSession();
  const { data, isLoading } = useGetOrganizationNetworkGraph();
  const { homeSettings } = useSettings();
  const [mutableData, setMutableData] = useState<GraphData<INetworkGraphNode, INetworkGraphLink>>({
    nodes: [],
    links: [],
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const forceGraphRef = useRef<ForceGraphMethods<INetworkGraphNode, INetworkGraphLink>>();
  const tickRef = useRef<number>(0);
  const [hoveredGraphNode, setHoveredGraphNode] = useState<INetworkGraphNode>();
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const resizeTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [graphDimensions, setGraphDimensions] = useState<{ height?: number; width?: number }>({});

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  function handleResize() {
    // Buffered to only resize every 500ms
    clearTimeout(resizeTimerRef.current);
    resizeTimerRef.current = setTimeout(() => {
      setGraphDimensions({
        height: containerRef.current?.clientHeight,
        width: containerRef.current?.clientWidth,
      });

      setTimeout(() => {
        forceGraphRef.current?.zoomToFit(1000, 40);
      }, 100);
    }, 500);
  }

  // Configure initial height and width
  useEffect(() => {
    setGraphDimensions({
      height: containerRef.current?.clientHeight,
      width: containerRef.current?.clientWidth,
    });
  }, []);

  // Configure d3 forces
  useEffect(() => {
    if (forceGraphRef.current) {
      forceGraphRef.current.d3Force("charge", d3.forceManyBody().strength(-2000).distanceMax(2000));
      forceGraphRef.current.d3Force("collide", d3.forceCollide(NODE_RADIUS));
      forceGraphRef.current.d3Force("link", d3.forceLink().distance(200).strength(2));
    }
  }, []);

  // Set up data structure used by react-force-graph
  useEffect(() => {
    if (data === undefined) {
      return;
    }

    const teamNodes = data.teamNodes.map(
      (x): NodeObject<INetworkGraphNode> => ({ ...x, fx: undefined, fy: undefined })
    );
    const peopleNodes =
      homeSettings.visualizationMode === HomeVisualizationMode.TeamsAndPeople
        ? data.peopleNodes.map((x): NodeObject<INetworkGraphNode> => ({ ...x, fx: undefined, fy: undefined }))
        : [];

    const teamLinks = data.teamLinks.map((x) => ({ ...x }));
    const peopleLinks =
      homeSettings.visualizationMode === HomeVisualizationMode.TeamsAndPeople
        ? data.peopleLinks.map((x) => ({ ...x }))
        : [];

    setMutableData({
      nodes: teamNodes.concat(peopleNodes),
      links: teamLinks.concat(peopleLinks),
    });

    setTimeout(() => {
      forceGraphRef.current?.zoomToFit(1000, 40);
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, homeSettings]);

  function handleNodeClicked(node: NodeObject<INetworkGraphNode>, event: MouseEvent) {
    if (event.ctrlKey) {
      window.open(node.url, "_blank");
    } else if (event.shiftKey) {
      window.open(node.url, "_new");
    } else {
      navigate(node.url);
    }
  }

  function getHoverNodePosition(node: NodeObject<INetworkGraphNode>): { x: number; y: number } | undefined {
    if (forceGraphRef.current && containerRef.current) {
      let screenCoord = forceGraphRef.current.graph2ScreenCoords(node.x!, node.y!);
      // Adjust coordinates based on the bounding box of the graph's container.
      let adjustedPosition = {
        x: screenCoord.x + containerRef.current.getBoundingClientRect().left,
        y: screenCoord.y + containerRef.current.getBoundingClientRect().top,
      };
      return adjustedPosition;
    }
  }

  return (
    <>
      <NetworkGraphTooltip open={isOpen} position={tooltipPosition} networkNode={hoveredGraphNode} />
      <Box
        ref={containerRef}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "calc(100vh - 81px)",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {isLoading && <CodeSpinnerIcon sx={{ position: "absolute", zIndex: 3 }} />}
        {!isLoading && <OrganizationNetworkGraphControls forceGraphRef={forceGraphRef} />}

        <ForceGraph2D
          ref={forceGraphRef}
          graphData={mutableData}
          height={graphDimensions.height}
          width={graphDimensions.width}
          nodeCanvasObject={(node, canvas, scale) =>
            drawNode(
              node,
              canvas,
              scale,
              theme,
              true,
              node.type === NetworkGraphNodeType.RootTeam
                ? NODE_RADIUS * 8
                : node.type === NetworkGraphNodeType.Member && node.id === userId
                ? NODE_RADIUS * 4
                : 0,
              calculateOpacity(tickRef.current, node.depth)
            )
          }
          onNodeHover={(node) => {
            if (node) {
              setIsOpen(true);
              setHoveredGraphNode(node);
              setTooltipPosition(getHoverNodePosition(node));
            } else {
              setIsOpen(false);
              setHoveredGraphNode(undefined);
            }
          }}
          nodeVal={20}
          linkDirectionalArrowLength={5}
          linkDirectionalArrowRelPos={1}
          linkCurvature={0.25}
          linkLineDash={[5, 3]}
          linkColor={(link) => {
            const source = link.source as NodeObject<INetworkGraphNode>;
            const opacity = calculateOpacity(tickRef.current, source.depth) ?? 1;
            return alpha("#cacaca", opacity);
          }}
          linkWidth={2}
          onEngineTick={() => tickRef.current++}
          onNodeClick={handleNodeClicked}
          warmupTicks={50}
          maxZoom={2}
          minZoom={0.1}
        />
      </Box>
    </>
  );
};

export { OrganizationNetworkGraph };
