import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetSurveyInstanceResponseDto, SubmitSurveyInstanceResponseDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";
import { DateTime } from "luxon";

type Variables = {
  responseGuid: string;
  instanceGuid: string;
  shouldInvalidate: boolean;
};

const useSubmitSurveyInstanceResponseRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function submitSurveyInstanceResponseRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();

    const body: SubmitSurveyInstanceResponseDto = {
      responseGuid: variables.responseGuid,
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-response/${variables.responseGuid}/submit`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { submitSurveyInstanceResponseRequest };
};

const useSubmitSurveyInstanceResponse = () => {
  const { submitSurveyInstanceResponseRequest } = useSubmitSurveyInstanceResponseRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: submitSurveyInstanceResponseRequest,
    onMutate: async (variables) => {
      // Optimistically update cache
      const cachedData = await optimisticallyUpdateResponse(queryClient, variables);

      return { cachedData };
    },
    onError: (_error, variables, context) => {
      // On error, rollback
      queryClient.setQueryData(
        queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid),
        context?.cachedData
      );
    },
    onSettled: (_data, _error, variables) => {
      if (variables.shouldInvalidate) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid),
        });
      }
    },
  });
};

async function optimisticallyUpdateResponse(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid));
  const cachedData = queryClient.getQueryData<GetSurveyInstanceResponseDto>(
    queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid)
  );

  if (cachedData) {
    const optimisticallyUpdatedData: GetSurveyInstanceResponseDto = {
      ...cachedData,
      submittedAtUtc: DateTime.utc().toString(),
    };

    queryClient.setQueryData(
      queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid),
      optimisticallyUpdatedData
    );

    return cachedData;
  }
}

export { useSubmitSurveyInstanceResponse };
