import { Link, Typography } from "@mui/material";
import { Links, SystemStatus } from "../../constants";
import { FeedItemDto } from "../../http";

interface IProps {
  feedItem: FeedItemDto;
}

const DescriptionPropertyChange = (props: IProps) => {
  if (props.feedItem.propertyChange!.changeType === SystemStatus.Created) {
    return (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> created this item</span>
      </Typography>
    );
  }

  if (props.feedItem.propertyChange!.changeType === SystemStatus.Deleted) {
    return (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> deleted this item</span>
      </Typography>
    );
  }

  return (
    <Typography variant="caption">
      <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
      <span> updated name to </span>
      <strong>{props.feedItem.propertyChange!.value}</strong>
    </Typography>
  );
};

export { DescriptionPropertyChange };
