import { MovingRounded, PublicRounded, RocketLaunchRounded, TrackChangesRounded } from "@mui/icons-material";
import { Box, Card, CardContent, Chip, Grid, Skeleton, Stack, styled } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useReducer } from "reinspect";
import { EditMetricModal, HeaderLayout } from "../../../components";
import { Links } from "../../../constants";
import { useError, usePlan, useSession } from "../../../hooks";
import { useGetMetricsForTeam, useGetTeam } from "../../../http";
import { getTop } from "../../../utilities";
import { TeamPageLayoutContext, TeamTitle } from "../TeamPageLayout";
import {
  AutosaveStatus,
  EditCard,
  EditGoals,
  EditKeyInitiative,
  EditMetrics,
  EditMilestones,
  EditMission,
  EditPurpose,
  EditStrategies,
  EditStrategy,
  EditTeamPlanActions,
  EditTeamPlanSideMenu,
  EditValues,
} from "./components";
import {
  GoalHintCard,
  KeyInitiativeHintCard,
  MilestoneHintCard,
  MissionHintCard,
  PurposeHintCard,
  StrategyHintCard,
  StrategyPlanHintCard,
  ValueHintCard,
} from "./components/hints";
import { initialState, reducer } from "./state";

const TEAM_HEADER_SIZE = 130;

const StickySideMenuCard = styled(Card)`
  position: sticky;
  top: ${TEAM_HEADER_SIZE}px;
  overflow: visible;
  z-index: 2;
`;

const StickyStack = styled(Stack)`
  position: sticky;
  top: ${TEAM_HEADER_SIZE}px;
  height: calc(100vh - ${TEAM_HEADER_SIZE + 20}px);
  overflow: visible;
`;

enum EditCardMode {
  None,
  Purpose,
  Value,
  Mission,
  Milestone,
  Goal,
  Strategy,
  KeyInitiative,
}

interface UrlParams {
  slug: string;
  planVersionId: string;
}

const TeamPlanEditPage = () => {
  const { slug, planVersionId } = useParams<keyof UrlParams>() as UrlParams;
  const { userId } = useSession();
  const { data: team, isFetching: isLoadingTeam } = useGetTeam(slug, userId);
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState, (x) => x, "edit_team_plan_reducer");
  const { getPlanVersion, getPlanVersions, editPlanVersion } = usePlan();
  const { data: metrics, isLoading: isLoadingMetrics } = useGetMetricsForTeam(slug);
  const { setError } = useError();
  const { enqueueSnackbar } = useSnackbar();
  const didMountAndLoadRef = useRef(false);

  const [isEditMetricModalVisible, setIsEditMetricModalVisible] = useState(false);
  const [editCardMode, setEditCardMode] = useState(EditCardMode.None);
  const [selectedMetricIndex, setSelectedMetricIndex] = useState<number | null>(null);
  const [selectedStrategyIndex, setSelectedStrategyIndex] = useState<number | null>(null);
  const [selectedKeyInitiativeIndex, setSelectedKeyInitiativeIndex] = useState<{
    strategyIndex: number;
    keyInitiativeIndex: number;
  } | null>(null);

  const whereRef = useRef<HTMLParagraphElement>(null);
  const nowRef = useRef<HTMLParagraphElement>(null);
  const metricsRef = useRef<HTMLParagraphElement>(null);
  const editStackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleMouseDown(event: any) {
      const isClickOutsideEditCard = editStackRef.current && !editStackRef.current.contains(event.target);

      if (isClickOutsideEditCard) {
        setEditCardMode(EditCardMode.None);
      }
    }

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await loadPlan();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, planVersionId]);

  useEffect(() => {
    (async () => {
      await loadPlanHistory();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    scrollToHash(location.hash);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  useEffect(() => {
    autoSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  async function autoSave() {
    if (didMountAndLoadRef.current && state.hasUnsavedChanges && !state.isSaving) {
      if (userId) {
        dispatch({ type: "saving" });

        (await editPlanVersion(userId, state.plan.teamSlug, state.plan.planVersionId, state.plan)).match(
          async () => {
            dispatch({ type: "saved", lastModifiedUtc: DateTime.utc().minus(1000) });
          },
          (error) => {
            showSaveErrorMessage();
            console.error(error);
            dispatch({ type: "saved", lastModifiedUtc: state.plan.lastModifiedUtc });
          }
        );
      }
    }

    if (!state.isLoadingPlan) {
      didMountAndLoadRef.current = true;
    }
  }

  async function loadPlan() {
    dispatch({ type: "loading_plan" });

    (await getPlanVersion(slug, planVersionId)).match(
      (plan) => dispatch({ type: "loaded_plan", plan }),
      (error) => setError(error)
    );
  }

  async function loadPlanHistory() {
    dispatch({ type: "loading_plan_history" });

    (await getPlanVersions(slug)).match(
      (planHistory) => dispatch({ type: "loaded_plan_history", planHistory }),
      (error) => setError(error)
    );
  }

  function scrollToHash(hash: string) {
    let top = 0;

    switch (hash) {
      case "#where":
        top = getTop(whereRef, 140);
        break;
      case "#now":
        top = getTop(nowRef, 140);
        break;
      case "#metrics":
        top = getTop(metricsRef, 140);
        break;
    }

    window.scrollTo({ top, behavior: "smooth" });
  }

  function handleStrategySelected(index: number) {
    setEditCardMode(EditCardMode.Strategy);
    setSelectedStrategyIndex(index);
  }

  function handleStrategyDeleted(index: number) {
    if (selectedStrategyIndex === index) {
      setSelectedStrategyIndex(null);
    }
  }

  function handleKeyInitiativeSelected(strategyIndex: number, keyInitiativeIndex: number) {
    setEditCardMode(EditCardMode.KeyInitiative);
    setSelectedKeyInitiativeIndex({ strategyIndex, keyInitiativeIndex });
  }

  function handleKeyInitiativeDeleted(strategyIndex: number, keyInitiativeIndex: number) {
    if (
      strategyIndex === selectedKeyInitiativeIndex?.strategyIndex &&
      keyInitiativeIndex === selectedKeyInitiativeIndex?.keyInitiativeIndex
    ) {
      setSelectedKeyInitiativeIndex(null);
    }
  }

  function handleMetricSelected(index: number) {
    setIsEditMetricModalVisible(true);
    setSelectedMetricIndex(index);
  }

  function handleMetricDeleted(index: number) {
    if (selectedMetricIndex === index) {
      setSelectedMetricIndex(null);
    }
  }

  function showSaveErrorMessage() {
    enqueueSnackbar("There was an error saving your most recent change. Please refresh the page and try again.", {
      variant: "error",
    });
  }

  return (
    <TeamPageLayoutContext.Provider value={{ isLoadingTeam, team }}>
      <HeaderLayout
        title={
          <Stack spacing={0.25} sx={{ pb: 1 }}>
            {!isLoadingTeam &&
              team?.parentTeam && (
                <Chip
                  label={team.parentTeam.name}
                  color="primary"
                  component="a"
                  href={Links.Team(team.parentTeam.slug)}
                  clickable
                />
              )}
            <TeamTitle />
            {!isLoadingTeam && <AutosaveStatus state={state} />}
          </Stack>}
        actions={
          !isLoadingTeam && (
            <EditTeamPlanActions
              state={state}
              dispatch={dispatch}
              onRefreshPlanHistory={loadPlanHistory}
              onError={showSaveErrorMessage}
            />
          )
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <StickySideMenuCard>
              <CardContent>
                <EditTeamPlanSideMenu />
              </CardContent>
            </StickySideMenuCard>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <EditCard title="Why" icon={<PublicRounded fontSize="large" />}>
                {state.isLoadingPlan ? (
                  <CardContent>
                    <Skeleton variant="rectangular" height="60px" width="100%" />
                  </CardContent>
                ) : (
                  <Stack spacing={1} sx={{ py: 1 }}>
                    <Box sx={{ px: 1 }}>
                      <EditPurpose
                        state={state}
                        dispatch={dispatch}
                        onFocus={() => setEditCardMode(EditCardMode.Purpose)}
                      />
                    </Box>
                    <EditValues state={state} dispatch={dispatch} onFocus={() => setEditCardMode(EditCardMode.Value)} />
                  </Stack>
                )}
              </EditCard>
              <EditCard ref={whereRef} title="Where" icon={<RocketLaunchRounded fontSize="large" />}>
                {state.isLoadingPlan ? (
                  <CardContent>
                    <Skeleton variant="rectangular" height="60px" width="100%" />
                  </CardContent>
                ) : (
                  <Stack spacing={1} sx={{ py: 1 }}>
                    <EditMission
                      state={state}
                      dispatch={dispatch}
                      onFocus={() => setEditCardMode(EditCardMode.Mission)}
                    />
                    <EditMilestones
                      state={state}
                      dispatch={dispatch}
                      onFocus={() => setEditCardMode(EditCardMode.Milestone)}
                    />
                  </Stack>
                )}
              </EditCard>
              <EditCard ref={nowRef} title="Now" icon={<TrackChangesRounded fontSize="large" />}>
                {state.isLoadingPlan ? (
                  <CardContent>
                    <Skeleton variant="rectangular" height="60px" width="100%" />
                  </CardContent>
                ) : (
                  <Stack spacing={1} sx={{ pt: 1 }}>
                    <EditGoals state={state} dispatch={dispatch} onFocus={() => setEditCardMode(EditCardMode.Goal)} />
                    <EditStrategies
                      state={state}
                      dispatch={dispatch}
                      onStrategySelected={handleStrategySelected}
                      onStrategyDeleted={handleStrategyDeleted}
                      onKeyInitiativeSelected={handleKeyInitiativeSelected}
                      onKeyInitiativeDeleted={handleKeyInitiativeDeleted}
                    />
                  </Stack>
                )}
              </EditCard>
              <EditCard ref={metricsRef} title="Critical Metrics" icon={<MovingRounded fontSize="large" />}>
                {isLoadingMetrics || !metrics ? (
                  <CardContent>
                    <Skeleton variant="rectangular" height="60px" width="100%" />
                  </CardContent>
                ) : (
                  <EditMetrics metrics={metrics} onSelected={handleMetricSelected} onDeleted={handleMetricDeleted} />
                )}
              </EditCard>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <StickyStack ref={editStackRef} direction="column" spacing={1}>
              <>
                {editCardMode === EditCardMode.None && <StrategyPlanHintCard />}

                {editCardMode === EditCardMode.Purpose && <PurposeHintCard />}

                {editCardMode === EditCardMode.Value && <ValueHintCard />}

                {editCardMode === EditCardMode.Mission && <MissionHintCard />}

                {editCardMode === EditCardMode.Milestone && <MilestoneHintCard />}

                {editCardMode === EditCardMode.Goal && <GoalHintCard />}

                {editCardMode === EditCardMode.Strategy && selectedStrategyIndex != null && (
                  <>
                    <StrategyHintCard />
                    <Card sx={{ overflow: "auto" }}>
                      <EditStrategy
                        strategyIndex={selectedStrategyIndex}
                        strategy={state.plan.strategies[selectedStrategyIndex]}
                        dispatch={dispatch}
                      />
                    </Card>
                  </>
                )}

                {editCardMode === EditCardMode.KeyInitiative && selectedKeyInitiativeIndex != null && (
                  <>
                    <KeyInitiativeHintCard />
                    <Card sx={{ overflow: "auto" }}>
                      <EditKeyInitiative
                        slug={slug}
                        strategyIndex={selectedKeyInitiativeIndex.strategyIndex}
                        keyInitiativeIndex={selectedKeyInitiativeIndex.keyInitiativeIndex}
                        keyInitiative={
                          state.plan.strategies[selectedKeyInitiativeIndex.strategyIndex].keyInitiatives[
                          selectedKeyInitiativeIndex.keyInitiativeIndex
                          ]
                        }
                        members={team?.followers ?? []}
                        dispatch={dispatch}
                      />
                    </Card>
                  </>
                )}
              </>
            </StickyStack>
          </Grid>
        </Grid>

        {metrics && selectedMetricIndex != null && (
          <EditMetricModal
            isVisible={isEditMetricModalVisible}
            metric={metrics[selectedMetricIndex]}
            onUpdated={() => setIsEditMetricModalVisible(false)}
            onCancelled={() => setIsEditMetricModalVisible(false)}
          />
        )}
      </HeaderLayout>
    </TeamPageLayoutContext.Provider>
  );
};

export { TeamPlanEditPage };
