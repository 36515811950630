import { CardContent, Chip, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { CardLink } from "../../../components";
import { ConnectObjectType, Links } from "../../../constants";
import { IOpenRole } from "../../../models";

interface IProps {
  role: IOpenRole;
}

const OpenRole = (props: IProps) => {
  return (
    <CardLink href={Links.RoleDetail(props.role.guid)} sx={{ borderRadius: "15px" }}>
      <CardContent>
        <Stack direction="row" gap={0.5} flexWrap="wrap" sx={{ mb: 0.5 }}>
          <Chip
            color="primary"
            clickable
            component="a"
            label={props.role.parentTeam.name}
            href={Links.Team(props.role.parentTeam.slug)}
          />
          <Chip color="orange" label={ConnectObjectType.Role} />
          <Chip
            label={`OPEN as of ${props.role.isOpenLastModifiedUtc
              .toLocal()
              .toLocaleString(DateTime.DATE_MED)
              .toLocaleUpperCase()}`}
            color="orange"
            variant="outlined"
            sx={{
              ".MuiChip-label": {
                textTransform: "none",
              },
            }}
          />
        </Stack>
        <Typography variant="h5">{props.role.name}</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>{props.role.description}</Typography>
      </CardContent>
    </CardLink>
  );
};

export { OpenRole };
