import { Box, Button, Chip, Divider, Stack, Switch, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { Autocomplete, SmallInputLabel, useDrawer } from "../../../../components";
import { useSession } from "../../../../hooks";
import { DeleteOutlined, InfoOutlined } from "@mui/icons-material";
import { SurveyInstancesList } from "../../components";
import {
  useEditSurveyAdministrators,
  useEditSurveyVerificationPPI,
  useEditSurveyDescription,
  useEditSurveyName,
  useGetUsers,
  useDeleteSurvey,
} from "../../../../http";
import { ISurvey, IUser } from "../../../../models";
import { useDestructiveConfirm } from "../../../../utilities";
import { Links } from "../../../../constants";
import { useNavigate } from "react-router";

interface IProps {
  survey: ISurvey;
}

const SurveySettingsDrawerContent = ({ survey }: IProps) => {
  const { userId } = useSession();
  const { closeDrawer } = useDrawer();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const confirm = useDestructiveConfirm();
  const theme = useTheme();
  const { isLoading: isLoadingUsers, data: users } = useGetUsers();
  const { mutate: editSurveyName } = useEditSurveyName();
  const { mutate: editSurveyDescription } = useEditSurveyDescription();
  const { mutate: editSurveyAdministrators } = useEditSurveyAdministrators();
  const { mutate: editSurveyVerificationPPI } = useEditSurveyVerificationPPI();
  const { mutate: deleteSurvey, isLoading: isDeletingSurvey } = useDeleteSurvey();
  const isLastAdministrator = survey?.administrators.length === 1;
  const isDisabledField = isDeletingSurvey;

  function handleShowSavedNotification() {
    enqueueSnackbar("Settings saved successfully.", { variant: "info" });
  }

  function handleNameChanged(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (survey === undefined) {
      return;
    }

    editSurveyName(
      {
        guid: survey.guid,
        name: e.target.value,
        modifiedByUserId: userId,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  function handleDescriptionChanged(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (survey === undefined) {
      return;
    }

    editSurveyDescription(
      {
        guid: survey.guid,
        description: e.target.value,
        modifiedByUserId: userId,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  function handleAdministratorsChanged(_: React.SyntheticEvent, value: IUser[]) {
    if (survey === undefined) {
      return;
    }

    editSurveyAdministrators(
      {
        guid: survey.guid,
        userIds: value != null ? value.map((user) => user.userId) : [],
        modifiedByUserId: userId,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  function handleRequestVerificationOfPPIChanged(e: any) {
    if (survey === undefined) {
      return;
    }

    editSurveyVerificationPPI(
      {
        guid: survey.guid,
        requestVerificationOfPPI: e.target.checked,
        modifiedByUserId: userId,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  async function handleDeleteClicked() {
    if (survey == null) {
      return;
    }

    try {
      await confirm({
        title: "Delete Survey",
        content: "Are you sure you want to delete this survey?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteSurvey(
      {
        guid: survey.guid,
      },
      {
        onSuccess: () => {
          closeDrawer();
          navigate(Links.Surveys());
        },
      }
    );
  }

  return (
    <Stack sx={{ py: 0.5, overflow: "auto" }}>
      <Stack spacing={1}>
        <Stack spacing={1} sx={{ px: 1 }}>
          <Stack spacing={0.25}>
            <Typography variant="body2">Basic Settings</Typography>
            <Divider />
          </Stack>
          <Stack>
            <SmallInputLabel>Survey Name</SmallInputLabel>
            <TextField
              sx={{ backgroundColor: theme.palette.common.white }}
              disabled={isDisabledField}
              onBlur={handleNameChanged}
              name="name"
              defaultValue={survey?.name}
            />
          </Stack>
          <Stack>
            <SmallInputLabel>Survey Description</SmallInputLabel>
            <TextField
              multiline
              sx={{ backgroundColor: theme.palette.common.white }}
              disabled={isDisabledField}
              onBlur={handleDescriptionChanged}
              name="description"
              defaultValue={survey?.description}
            />
          </Stack>
          <Stack>
            <SmallInputLabel>Administrators</SmallInputLabel>
            <Autocomplete
              onChange={handleAdministratorsChanged}
              sx={{ backgroundColor: theme.palette.common.white }}
              multiple
              isLoading={isLoadingUsers}
              disabled={isLoadingUsers || isDisabledField}
              options={users || []}
              getOptionLabel={(option) => option.displayName}
              getOptionDisabled={(option) =>
                isLastAdministrator && survey.administrators.some((admin) => admin.userId === option.userId)
              }
              renderOption={(props, option: any) => (
                <Box sx={{ pl: "10px !important" }} component="li" {...props} key={option.userId}>
                  <Stack>
                    <Typography>{option.displayName}</Typography>
                    <Typography variant="caption">{option.email}</Typography>
                  </Stack>
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select people" inputProps={{ ...params.inputProps }} />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip label={option.displayName} {...getTagProps({ index })} disabled={isLastAdministrator} />
                ))
              }
              value={survey.administrators || []}
              isOptionEqualToValue={(option, value) => option.userId === value?.userId}
            />
          </Stack>
          <Divider variant="dashed" />
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Switch
              name="request"
              onChange={handleRequestVerificationOfPPIChanged}
              disabled={isDisabledField}
              checked={survey?.requestVerificationOfPPI}
            />
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography>Request Verification of Personal Profile Information</Typography>
              <Tooltip
                title={
                  <Stack direction="row" spacing={0.5} sx={{ alignItems: "baseline" }}>
                    <InfoOutlined sx={{ position: "relative", top: "2px" }} />
                    <Typography variant="caption">
                      Respondents will be asked to verify personal profile information: location, date joined, and home
                      team before proceeding with the survey.
                    </Typography>
                  </Stack>
                }
              >
                <InfoOutlined />
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
        <SurveyInstancesList instances={survey.surveyInstances} surveyGuid={survey.guid} />
      </Stack>
      <Button
        startIcon={<DeleteOutlined />}
        onClick={handleDeleteClicked}
        variant="tertiary"
        color="error"
        sx={{ alignSelf: "end", mx: 1, my: 0.5 }}
      >
        Delete Survey
      </Button>
    </Stack>
  );
};

export { SurveySettingsDrawerContent };
