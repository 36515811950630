import { useMsal } from "@azure/msal-react";

const useAccessToken = () => {
  const { instance } = useMsal();

  async function getAccessToken() {
    const account = instance.getActiveAccount();

    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await instance.acquireTokenSilent({
      account: account,
      scopes: ["api://0d84e22a-e1f3-4a40-9e92-cf70356a1cc9/access_as_user"],
    });

    return response.accessToken;
  }

  return {
    getAccessToken,
  };
};

export { useAccessToken };
