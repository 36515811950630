import { Box, SxProps } from "@mui/material";

interface IProps {
  sx?: SxProps;
}

const HistoryLedgerItemEmptyValue = (props: IProps) => {
  return (
    <Box component="span" sx={{ fontStyle: "italic", ...props.sx }}>
      none
    </Box>
  );
};

export { HistoryLedgerItemEmptyValue };
