import { Skeleton, Stack } from "@mui/material";

const TextResponseSkeleton = () => {
  return (
    <Stack spacing={0.5}>
      <Skeleton variant="rectangular" height={24} width={120} />
      <Skeleton variant="rectangular" height={24} width={150} />
      <Skeleton variant="rectangular" height={72} />
    </Stack>
  );
};

export { TextResponseSkeleton };
