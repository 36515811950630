import { Box, Button, Link, Stack, styled, SxProps, Tooltip } from "@mui/material";
import { ConnectionState, ConnectionType, Links, PlanFieldType } from "../constants";
import { IInformedBy } from "../models";
import { ISyncStateAlertProps, SyncStateAlert } from "./SyncStateAlert";
import { Typography } from "./Typography";

const InformedByContainer = styled(Box)`
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;

  > * {
    vertical-align: top;
  }
`;

const InformedByLabel = styled(Typography)`
  font-size: 0.75rem;
  display: inline;
  margin-right: ${({ theme }) => theme.spacing(0.2)};
`;

InformedByLabel.defaultProps = {
  noWrap: true,
};

const TeamNameText = styled(InformedByLabel)`
  font-weight: 700;
  text-transform: uppercase;
`;

interface IProps {
  informedBy: IInformedBy;
  readOnly?: boolean;
  disabled?: boolean;
  faded?: boolean;
  SyncStateAlertProps?: ISyncStateAlertProps;
  sx?: SxProps;
  editInformedByClicked?: () => void;
}

const InformedByTag = (props: IProps) => {
  return (
    <Stack direction="row" sx={{ alignItems: "center", position: "relative", zIndex: 2, ...props.sx }}>
      <Tooltip
        placement="top-start"
        enterDelay={1000}
        enterNextDelay={1000}
        leaveDelay={500}
        title={
          <Typography sx={{ fontSize: 12, lineHeight: "18px" }}>
            <Box component="span" sx={{ textTransform: "uppercase" }}>
              Connected to{" "}
            </Box>
            {props.disabled ? (
              <Box component="span" sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
                {props.informedBy.teamName}
              </Box>
            ) : (
              <Link
                href={Links.Team(props.informedBy.teamSlug)}
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                {props.informedBy.teamName}
              </Link>
            )}
            {props.informedBy.type === ConnectionType.Strategy
              ? ` ${PlanFieldType.Strategy} `
              : ` ${PlanFieldType.Priority} `}
            {props.disabled ? (
              <>{props.informedBy.description}</>
            ) : (
              <Link
                href={
                  props.informedBy.type === ConnectionType.Strategy
                    ? Links.StrategyDetail(props.informedBy.teamSlug, props.informedBy.guid)
                    : Links.KeyInitiativeDetail(props.informedBy.teamSlug, props.informedBy.guid)
                }
              >
                {props.informedBy.description}
              </Link>
            )}
          </Typography>
        }
      >
        <InformedByContainer>
          {props.readOnly || props.editInformedByClicked == null ? (
            <InformedByLabel sx={{ textTransform: "uppercase" }} faded={props.faded}>
              Connected to
            </InformedByLabel>
          ) : (
            <Button
              variant="text"
              onClick={props.editInformedByClicked}
              disableRipple
              sx={{
                padding: 0,
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <InformedByLabel>Connected to</InformedByLabel>
            </Button>
          )}

          {props.disabled ? (
            <TeamNameText faded={props.faded}>{props.informedBy.teamName}</TeamNameText>
          ) : (
            <Link href={Links.Team(props.informedBy.teamSlug)} onClick={(e) => e.stopPropagation()}>
              <TeamNameText faded={props.faded}>{props.informedBy.teamName}</TeamNameText>
            </Link>
          )}

          <InformedByLabel faded={props.faded}>
            {props.informedBy.type === ConnectionType.Strategy ? PlanFieldType.Strategy : PlanFieldType.Priority}
          </InformedByLabel>

          {props.disabled ? (
            <InformedByLabel faded={props.faded}>{props.informedBy.description}</InformedByLabel>
          ) : (
            <Link
              href={
                props.informedBy.type === ConnectionType.Strategy
                  ? Links.StrategyDetail(props.informedBy.teamSlug, props.informedBy.guid)
                  : Links.KeyInitiativeDetail(props.informedBy.teamSlug, props.informedBy.guid)
              }
              onClick={(e) => e.stopPropagation()}
            >
              <InformedByLabel faded={props.faded}>{props.informedBy.description}</InformedByLabel>
            </Link>
          )}
        </InformedByContainer>
      </Tooltip>

      {!props.disabled && props.informedBy.syncState !== ConnectionState.InSync && (
        <SyncStateAlert {...props.SyncStateAlertProps} informedBy={props.informedBy} />
      )}
    </Stack>
  );
};

export { InformedByTag };
