import { BookmarkBorderOutlined, HubOutlined } from "@mui/icons-material";
import { Stack, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useSession } from "../../hooks";
import { useGetTeams } from "../../http";
import { ITeamSummary } from "../../models";
import { SideMenuTeamList } from "./SideMenuTeamList";

const ScrollBox = styled(Stack)`
  padding-right: 5px;
  overscroll-behavior: contain;
  height: "100%";
  min-height: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 50px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
`;

const SideMenuTeamsList = () => {
  const { userId } = useSession();
  const { isLoading, data: teams } = useGetTeams(userId);
  const [myTeams, setMyTeams] = useState<ITeamSummary[]>([]);
  const [followedTeams, setFollowedTeams] = useState<ITeamSummary[]>([]);

  useEffect(() => {
    if (!isLoading && teams) {
      setMyTeams(teams.filter((x) => x.isMember));
      setFollowedTeams(teams.filter((x) => !x.isMember && x.isFollowing));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, teams]);

  return (
    <ScrollBox spacing={0.5}>
      <SideMenuTeamList
        isLoading={isLoading}
        icon={
          <HubOutlined
            style={{
              height: 24,
              width: 24,
              margin: "8px",
            }}
          />
        }
        title="My Teams"
        teams={myTeams}
      />
      <SideMenuTeamList
        isLoading={isLoading}
        icon={
          <BookmarkBorderOutlined
            style={{
              height: 24,
              width: 24,
              margin: "8px",
            }}
          />
        }
        title="Following"
        teams={followedTeams}
      />
    </ScrollBox>
  );
};

export { SideMenuTeamsList };
