import { Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { QuestionType } from "../../../../constants";
import { GetSurveyCoherenceReportResultDto } from "../../../../http";
import { BinaryResult } from "./BinaryResult";
import { LinearScaleResult } from "./LinearScaleResult";
import { LinearScaleResultSkeleton } from "./LinearScaleResultSkeleton";
import { QuestionFilter } from "./QuestionFilter";

interface IProps {
  isLoading: boolean;
  report: GetSurveyCoherenceReportResultDto | undefined;
}

const LinearScaleResults = (props: IProps) => {
  const [selectedQuestionGuids, setSelectedQuestionGuids] = useState<string[]>([]);

  useEffect(() => {
    // Select all questions by default.
    setSelectedQuestionGuids(
      Object.entries(props.report?.questions ?? {})
        .filter(([, question]) => question.questionTypeId === QuestionType.LinearScale)
        .map(([questionGuid]) => questionGuid)
    );
  }, [props.report]);

  return (
    <Stack>
      <QuestionFilter
        questions={Object.entries(props.report?.questions ?? {})
          .filter(([, question]) => question.questionTypeId === QuestionType.LinearScale)
          .map(([questionGuid, question], i) => ({
            questionGuid: questionGuid,
            questionName: question.name,
            questionTags: question.tags,
          }))}
        selectedQuestionGuids={selectedQuestionGuids}
        onChange={(values) => setSelectedQuestionGuids(values)}
        disabled={props.isLoading || props.report === undefined}
      />
      <Divider />
      <Stack spacing={1} divider={<Divider variant="dashed" sx={{ color: "#c9c9c9" }} />} sx={{ m: 1 }}>
        {props.isLoading ? (
          <LinearScaleResultSkeleton />
        ) : props.report?.linearScaleResponses.some((x) => selectedQuestionGuids.includes(x.questionGuid)) ? (
          props.report?.linearScaleResponses
            .filter((x) => selectedQuestionGuids.includes(x.questionGuid))
            .map((x, index) => {
              const question = props.report?.questions[x.questionGuid];

              if (question === undefined) {
                return null;
              }

              if (question.maxPoints === 2) {
                return (
                  <BinaryResult
                    key={index}
                    question={{
                      name: question.name,
                      tags: question.tags,
                      maxPoints: question.maxPoints,
                      minLabel: question.minLabel,
                      maxLabel: question.maxLabel,
                    }}
                    selectedTeamsAverage={x.selectedTeamsAverage}
                    comparisonTeamsAverage={x.comparisonTeamsAverage}
                  />
                );
              } else {
                return (
                  <LinearScaleResult
                    key={index}
                    question={{
                      name: question.name,
                      tags: question.tags,
                      maxPoints: question.maxPoints,
                      minLabel: question.minLabel,
                      maxLabel: question.maxLabel,
                    }}
                    selectedTeamsAverage={x.selectedTeamsAverage}
                    comparisonTeamsAverage={x.comparisonTeamsAverage}
                  />
                );
              }
            })
        ) : (
          <Typography>No responses for selected questions and entities.</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export { LinearScaleResults };
