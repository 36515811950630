import { Box, Button, Stack, SxProps } from "@mui/material";
import { useNavigate } from "react-router";
import { Paths } from "../../constants";
import { Typography } from "../Typography";

interface IProps {
  sx?: SxProps;
}

const ForbiddenError = (props: IProps) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", ...props.sx }}>
      <Stack spacing={0.25} sx={{ alignItems: "center", textAlign: "center", width: "fit-content" }}>
        <Typography variant="h3">403</Typography>
        <Typography variant="h5">Access denied.</Typography>
        <Typography>What were you looking for?</Typography>
        <Box>
          <Button variant="contained" sx={{ mt: 0.75, width: "fit-content" }} onClick={() => navigate(Paths.Search)}>
            Search
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export { ForbiddenError };
