import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, IssueDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetIssuesRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getIssueRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.issues.getIssueDetail>>): Promise<IssueDto> {
    const accessToken = await getAccessToken();
    const [{ guid }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/issues/${guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getIssueRequest };
};

const useGetIssueDetail = (guid: string) => {
  const { getIssueRequest } = useGetIssuesRequest();

  return useQuery({
    queryKey: queryKeys.issues.getIssueDetail(guid),
    queryFn: getIssueRequest,
  });
};

export { useGetIssueDetail };
