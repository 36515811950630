import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditActionItemContextDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  contextActionItemHistoryId?: number;
  modifiedByUserId: string;
};

const useEditIssueContextRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editIssueContextRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditActionItemContextDto = {
      contextActionItemHistoryId: variables.contextActionItemHistoryId,
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/issues/${variables.guid}/context`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editIssueContextRequest };
};

const useEditIssueContext = () => {
  const { editIssueContextRequest } = useEditIssueContextRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editIssueContextRequest,
    onSuccess: (_, variables) => {
      queryClient.refetchQueries(queryKeys.issues.getIssueDetail(variables.guid));
      queryClient.refetchQueries(queryKeys.issues.getIssueFeed(variables.guid));
    },
  });
};

export { useEditIssueContext };
