import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { IMetricDetail } from "../../../models";
import { FetchError, GetMetricDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetMetricRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getMetricRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.metrics.getMetric>>): Promise<GetMetricDto> {
    const accessToken = await getAccessToken();
    const [{ guid }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/metrics/${guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getMetricRequest };
};

const useGetMetric = (guid: string) => {
  const { getMetricRequest } = useGetMetricRequest();

  return useQuery({
    queryKey: queryKeys.metrics.getMetric(guid),
    queryFn: getMetricRequest,
    select: (data): IMetricDetail => {
      return {
        guid: data.guid,
        teamName: data.teamName,
        teamSlug: data.teamSlug,
        title: data.title,
        description: data.description,
        datasets: data.datasets.map((dataset) => ({
          guid: dataset.guid,
          title: dataset.title,
          color: dataset.color,
          dotted: dataset.dotted,
          data: dataset.data.map((dataPoint) => ({
            date: DateTime.fromISO(dataPoint.date, { zone: "utc" }),
            rowGuid: dataPoint.rowGuid,
            value: dataPoint.value,
          })),
          sortOrder: dataset.sortOrder,
        })),
        sortOrder: data.sortOrder,
        dataLastModifiedByDisplayName: data.dataLastModifiedByDisplayName,
        dataLastModifiedDateTimeUtc: DateTime.fromISO(data.dataLastModifiedDateTimeUtc, { zone: "utc" }),
        historyLedger: data.historyLedger.map((x) => ({
          changes: x.changes,
          changeType: x.changeType,
          timestampUtc: DateTime.fromISO(x.timestampUtc, { zone: "utc" }),
          user: x.user,
        })),
        archived: false,
      };
    },
  });
};

export { useGetMetric };
