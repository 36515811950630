import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Outlet } from "react-router";
import { DialogProvider } from "./DialogProvider";
import { SessionProvider } from "./session";

const PrivateRoute = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <SessionProvider>
        <DialogProvider>
          <Outlet />
        </DialogProvider>
      </SessionProvider>
    </MsalAuthenticationTemplate>
  );
};

export { PrivateRoute };
