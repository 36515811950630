import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { CreateSurveyQuestionDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";


type Variables = {
  surveyGuid: string;
  name?: string;
  sortOrder?: string;
  description?: string,
  questionTypeId: number,
  scalePoints?: number,
  includeNotApplicableOption?: boolean,
  minLabel?: string,
  maxLabel?: string,
};

const useCreateSurveyQuestionRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createSurveyQuestionRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: CreateSurveyQuestionDto = {
      surveyGuid: variables.surveyGuid,
      name: "",
      sortOrder: variables.sortOrder,
      questionTypeId: variables.questionTypeId,
      scalePoints: 10,
      includeNotApplicableOption: true,
      minLabel: "Fully Disagree",
      maxLabel: "Fully Agree",
      description: ""
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-questions`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response;
  }

  return { createSurveyQuestionRequest };
};

const useCreateSurveyQuestion = () => {
  const { createSurveyQuestionRequest } = useCreateSurveyQuestionRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSurveyQuestionRequest,
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurveyQuestions(variables.surveyGuid) });
    },
  });
}

export { useCreateSurveyQuestion };