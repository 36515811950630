import { Typography } from "@mui/material";
import { EditHintCard } from "../EditHintCard";

const StrategyPlanHintCard = () => {
  return (
    <EditHintCard title="Strategy Plan">
      <Typography variant="body1">
        Documenting your strategy allows all members of your team to understand the big picture: why your team exists,
        where you want to be in the next few years, and what your immediate goals and priorities are.
      </Typography>
    </EditHintCard>
  );
};

export { StrategyPlanHintCard };
