import { BarChartRounded, FilterListOffRounded, IosShareRounded } from "@mui/icons-material";
import { Button, Card, CardContent, Grid, Stack, styled } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { useState } from "react";
import { useParams } from "react-router";
import { ColumnFiltersButton, SurveyInstancePicker, TeamFilterButton } from "../../../components";
import { GetSurveyResponsesReportResultDto, useGetSurveyAnswersReport } from "../../../http";
import { setTitlePrefix } from "../../../utilities";

const ToolBar = styled(Stack)`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: -20px;
  margin-bottom: 20px;
  justify-content: space-between;
  left: -20px;
  width: calc(100% + 40px);
  min-height: 50px;
  padding: 6px 10px;
  background: rgba(248, 249, 250, 0.7);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
`;

const GridWrapper = styled(CardContent)`
  padding: 0 !important;

  .MuiDataGrid-root {
    border: none;
  }

  .MuiDataGrid-columnHeader {
    font-weight: 500;
    font-size: 14px;
  }

  .MuiDataGrid-virtualScroller {
    min-height: 60px;
  }
`;

interface IParams {
  guid: string;
}

function handleGenerateColumns({ data }: GetSurveyResponsesReportResultDto) {
  const headers = [
    { field: "responseGuid", headerName: "Response ID", width: 120 },
    { field: "instanceGuid", headerName: "Instance ID", width: 300 },
    { field: "submittedDateTimeUtc", headerName: "Submission Time", width: 220 },
    { field: "teamId", headerName: "Team ID" },
    { field: "teamName", headerName: "Team Name", width: 220 },
    { field: "dateJoined", headerName: "Date Joined", width: 220 },
    { field: "location", headerName: "Location", width: 220 },
  ];

  const questionHeaders = data.columns.map((column, index) => ({
    field: `question${index + 1}`,
    headerName: column.header,
    width: 220,
  }));

  return [...headers, ...questionHeaders];
}

function handleGenerateRows({ data }: GetSurveyResponsesReportResultDto) {
  return data.rows.map((row) => {
    const newRow: Record<string, any> = {
      responseGuid: row.responseGuid,
      instanceGuid: row.instanceGuid,
      submittedDateTimeUtc: DateTime.fromISO(row.submittedDateTimeUtc, { zone: "utc" })
        .toLocal()
        .toLocaleString(DateTime.DATETIME_FULL),
      teamId: row.teamGuid,
      teamName: row.teamName,
      dateJoined: DateTime.fromISO(row.joinedDate, { zone: "utc" }).toLocal().toLocaleString(DateTime.DATE_MED),
      location: `${row.countryCode}${row.cityName ? `, ${row.cityName}` : ""}`,
    };

    data.columns.forEach((column, index) => {
      const questionKey = `question${index + 1}`;
      const answer = row.answers.find((answer) => answer.questionGuid === column.guid);
      newRow[questionKey] = answer?.value || "";
    });

    return newRow;
  });
}

const SurveyResponsesPage = () => {
  setTitlePrefix("Survey Responses");

  const { guid: surveyGuid } = useParams<keyof IParams>() as IParams;
  const [surveyInstanceGuid, setSurveyInstanceGuid] = useState<string | undefined>(undefined);
  const { data: answersReport, isLoading: isLoadingAnswersReport } = useGetSurveyAnswersReport(
    surveyGuid,
    surveyInstanceGuid
  );
  const columns = answersReport ? handleGenerateColumns(answersReport) : [];
  const rows = answersReport ? handleGenerateRows(answersReport) : [];
  const hiddenColumns = ["instanceId", "responseId", "teamId"];
  const apiRef = useGridApiRef();

  function handleClearFiltersClicked() {
    setSurveyInstanceGuid(undefined);
    apiRef.current.setFilterModel({
      items: [],
    });
  }

  function handleExportClicked() {
    apiRef.current.exportDataAsCsv({
      fileName: `survey-responses-${DateTime.now().toISODate()}`,
    });
  }

  return (
    <Grid>
      <ToolBar>
        <Stack direction="row" spacing={1}>
          <SurveyInstancePicker
            surveyGuid={surveyGuid}
            surveyInstanceGuid={surveyInstanceGuid}
            onChange={setSurveyInstanceGuid}
          />
          <TeamFilterButton apiRef={apiRef} />
          <ColumnFiltersButton apiRef={apiRef} columns={columns} hiddenColumns={hiddenColumns} />
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button variant="tertiary" startIcon={<FilterListOffRounded />} onClick={handleClearFiltersClicked}>
            Clear Filters
          </Button>
          <Button variant="tertiary" startIcon={<IosShareRounded />} onClick={handleExportClicked}>
            Export CSV
          </Button>
          <Button variant="tertiary" startIcon={<BarChartRounded />} disabled>
            Add to public report
          </Button>
        </Stack>
      </ToolBar>

      <Stack spacing={1}>
        <Card>
          <GridWrapper>
            <DataGridPro
              apiRef={apiRef}
              columns={columns}
              columnVisibilityModel={{
                teamId: false,
                instanceGuid: false,
              }}
              rows={rows ?? []}
              loading={isLoadingAnswersReport}
              getRowId={(row) => row.responseGuid}
              pagination
              rowHeight={50}
              disableColumnMenu
              disableColumnFilter
              disableRowSelectionOnClick
              sx={{
                ".MuiDataGrid-cell": {
                  fontWeight: "normal",
                },
              }}
            />
          </GridWrapper>
        </Card>
      </Stack>
    </Grid>
  );
};

export { SurveyResponsesPage };
