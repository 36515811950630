import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { CreateTeamModal, HeaderLayout } from "../../components";
import { Links } from "../../constants";
import { setTitlePrefix } from "../../utilities";
import { OrganizationNetworkGraph } from "./components";

const HomePage = () => {
  setTitlePrefix();

  const navigate = useNavigate();
  const [isCreateTeamModalVisible, setIsCreateTeamModalVisible] = useState(false);

  function handleTeamCreated(slug: string) {
    setIsCreateTeamModalVisible(false);
    navigate(Links.Team(slug));
  }

  return (
    <HeaderLayout
      title={<Typography variant="h3">Home</Typography>}
      actions={
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Button variant="contained" sx={{ whiteSpace: "nowrap" }} onClick={() => setIsCreateTeamModalVisible(true)}>
            Add Team
          </Button>
        </Stack>
      }
      contentContainerProps={{
        sx: { p: 0 },
      }}
    >
      <OrganizationNetworkGraph />
      <CreateTeamModal
        visible={isCreateTeamModalVisible}
        onCreated={handleTeamCreated}
        onCancel={() => setIsCreateTeamModalVisible(false)}
      />
    </HeaderLayout>
  );
};

export { HomePage };
