import { BarChartRounded } from "@mui/icons-material";
import { CardContent, Grid, Stack } from "@mui/material";
import { CardLink, HeaderLayout, Typography } from "../../../components";
import { Paths } from "../../../constants";
import { setTitlePrefix } from "../../../utilities";

interface IReport {
  title: string;
  description: string;
  href: string;
}

const reports: IReport[] = [
  {
    title: "Organization Report",
    description: "Report on organizational structure, including teams, roles, people and all associated data.",
    href: Paths.OrganizationReport,
  },
  {
    title: "Coherence Report",
    description: "A multidimensional employee experience feedback loop report.",
    href: Paths.CoherenceReport,
  },
];

const ReportsPage = () => {
  setTitlePrefix("Reports");

  return (
    <HeaderLayout
      title={
        <Stack spacing={0.25}>
          <Typography variant="h3">Reports</Typography>
          <Typography>Analyze and Export Data</Typography>
        </Stack>
      }
    >
      <Grid container alignItems="center">
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={6}>
          <Stack spacing={{ xs: 0.5, sm: 1 }}>
            {reports.map((report, index) => (
              <CardLink key={index} href={report.href}>
                <CardContent>
                  <Stack direction="row" alignItems="center" spacing={0.25}>
                    <BarChartRounded fontSize="large" />
                    <Typography variant="h5">{report.title}</Typography>
                  </Stack>
                  <Typography sx={{ ml: "29px" }}>{report.description}</Typography>
                </CardContent>
              </CardLink>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </HeaderLayout>
  );
};

export { ReportsPage };
