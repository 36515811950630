import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditActionItemDto, FetchError, GetActionItemDto, GetActionItemSummaryDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  description: string;
  modifiedByUserId: string;
};

const useEditActionItemRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editActionItemRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: EditActionItemDto = {
      description: variables.description,
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/action-items/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editActionItemRequest };
};

const useEditActionItem = () => {
  const { editActionItemRequest } = useEditActionItemRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editActionItemRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
      await queryClient.cancelQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));

      // Snapshot the current cache value
      const cachedActionItemSummaryData = queryClient.getQueryData<GetActionItemSummaryDto>(
        queryKeys.actionItems.getActionItemSummary(variables.guid)
      );
      const cachedActionItemDetailData = queryClient.getQueryData<GetActionItemDto>(
        queryKeys.actionItems.getActionItemDetail(variables.guid)
      );

      if (cachedActionItemSummaryData) {
        // Optimistically update the cache
        const optimisticallyUpdatedActionItemSummaryData: GetActionItemSummaryDto = {
          ...cachedActionItemSummaryData,
          description: variables.description,
        };

        queryClient.setQueryData(
          queryKeys.actionItems.getActionItemSummary(variables.guid),
          optimisticallyUpdatedActionItemSummaryData
        );
      }

      if (cachedActionItemDetailData) {
        // Optimistically updated the cache
        const optimisticallyUpdatedActionItemDetailsData: GetActionItemDto = {
          ...cachedActionItemDetailData,
          description: variables.description,
        };

        queryClient.setQueryData(
          queryKeys.actionItems.getActionItemDetail(variables.guid),
          optimisticallyUpdatedActionItemDetailsData
        );
      }

      return { cachedActionItemSummaryData, cachedActionItemDetailData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(
        queryKeys.actionItems.getActionItemSummary(variables.guid),
        context?.cachedActionItemSummaryData
      );
      queryClient.setQueryData(
        queryKeys.actionItems.getActionItemDetail(variables.guid),
        context?.cachedActionItemDetailData
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemFeed(variables.guid));
    },
  });
};

export { useEditActionItem };
