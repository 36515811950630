import { Box, Grid as MuiGrid, Stack, styled } from "@mui/material";
import { Links } from "../../../../constants";
import { IPlan } from "../../../../models";
import { ViewCard } from "./ViewCard";
import { ViewCondensedPlan } from "./ViewCondensedPlan";
import { ViewNow } from "./ViewNow";
import { ViewWhere } from "./ViewWhere";
import { ViewWhy } from "./ViewWhy";

const GridContainer = styled(MuiGrid)<{ $isEmbedded: boolean; $isCondensed: boolean }>`
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  justify-content: ${({ $isEmbedded }) => ($isEmbedded ? "flex-start" : "flex-end")};
  pointer-events: none;

  & > .MuiGrid-item {
    transition: ${({ theme }) => theme.transitions.create(["transform", "opacity"])};

    & > div {
      display: ${({ $isEmbedded, $isCondensed }) => ($isCondensed ? ($isEmbedded ? "none" : undefined) : undefined)};
    }

    &:nth-child(1) {
      transform: ${({ $isEmbedded, $isCondensed }) =>
        $isCondensed ? ($isEmbedded ? "translateX(0)" : "translateX(200%)") : "translateX(0)"};
      opacity: ${({ $isCondensed }) => ($isCondensed ? 0 : 1)};
    }

    &:nth-child(2) {
      transform: ${({ $isEmbedded, $isCondensed }) =>
        $isCondensed ? ($isEmbedded ? "translateX(-100%)" : "translateX(100%)") : "translateX(0)"};
      opacity: ${({ $isCondensed }) => ($isCondensed ? 0 : 1)};
    }

    &:nth-child(3) {
      z-index: 2;
      transform: ${({ $isEmbedded, $isCondensed }) =>
        $isCondensed ? ($isEmbedded ? "translateX(-200%)" : "translateX(0)") : "translateX(0)"};
      opacity: ${({ $isEmbedded, $isCondensed }) => ($isCondensed ? ($isEmbedded ? 0 : 1) : 1)};
    }
  }
`;

const OpacityBox = styled(Box)<{ $isVisible: boolean }>`
  grid-column: 1;
  grid-row: 1;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: ${({ theme }) => theme.transitions.create("opacity")};
  pointer-events: ${({ $isVisible }) => ($isVisible ? "auto" : "none")};
`;

GridContainer.defaultProps = {
  container: true,
  spacing: 1,
};

const GridItem = styled(MuiGrid)`
  transition: ${({ theme }) => theme.transitions.create("all")};
  pointer-events: auto;
`;

GridItem.defaultProps = {
  item: true,
};

interface IProps {
  isLoading: boolean;
  isEmbedded: boolean;
  isCondensed: boolean;
  rowView?: boolean;
  faded?: boolean;
  plan: IPlan | undefined;
}

const ViewPlan = (props: IProps) => {
  let linksDisabled = props.plan ? !props.plan.isPublished : false;

  return (
    <GridContainer $isCondensed={props.isCondensed} $isEmbedded={props.isEmbedded}>
      <GridItem xs={12} md={props.rowView ? 12 : props.isCondensed || props.isEmbedded ? 3 : 4}>
        <ViewCard
          title="Why"
          faded={props.faded}
          chip={props.isEmbedded && !props.isLoading ? props.plan?.teamName : undefined}
          chipLink={props.isEmbedded && !props.isLoading && props.plan ? Links.Team(props.plan.teamSlug) : undefined}
          elevation={props.isEmbedded ? 0 : undefined}
        >
          <ViewWhy isLoading={props.isLoading} disabled={linksDisabled} faded={props.faded} plan={props.plan} />
        </ViewCard>
      </GridItem>
      <GridItem xs={12} md={props.rowView ? 12 : props.isCondensed || props.isEmbedded ? 3 : 4}>
        <ViewCard
          title="Where"
          faded={props.faded}
          chip={props.isEmbedded && !props.isLoading ? props.plan?.teamName : undefined}
          chipLink={props.isEmbedded && !props.isLoading && props.plan ? Links.Team(props.plan.teamSlug) : undefined}
          elevation={props.isEmbedded ? 0 : undefined}
        >
          <ViewWhere isLoading={props.isLoading} disabled={linksDisabled} faded={props.faded} plan={props.plan} />
        </ViewCard>
      </GridItem>
      <GridItem xs={12} md={props.rowView ? 12 : props.isCondensed || props.isEmbedded ? 3 : 4}>
        {props.isEmbedded && (
          <OpacityBox $isVisible={!props.isCondensed} sx={{ zIndex: 1 }}>
            <Stack spacing={1}>
              <ViewCard
                title="Now"
                faded={props.faded}
                chip={props.isEmbedded && !props.isLoading ? props.plan?.teamName : undefined}
                chipLink={
                  props.isEmbedded && !props.isLoading && props.plan ? Links.Team(props.plan.teamSlug) : undefined
                }
                elevation={props.isEmbedded ? 0 : undefined}
              >
                <ViewNow isLoading={props.isLoading} plan={props.plan} />
              </ViewCard>
            </Stack>
          </OpacityBox>
        )}

        {!props.isEmbedded && (
          <Box style={{ display: "grid", gridTemplateColumns: "100%" }}>
            <OpacityBox $isVisible={!props.isCondensed} sx={{ zIndex: 1 }}>
              <ViewCard title="Now" faded={props.faded} chip={!props.isLoading ? props.plan?.teamName : undefined}>
                <ViewNow isLoading={props.isLoading} disabled={linksDisabled} faded={props.faded} plan={props.plan} />
              </ViewCard>
            </OpacityBox>

            <OpacityBox $isVisible={props.isCondensed} sx={{ zIndex: 2 }}>
              <ViewCondensedPlan
                isLoading={props.isLoading}
                disabled={linksDisabled}
                faded={props.faded}
                plan={props.plan}
              />
            </OpacityBox>
          </Box>
        )}
      </GridItem>
    </GridContainer>
  );
};

export { ViewPlan };
