import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { DeleteMetricDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  teamSlug: string;
  modifiedByUserId: string;
};

const useDeleteMetricRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteMetricRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: DeleteMetricDto = {
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/metrics/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteMetricRequest };
};

const useDeleteMetric = () => {
  const { deleteMetricRequest } = useDeleteMetricRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteMetricRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeam(variables.teamSlug) });
      await queryClient.refetchQueries({ queryKey: queryKeys.metrics.getMetricsForTeam(variables.teamSlug) });
    },
  });
};

export { useDeleteMetric };
