import { InputLabel, styled } from "@mui/material";

const SmallInputLabel = styled(InputLabel)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  transform: none;

  &.Mui-focused {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export { SmallInputLabel };
