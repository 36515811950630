import { Grid } from "@mui/material";

const SurveyActionsPage = () => {
  return (
    <Grid container spacing={1} sx={{ justifyContent: "center" }}>
      <span>SurveyActionsPage</span>
    </Grid>
  );
};

export { SurveyActionsPage };