class FetchError extends Error {
  status: number;
  statusText: string;

  constructor(response: Response) {
    super();

    this.name = "FetchError";
    this.status = response.status;
    this.statusText = response.statusText;
  }
}

export { FetchError };
