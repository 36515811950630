import {useAccessToken} from "../../use-access-token";
import {QueryFunctionContext, useQuery} from "@tanstack/react-query";
import {queryKeys} from "../queryKeys";
import {FetchError, GetSurveyQuestionsDto} from "../../types";
import {configuration} from "../../../configuration";
import {SurveyQuestion} from "../../../models";

const useGetSurveyQuestionsRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getSurveyQuestionsRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.surveys.getSurveyQuestions>>): Promise<GetSurveyQuestionsDto> {
    const accessToken = await getAccessToken();
    const [{ guid }] = queryKey;
    const url = new URL(`${configuration.apiRootUrl}/surveys/${guid}/questions`);

    const response = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getSurveyQuestionsRequest };
}

const useGetSurveyQuestions = (guid: string) => {
  const { getSurveyQuestionsRequest } = useGetSurveyQuestionsRequest();

  return useQuery({
    queryKey: queryKeys.surveys.getSurveyQuestions(guid),
    queryFn: getSurveyQuestionsRequest,
    select: (data): SurveyQuestion[] => data});
};

export { useGetSurveyQuestions };
