import { Box, BoxProps } from "@mui/material";

const CodeSpinnerIcon = (props: BoxProps) => {
  return (
    <Box component="object" type="image/svg+xml" data="/svg/spinner.svg" width={100} {...props}>
      Loading Spinner Animation
    </Box>
  );
};

export { CodeSpinnerIcon };
