import { MovingRounded, PublicRounded, RocketLaunchRounded, TrackChangesRounded } from "@mui/icons-material";
import {
  Link as MuiLink,
  List,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  styled,
} from "@mui/material";

const Link = styled(MuiLink)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;

  &:last-child > .MuiListItem-root {
    padding-bottom: 0;
  }
`;

const ListItem = styled(MuiListItem)`
  padding: 0 0 ${({ theme }) => theme.spacing(1)} 0;
`;

const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 32px;
`;

const ListItemText = styled(MuiListItemText)`
  & span {
    font-size: 0.875rem;
    text-transform: uppercase;
  }
`;

const EditTeamPlanSideMenu = () => {
  return (
    <List sx={{ p: 0 }}>
      <Link href="#why">
        <ListItem>
          <ListItemIcon>
            <PublicRounded fontSize="large" />
          </ListItemIcon>
          <ListItemText>Why</ListItemText>
        </ListItem>
      </Link>
      <Link href="#where">
        <ListItem>
          <ListItemIcon>
            <RocketLaunchRounded fontSize="large" />
          </ListItemIcon>
          <ListItemText>Where</ListItemText>
        </ListItem>
      </Link>
      <Link href="#now">
        <ListItem>
          <ListItemIcon>
            <TrackChangesRounded fontSize="large" />
          </ListItemIcon>
          <ListItemText>Now</ListItemText>
        </ListItem>
      </Link>
      <Link href="#metrics">
        <ListItem>
          <ListItemIcon>
            <MovingRounded fontSize="large" />
          </ListItemIcon>
          <ListItemText>Critical Metrics</ListItemText>
        </ListItem>
      </Link>
    </List>
  );
};

export { EditTeamPlanSideMenu };
