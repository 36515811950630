import { Alert as MuiAlert, AlertColor, styled } from "@mui/material";

const Alert = styled(MuiAlert)`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 12px;
  height: 30px;
  text-wrap: nowrap;
  z-index: 9999;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
`;

interface IProps {
  environment: string;
}

const EnvironmentAlert = (props: IProps) => {
  function getAlertSeverity(): AlertColor {
    switch (props.environment) {
      case "int":
        return "warning";
      default:
        return "info";
    }
  }

  return <Alert severity={getAlertSeverity()}>DEVELOPMENT BUILD {process.env.REACT_APP_VERSION}</Alert>;
};

export { EnvironmentAlert };
