import { alpha, InputBase, InputProps, styled, Typography } from "@mui/material";
import React from "react";

const Input = styled(InputBase)`
  label + & {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }

  & .MuiInputBase-input {
    border-radius: 10px;
    border: 0.5px solid;
    border-color: ${({ theme }) => alpha(theme.palette.grey[400], 0.3)};
    padding: ${({ theme }) => theme.spacing(0.5)};
  }

  &:hover .MuiInputBase-input {
    border: 1px solid;
    border-color: #000;
  }

  &:focus-within .MuiInputBase-input {
    border: 2px solid;
    margin: -1px;
    border-color: ${({ theme }) => theme.palette.turquoise.main};
  }
`;

interface IProps {
  helperText?: string;
}

const TextField = React.forwardRef((props: IProps & InputProps, ref: React.Ref<unknown>) => {
  const { helperText, ...inputProps } = props;

  return (
    <>
      <Input {...inputProps} multiline />
      {helperText && (
        <Typography variant="caption" sx={{ color: "error.main" }}>
          {helperText}
        </Typography>
      )}
    </>
  );
});

export { TextField };
