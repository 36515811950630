import { Typography } from "@mui/material";
import { CompletedStatusAnimation } from "../../assets/animations";
import { CommentSubject } from "../../constants";
import { StatusChangedDialogContent } from "../StatusChangedDialogContent";

interface IProps {
  actionItemGuid: string;
  onDone: () => void;
  onCancel: () => void;
}

const ActionItemStatusChangedDialogContent = (props: IProps) => {
  return (
    <StatusChangedDialogContent
      icon={<CompletedStatusAnimation />}
      title="Completed"
      content={
        <Typography>
          Congratulations! You've marked this card COMPLETED.
          <br />
          Please enter a comment with reasons for the change of status.
        </Typography>
      }
      commentSubjectType={CommentSubject.ActionItem}
      commentSubjectGuid={props.actionItemGuid}
      onDone={props.onDone}
      onCancel={props.onCancel}
    />
  );
};

export { ActionItemStatusChangedDialogContent };
