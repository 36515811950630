import { Link, Typography } from "@mui/material";
import {
  DefaultHistoryLedgerMappingStrategy,
  HistoryLedgerItemValue,
  IHistoryLedgerMappingStrategy,
} from "../../../../components";
import { Links } from "../../../../constants";
import { IHistoryLedgerItem, IHistoryLedgerItemChange, IHistoryLedgerItemTeamValue } from "../../../../models";

class TeamHistoryLedgerMappingStrategy implements IHistoryLedgerMappingStrategy {
  defaultStrategy: IHistoryLedgerMappingStrategy;
  showDeactivatedUserLabels?: boolean | undefined;

  constructor() {
    this.defaultStrategy = new DefaultHistoryLedgerMappingStrategy("Team");
    this.showDeactivatedUserLabels = false;
  }

  getCreatedText(historyLedgerItem: IHistoryLedgerItem) {
    const teamName = historyLedgerItem.changes.filter((x) => x.propertyName === "team name")[0].newValue;

    return (
      <Typography>
        {`Created `}
        <HistoryLedgerItemValue value={teamName} />
        {` team`}
      </Typography>
    );
  }

  getDeletedText(historyLedgerItem: IHistoryLedgerItem) {
    return this.defaultStrategy.getDeletedText(historyLedgerItem);
  }

  getEditedText(change: IHistoryLedgerItemChange): React.ReactNode {
    switch (change.propertyName) {
      case "team slug": {
        const newSlugValue = change.newValue as string;

        return (
          <Typography>
            {`Updated team url to `}
            <HistoryLedgerItemValue value={Links.Team(newSlugValue)} />
          </Typography>
        );
      }
      case "team": {
        const oldTeamValue = change.oldValue as IHistoryLedgerItemTeamValue;
        const newTeamValue = change.newValue as IHistoryLedgerItemTeamValue;

        if (oldTeamValue == null && newTeamValue != null) {
          return (
            <Typography>
              {`Added connection to `}
              <HistoryLedgerItemValue value={<Link href={Links.Team(newTeamValue.slug)}>{newTeamValue.name}</Link>} />
            </Typography>
          );
        }

        if (oldTeamValue != null && newTeamValue == null) {
          return (
            <Typography>
              {`Removed connection to `}
              <HistoryLedgerItemValue value={<Link href={Links.Team(oldTeamValue.slug)}>{oldTeamValue.name}</Link>} />
            </Typography>
          );
        }

        return (
          <Typography>
            {`Updated connection from `}
            <HistoryLedgerItemValue value={<Link href={Links.Team(oldTeamValue.slug)}>{oldTeamValue.name}</Link>} />
            {` to `}
            <HistoryLedgerItemValue value={<Link href={Links.Team(newTeamValue.slug)}>{newTeamValue.name}</Link>} />
          </Typography>
        );
      }

      default: {
        return this.defaultStrategy.getEditedText(change);
      }
    }
  }
}

export { TeamHistoryLedgerMappingStrategy };
