import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { CommentSubject } from "../../../constants";
import { CreateCommentDto, CreateCommentResultDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  subjectType: CommentSubject;
  subjectGuid: string;
  commentGuid: string;
  createdByUserId: string;
  text: string;
};

const useCreateCommentRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createCommentRequest(variables: Variables): Promise<CreateCommentResultDto> {
    const accessToken = await getAccessToken();
    const body: CreateCommentDto = {
      subjectType: variables.subjectType,
      commentGuid: variables.commentGuid,
      createdByUserId: variables.createdByUserId,
      text: variables.text,
    };

    const response = await fetch(`${configuration.apiRootUrl}/threads/${variables.subjectGuid}/comments`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { createCommentRequest };
};

const useCreateComment = () => {
  const { createCommentRequest } = useCreateCommentRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCommentRequest,
    onSuccess: (_, variables) => {
      switch (variables.subjectType) {
        case CommentSubject.ActionItem:
          queryClient.refetchQueries(queryKeys.actionItems.getActionItemFeed(variables.subjectGuid));
          queryClient.refetchQueries(queryKeys.actionItems.getActionItemSummary(variables.subjectGuid));
          break;
        case CommentSubject.Issue:
          queryClient.refetchQueries(queryKeys.issues.getIssueFeed(variables.subjectGuid));
          break;
      }
    },
  });
};

export { useCreateComment };
