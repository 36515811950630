import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import { Box, IconButton, Stack, styled } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { forwardRef, ReactNode, useState } from "react";
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

const EditRow = styled(Stack)<{
  $isHovering: boolean;
  $isDragging: boolean;
}>(({ theme, $isHovering, $isDragging }) => {
  return {
    backgroundColor: $isDragging
      ? theme.palette.primary.opacity5
      : $isHovering
      ? theme.palette.primary.transparent5
      : "transparent",
    transition: theme.transitions.create("background-color"),
  };
});

const StyledStack = styled(Stack)<{ $isDragging: boolean }>`
  padding-left: ${({ theme }) => theme.spacing(0)};
  width: 100%;
  transition: ${({ theme }) => theme.transitions.create("background-color")};
  background-color: ${({ $isDragging, theme }) => ($isDragging ? theme.palette.primary.opacity10 : undefined)};

  .dragHandle {
    opacity: 1;
    @media (pointer: fine) {
      opacity: ${({ $isDragging }) => ($isDragging ? 1 : 0)};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.opacity5};

    .dragHandle {
      opacity: 1;
    }
  }
`;

const Content = styled("div")`
  margin-left: ${({ theme }) => theme.spacing(0.25)};
  min-width: 0;
  width: 100%;
`;

const DragHandleCol = styled(Box)`
  color: ${({ theme }) => theme.palette.grey[200]};
  background-color: "transparent";
  @media (pointer: fine) {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.primary.transparent5};
  }
  display: "flex";
  align-content: center;
  transition: ${({ theme }) => theme.transitions.create(["opacity"])};
  cursor: "pointer";
  min-width: 20px;
`;

const DeleteCol = styled(IconButton)<{
  $showDeleteOnHover: boolean;
  $isHoveringOrDragging: boolean;
}>`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.error.main};
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  margin-right: ${({ theme }) => theme.spacing(0.5)};

  & svg {
    align-self: center;
  }
`;

interface IProps {
  index: number;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  draggableProps?: DraggableProvidedDraggableProps;
  isDragging: boolean;
  children: ReactNode;
  showDeleteOnHover?: boolean;
  confirmDeletion?: boolean;
  onDelete?: (index: number) => void;
}

const DraggableListItem = forwardRef((props: IProps, ref: any) => {
  const [isHovering, setIsHovering] = useState(false);
  const confirm = useConfirm();

  return (
    <EditRow
      direction="row"
      alignItems="center"
      $isHovering={isHovering}
      $isDragging={props.isDragging}
      ref={ref}
      {...props.draggableProps}
      style={{ ...props.draggableProps?.style }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <StyledStack $isDragging={props.isDragging} direction="row">
        <DragHandleCol className="dragHandle" {...props.dragHandleProps}>
          <DragIndicatorRoundedIcon />
        </DragHandleCol>

        <Content>{props.children}</Content>

        {props.onDelete ? (
          <DeleteCol
            $showDeleteOnHover={props.showDeleteOnHover ?? true}
            $isHoveringOrDragging={isHovering || props.isDragging}
            size="medium"
            onClick={async (e) => {
              e.stopPropagation();

              if (props.confirmDeletion === undefined || props.confirmDeletion === true) {
                try {
                  await confirm({
                    title: "Delete Item",
                    description: "Are you sure you want to delete this item?",
                    confirmationText: "Delete",
                    cancellationText: "Cancel",
                  });
                } catch {
                  return;
                }
              }

              props.onDelete && props.onDelete(props.index);
            }}
          >
            <DeleteOutlineRoundedIcon fontSize="large" />
          </DeleteCol>
        ) : (
          <></>
        )}
      </StyledStack>
    </EditRow>
  );
});

export { DraggableListItem };
