import { Box, Button, Card, CardContent, Chip, Grid, Link, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { HeaderLayout } from "../../../components";
import { Links } from "../../../constants";
import { useSession } from "../../../hooks";
import { useGetSurveys } from "../../../http";
import { ISurveySummary } from "../../../models";
import { setTitlePrefix } from "../../../utilities";
import { CreateSurveyModal } from "../components";
import { SurveyCard } from "./components";

const SurveysHomePage = () => {
  setTitlePrefix("Surveys");

  const navigate = useNavigate();
  const theme = useTheme();
  const { userId } = useSession();
  const { isLoading, data: surveys } = useGetSurveys();
  const [mySurveys, setMySurveys] = useState<ISurveySummary[]>([]);
  const [otherSurveys, setOtherSurveys] = useState<ISurveySummary[]>([]);
  const [isCreateSurveysModalVisible, setIsCreateSurveysModalVisible] = useState(false);

  useEffect(() => {
    if (surveys === undefined) {
      return;
    }

    setMySurveys(surveys.filter((survey) => survey.administrators.some((admin) => admin.userId === userId)));
    setOtherSurveys(surveys.filter((survey) => survey.administrators.every((admin) => admin.userId !== userId)));
  }, [surveys, userId]);

  function handleSurveysCreated(guid: string) {
    setIsCreateSurveysModalVisible(false);

    if (guid) {
      navigate(Links.Survey(guid));
    }
  }

  return (
    <HeaderLayout
      title={
        <Stack direction="column">
          <Typography variant="h3">Surveys</Typography>
          <Typography variant="inherit">Create and manage organization surveys</Typography>
        </Stack>
      }
      actions={
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Button
            variant="contained"
            sx={{ whiteSpace: "nowrap" }}
            onClick={() => setIsCreateSurveysModalVisible(true)}
          >
            Create Survey
          </Button>
        </Stack>
      }
    >
      <Grid container spacing={1} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={9} md={6}>
          <Stack direction="column" spacing={1.5} sx={{ mt: 0.5 }}>
            <Box>
              <Typography variant="h6" sx={{ mb: 0.75 }}>
                My Surveys
              </Typography>
              {isLoading ? (
                <Card>
                  <CardContent>
                    <Chip label="Survey" color="primary" sx={{ mb: 1 }} />
                    <Skeleton variant="rectangular" height={30} sx={{ mb: 0.5 }} />
                    <Skeleton variant="rectangular" height={25} />
                  </CardContent>
                </Card>
              ) : mySurveys.length > 0 ? (
                <Box>
                  <Stack spacing={1}>
                    {mySurveys.map((survey) => (
                      <SurveyCard key={survey.guid} survey={survey} isInteractive />
                    ))}
                  </Stack>
                </Box>
              ) : (
                <Box sx={{ border: "1px dashed #C9C9C9", borderRadius: "10px", minHeight: "150px", padding: "20px" }}>
                  <Typography
                    sx={{
                      color: theme.palette.grey[300],
                      fontStyle: "italic",
                      display: "flex",
                      alignItems: "baseline",
                      gap: [0.25],
                    }}
                  >
                    Nothing here yet.
                    <Link component="button" onClick={() => setIsCreateSurveysModalVisible(true)}>
                      Create your first survey...
                    </Link>
                  </Typography>
                </Box>
              )}
            </Box>

            {isLoading ? (
              <Stack spacing={1.5} sx={{ mt: 0.5 }}>
                <Box>
                  <Skeleton variant="rectangular" height={30} width={100} sx={{ mb: 0.75 }} />
                  <Card>
                    <CardContent>
                      <Chip label="Survey" color="primary" sx={{ mb: 1 }} />
                      <Skeleton variant="rectangular" height={30} sx={{ mb: 0.5 }} />
                      <Skeleton variant="rectangular" height={25} />
                    </CardContent>
                  </Card>
                </Box>
              </Stack>
            ) : (
              <>
                {otherSurveys.length > 0 && (
                  <Box>
                    <Typography variant="h6" sx={{ mb: 0.75 }}>
                      Other Surveys
                    </Typography>
                    <Stack spacing={1}>
                      {otherSurveys.map((survey) => (
                        <SurveyCard key={survey.guid} survey={survey} />
                      ))}
                    </Stack>
                  </Box>
                )}
              </>
            )}
          </Stack>
        </Grid>
      </Grid>

      <CreateSurveyModal
        visible={isCreateSurveysModalVisible}
        onCreated={(data) => handleSurveysCreated(data)}
        onCancel={() => setIsCreateSurveysModalVisible(false)}
      />
    </HeaderLayout>
  );
};

export { SurveysHomePage };
