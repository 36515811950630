import { InfoOutlined, MoreVertRounded } from "@mui/icons-material";
import {
  Box,
  Card,
  IconButton,
  Menu,
  MenuItem,
  CardHeader as MuiCardHeader,
  Stack,
  Tooltip,
  alpha,
  css,
  styled,
  useTheme,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useEffect, useState } from "react";
import { useSession } from "../../hooks";
import { useDeleteBlock } from "../../http";
import { IBlock } from "../../models";
import { Typography } from "../Typography";
import { CreateSectionModal } from "./CreateSectionModal";
import { EditBlockModal } from "./EditBlockModal";
import { usePlanContext } from "./Plan";
import { Section } from "./Section";

const CardHeader = styled(MuiCardHeader)<{ $hasHoverEffect: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => alpha(theme.palette.grey[200], 0.05)};
  border-bottom-color: ${({ theme }) => theme.palette.grey[200]};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  transition: ${({ theme }) => theme.transitions.create("background-color")};

  ${({ $hasHoverEffect, theme }) =>
    $hasHoverEffect &&
    css`
      &:hover {
        background-color: ${theme.palette.primary.opacity5};
      }
    `};
`;

interface IProps {
  isRowView: boolean;
  isPastView: boolean;
  canEditPlan: boolean;
  block: IBlock;
  blocks: IBlock[];
  onBlockAddClick: () => void;
}

const Block = (props: IProps) => {
  const { userId } = useSession();
  const { planGuid } = usePlanContext();
  const [isHovering, setIsHovering] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const { mutate: deleteBlock } = useDeleteBlock();
  const [isEditBlockModalVisible, setIsEditBlockModalVisible] = useState(false);
  const [isCreateSectionModalVisible, setIsCreateSectionModalVisible] = useState(false);
  const confirm = useConfirm();
  const theme = useTheme();

  useEffect(() => {
    if (!isHovering) {
      setMenuAnchor(null);
    }
  }, [isHovering]);

  async function handleDeleteClicked() {
    setMenuAnchor(null);

    try {
      await confirm({
        dialogActionsProps: {
          sx: {
            justifyContent: "space-between",
          },
        },
        title: "Delete Block",
        titleProps: {
          sx: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
          },
        },
        content: (
          <Stack spacing={1}>
            <Typography>
              Deleting this block will delete all of the block's sections and their respective items.
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>This action is undoable.</Typography>
          </Stack>
        ),
        confirmationText: "Delete Block",
        confirmationButtonProps: {
          color: "error",
        },
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteBlock({
      planGuid: planGuid,
      guid: props.block.guid,
      modifiedByUserId: userId,
    });
  }

  return (
    <>
      <Card
        variant={props.isPastView ? "faded" : undefined}
        sx={{ flexGrow: 1, flexBasis: { md: props.isRowView ? "unset" : 0 }, width: "100%" }}
      >
        <CardHeader
          title={
            <Stack direction="row" spacing={0.5} alignItems={"center"}>
              <Stack direction="row" spacing={0.5} flexGrow={1} alignItems="center">
                <Typography variant="h6">{props.block.name}</Typography>
                {props.block.hintText && (
                  <Tooltip title={props.block.hintText}>
                    <InfoOutlined sx={{ color: "grey.300" }} />
                  </Tooltip>
                )}
              </Stack>

              {props.canEditPlan &&
                (props.block.isEditable || props.block.createBlockAllowed || props.block.createSectionAllowed) && (
                  <>
                    <IconButton size="small" onClick={(e) => setMenuAnchor(e.currentTarget)}>
                      <MoreVertRounded />
                    </IconButton>
                    <Menu
                      anchorEl={menuAnchor}
                      open={Boolean(menuAnchor)}
                      onClose={() => {
                        setMenuAnchor(null);
                        setIsHovering(false);
                      }}
                    >
                      {props.block.createSectionAllowed && (
                        <MenuItem onClick={() => setIsCreateSectionModalVisible(true)}>Add Section to Block</MenuItem>
                      )}
                      {props.block.createBlockAllowed && (
                        <MenuItem onClick={props.onBlockAddClick} disabled={props.blocks.length >= 4}>
                          Add Block
                        </MenuItem>
                      )}
                      {props.block.isEditable && [
                        <MenuItem key="edit" onClick={() => setIsEditBlockModalVisible(true)}>
                          Edit Block
                        </MenuItem>,
                        <MenuItem
                          key="delete"
                          onClick={handleDeleteClicked}
                          disabled={props.blocks.length === 1}
                          sx={{ color: theme.palette.error.main }}
                        >
                          Delete Block
                        </MenuItem>,
                      ]}
                    </Menu>
                  </>
                )}
            </Stack>
          }
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          $hasHoverEffect={
            props.canEditPlan &&
            (props.block.isEditable || props.block.createBlockAllowed || props.block.createSectionAllowed)
          }
        />
        <Box sx={{ py: { xs: 0.5, sm: 1 } }}>
          <Stack spacing={{ xs: 0.5, sm: 1 }}>
            {props.block.sections
              .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
              .map((section) => (
                <Section
                  key={section.guid}
                  section={section}
                  sections={props.block.sections}
                  block={props.block}
                  blocks={props.blocks}
                  canEditPlan={props.canEditPlan}
                  isPastView={props.isPastView}
                />
              ))}
          </Stack>
        </Box>
      </Card>

      <EditBlockModal
        visible={isEditBlockModalVisible}
        block={props.block}
        onSaved={() => setIsEditBlockModalVisible(false)}
        onCancelled={() => setIsEditBlockModalVisible(false)}
      />

      <CreateSectionModal
        visible={isCreateSectionModalVisible}
        block={props.block}
        onAdded={() => setIsCreateSectionModalVisible(false)}
        onCancelled={() => setIsCreateSectionModalVisible(false)}
      />
    </>
  );
};

export { Block };
