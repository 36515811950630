import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { Box, Chip, Divider, MenuItem, Skeleton, Stack, Theme, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { ReactNode, useEffect, useState } from "react";
import { Avatar, AvatarGroup, BlockLink, TertiarySelect } from "../../../../components";
import { Links } from "../../../../constants";
import { useGetTeamNetwork } from "../../../../http";
import { ITeam, ITeamNetworkMember, ITeamNetworkRole, ITeamNetworkTeam } from "../../../../models";

enum TeamNetworkObjectType {
  Team = 0,
  Role = 1,
  Member = 2,
}

enum FilterValues {
  All = -1,
  Team = TeamNetworkObjectType.Team,
  Role = TeamNetworkObjectType.Role,
  People = TeamNetworkObjectType.Member,
}

interface IFilterOption {
  label: string;
  value: FilterValues;
}

interface ITeamNetworkObject {
  type: TeamNetworkObjectType;
  title: string;
  render: () => React.ReactNode;
}

interface IProps {
  isLoadingTeam: boolean;
  team: ITeam | undefined;
}

const TeamNetworkList = (props: IProps) => {
  const { isLoading: isLoadingNetworkList, isSuccess, isError, data } = useGetTeamNetwork(props.team?.slug);
  const theme = useTheme();
  const [filterValue, setFilterValue] = useState<FilterValues>(FilterValues.All);
  const [filterOptions, setFilterOptions] = useState<IFilterOption[]>([{ label: "All", value: FilterValues.All }]);

  useEffect(() => {
    if (isSuccess) {
      let filterOpts: IFilterOption[] = [];
      filterOpts.push({ value: FilterValues.All, label: "All" });
      if (data.childTeams.length > 0) {
        filterOpts.push({ value: FilterValues.Team, label: `Teams (${data.childTeams.length})` });
      }
      if (data.roles.length > 0) {
        filterOpts.push({ value: FilterValues.Role, label: `Roles (${data.roles.length})` });
      }
      if (data.members.length > 0) {
        filterOpts.push({ value: FilterValues.People, label: `People (${data.members.length})` });
      }

      setFilterOptions(filterOpts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  if (props.isLoadingTeam || isLoadingNetworkList) {
    return (
      <Box sx={{ p: 1 }}>
        <Stack spacing={1}>
          {[...Array(2)].map((_, i) => (
            <Stack key={i} spacing={0.25}>
              <Skeleton variant="rectangular" height="25px" width="60px" />
              <Skeleton variant="rectangular" height="30px" width="200px" />
            </Stack>
          ))}
        </Stack>
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography variant="body1" sx={{ p: 1 }}>
        Couldn't load network.
      </Typography>
    );
  }

  function renderValue(selectedValue: FilterValues): ReactNode {
    const selectedOption = filterOptions.find((option: any) => option!.value === selectedValue);
    return (
      <Stack direction="row" spacing={0.25} alignItems="center">
        <FilterListRoundedIcon fontSize="large" />
        <Typography variant="button">{selectedOption?.label}</Typography>
      </Stack>
    );
  }

  const networkObjects: ITeamNetworkObject[] = [];
  networkObjects.push(...data.childTeams.map((x) => mapTeamToTeamNetworkObject(x, theme)));
  networkObjects.push(...data.roles.map((x) => mapRoleToTeamNetworkObject(x, theme)));
  networkObjects.push(...data.members.map((x) => mapMemberToTeamNetworkObject(x, theme)));

  if (networkObjects.length === 0) {
    return (
      <Box sx={{ p: 1 }}>
        <Typography variant="body1">Nothing here yet.</Typography>
      </Box>
    );
  }

  return (
    <>
      <TertiarySelect
        sx={{ mx: { xs: 0.5, sm: 1 }, my: 0.5, width: "fit-content" }}
        value={filterValue}
        renderValue={(value: any) => renderValue(value)}
        onChange={(e) => setFilterValue(e.target.value as number)}
        onClose={(e: any) => e.currentTarget.blur()}
      >
        {filterOptions.map((option: any) => (
          <MenuItem key={option!.value} value={option!.value}>
            {option!.label}
          </MenuItem>
        ))}
      </TertiarySelect>

      <Divider />
      {data && (
        <Stack divider={<Divider />} sx={{ overflowY: "auto" }}>
          {networkObjects
            .filter((x) => (filterValue === FilterValues.All ? true : x.type.valueOf() === filterValue))
            .sort((a, b) => (a.type > b.type ? 1 : 0 || a.title.localeCompare(b.title)))
            .map((x, i) => (
              <Box key={i}>{x.render()}</Box>
            ))}
        </Stack>
      )}
    </>
  );
};

function mapTeamToTeamNetworkObject(team: ITeamNetworkTeam, theme: Theme): ITeamNetworkObject {
  return {
    type: TeamNetworkObjectType.Team,
    title: team.name,
    render: () => (
      <BlockLink
        href={Links.Team(team.slug)}
        backgroundColorOnHover={theme.palette.primary.transparent5}
        sx={{ p: { xs: 0.5, sm: 1 } }}
      >
        <Stack>
          <Chip label="Team" color="primary" sx={{ mb: 0.5 }} />
          <Typography variant="h5">{team.name}</Typography>
          {team.description && <Typography variant="body1">{team.description}</Typography>}
        </Stack>
      </BlockLink>
    ),
  };
}

function mapRoleToTeamNetworkObject(role: ITeamNetworkRole, theme: Theme): ITeamNetworkObject {
  return {
    type: TeamNetworkObjectType.Role,
    title: role.name,
    render: () => (
      <BlockLink
        href={Links.RoleDetail(role.guid)}
        backgroundColorOnHover={theme.palette.primary.transparent5}
        sx={{ p: { xs: 0.5, sm: 1 } }}
      >
        <Stack>
          <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }}>
            <Chip label="Role" color="orange" />

            {role.isOpen && (
              <Chip
                label={`OPEN as of ${role.isOpenLastModifiedUtc
                  .toLocal()
                  .toLocaleString(DateTime.DATE_MED)
                  .toLocaleUpperCase()}`}
                color="orange"
                variant="outlined"
                sx={{
                  ".MuiChip-label": {
                    textTransform: "none",
                  },
                }}
              />
            )}
          </Stack>
          <Typography variant="h5">{role.name}</Typography>
          {role.description && (
            <Typography variant="body1" sx={{ mb: 0.25, wordWrap: "break-word" }}>
              {role.description}
            </Typography>
          )}
          <AvatarGroup users={role.members} max={10} />
        </Stack>
      </BlockLink>
    ),
  };
}

function mapMemberToTeamNetworkObject(member: ITeamNetworkMember, theme: Theme): ITeamNetworkObject {
  return {
    type: TeamNetworkObjectType.Member,
    title: member.user.displayName,
    render: () => (
      <BlockLink
        href={Links.User(member.user.userId)}
        backgroundColorOnHover={theme.palette.primary.transparent5}
        sx={{ p: { xs: 0.5, sm: 1 } }}
      >
        <Stack>
          <Chip label="Person" color="wine" sx={{ mb: 0.5 }} />
          <Stack direction="row" spacing={0.5}>
            <Avatar user={member.user} border />
            <Box>
              <Typography variant="h5">{member.user.displayName}</Typography>
              <Typography variant="body1">{member.roleNames.join(", ")}</Typography>
            </Box>
          </Stack>
        </Stack>
      </BlockLink>
    ),
  };
}

export { TeamNetworkList };
