import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetSurveyInstanceResponseDto } from "../../types";
import { EditSurveyInstanceQuestionAnswerDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  answer: string;
  answerGuid: string;
  instanceGuid: string;
};

const useEditSurveyInstanceQuestionAnswerRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editSurveyInstanceQuestionAnswerRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();

    const body: EditSurveyInstanceQuestionAnswerDto = {
      answer: variables.answer,
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-response-answer/${variables.answerGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editSurveyInstanceQuestionAnswerRequest };
};

const useEditSurveyInstanceQuestionAnswer = () => {
  const { editSurveyInstanceQuestionAnswerRequest } = useEditSurveyInstanceQuestionAnswerRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editSurveyInstanceQuestionAnswerRequest,
    onMutate: async (variables) => {
      // Optimistically update cache
      const cachedData = await optimisticallyUpdateResponseAnswers(queryClient, variables);

      return { cachedData };
    },
    onError: (_error, variables, context) => {
      // On error, rollback
      queryClient.setQueryData(
        queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid),
        context?.cachedData
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid));
    },
  });
};

async function optimisticallyUpdateResponseAnswers(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid));
  const cachedData = queryClient.getQueryData<GetSurveyInstanceResponseDto>(
    queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid)
  );

  if (cachedData) {
    const optimisticallyUpdatedData: GetSurveyInstanceResponseDto = {
      ...cachedData,
      questions: cachedData.questions.map((question) => {
        if (question.answer?.answerGuid === variables.answerGuid) {
          return {
            ...question,
            answer: { ...question.answer, value: variables.answer },
          };
        }
        return question;
      }),
    };

    queryClient.setQueryData(
      queryKeys.surveys.getSurveyInstanceResponse(variables.instanceGuid),
      optimisticallyUpdatedData
    );

    return cachedData;
  }
}

export { useEditSurveyInstanceQuestionAnswer };
