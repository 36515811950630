import React from "react";
import { DateTime } from "luxon";
import { Link, Skeleton, Stack, SxProps, useTheme } from "@mui/material";
import { Typography } from "../../../../components";
import { Links } from "../../../../constants";
import { useSurveyPageLayoutContext } from "../SurveyPageLayout";
import { HowToVote } from "@mui/icons-material";

interface IProps {
  sx?: SxProps;
}

const SurveyTitle = (props: IProps) => {
  const theme = useTheme();
  const { isLoadingSurvey, survey } = useSurveyPageLayoutContext();

  if (isLoadingSurvey) {
    return (
      <Stack spacing={0.25} sx={props.sx}>
        <Skeleton variant="rectangular" height="25px" width="60px" />
        <Skeleton variant="rectangular" height="58px" width="300px" />
      </Stack>
    );
  }

  if (survey) {
    return (
      <Stack direction="column">
        <Link href={Links.Surveys()} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: [0.3] }}>
          <HowToVote sx={{ fill: theme.palette.primary.main, height: 20, width: 20 }} />
          <Typography sx={{ fontSize: "0.875rem", textTransform: "uppercase" }}>Surveys</Typography>
        </Link>
        <Typography variant="h3" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {survey?.name}
        </Typography>
        <Typography>
          {`Last updated by `}
          <Link href={Links.User(survey.lastModifiedUser.userId)} onClick={(e) => e.stopPropagation()}>
            {survey.lastModifiedUser.displayName}
          </Link>
          {` on ${survey.lastModifiedDateTimeUtc.toLocal().toLocaleString(DateTime.DATE_FULL)}`}
        </Typography>
      </Stack>
    );
  }

  return null;
};

export { SurveyTitle };
