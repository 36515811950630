import { Box, styled, Typography } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { CreateMetricModal, EditList } from "../../../../components";
import { ConnectObjectType } from "../../../../constants";
import { IMetric } from "../../../../models";
import { useDeleteMetric, useEditMetric } from "../../../../http";
import { useSession } from "../../../../hooks";
import { useEffect, useState } from "react";
import { lexorank } from "../../../../utilities";

ChartJS.register(LinearScale, CategoryScale, PointElement, LineElement, Title, Tooltip, Legend);

const Placeholder = styled(Typography)`
  font-style: italic;
`;

interface IProps {
  metrics: IMetric[];
  onSelected: (index: number) => void;
  onDeleted: (index: number) => void;
}

const EditMetrics = (props: IProps) => {
  const { mutate: deleteMetric } = useDeleteMetric();
  const { mutate: editMetric } = useEditMetric();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [internalMetricList, setInternalMetricList] = useState<IMetric[]>(props.metrics);
  const { userId } = useSession();

  useEffect(() => {
    setInternalMetricList(props.metrics);
  }, [props.metrics]);

  function handleReorder(sourceIndex: number, destinationIndex: number) {
    var newSortOrderValue = lexorank.getRank(internalMetricList, sourceIndex, destinationIndex);

    setInternalMetricList((prev) =>
      prev.map((metric, index) => {
        if (index === sourceIndex) {
          return { ...metric, sortOrder: newSortOrderValue };
        }
        return metric;
      })
    );

    editMetric({
      ...internalMetricList[sourceIndex],
      datasets: internalMetricList[sourceIndex].datasets.map((dataset) => {
        return {
          ...dataset,
          data: dataset.data.map((data) => {
            return { ...data, modifiedByUserId: userId! };
          }),
          modifiedByUserId: userId!,
        };
      }),
      sortOrder: newSortOrderValue,
      modifiedByUserId: userId!,
    });
  }

  function handleDeleteClicked(index: number) {
    deleteMetric({
      teamSlug: internalMetricList[index].teamSlug,
      guid: internalMetricList[index].guid,
      modifiedByUserId: userId!,
    });

    props.onDeleted(index);
  }

  return (
    <>
      <EditList
        id="metrics"
        values={internalMetricList.sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))}
        buttonText={`Add ${ConnectObjectType.Metric}`}
        buttonProps={{ sx: { mb: 1 } }}
        renderItem={(item: IMetric, index) => (
          <Box sx={{ cursor: "pointer", py: 0.5 }} onClick={() => props.onSelected(index)}>
            {item.title === "" ? (
              <Placeholder variant="h5">Placeholder</Placeholder>
            ) : (
              <Typography variant="h5">{item.title}</Typography>
            )}

            {item.description === "" ? (
              <Placeholder variant="body1">Placeholder</Placeholder>
            ) : (
              <Typography variant="body1">{item.description}</Typography>
            )}
          </Box>
        )}
        renderEmptyPlaceholder={() => (
          <Box sx={{ px: 1, pt: 1 }}>
            <Typography variant="body1">No critical metrics entered yet.</Typography>
          </Box>
        )}
        onAdd={() => setIsCreateModalVisible(true)}
        onReorder={handleReorder}
        onDelete={handleDeleteClicked}
      />
      <CreateMetricModal
        visible={isCreateModalVisible}
        onAdded={() => setIsCreateModalVisible(false)}
        onCancelled={() => setIsCreateModalVisible(false)}
      />
    </>
  );
};

export { EditMetrics };
