import { Card, Stack, Typography, useTheme } from "@mui/material";
import { GetSurveyCoherenceScoreOverTimeResultDto } from "../../../../http";
import { useCoherenceReportContext } from "../CoherenceReportPage";
import { DateTime } from "luxon";
import { LineGraph, LineGraphSkeleton } from "../../../../components";
import { useBreakpoints } from "../../../../hooks";

interface IProps {
  isLoading: boolean;
  data: GetSurveyCoherenceScoreOverTimeResultDto | undefined;
}

const CoherenceGraph = (props: IProps) => {
  const theme = useTheme();
  const { selectedTeamLabel, comparisonTeamLabel } = useCoherenceReportContext();
  const { isSmUp, isMdUp } = useBreakpoints();

  return (
    <Card sx={{ p: 1 }}>
      <Stack>
        <Typography variant="h4">Coherence</Typography>
        <Typography variant="caption">Last 12 months</Typography>
        {props.isLoading ? (
          <LineGraphSkeleton sx={{ height: "200px" }} />
        ) : (
          <LineGraph
            datasets={[
              {
                title: selectedTeamLabel ?? "Me",
                color: theme.palette.primary.main,
                dotted: false,
                data:
                  props.data?.selectedTeamsCoherenceScores.map((d) => {
                    return {
                      x: DateTime.fromISO(d.dateUtc).toFormat(isMdUp ? "MMMM yyyy" : "MMM"),
                      y: d.coherenceScore ?? null,
                    };
                  }) ?? [],
              },
              {
                title: comparisonTeamLabel ?? "-",
                color: theme.palette.orange.main,
                dotted: true,
                data:
                  props.data?.comparisonTeamsCoherenceScores.map((d) => {
                    return {
                      x: DateTime.fromISO(d.dateUtc).toFormat(isMdUp ? "MMMM yyyy" : "MMM"),
                      y: d.coherenceScore ?? null,
                    };
                  }) ?? [],
              },
            ]}
            minYValue={0}
            maxYValue={10}
            drawPoints
            sx={{ mt: 0.5, height: isSmUp ? "200px" : "40vw" }}
            showYAxis
            showXAxis
          />
        )}
      </Stack>
    </Card>
  );
};

export { CoherenceGraph };
