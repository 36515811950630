import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditActionItemNoteDto, FetchError, GetActionItemDto, GetActionItemSummaryDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  note: string;
  modifiedByUserId: string;
};

const useEditActionItemNoteRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editActionItemNoteRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditActionItemNoteDto = {
      note: variables.note,
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/action-items/${variables.guid}/note`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editActionItemNoteRequest };
};

const useEditActionItemNote = () => {
  const { editActionItemNoteRequest } = useEditActionItemNoteRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editActionItemNoteRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
      await queryClient.cancelQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));

      // Snapshot the current cache value
      const cachedActionItemSummaryData = queryClient.getQueryData<GetActionItemSummaryDto>(
        queryKeys.actionItems.getActionItemSummary(variables.guid)
      );
      const cachedActionItemDetailsData = queryClient.getQueryData<GetActionItemDto>(
        queryKeys.actionItems.getActionItemDetail(variables.guid)
      );

      if (cachedActionItemSummaryData) {
        const optimisticallyUpdatedActionItemSummaryData: GetActionItemSummaryDto = {
          ...cachedActionItemSummaryData,
          note: variables.note,
        };

        queryClient.setQueryData(
          queryKeys.actionItems.getActionItemSummary(variables.guid),
          optimisticallyUpdatedActionItemSummaryData
        );
      }

      if (cachedActionItemDetailsData) {
        const optimisticallyUpdatedActionItemDetailsData: GetActionItemDto = {
          ...cachedActionItemDetailsData,
          note: variables.note,
        };

        queryClient.setQueryData(
          queryKeys.actionItems.getActionItemDetail(variables.guid),
          optimisticallyUpdatedActionItemDetailsData
        );
      }

      return { cachedActionItemSummaryData, cachedActionItemDetailsData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(
        queryKeys.actionItems.getActionItemSummary(variables.guid),
        context?.cachedActionItemSummaryData
      );
      queryClient.setQueryData(
        queryKeys.actionItems.getActionItemDetail(variables.guid),
        context?.cachedActionItemDetailsData
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemFeed(variables.guid));
    },
  });
};

export { useEditActionItemNote };
