import {useMutation, useQueryClient} from "@tanstack/react-query";
import {configuration} from "../../../configuration";
import {FetchError} from "../../types";
import {EditSurveyQuestionTypeDto} from "../../types";
import {useAccessToken} from "../../use-access-token";
import {queryKeys} from "../queryKeys";

type Variables = {
  guid: string;
  surveyGuid: string;
  modifiedByUserId: string;
  questionTypeId?: number;
  scalePoints?: number;
  includeNotApplicableOption?: boolean;
  minLabel?: string;
  maxLabel?: string;
};

const useEditSurveyQuestionTypeRequest = () => {
  const {getAccessToken} = useAccessToken();

  async function editSurveyQuestionTypeRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();

    const body: EditSurveyQuestionTypeDto = {
      modifiedByUserId: variables.modifiedByUserId,
      surveyGuid: variables.surveyGuid,
      questionTypeId: variables.questionTypeId,
      scalePoints: variables.scalePoints,
      includeNotApplicableOption: variables.includeNotApplicableOption,
      minLabel: variables.minLabel,
      maxLabel: variables.maxLabel,
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-questions/${variables.guid}/question-type`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return {editSurveyQuestionTypeRequest};
};

const useEditSurveyQuestionType = () => {
  const {editSurveyQuestionTypeRequest} = useEditSurveyQuestionTypeRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editSurveyQuestionTypeRequest,
    onSuccess: async (_data, variables) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurveyQuestion(variables.guid) });
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurveyQuestions(variables.surveyGuid) });
    },
  });
};

export {useEditSurveyQuestionType};
