import { Box, Divider, Skeleton, Stack, SxProps, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

interface ViewListRenderShowClosedButtonParams {
  closedItemsVisible: boolean;
  closedItemCount: number;
  onClick: () => void;
}

interface IClosedItemsListProps<T> {
  showClosed?: boolean;
  closedItemFilter: (item: T) => boolean;
  renderShowClosedButton: (params: ViewListRenderShowClosedButtonParams) => React.ReactNode;
}

interface IProps<T> {
  dataSource?: T[];
  isLoading?: boolean;
  spacing?: number;
  divider?: React.ReactNode;
  sx?: SxProps;
  closedItemListProps?: IClosedItemsListProps<T>;
  sortFunction?: (a: T, b: T) => number;
  renderSkeleton?: (index: number) => React.ReactNode;
  renderItem: (item: T, index: number) => React.ReactNode;
}

const ViewList = <T extends unknown>(props: IProps<T>) => {
  const theme = useTheme();
  const [showClosed, setShowClosed] = useState(false);

  const activeItems = props.closedItemListProps
    ? props.dataSource?.filter((item) => props.closedItemListProps!.closedItemFilter!(item))
    : props.dataSource;
  const closedItems =
    props.dataSource && props.closedItemListProps
      ? props.dataSource.filter((item) => !props.closedItemListProps!.closedItemFilter(item))
      : [];

  useEffect(() => {
    setShowClosed(Boolean(props.closedItemListProps?.showClosed));
  }, [props.closedItemListProps?.showClosed]);

  return (
    <>
      {props.isLoading || !props.dataSource ? (
        <Stack sx={{ ...props.sx }} spacing={props.spacing} divider={props.divider ?? <Divider />}>
          {[...Array(3)].map((_, i) =>
            props.renderSkeleton ? props.renderSkeleton(i) : <Skeleton variant="rectangular" key={i} />
          )}
        </Stack>
      ) : (
        <Stack sx={{ ...props.sx }}>
          {!activeItems || activeItems.length === 0 ? (
            <Box sx={{ p: 0.5, pl: 1 }}>
              <Typography variant="body1" sx={{ color: theme.palette.grey[300] }}>
                Nothing here yet.
              </Typography>
            </Box>
          ) : (
            <Stack spacing={props.spacing} divider={props.divider ?? <Divider />}>
              {activeItems
                .sort((a, b) => (props.sortFunction ? props.sortFunction(a, b) : 1))
                .map((item, index) => props.renderItem(item, index))}
            </Stack>
          )}

          {props.closedItemListProps && closedItems.length > 0 && (
            <>
              {props.divider ?? <Divider />}

              {props.closedItemListProps.renderShowClosedButton({
                closedItemsVisible: showClosed,
                closedItemCount: closedItems.length,
                onClick: () => setShowClosed(!showClosed),
              })}

              {showClosed && (
                <>
                  {props.divider ?? <Divider />}
                  <Stack spacing={props.spacing} divider={props.divider ?? <Divider />}>
                    {closedItems
                      .sort((a, b) => (props.sortFunction ? props.sortFunction(a, b) : 1))
                      .map((item, index) => props.renderItem(item, index))}
                  </Stack>
                </>
              )}
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export { ViewList };
