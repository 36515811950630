import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { INotificationPreferences } from "../../../models";
import { FetchError, GetNotificationPreferencesDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetNotificationPreferencesRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getNotificationPreferencesRequest({
    queryKey,
    signal,
  }: QueryFunctionContext<
    ReturnType<typeof queryKeys.users.getNotificationPreferences>
  >): Promise<GetNotificationPreferencesDto> {
    const accessToken = await getAccessToken();
    const [{ userId }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/users/${userId}/notification-preferences`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal,
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getNotificationPreferencesRequest };
};

const useGetNotificationPreferences = (userId?: string) => {
  const { getNotificationPreferencesRequest } = useGetNotificationPreferencesRequest();

  return useQuery({
    queryKey: queryKeys.users.getNotificationPreferences(userId),
    queryFn: getNotificationPreferencesRequest,
    select: (data): INotificationPreferences => {
      return data;
    },
    enabled: userId !== undefined,
  });
};

export { useGetNotificationPreferences };
