import ErrorIcon from "@mui/icons-material/Error";
import {
  CardHeader,
  Divider,
  Fade,
  keyframes,
  Stack,
  styled,
  SxProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { CommentType, ConnectionState, ConnectionType, PlanFieldType } from "../constants";
import { IInformedBy } from "../models";
import { Comment } from "./Comment";
import { HistoryLedgerItemValue } from "./historyLedgers";

const CardTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
  },
}));

const breathe = keyframes`
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% {opacity: 0.2; }
`;

const AnimatedIcon = styled(ErrorIcon)`
  animation: ${breathe} 3s ease-in-out infinite;
`;

interface ISyncStateAlertProps {
  footer?: React.ReactNode;
  sx?: SxProps;
}

interface IProps {
  informedBy: IInformedBy;
  footer?: React.ReactNode;
  sx?: SxProps;
}

const SyncStateAlert = (props: IProps) => {
  const theme = useTheme();

  return (
    <>
      <CardTooltip
        sx={{ background: "transparent" }}
        TransitionComponent={Fade}
        placement="bottom-start"
        leaveDelay={200}
        PopperProps={{
          onClick: (e) => {
            e.stopPropagation();
          },
        }}
        title={
          <>
            <CardHeader
              title={
                <Typography variant="h6">
                  {props.informedBy.syncState === ConnectionState.OutOfSync
                    ? "Connected Item Updated"
                    : "Connected Item Deleted"}
                </Typography>
              }
            />
            <Divider />
            <Stack spacing={1} sx={{ p: 1 }}>
              <Comment
                comment={{
                  type: CommentType.HistoryLedgerItem,
                  user: props.informedBy.updatedVersion!.lastModifiedBy,
                  timestampUtc: props.informedBy.updatedVersion!.lastModifiedUtc,
                  lastModifiedUtc: props.informedBy.updatedVersion!.lastModifiedUtc,
                  text: (
                    <>
                      {props.informedBy.syncState === ConnectionState.OutOfSync ? (
                        <Typography>
                          {props.informedBy.type === ConnectionType.KeyInitiative
                            ? PlanFieldType.Priority
                            : PlanFieldType.Strategy}
                          {` updated to `}
                          <HistoryLedgerItemValue value={props.informedBy.updatedVersion!.description} />
                          {`.`}
                        </Typography>
                      ) : (
                        <Typography>
                          {props.informedBy.type === ConnectionType.KeyInitiative
                            ? PlanFieldType.Priority
                            : PlanFieldType.Strategy}
                          {` `}
                          <HistoryLedgerItemValue value={props.informedBy.description} />
                          {` removed.`}
                        </Typography>
                      )}
                    </>
                  ),
                }}
                sx={{ p: 0 }}
              />
              <Stack spacing={1}>
                <Typography>
                  {props.informedBy.syncState === ConnectionState.OutOfSync
                    ? "Update the connection between items to ensure alignment. "
                    : `Remove the connection to this ${
                        props.informedBy.type === ConnectionType.KeyInitiative
                          ? PlanFieldType.Priority.toLowerCase()
                          : PlanFieldType.Strategy.toLowerCase()
                      } to ensure alignment.`}
                </Typography>
                {props.footer}
              </Stack>
            </Stack>
          </>
        }
      >
        <AnimatedIcon
          sx={{
            ...props.sx,
            color:
              props.informedBy.syncState === ConnectionState.OutOfSync
                ? theme.palette.orange.main
                : theme.palette.error.main,
          }}
        />
      </CardTooltip>
    </>
  );
};

export { SyncStateAlert };
export type { ISyncStateAlertProps };
