import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Popper,
  Stack,
  styled,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { isWhitespace } from "..";
import { useGetPlanConnectionPoints, useGetTeams } from "../../http";
import { IContextActionItem, IPlanConnectionPoint, ITeamSummary } from "../../models";
import { Autocomplete } from "../Autocomplete";
import { Typography } from "../Typography";

const MenuItem = styled(Box)`
  &:first-child {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }

  &:last-child {
    margin-bottom: 2px;
  }
` as typeof Box;

interface IProps {
  open: boolean;
  context?: IContextActionItem;
  onUpdateClicked?: (selectedContextActionItemHistoryId?: number) => Promise<void>;
  onCancelled: () => void;
}

const EditContextModal = (props: IProps) => {
  const [selectedTeam, setSelectedTeam] = useState<ITeamSummary | null>(null);
  const [selectedConnectionPoint, setSelectedConnectionPoint] = useState<IPlanConnectionPoint | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { isFetching: isFetchingTeams, data: teams } = useGetTeams(undefined, props.open);
  const { isFetching: isFetchingConnectionPoints, data: connectionPoints } = useGetPlanConnectionPoints(
    selectedTeam?.slug
  );
  const theme = useTheme();

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.context]);

  async function handlePlanChanged(e: React.SyntheticEvent, value: ITeamSummary | null) {
    setSelectedTeam(value);
    setSelectedConnectionPoint(null);
  }

  function handleCancelClicked() {
    reset();
    props.onCancelled();
  }

  function reset() {
    if (props.context) {
      setSelectedTeam(teams?.find((x) => x.slug === props.context!.teamPlanSection.teamSlug) ?? null);
      setSelectedConnectionPoint({
        guid: props.context.actionItemGuid,
        description: props.context.description,
        historyId: props.context.historyId,
      });
    } else {
      setSelectedTeam(null);
      setSelectedConnectionPoint(null);
    }
  }

  return (
    <Dialog open={props.open} onClose={handleCancelClicked}>
      <DialogTitle>Connect to Context</DialogTitle>
      <DialogContent>
        <Stack spacing={{ xs: 0.5, sm: 1 }}>
          <Typography>Select an item that provides context.</Typography>
          <Autocomplete
            isLoading={isFetchingTeams || !teams}
            options={teams ?? []}
            getOptionLabel={(option: ITeamSummary) => option.name}
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.slug} component="li">
                <Stack>
                  <Typography>{option.name}</Typography>
                  {option.planGuid === null && (
                    <Typography variant="caption">This team has no strategic map</Typography>
                  )}
                </Stack>
              </MenuItem>
            )}
            getOptionDisabled={(option) => option.planGuid === null}
            isOptionEqualToValue={(option, value) => option.slug === value.slug}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select a team" inputProps={{ ...params.inputProps }} />
            )}
            onChange={handlePlanChanged}
            PopperComponent={(props) => (
              <Popper {...props} style={{ ...props.style, margin: 0, padding: 0, minWidth: "300px" }} />
            )}
            value={selectedTeam}
          />

          <Autocomplete
            isLoading={selectedTeam !== null && (isFetchingConnectionPoints || !connectionPoints)}
            disabled={selectedTeam == null}
            groupBy={(option) => option.sectionName!}
            options={connectionPoints?.filter((x) => !isWhitespace(x.description)) ?? []}
            getOptionLabel={(option: IPlanConnectionPoint) => option.description}
            renderGroup={(group) => (
              <Box key={group.key} sx={{ pb: 0.25 }}>
                <Stack
                  spacing={0.2}
                  sx={{ px: 0.5, pt: 0.75, position: "sticky", top: 0, backgroundColor: theme.palette.common.white }}
                >
                  <Typography variant="caption">{group.group.toLocaleUpperCase()}</Typography>
                  <Divider />
                </Stack>
                {group.children}
              </Box>
            )}
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.guid} component="li">
                <Typography>{option.description}</Typography>
              </MenuItem>
            )}
            isOptionEqualToValue={(option, value) => option.guid === value.guid}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select a strategic plan item" inputProps={{ ...params.inputProps }} />
            )}
            onChange={(_, value) => setSelectedConnectionPoint(value)}
            PopperComponent={(props) => (
              <Popper {...props} style={{ ...props.style, margin: 0, padding: 0, minWidth: "300px" }} />
            )}
            value={selectedConnectionPoint}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isSaving} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isSaving}
          endIcon={isSaving ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={async () => {
            setIsSaving(true);
            await props.onUpdateClicked?.(selectedConnectionPoint?.historyId);
            setIsSaving(false);
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { EditContextModal };
