import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useSession } from "../../../../hooks";
import { useCreateResource, useGetResourcesForTeam } from "../../../../http";
import { lexorank } from "../../../../utilities";
import { useTeamPageLayoutContext } from "../../TeamPageLayout";
import { CreateEditResourceForm, ICreateEditResourceFormState } from "./CreateEditResourceForm";

interface IParams {
  slug: string;
}

interface IProps {
  visible: boolean;
  onAdded: () => void;
  onCancelled: () => void;
}

const CreateResourceModal = (props: IProps) => {
  const { slug } = useParams<keyof IParams>() as IParams;
  const { team } = useTeamPageLayoutContext();
  const { userId } = useSession();
  const { data: resources, isLoading } = useGetResourcesForTeam(slug, props.visible);
  const { mutate, isLoading: isCreating } = useCreateResource();
  const { formState, control, handleSubmit, reset } = useForm<ICreateEditResourceFormState>({
    defaultValues: {
      title: "",
      url: "",
      isEmbedded: false,
      sortOrder: "",
    },
  });

  async function handleCreateClicked() {
    handleSubmit((data) => {
      mutate(
        {
          teamSlug: team!.slug,
          title: data.title,
          url: data.url,
          isEmbedded: data.isEmbedded,
          sortOrder: lexorank.getNextRank(resources!),
          createdByUserId: userId!,
        },
        {
          onSuccess: () => {
            reset(undefined, { keepDefaultValues: true });
            props.onAdded();
          },
        }
      );
    })();
  }

  function handleClose(_: object, reason: string) {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    handleCancelClicked();
  }

  function handleCancelClicked() {
    reset(undefined, { keepDefaultValues: true });
    props.onCancelled();
  }

  return (
    <Dialog open={props.visible} onClose={handleClose}>
      <DialogTitle>Create Resource</DialogTitle>
      <DialogContent>
        <CreateEditResourceForm formState={formState} control={control} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isLoading || isCreating} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isLoading || isCreating}
          endIcon={isLoading || isCreating ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleCreateClicked}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { CreateResourceModal };
