import { Circle, HorizontalRuleRounded, MoreHorizRounded } from "@mui/icons-material";
import { Button, Menu, Stack, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import { BlockPicker } from "react-color";
import { ConnectObjectType, GRAPH_COLORS } from "../../constants";
import { v4 as newGuid } from "uuid";
import { IMetricDataset } from "../../models";
import { EditList } from "../EditList";
import { TooltipToggleButton } from "../TooltipToggleButton";
import { useFieldArray, Controller, UseFormReturn } from "react-hook-form";
import { lexorank } from "../../utilities";
import { ICreateEditMetricFormState } from "./CreateEditMetricForm";
import { TextField } from "../TextField";

interface IProps {
  form: UseFormReturn<ICreateEditMetricFormState>;
}

const EditMetricDatasets = (props: IProps) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [selectedDatasetIndex, setSelectedDatasetIndex] = useState<number>(0);
  const { fields, append, remove, update } = useFieldArray({ name: "datasets", control: props.form.control });

  function handleAddDataset() {
    append({
      guid: newGuid(),
      title: "",
      color: GRAPH_COLORS[2],
      dotted: false,
      data: [],
      sortOrder: lexorank.getNextRank(fields),
    });
  }

  function handleReorder(sourceIndex: number, destinationIndex: number) {
    update(sourceIndex, {
      ...fields[sourceIndex],
      sortOrder: lexorank.getRank(fields, sourceIndex, destinationIndex),
    });
  }

  function handleLineStyleChanged(value: boolean, datasetIndex: number) {
    if (value != null) {
      props.form.setValue(`datasets.${datasetIndex}.dotted`, value);
    }
  }

  return (
    <>
      <EditList
        id="datasets"
        values={fields.sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))}
        buttonText={`Add ${ConnectObjectType.Metric}`}
        buttonProps={{ sx: { mt: 0.25 } }}
        renderItem={(_item: IMetricDataset, index: number) => (
          <Stack direction="row" spacing={1} sx={{ p: 0.25, alignItems: "center" }}>
            <Controller
              name={`datasets.${index}.title`}
              control={props.form.control}
              rules={{ required: { message: "Title is required", value: true } }}
              render={({ field }) => <TextField {...field} />}
            />
            <Button
              variant="text"
              size="small"
              onClick={(event) => {
                setMenuAnchor(event.currentTarget);
                setSelectedDatasetIndex(index);
              }}
              sx={{ minWidth: 0 }}
              disableRipple
            >
              <Controller
                name={`datasets.${index}.color`}
                control={props.form.control}
                render={({ field }) => <Circle sx={{ color: field.value }} fontSize="large" />}
              />
            </Button>
            <Controller
              name={`datasets.${index}.dotted`}
              control={props.form.control}
              render={({ field }) => (
                <ToggleButtonGroup
                  value={field.value}
                  color="primary"
                  size="small"
                  exclusive
                  onChange={(e, value: boolean) => handleLineStyleChanged(value, index)}
                >
                  <TooltipToggleButton title="Solid Line" value={false}>
                    <HorizontalRuleRounded />
                  </TooltipToggleButton>

                  <TooltipToggleButton title="Dotted Line" value={true}>
                    <MoreHorizRounded />
                  </TooltipToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Stack>
        )}
        onAdd={handleAddDataset}
        onReorder={handleReorder}
        onDelete={(index) => remove(index)}
      />
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        MenuListProps={{ sx: { p: 0 } }}
      >
        <Controller
          name={`datasets.${selectedDatasetIndex}.color`}
          control={props.form.control}
          render={({ field }) => (
            <BlockPicker
              color={field.value}
              colors={GRAPH_COLORS}
              triangle="hide"
              onChangeComplete={(color) => props.form.setValue(`datasets.${selectedDatasetIndex}.color`, color.hex)}
            />
          )}
        />
      </Menu>
    </>
  );
};

export { EditMetricDatasets };
