import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IResource } from "../../../models";
import { FetchError, GetResourcesDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetResourcesForTeamRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getResourcesForTeamRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.resources.getResourcesForTeam>>): Promise<GetResourcesDto> {
    const accessToken = await getAccessToken();
    const [{ slug }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/resources`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getResourcesForTeamRequest };
};

const useGetResourcesForTeam = (slug: string, enabled: boolean = true) => {
  const { getResourcesForTeamRequest } = useGetResourcesForTeamRequest();

  return useQuery({
    queryKey: queryKeys.resources.getResourcesForTeam(slug),
    queryFn: getResourcesForTeamRequest,
    select: (data): IResource[] => {
      return data.resources;
    },
    enabled: enabled,
  });
};

export { useGetResourcesForTeam };
