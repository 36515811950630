import { Card } from "@mui/material";
import { CardLink } from "../../../components";
import { Links } from "../../../constants";
import { IConnectedKeyInitiative, IConnectedQuarterlyPriority, IConnectedStrategy } from "../../../models";
import { KeyInitiativeDetail, QuarterlyPriorityDetail, StrategyDetail } from "../components";

interface IConnection {
  teamName: string | null;
  type: ConnectionType;
  userDisplayName: string | null;
  description: string;
  element: React.ReactNode;
}

enum ConnectionType {
  Strategy = 0,
  KeyInitiative = 1,
  QuarterlyPriority = 2,
}

const renderSortedConnections = (
  connectedStrategies: IConnectedStrategy[],
  connectedKeyInitiatives: IConnectedKeyInitiative[],
  connectedQuarterlyPriorities: IConnectedQuarterlyPriority[]
) => {
  const connections = connectedStrategies
    .map<IConnection>((x) => ({
      teamName: x.teamName,
      type: ConnectionType.Strategy,
      description: x.description,
      userDisplayName: null,
      element: (
        <CardLink key={x.guid} href={Links.StrategyDetail(x.teamSlug, x.guid)} cardProps={{ sx: { pt: 1 } }}>
          <StrategyDetail strategy={x} />
        </CardLink>
      ),
    }))
    .concat(
      connectedKeyInitiatives.map<IConnection>((x) => ({
        teamName: x.teamName,
        type: ConnectionType.KeyInitiative,
        description: x.description,
        userDisplayName: null,
        element: (
          <CardLink key={x.guid} href={Links.KeyInitiativeDetail(x.teamSlug, x.guid)} cardProps={{ sx: { pt: 1 } }}>
            <KeyInitiativeDetail keyInitiative={x} />
          </CardLink>
        ),
      }))
    )
    .concat(
      connectedQuarterlyPriorities.map<IConnection>((x) => ({
        teamName: null,
        type: ConnectionType.QuarterlyPriority,
        description: x.description,
        userDisplayName: x.user.displayName,
        element: (
          <Card key={x.guid} sx={{ p: 1 }}>
            <QuarterlyPriorityDetail quarterlyPriority={x} />
          </Card>
        ),
      }))
    );

  return connections
    .sort(
      (a, b) =>
        (a.teamName != null && b.teamName != null && a.teamName.localeCompare(b.teamName)) ||
        (a.type > b.type ? 1 : a.type === b.type ? 0 : -1) ||
        (a.userDisplayName != null &&
          b.userDisplayName != null &&
          a.userDisplayName.localeCompare(b.userDisplayName)) ||
        a.description.localeCompare(b.description)
    )
    .map((x) => x.element);
};

export { renderSortedConnections };
