import { CalendarMonthOutlined, KeyboardArrowDownRounded } from "@mui/icons-material";
import { Button, MenuItem, Popover, Skeleton, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useGetSurveyInstances } from "../http";

interface IProps {
  surveyGuid: string;
  surveyInstanceGuid: string | undefined;
  hideActiveInstance?: boolean;
  onChange: (surveyInstanceName: string | undefined, surveyInstanceGuid: string | undefined) => void;
}

interface ISurveyInstance {
  name: string;
  guid: string;
  sortOrder: number;
}

const SurveyInstancePicker = (props: IProps) => {
  const { data, isLoading } = useGetSurveyInstances(props.surveyGuid);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [surveyInstances, setSurveyInstances] = useState<ISurveyInstance[]>([]);

  useEffect(() => {
    if (!isLoading && data) {
      const surveyInstances: ISurveyInstance[] = [
        {
          guid: "",
          name: "All",
          sortOrder: 1,
        },
        ...data.surveyInstances
          .filter((x) => !props.hideActiveInstance || (x.endDateUtc && x.endDateUtc < DateTime.utc().toISO()))
          .sort((a, b) => b.startDateUtc.localeCompare(a.startDateUtc))
          .map((x, i) => ({
            guid: x.surveyInstanceGuid,
            name: getSurveyInstanceName(x.startDateUtc, x.endDateUtc),
            sortOrder: i + 1,
          })),
      ];

      setSurveyInstances(surveyInstances);
      handleSelectionChanged(surveyInstances[1].name, surveyInstances[1].guid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  useEffect(() => {
    // If instances are loaded, but the selected survey instance has been cleared, reset it to the default.
    if (surveyInstances.length > 0 && props.surveyInstanceGuid === undefined) {
      handleSelectionChanged(surveyInstances[1].name, surveyInstances[1].guid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, props.surveyInstanceGuid]);

  function getSurveyInstanceName(startDateUtc: string, endDateUtc?: string) {
    const startDate = DateTime.fromISO(startDateUtc, { zone: "utc" });
    const endDate = endDateUtc ? DateTime.fromISO(endDateUtc, { zone: "utc" }) : undefined;
    const formattedStartDate = startDate.toLocal().toLocaleString(DateTime.DATE_MED);
    const formattedEndDate = endDate ? endDate.toLocal().toLocaleString(DateTime.DATE_MED) : "End Date Not Set";
    const name = `${formattedStartDate} - ${formattedEndDate}`;

    return name;
  }

  function handleSelectionChanged(surveyInstanceName: string | undefined, surveyInstanceGuid: string | undefined) {
    setMenuAnchor(null);
    props.onChange(surveyInstanceName, surveyInstanceGuid);
  }

  return (
    <>
      <Button
        variant="tertiary"
        startIcon={<CalendarMonthOutlined />}
        endIcon={<KeyboardArrowDownRounded />}
        onClick={(e) => setMenuAnchor(e.currentTarget)}
      >
        {props.surveyInstanceGuid === undefined
          ? "Select Period"
          : surveyInstances.find((x) => x.guid === props.surveyInstanceGuid)?.name}
      </Button>

      <Popover
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "10px",
            },
          },
        }}
      >
        <Stack sx={{ m: 0.5 }}>
          {isLoading && [...Array(3)].map((_, i) => <Skeleton key={i} />)}

          {!isLoading &&
            data &&
            surveyInstances
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((x, i) => (
                <MenuItem
                  key={i}
                  selected={x.guid === props.surveyInstanceGuid}
                  value={x.guid}
                  onClick={() => handleSelectionChanged(x.name, x.guid)}
                >
                  {x.name}
                </MenuItem>
              ))}
        </Stack>
      </Popover>
    </>
  );
};

export { SurveyInstancePicker };
