import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { CreateSurveyDto, CreateSurveyResultsDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  name?: string;
  userId: string;
  startDateUtc: string;
};

const useCreateSurveyRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createSurveyRequest(variables: Variables): Promise<CreateSurveyResultsDto> {
    const accessToken = await getAccessToken();
    const body: CreateSurveyDto = {
      userId: variables.userId,
      name: variables.name,
      startDateUtc: variables.startDateUtc,
    };

    const response = await fetch(`${configuration.apiRootUrl}/surveys`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { createSurveyRequest };
};

const useCreateSurvey = () => {
  const { createSurveyRequest } = useCreateSurveyRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSurveyRequest,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurveys() });
    },
  });
}

export { useCreateSurvey };