import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError } from "../../types";
import { EditSurveyQuestionDescriptionDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  surveyGuid: string;
  description: string;
  modifiedByUserId: string;
};

const useEditSurveyQuestionDescriptionRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editSurveyQuestionDescriptionRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();

    const body: EditSurveyQuestionDescriptionDto = {
      description: variables.description,
      modifiedByUserId: variables.modifiedByUserId,
      surveyGuid: variables?.surveyGuid
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-questions/${variables.guid}/description`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editSurveyQuestionDescriptionRequest };
};

const useEditSurveyQuestionDescription = () => {
  const { editSurveyQuestionDescriptionRequest } = useEditSurveyQuestionDescriptionRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editSurveyQuestionDescriptionRequest,
    onSuccess: async (_data, variables) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurveyQuestion(variables.guid) });
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurveyQuestions(variables.surveyGuid) });
    },
  });
};

export { useEditSurveyQuestionDescription };
