import { Card, CardHeader, Divider, Grid, Stack, Typography } from "@mui/material";
import { RoleList, UserAbout } from "./components";
import { CommentSubject } from "../../../constants";
import { Comments } from "../../../components";
import { useParams } from "react-router";

interface IParams {
  userId: string;
}

const UserOverviewPage = () => {
  const { userId } = useParams<keyof IParams>() as IParams;

  return (
    <Grid container spacing={1} sx={{ justifyContent: "center" }}>
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title={<Typography variant="h6">Feed</Typography>} />
          <Divider />
          <Comments subjectType={CommentSubject.User} subjectGuid={userId} />
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={1}>
          <UserAbout />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title={<Typography variant="h6">Roles</Typography>} />
          <Divider />
          <RoleList userId={userId} />
        </Card>
      </Grid>
    </Grid>
  );
};

export { UserOverviewPage };
