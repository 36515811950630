import { Link, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { FeedItemProperty, Links } from "../../constants";
import { FeedItemDto } from "../../http";
import { ContextPropertyChange } from "./ContextPropertyChange";
import { DescriptionPropertyChange } from "./DescriptionPropertyChange";
import { ActionItemNotePropertyChange } from "./ActionItemNotePropertyChange";
import { ActionItemOwnershipPropertyChange } from "./ActionItemOwnershipPropertyChange";
import { ActionItemStatusPropertyChange } from "./ActionItemStatusPropertyChange";
import { IssueStatusPropertyChange } from "./IssueStatusPropertyChange";

interface IProps {
  feedItem: FeedItemDto;
}

const FeedPropertyChange = (props: IProps) => {
  function renderPropertyChange() {
    switch (props.feedItem.propertyChange!.propertyName) {
      case FeedItemProperty.Context:
        return <ContextPropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.Description:
        return <DescriptionPropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.ActionItemNote:
        return <ActionItemNotePropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.ActionItemOwnership:
        return <ActionItemOwnershipPropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.ActionItemStatus:
        return <ActionItemStatusPropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.IssueStatus:
        return <IssueStatusPropertyChange feedItem={props.feedItem} />;
      default:
        return (
          <Typography variant="caption">
            <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
            <span> updated </span>
            {props.feedItem.propertyChange!.propertyName}
          </Typography>
        );
    }
  }

  return (
    <Stack
      direction="row"
      spacing={0.25}
      sx={{ backgroundColor: "rgba(0, 0, 0, 0.03)", px: { xs: 0.5, sm: 1 }, py: 0.5 }}
    >
      <Typography variant="caption">&bull;</Typography>
      <Stack direction="column">
        <Typography variant="caption">
          {DateTime.fromISO(props.feedItem.timestampUtc, { zone: "utc" })
            .toLocal()
            .toLocaleString(DateTime.DATETIME_MED)}
        </Typography>

        {renderPropertyChange()}
      </Stack>
    </Stack>
  );
};

export { FeedPropertyChange };
