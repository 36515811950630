import { Card, CardContent, Divider, Skeleton, Stack, Typography, useTheme } from "@mui/material";

interface IProps {
  label: string;
  color: string;
  teamLabel: string;
  isLoading?: boolean;
  coherenceScore: number | undefined;
  responseCount: number | undefined;
  responseRate: number | undefined;
}

const CoherenceScore = (props: IProps) => {
  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <Stack spacing={1}>
          <Stack>
            <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
              {props.label}
            </Typography>
            <Typography variant="h4" sx={{ color: props.color }}>
              {props.teamLabel}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} divider={<Divider flexItem orientation="vertical" />}>
            <Stack flex={1}>
              <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
                Coherence
              </Typography>
              <Typography variant="h1" sx={{ color: props.color }}>
                {props.isLoading ? <Skeleton height={"unset"} width={"100px"} /> : props.coherenceScore ?? "-"}
              </Typography>
            </Stack>
            <Stack flex={1}>
              <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
                Responses
              </Typography>
              <Typography variant="h1" sx={{ color: theme.palette.grey[300] }}>
                {props.isLoading ? <Skeleton height={"unset"} width={"100px"} /> : props.responseCount ?? "-"}
              </Typography>
            </Stack>
            <Stack flex={1}>
              <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
                Response Rate
              </Typography>
              <Typography variant="h1" sx={{ color: theme.palette.grey[300] }}>
                {props.isLoading ? (
                  <Skeleton height={"unset"} width={"100px"} />
                ) : props.responseRate ? (
                  `${props.responseRate}%`
                ) : (
                  "-"
                )}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export { CoherenceScore };
