import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";
import { Paths } from "../constants";

const msalConfig: Configuration = {
  auth: {
    clientId: "96bcce79-a028-4c88-9f8f-78a0d2bc4ae6",
    authority: "https://login.microsoftonline.com/b4e19cf5-ed91-44d3-81ce-70c848fa6841",
    navigateToLoginRequestUrl: true,
    redirectUri: Paths.Home,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Warning,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export { msalConfig };
