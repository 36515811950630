import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { HttpResponse } from "../http";

class ApplicationError {
  status: number | undefined;
  statusText: string;

  constructor(status: number | undefined, statusText: string) {
    this.status = status;
    this.statusText = statusText;
  }

  static fromHttpResponse<T>(response: HttpResponse<T>) {
    return new ApplicationError(response.status, response.statusText);
  }

  static fromError(error: unknown) {
    const errorMessage = (error as Error)?.message ?? "Unknown error";

    if (error instanceof InteractionRequiredAuthError) {
      return new ApplicationError(401, errorMessage);
    }

    return new ApplicationError(undefined, errorMessage);
  }
}

export { ApplicationError };
