import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IRoleAccountability } from "../../../models";
import { EditRoleDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  teamSlug: string;
  name: string;
  description: string;
  isOpen: boolean;
  accountabilities: IRoleAccountability[];
  tags: string[];
  memberUserIds: string[];
  modifiedByUserId: string;
};

const useEditRoleRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editRoleRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditRoleDto = {
      teamSlug: variables.teamSlug,
      name: variables.name,
      description: variables.description,
      isOpen: variables.isOpen,
      modifiedByUserId: variables.modifiedByUserId,
      accountabilities: variables.accountabilities,
      tags: variables.tags,
      memberUserIds: variables.memberUserIds,
    };

    const response = await fetch(`${configuration.apiRootUrl}/roles/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editRoleRequest };
};

const useEditRole = () => {
  const { editRoleRequest } = useEditRoleRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editRoleRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeams(variables.modifiedByUserId) });
      await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeamNetworkGraph(variables.teamSlug)});
      await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeamNetworkList(variables.teamSlug)});
      await queryClient.refetchQueries({ queryKey: queryKeys.roles.getRole(variables.guid) });
    },
  });
};

export { useEditRole };
