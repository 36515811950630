import { IEditTeamPlanPageAction, IEditTeamPlanPageState } from "../state";
import { EditTextField } from "./EditTextField";

interface IProps {
  state: IEditTeamPlanPageState;
  dispatch: React.Dispatch<IEditTeamPlanPageAction>;
  onFocus: () => void;
}

const EditPurpose = (props: IProps) => {
  const { state, dispatch } = props;

  function handleChange(purpose: string) {
    dispatch({ type: "edit_purpose", purpose });
  }

  return (
    <EditTextField
      label="Purpose"
      value={state.plan.purpose}
      onFocus={props.onFocus}
      onChange={(e) => handleChange(e.target.value)}
      inputProps={{ maxLength: 512 }}
    />
  );
};

export { EditPurpose };
