import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { SurveyQuestionDetail } from "../../../models";
import { FetchError, GetSurveyQuestionDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetSurveyQuestionRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getSurveyQuestionRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.surveys.getSurveyQuestion>>): Promise<GetSurveyQuestionDto> {
    const accessToken = await getAccessToken();
    const [{ guid }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/survey-questions/${guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getSurveyQuestionRequest };
};

const useGetSurveyQuestion = (guid: string | undefined) => {
  const { getSurveyQuestionRequest } = useGetSurveyQuestionRequest();

  return useQuery({
    queryKey: queryKeys.surveys.getSurveyQuestion(guid),
    queryFn: getSurveyQuestionRequest,
    select: (question): SurveyQuestionDetail => question,
    enabled: guid !== undefined,
  });
};

export { useGetSurveyQuestion };
