import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";
import { EditUserDetailsDto, FetchError } from "../../types";
import { DateTime } from "luxon";
import { ILocation } from "../../../models";

type Variables = {
  guid: string;
  bio: string;
  joinedDate: DateTime | null;
  pronouns: string;
  peopleLeadUserId: string | null;
  location: ILocation | null;
};

const useEditUserRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editUserRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditUserDetailsDto = {
      bio: variables.bio,
      joinedDate: variables.joinedDate?.toString() ?? null,
      pronouns: variables.pronouns,
      peopleLeadUserId: variables.peopleLeadUserId,
      countryCode: variables.location?.countryCode ?? null,
      stateCode: variables.location?.stateCode ?? null,
      cityName: variables.location?.cityName ?? null,
    };

    const response = await fetch(`${configuration.apiRootUrl}/users/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editUserRequest };
};

const useEditUser = () => {
  const { editUserRequest } = useEditUserRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editUserRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.users.getUser(variables.guid) });
    },
  });
};

export { useEditUser };
