import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { ISurveySummary } from "../../../models";
import { FetchError, GetSurveysDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetSurveysRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getSurveysRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.surveys.getSurveys>>): Promise<GetSurveysDto> {
    const accessToken = await getAccessToken();
    const [{ userId }] = queryKey;
    const url = new URL(`${configuration.apiRootUrl}/surveys`);

    if (userId) {
      url.searchParams.set("userId", userId);
    }

    const response = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getSurveysRequest };
}

const useGetSurveys = (userId?: string) => {
  const { getSurveysRequest } = useGetSurveysRequest();

  return useQuery({
    queryKey: queryKeys.surveys.getSurveys(userId),
    queryFn: getSurveysRequest,
    select: (data): ISurveySummary[] => {
      return data.surveys
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((survey) => ({
          name: survey.name,
          description: survey.description,
          guid: survey.guid,
          isAcceptingResponses: survey.isAcceptingResponses,
          administrators: survey.administrators,
        }))
    },
  });
};

export { useGetSurveys };
