import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetPlanStructureDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetPlanStructureRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getPlanStructureRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.plans.getStructure>>): Promise<GetPlanStructureDto> {
    const accessToken = await getAccessToken();
    const [{ guid }] = queryKey;

    if (guid == null) {
      return {
        blocks: [],
      };
    }

    const response = await fetch(`${configuration.apiRootUrl}/plans/${guid}/structure`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getPlanStructureRequest };
};

const useGetPlanStructure = (planGuid: string, enabled: boolean = true) => {
  const { getPlanStructureRequest } = useGetPlanStructureRequest();

  return useQuery({
    queryKey: queryKeys.plans.getStructure(planGuid),
    queryFn: getPlanStructureRequest,
    enabled: enabled,
  });
};

export { useGetPlanStructure };
