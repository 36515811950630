import { Button, Card, Grid, Stack, useTheme } from "@mui/material";
import { HeaderLayout } from "../../components";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

interface IProps {
  children?: React.ReactNode;
  faded?: boolean;
  actions?: React.ReactNode;
  sideBarContent?: React.ReactNode;
}

const DetailPageLayout = (props: IProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <HeaderLayout
      title={
        <Stack direction="row" justifyContent="space-between">
          <Button
            variant="tertiary"
            startIcon={<ArrowBackIosNewRoundedIcon fontSize="large" />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          {props.actions}
        </Stack>
      }
    >
      <Grid container spacing={1} sx={{ justifyContent: "center" }}>
        <Grid sx={{ width: "100%" }} item md={8} sm={12}>
          <Card sx={{ background: props.faded ? theme.palette.grey[100] : undefined }}>{props.children}</Card>
        </Grid>
        {props.sideBarContent && !props.faded && (
          <Grid md={4} xs={12} item>
            {props.sideBarContent}
          </Grid>
        )}
      </Grid>
    </HeaderLayout>
  );
};

export { DetailPageLayout };
