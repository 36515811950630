import { FolderOffOutlined, MoreVertRounded, OpenInNewRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Loading } from "../../../../components";
import { useError, useProxy, useSession } from "../../../../hooks";
import { IResource } from "../../../../models";
import { EditResourceModal } from "./EditResourceModal";
import { useConfirm } from "material-ui-confirm";
import { useDeleteResource } from "../../../../http";
import { useParams } from "react-router";

const IFrameContainer = styled("div")`
  position: relative;
  aspect-ratio: 16/9;
  height: 100%;
  width: 100%;
`;

const IFrameOverlay = styled("div")`
  position: absolute;
  z-index: 1;
  pointer-events: none;
  height: 100%;
  width: 100%;
`;

const IFrame = styled("iframe")`
  position: absolute;
  border: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.palette.grey[200]};
  height: 100%;
  width: 100%;
`;

const IFramePlaceholder = styled("div")`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.background.default};
  height: 100%;
  width: 100%;
`;

const ResourceContainer = styled(Stack)`
  border: solid;
  cursor: pointer;
  border-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.5)};
  border-width: 1px;
  border-radius: 10px;
`;

interface IParams {
  slug: string;
}

interface IProps {
  resource: IResource;
  isEditable: boolean;
}

const TeamResource = (props: IProps) => {
  const { slug } = useParams<keyof IParams>() as IParams;
  const { userId } = useSession();
  const { getHeaders } = useProxy();
  const { setError } = useError();
  const { mutate: deleteResource } = useDeleteResource();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [httpStatusError, setHttpStatusError] = useState(false);
  const [xframeOptionsError, setXframeOptionsError] = useState(false);
  const confirm = useConfirm();
  const theme = useTheme();

  useEffect(() => {
    validateUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resource.isEmbedded, props.resource.url]);

  async function validateUrl() {
    if (props.resource.url === "") {
      return;
    }

    setIsLoading(true);

    if (!props.resource.isEmbedded) {
      setIsLoading(false);
      return;
    }

    // Reset errors
    setHttpStatusError(false);
    setXframeOptionsError(false);

    const formattedUrl = formatUrl(props.resource.url);
    const encodedUrl = encodeURIComponent(formattedUrl);

    (await getHeaders(encodedUrl)).match(
      (result) => {
        if (result.statusCode !== 200) {
          setHttpStatusError(true);
        } else if (result.xFrameOptions != null) {
          const xFrameOptions = result.xFrameOptions.toLowerCase();

          if (xFrameOptions === "deny") {
            setXframeOptionsError(true);
          } else {
            const resourceDomain = getDomain(formattedUrl);
            const currentDomain = window.location.hostname;

            if (xFrameOptions === "sameorigin" && resourceDomain !== currentDomain) {
              setXframeOptionsError(true);
            }
          }
        }

        setIsLoading(false);
      },
      (error) => setError(error)
    );
  }

  function getDomain(url: string) {
    var found = url.match(/https?:\/\/(?<domain>[A-Za-z0-9-.]+).*/);

    if (found?.groups && found.groups["domain"] !== undefined) {
      return found.groups["domain"];
    }

    return null;
  }

  function formatUrl(url: string) {
    let formattedUrl = url;

    if (formattedUrl.includes("<iframe")) {
      var found = formattedUrl.match(/src="(?<url>[^"]*)"/);

      if (found?.groups && found.groups["url"] !== undefined) {
        formattedUrl = found.groups["url"];
      }
    }

    if (!formattedUrl.startsWith("http://") && !formattedUrl.startsWith("https://")) {
      formattedUrl = `https://${formattedUrl}`;
    }

    return formattedUrl;
  }

  async function handleDeleteClicked() {
    setMenuAnchor(null);

    try {
      await confirm({
        title: "Delete Resource",
        content: "Are you sure you want to delete this resource?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteResource({
      teamSlug: slug,
      guid: props.resource.guid,
      modifiedByUserId: userId!,
    });
  }

  return (
    <>
      <Stack direction="row" spacing={0.5}>
        {props.resource.isEmbedded ? (
          <ResourceContainer sx={{ pt: 0.25, pb: 0.75, px: 0.75, width: "100%" }} spacing={0.25}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {props.resource.title && (
                <>
                  <Link href={formatUrl(props.resource.url)} target="_blank" underline="none">
                    <Typography variant="button">{props.resource.title}</Typography>
                  </Link>
                  <Link href={formatUrl(props.resource.url)} sx={{ height: "20px" }} target="_blank" underline="none">
                    <OpenInNewRounded sx={{ fontSize: "20px" }} />
                  </Link>
                </>
              )}
            </Stack>
            <Box>
              {props.resource.url ? (
                <>
                  {isLoading ? (
                    <IFrameContainer>
                      <Loading />
                    </IFrameContainer>
                  ) : (
                    <IFrameContainer>
                      <IFrameOverlay />
                      {httpStatusError ? (
                        <EmbeddedResourceError errorMessage="This resource could not be found" />
                      ) : xframeOptionsError ? (
                        <EmbeddedResourceError errorMessage="This resource does not allow itself to be embedded" />
                      ) : (
                        <IFrame title={props.resource.title} src={formatUrl(props.resource.url)} />
                      )}
                    </IFrameContainer>
                  )}
                </>
              ) : (
                <IFrameContainer>
                  <IFrameOverlay />
                  <IFramePlaceholder />
                </IFrameContainer>
              )}
            </Box>
          </ResourceContainer>
        ) : (
          <Button
            href={formatUrl(props.resource.url)}
            variant="outlined"
            target="_blank"
            sx={{ width: "100%", justifyContent: "space-between" }}
            endIcon={<OpenInNewRounded />}
          >
            {props.resource.title}
          </Button>
        )}

        {props.isEditable && (
          <IconButton size="small" sx={{ alignSelf: "center" }} onClick={(e) => setMenuAnchor(e.currentTarget)}>
            <MoreVertRounded />
          </IconButton>
        )}
      </Stack>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            setIsEditModalVisible(true);
            setMenuAnchor(null);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={handleDeleteClicked} sx={{ color: theme.palette.error.main }}>
          Delete
        </MenuItem>
      </Menu>

      <EditResourceModal
        visible={isEditModalVisible}
        resource={props.resource}
        onUpdated={() => setIsEditModalVisible(false)}
        onCancelled={() => setIsEditModalVisible(false)}
      />
    </>
  );
};

const EmbeddedResourceError = (props: { errorMessage: string }) => {
  return (
    <Stack spacing={0.25} sx={{ alignItems: "center", justifyContent: "center", height: "100%" }}>
      <FolderOffOutlined sx={{ color: "grey.300", height: 40, width: 40 }} />
      <Typography variant="body2" sx={{ color: "grey.300" }}>
        Unable to Load Resource
      </Typography>
      <Typography variant="caption">{props.errorMessage}</Typography>
    </Stack>
  );
};

export { TeamResource };