import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSession } from "../../hooks";
import { useEditRole } from "../../http";
import { IRoleDetail } from "../../models";
import { CreateEditRoleForm, ICreateEditRoleFormState } from "./CreateEditRoleForm";

interface IProps {
  visible: boolean;
  role: IRoleDetail;
  onUpdated: () => void;
  onCancelled: () => void;
}

const EditRoleModal = (props: IProps) => {
  const { userId } = useSession();
  const { mutate: editRole, isLoading: isUpdatingRole } = useEditRole();

  const { formState, control, handleSubmit, reset } = useForm<ICreateEditRoleFormState>({
    defaultValues: {
      team: props.role.parentTeam,
      name: props.role.name,
      description: props.role.description,
      isOpen: props.role.isOpen,
      accountabilities: props.role.accountabilities,
      tags: props.role.tags,
      members: props.role.members,
    },
  });

  useEffect(() => {
    reset({
      team: props.role.parentTeam,
      name: props.role.name,
      description: props.role.description,
      isOpen: props.role.isOpen,
      accountabilities: props.role.accountabilities,
      tags: props.role.tags,
      members: props.role.members,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.role]);

  async function handleUpdateClicked() {
    handleSubmit((data) => {
      editRole(
        {
          guid: props.role.guid,
          teamSlug: data.team!.slug,
          name: data.name,
          description: data.description,
          isOpen: data.isOpen,
          accountabilities: data.accountabilities,
          tags: data.tags,
          memberUserIds: data.members.map((x) => x.userId),
          modifiedByUserId: userId!,
        },
        {
          onSuccess: () => {
            props.onUpdated();
          },
        }
      );
    })();
  }

  function handleClose(_: object, reason: string) {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    handleCancelClicked();
  }

  function handleCancelClicked() {
    reset(undefined, { keepDefaultValues: true });
    props.onCancelled();
  }

  return (
    <Dialog open={props.visible} onClose={handleClose}>
      <DialogTitle>Edit Role</DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <CreateEditRoleForm formState={formState} control={control} loadingEnabled={props.visible} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isUpdatingRole} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isUpdatingRole}
          endIcon={isUpdatingRole ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleUpdateClicked}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { EditRoleModal };
