import { Plan, Typography } from "../../../components";
import { useUserPageLayoutContext } from "../UserPageLayout";
import { useSession } from "../../../hooks";
import { Skeleton } from "@mui/material";

const UserMapPage = () => {
  const { userId } = useSession();
  const { user, isLoadingUser } = useUserPageLayoutContext();

  const hasEditPermission = user?.userId === userId ?? false;

  if (!isLoadingUser && user) {
    return (
      <Plan
        planGuid={user.planGuid}
        canEdit={hasEditPermission}
        headerContent={({ isLoading, plan }) => {
          return isLoading || !plan ? (
            <Skeleton variant="rectangular" height={30} width={130} />
          ) : (
            <Typography variant="h5">{hasEditPermission ? "My Map" : "Map"}</Typography>
          );
        }}
      />
    );
  } else {
    return null;
  }
};

export { UserMapPage };
