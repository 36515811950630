import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, FilterListRounded } from "@mui/icons-material";
import { Divider, MenuItem as MuiMenuItem, Stack, Typography, styled } from "@mui/material";
import { TertiarySelect } from "../../../../components";

const MenuItem = styled(MuiMenuItem)`
  background-color: transparent !important; // Disable all hover, focus, active, etc. effects
  padding-left: 0;
  padding-right: ${({ theme }) => theme.spacing(0.5)};
`;

interface IProps {
  questions: { questionGuid: string; questionName: string; questionTags: string[] }[];
  selectedQuestionGuids: string[];
  onChange: (value: string[]) => void;
  disabled: boolean;
}

const QuestionFilter = (props: IProps) => {
  return (
    <TertiarySelect
      multiple
      value={props.selectedQuestionGuids}
      renderValue={() => (
        <Stack direction="row" spacing={0.25} alignItems="center">
          <FilterListRounded />
          <Typography variant="button">
            {props.selectedQuestionGuids.length > 1
              ? `${props.selectedQuestionGuids.length} Questions`
              : props.selectedQuestionGuids.length === 1
              ? "1 Question"
              : "No questions selected"}
          </Typography>
        </Stack>
      )}
      displayEmpty
      onChange={(e) => props.onChange(e.target.value as string[])}
      MenuProps={{
        anchorOrigin: {
          horizontal: "left",
          vertical: "bottom",
        },
        transformOrigin: {
          horizontal: "left",
          vertical: "top",
        },
        slotProps: {
          paper: {
            sx: {
              p: 0.25,
            },
          },
        },
      }}
      sx={{ alignSelf: "flex-start", mx: 1, my: 0.5 }}
      disabled={props.disabled}
    >
      <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
        Questions
      </Typography>
      <Divider sx={{ mb: 0.25 }} />

      {props.questions
        .sort((a, b) => a.questionName.localeCompare(b.questionName))
        .map((x) => (
          <MenuItem key={x.questionGuid} value={x.questionGuid} disableRipple>
            <Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                {props.selectedQuestionGuids.includes(x.questionGuid) ? (
                  <CheckBoxOutlined />
                ) : (
                  <CheckBoxOutlineBlankOutlined />
                )}
                <Typography>{x.questionName}</Typography>
              </Stack>
              <Typography variant="caption" sx={{ textTransform: "uppercase", pl: 1.5 }}>
                {x.questionTags.join(", ")}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
    </TertiarySelect>
  );
};

export { QuestionFilter };
