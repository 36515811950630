import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { ITeamSummary } from "../../../models";
import { FetchError, GetTeamsDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetTeamsRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getTeamsRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.teams.getTeams>>): Promise<GetTeamsDto> {
    const accessToken = await getAccessToken();
    const [{ userId }] = queryKey;
    const url = new URL(`${configuration.apiRootUrl}/teams`);

    if (userId) {
      url.searchParams.set("userId", userId);
    }

    const response = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getTeamsRequest };
};

const useGetTeams = (userId?: string, enabled: boolean = true) => {
  const { getTeamsRequest } = useGetTeamsRequest();

  return useQuery({
    queryKey: queryKeys.teams.getTeams(userId),
    queryFn: getTeamsRequest,
    select: (data): ITeamSummary[] => {
      return data.teams
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((team) => ({
          name: team.name,
          slug: team.slug,
          description: team.description,
          parentTeam: team.parentTeam,
          planGuid: team.planGuid,
          isAdministrator: team.isAdministrator,
          isMember: team.isMember,
          isFollowing: team.isFollowing,
        }));
    },
    enabled: enabled,
  });
};

export { useGetTeams };
