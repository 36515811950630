import { useContext } from "react";
import { SessionContext } from "../components";

interface ISafeSessionContext {
  isSessionLoaded: boolean;
  displayName: string;
  planGuid: string;
  profilePhoto: string;
  userId: string;
  endSession: () => void;
}

const useSession = () => {
  const unsafeSessionContext = useContext(SessionContext);

  if (unsafeSessionContext.isSessionLoaded && unsafeSessionContext.user?.displayName === undefined) {
    throw new Error("Session displayName not initialized.");
  }

  if (unsafeSessionContext.isSessionLoaded && unsafeSessionContext.user?.profilePhoto === undefined) {
    throw new Error("Session profilePhoto not initialized.");
  }

  if (unsafeSessionContext.isSessionLoaded && unsafeSessionContext.user?.userId === undefined) {
    throw new Error("Session userId not initialized.");
  }

  if (unsafeSessionContext.isSessionLoaded && unsafeSessionContext.user?.planGuid === undefined) {
    unsafeSessionContext.endSession();
  }

  const safeSessionContext: ISafeSessionContext = {
    isSessionLoaded: unsafeSessionContext.isSessionLoaded,
    displayName: unsafeSessionContext.user!.displayName!,
    profilePhoto: unsafeSessionContext.user!.profilePhoto!,
    userId: unsafeSessionContext.user!.userId!,
    planGuid: unsafeSessionContext.user!.planGuid!,
    endSession: unsafeSessionContext.endSession,
  };

  return safeSessionContext;
};

export { useSession };
