import { Link, Typography } from "@mui/material";
import { Links } from "../../constants";
import { FeedItemDto, FeedItemOwnershipValueDto } from "../../http";

interface IProps {
  feedItem: FeedItemDto;
}

const ActionItemOwnershipPropertyChange = (props: IProps) => {
  const owners = props.feedItem.propertyChange?.value as FeedItemOwnershipValueDto[];

  if (owners == null) {
    return (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> updated ownership</span>
      </Typography>
    );
  }

  if (owners.length === 0) {
    return (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> removed all owners</span>
      </Typography>
    );
  }

  return (
    <Typography variant="caption">
      <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
      <span> updated ownership to </span>
      {owners
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map((u, i) => (
          <span key={i}>
            <Link href={Links.User(u.userId)} sx={{ fontWeight: "bold" }}>
              {u.displayName}
            </Link>
            {i < owners.length - 1 && <span>, </span>}
          </span>
        ))}
    </Typography>
  );
};

export { ActionItemOwnershipPropertyChange };
