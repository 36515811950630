import { Link, LinkProps, Stack } from "@mui/material";

interface IProps {
  startIcon?: React.ReactNode;
}

const IconLink = (props: IProps & LinkProps) => {
  const { startIcon, children, ...other } = props;

  return (
    <Link variant="caption" sx={{ textTransform: "uppercase", alignSelf: "flex-start" }} {...other}>
      <Stack direction="row" alignItems="center">
        {startIcon}
        {children}
      </Stack>
    </Link>
  );
};

export { IconLink };
