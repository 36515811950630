import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";
import { BarController, BarElement, CategoryScale, Chart as ChartJS, LinearScale } from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import { useCoherenceReportContext } from "../CoherenceReportPage";

ChartJS.register(CategoryScale, LinearScale, BarController, BarElement);

interface IProps {
  question: { name: string; tags: string[]; maxPoints: number; minLabel: string; maxLabel: string };
  selectedTeamsAverage?: number;
  comparisonTeamsAverage?: number;
}

interface IDataset {
  label: string;
  data: number[];
  backgroundColor: string;
}

const LinearScaleResult = (props: IProps) => {
  const chartRef = useRef<ChartJS>(null);
  const theme = useTheme();
  const [datasets, setDatasets] = useState<IDataset[]>([]);
  const { selectedTeamLabel, selectedSurveyInstanceName, comparisonTeamLabel, comparisonSurveyInstanceName } =
    useCoherenceReportContext();

  useEffect(() => {
    let datasets: IDataset[] = [];

    if (props.selectedTeamsAverage) {
      datasets.push({
        label: `${selectedTeamLabel ?? "Me"}, ${selectedSurveyInstanceName}`,
        data: [props.selectedTeamsAverage],
        backgroundColor: theme.palette.primary.main,
      });
    }

    if (props.comparisonTeamsAverage) {
      datasets.push({
        label: comparisonTeamLabel !== undefined ? `${comparisonTeamLabel}, ${comparisonSurveyInstanceName}` : "-",
        data: [props.comparisonTeamsAverage],
        backgroundColor: theme.palette.orange.main,
      });
    }

    setDatasets(datasets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTeamsAverage, props.comparisonTeamsAverage]);

  function drawLabels() {
    const chart = chartRef.current;

    if (chart == null) {
      return;
    }

    const context = chart.canvas.getContext("2d");

    if (context == null) {
      return;
    }

    context.font = "12px Open Sans";

    for (var i = 0; i < chart.data.datasets.length; i++) {
      let metaData = chart.getDatasetMeta(i);

      // Add a dataset label above the bar if there are multiple datasets
      if (chart.data.datasets.length > 1) {
        context.fillStyle = "#4d4d4d";
        context.textAlign = "left";
        context.fillText(metaData.label, 15, metaData.data[0].y - 15);
      }

      // Add a value label to the bar
      const value = chart.data.datasets[i].data[0] as number;
      const valueLabel = (Math.round(value * 10) / 10).toFixed(1);
      context.fillStyle = "#fff";
      context.textAlign = "right";
      context.fillText(valueLabel, metaData.data[0].x - 5, metaData.data[0].y + 5);
    }
  }

  return (
    <Stack spacing={0.5}>
      <Typography variant="body2">{props.question.name}</Typography>

      {props.question.tags && props.question.tags.length > 0 && (
        <Stack direction="row" spacing={0.5}>
          {props.question.tags.map((questionTag, index) => (
            <Chip key={index} label={questionTag} sx={{ color: theme.palette.grey[300] }} />
          ))}
        </Stack>
      )}

      <Box>
        <Box sx={{ height: props.comparisonTeamsAverage ? 125 : 75 }}>
          <Chart
            type="bar"
            ref={chartRef}
            options={{
              indexAxis: "y",
              responsive: true,
              maintainAspectRatio: false,
              events: [],
              plugins: {
                title: {
                  display: false,
                },
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              datasets: {
                bar: {
                  maxBarThickness: 20,
                  categoryPercentage: 1,
                },
              },
              scales: {
                x: {
                  min: 1,
                  max: props.question.maxPoints,
                  grid: {
                    drawBorder: true,
                  },
                  ticks: {
                    stepSize: 1,
                  },
                },
                y: {
                  grid: {
                    drawOnChartArea: false,
                  },
                  ticks: {
                    display: false,
                  },
                },
              },
              animation: {
                duration: 1,
                onComplete: () => {
                  drawLabels();
                },
              },
            }}
            data={{
              labels: [""],
              datasets: datasets,
            }}
          />
        </Box>
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between", px: "3px" }}>
          <Typography variant="caption" sx={{ color: theme.palette.grey[400], textTransform: "uppercase" }}>
            {props.question.minLabel}
          </Typography>
          <Typography variant="caption" sx={{ color: theme.palette.grey[400], textTransform: "uppercase" }}>
            {props.question.maxLabel}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export { LinearScaleResult };
