import { GridApiCommunity } from "@mui/x-data-grid-pro/internals";
import React, { useEffect, useState } from "react";
import { TeamPicker } from "..";
import { ITreeNode } from "../../models";

interface IProps {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}

const TeamFilterButton = (props: IProps) => {
  const [selectedTeamLabel, setSelectedTeamLabel] = useState<string | undefined>(undefined);
  const [selectedTeams, setSelectedTeams] = useState<ITreeNode[]>([]);

  // Clear selected nodes when filters are reset
  useEffect(() => {
    return props.apiRef.current.subscribeEvent("filterModelChange", (model) => {
      if (model.items.length === 0) {
        setSelectedTeams([]);
      }
    });
  }, [props.apiRef]);

  // Apply a filter when a team is selected
  function handleTeamSelected(aggregateTeamLabel: string, selectedNodes: ITreeNode[]) {
    const selectedNodeIds = selectedNodes.map((x) => x.nodeId);

    setSelectedTeamLabel(aggregateTeamLabel);
    setSelectedTeams(selectedNodes);

    props.apiRef.current.upsertFilterItem({
      id: "teamFilter",
      field: "teamId",
      operator: "isAnyOf",
      value: selectedNodeIds,
    });
  }

  function handleClearClicked() {
    setSelectedTeams([]);

    props.apiRef.current.upsertFilterItem({
      id: "teamFilter",
      field: "teamId",
      operator: "isAnyOf",
      value: [],
    });
  }

  return (
    <TeamPicker
      isMeVisible={false}
      selectedTeamsLabel={selectedTeamLabel}
      selectedTeams={selectedTeams}
      onMeSelected={() => null}
      onTeamSelected={handleTeamSelected}
      onClearClicked={handleClearClicked}
    />
  );
};

export { TeamFilterButton };
