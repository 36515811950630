import React, { useState } from "react";
import { Card, CardActions, CardContent, Chip, Menu, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import { useNavigate } from "react-router";
import { Links } from "../../../../constants";
import { AvatarGroup, CardLink } from "../../../../components";
import { ISurveySummary } from "../../../../models";
import { useDestructiveConfirm } from "../../../../utilities";
import { useDeleteSurvey } from "../../../../http";

interface SurveyCardProps {
  survey: ISurveySummary;
  isInteractive?: boolean;
}

const SurveyCard = ({ survey, isInteractive = false }: SurveyCardProps) => {
  const theme = useTheme();
  const confirm = useDestructiveConfirm();
  const { mutate: deleteSurvey } = useDeleteSurvey();
  const { guid, name, description, administrators, isAcceptingResponses } = survey;
  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  function handleSurveyEditClicked() {
    navigate(Links.Survey(guid));
  }

  async function handleDeleteClicked() {
    setMenuAnchor(null);

    try {
      await confirm({
        title: "Delete Survey",
        content: "Are you sure you want to delete this survey?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteSurvey({
      guid: survey.guid,
    });
  }

  const renderCardContent = (
    <CardContent>
      <Stack direction="column" spacing={0.25}>
        <Stack sx={{ paddingLeft: [1.5] }}>
          {isAcceptingResponses ? (
            <Chip color="primary" label="accepting responses" />
          ) : (
            <Chip label="not accepting responses" />
          )}
        </Stack>
        <Stack direction="row">
          <HowToVoteIcon fontSize="large" />
          <Typography variant="h5" sx={{ color: theme.palette.grey[isInteractive ? 400 : 300], marginLeft: [0.3] }}>
            {name}
          </Typography>
        </Stack>
        {description && (
          <Typography sx={{ color: theme.palette.grey[isInteractive ? 400 : 300], paddingLeft: [1.5] }}>
            {description}
          </Typography>
        )}
        <Stack sx={{ paddingLeft: [1.5] }}>
          <AvatarGroup sx={{ marginTop: [0.5] }} users={[...administrators]} max={10} />
        </Stack>
      </Stack>
    </CardContent>
  );

  const renderActionsMenu = (
    <>
      <CardActions sx={{ px: 0.4 }}>
        <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)} aria-label="settings" size="large">
          <MoreVertIcon />
        </IconButton>
      </CardActions>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            setMenuAnchor(null);
            handleSurveyEditClicked();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={handleDeleteClicked} sx={{ color: theme.palette.error.main }}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );

  const renderCard = (
    <Card sx={{ borderRadius: "15px" }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {renderCardContent}
        {isInteractive && renderActionsMenu}
      </Stack>
    </Card>
  );

  return isInteractive ? <CardLink href={Links.Survey(guid)}>{renderCard}</CardLink> : <>{renderCard}</>;
};

export { SurveyCard };
