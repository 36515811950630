import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSession } from "../../../../hooks";
import { useEditResource } from "../../../../http";
import { IResource } from "../../../../models";
import { useTeamPageLayoutContext } from "../../TeamPageLayout";
import { CreateEditResourceForm, ICreateEditResourceFormState } from "./CreateEditResourceForm";

interface IProps {
  visible: boolean;
  resource: IResource | undefined;
  onUpdated: () => void;
  onCancelled: () => void;
}

const EditResourceModal = (props: IProps) => {
  const { team } = useTeamPageLayoutContext();
  const { userId } = useSession();
  const { mutate, isLoading } = useEditResource();
  const { formState, control, handleSubmit, reset } = useForm<ICreateEditResourceFormState>({
    defaultValues: {
      title: props.resource?.title ?? "",
      url: props.resource?.url ?? "",
      isEmbedded: props.resource?.isEmbedded ?? false,
      sortOrder: props.resource?.sortOrder ?? "",
    },
  });

  useEffect(() => {
    reset({
      title: props.resource?.title ?? "",
      url: props.resource?.url ?? "",
      isEmbedded: props.resource?.isEmbedded ?? false,
      sortOrder: props.resource?.sortOrder ?? "",
    });
  }, [props.resource, reset]);

  async function handleUpdateClicked() {
    if (props.resource === undefined) {
      return;
    }

    handleSubmit((data) => {
      mutate(
        {
          guid: props.resource!.guid,
          teamSlug: team!.slug,
          title: data.title,
          url: data.url,
          isEmbedded: data.isEmbedded,
          sortOrder: data.sortOrder,
          modifiedByUserId: userId!,
        },
        {
          onSuccess: () => {
            props.onUpdated();
          },
        }
      );
    })();
  }

  function handleClose(_: object, reason: string) {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    handleCancelClicked();
  }

  function handleCancelClicked() {
    reset(undefined, { keepDefaultValues: true });
    props.onCancelled();
  }

  return (
    <Dialog open={props.visible} onClose={handleClose}>
      <DialogTitle>Edit Resource</DialogTitle>
      <DialogContent>
        <CreateEditResourceForm formState={formState} control={control} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isLoading} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          endIcon={isLoading ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleUpdateClicked}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { EditResourceModal };
