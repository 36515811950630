import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { SmallInputLabel, TextEditor } from "../../../../components";

interface IProps {
  visible: boolean;
  loading: boolean;
  onPublish: (notes: string) => void;
  onClose: () => void;
}

const ConfirmPublishModal = (props: IProps) => {
  const [notes, setNotes] = useState("");

  useEffect(() => {
    // Reset notes if the modal was previously dismissed
    if (props.visible) {
      setNotes("");
    }
  }, [props.visible]);

  return (
    <Dialog open={props.visible} onClose={props.onClose}>
      <DialogTitle>Change Log</DialogTitle>
      <DialogContent>
        <Box>
          <SmallInputLabel>Notes</SmallInputLabel>
          <TextEditor
            placeholder="A note about the changes..."
            initialValue={notes}
            onChange={(value) => setNotes(value)}
            readonly={props.loading}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose} disabled={props.loading}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => props.onPublish(notes)} disabled={props.loading}>
          Publish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmPublishModal };
