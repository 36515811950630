import { Box, Stack } from "@mui/material";
import { CommentType, SystemStatus } from "../../constants";
import { IComment, IHistoryLedgerItem, IHistoryLedgerItemChange } from "../../models";

interface IHistoryLedgerMappingStrategy {
  getCreatedText(historyLedgerItem: IHistoryLedgerItem): React.ReactNode;
  getDeletedText(historyLedgerItem: IHistoryLedgerItem): React.ReactNode;
  getEditedText(historyLedgerItemChange: IHistoryLedgerItemChange): React.ReactNode;
  showDeactivatedUserLabels?: boolean;
}

function toComments(historyLedgerItems: IHistoryLedgerItem[], strategy: IHistoryLedgerMappingStrategy): IComment[] {
  return historyLedgerItems.reduce((comments, historyLedgerItem) => {
    return [...comments, toComment(historyLedgerItem, strategy)];
  }, [] as IComment[]);
}

function toComment(historyLedgerItem: IHistoryLedgerItem, strategy: IHistoryLedgerMappingStrategy): IComment {
  var comment = {
    type: CommentType.HistoryLedgerItem,
    timestampUtc: historyLedgerItem.timestampUtc,
    sortOrder: historyLedgerItem.changeType,
    lastModifiedUtc: historyLedgerItem.timestampUtc,
    user: historyLedgerItem.user,
    showDeactivatedUserLabel: strategy.showDeactivatedUserLabels ?? true,
  };

  switch (historyLedgerItem.changeType) {
    case SystemStatus.Created: {
      return {
        ...comment,
        text: strategy.getCreatedText(historyLedgerItem),
      };
    }
    case SystemStatus.Deleted: {
      return {
        ...comment,
        text: strategy.getDeletedText(historyLedgerItem),
      };
    }
    case SystemStatus.Edited: {
      return {
        ...comment,
        text: (
          <Stack spacing={0.2}>
            {historyLedgerItem.changes.map((change, index) => (
              <Box key={index}>{strategy.getEditedText(change)}</Box>
            ))}
          </Stack>
        ),
      };
    }
    default: {
      throw new Error(`Invalid system status: ${historyLedgerItem.changeType}`);
    }
  }
}

const HistoryLedgerMapper = {
  toComments,
};

export { HistoryLedgerMapper };
export type { IHistoryLedgerMappingStrategy };
