import {
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import {
  useGetSurveyQuestion,
  useEditSurveyQuestionIsRequired,
  useEditSurveyQuestionName,
  useEditSurveyQuestionDescription,
  useEditSurveyQuestionType,
  useEditSurveyQuestionTags,
  useDeleteSurveyQuestion,
} from "../../../../http";
import { QuestionType, TagType } from "../../../../constants";
import { SmallInputLabel, TagPicker, TextEditor, useDrawer } from "../../../../components";
import { useSession } from "../../../../hooks";
import { useDestructiveConfirm } from "../../../../utilities";

interface IProps {
  questionGuid: string;
  surveyGuid: string;
}

const SurveyQuestionDrawerContent = ({ questionGuid, surveyGuid }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { closeDrawer } = useDrawer();
  const confirm = useDestructiveConfirm();
  const { mutate: deleteSurveyQuestion, isLoading: isDeletingSurveyQuestion } = useDeleteSurveyQuestion();
  const { userId } = useSession();
  const { data: question, isLoading: isLoadingQuestion } = useGetSurveyQuestion(questionGuid);
  const { mutate: editSurveyQuestionName, isLoading: isUpdatingSurveyQuestionName } = useEditSurveyQuestionName();
  const { mutate: editSurveyQuestionDescription, isLoading: isUpdatingSurveyQuestionDescription } =
    useEditSurveyQuestionDescription();
  const { mutate: editSurveyQuestionTags } = useEditSurveyQuestionTags();
  const { mutate: editSurveyQuestionIsRequired, isLoading: isUpdatingSurveyQuestionIsRequired } =
    useEditSurveyQuestionIsRequired();
  const { mutate: editSurveyQuestionType, isLoading: isUpdatingSurveyQuestionType } = useEditSurveyQuestionType();
  const isLinearScale = question?.questionTypeId === QuestionType.LinearScale;
  const isTitleAndDescription = question?.questionTypeId === QuestionType.TitleAndDescription;

  function handleShowSavedNotification() {
    enqueueSnackbar("Settings saved successfully.", { variant: "success" });
  }

  function handleNameChanged(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    editSurveyQuestionName(
      {
        guid: questionGuid,
        surveyGuid: surveyGuid,
        name: e.target.value,
        modifiedByUserId: userId,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  function handleDescriptionChanged(value: string) {
    editSurveyQuestionDescription(
      {
        guid: questionGuid,
        surveyGuid: surveyGuid,
        description: value,
        modifiedByUserId: userId,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  function handleIsRequiredChanged(e: any) {
    editSurveyQuestionIsRequired(
      {
        guid: questionGuid,
        surveyGuid: surveyGuid,
        isRequired: e.target.checked,
        modifiedByUserId: userId,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  function handleResponseTypeChanged(e: SelectChangeEvent) {
    editSurveyQuestionType(
      {
        guid: questionGuid,
        surveyGuid: surveyGuid,
        modifiedByUserId: userId,
        questionTypeId: Number(e.target.value),
        scalePoints: question?.scalePoints,
        includeNotApplicableOption: question?.includeNotApplicableOption,
        minLabel: question?.minLabel,
        maxLabel: question?.maxLabel,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  function handleScalePointsChanged(e: SelectChangeEvent) {
    editSurveyQuestionType(
      {
        guid: questionGuid,
        surveyGuid: surveyGuid,
        modifiedByUserId: userId,
        questionTypeId: question?.questionTypeId,
        scalePoints: Number(e.target.value),
        includeNotApplicableOption: question?.includeNotApplicableOption,
        minLabel: question?.minLabel,
        maxLabel: question?.maxLabel,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  function handleIncludeOptionChanged(e: SelectChangeEvent) {
    editSurveyQuestionType(
      {
        guid: questionGuid,
        surveyGuid: surveyGuid,
        modifiedByUserId: userId,
        questionTypeId: question?.questionTypeId,
        scalePoints: question?.scalePoints,
        includeNotApplicableOption: JSON.parse(e.target.value),
        minLabel: question?.minLabel,
        maxLabel: question?.maxLabel,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  function handleMinLabelChanged(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    editSurveyQuestionType(
      {
        guid: questionGuid,
        surveyGuid: surveyGuid,
        modifiedByUserId: userId,
        questionTypeId: question?.questionTypeId,
        scalePoints: question?.scalePoints,
        includeNotApplicableOption: question?.includeNotApplicableOption,
        minLabel: e.target.value,
        maxLabel: question?.maxLabel,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  function handleMaxLabelChanged(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    editSurveyQuestionType(
      {
        guid: questionGuid,
        surveyGuid: surveyGuid,
        modifiedByUserId: userId,
        questionTypeId: question?.questionTypeId,
        scalePoints: question?.scalePoints,
        includeNotApplicableOption: question?.includeNotApplicableOption,
        minLabel: question?.minLabel,
        maxLabel: e.target.value,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  function handleTagsChanged(value: string[]) {
    editSurveyQuestionTags(
      {
        guid: questionGuid,
        surveyGuid: surveyGuid,
        tags: value,
      },
      {
        onSuccess: handleShowSavedNotification,
      }
    );
  }

  const handleDeleteClicked = async () => {
    if (question == null) {
      return;
    }

    try {
      await confirm({
        title: `Delete Question`,
        content: <Typography sx={{ marginBottom: [1] }}>Are you sure you want to delete this question?</Typography>,
        confirmationText: `Delete Question`,
        confirmationButtonProps: {
          disabled: isDeletingSurveyQuestion,
          endIcon: isDeletingSurveyQuestion && <CircularProgress size={20} />,
        },
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteSurveyQuestion(
      {
        guid: question.guid,
        surveyGuid: surveyGuid,
      },
      {
        onSuccess: () => {
          closeDrawer();
          enqueueSnackbar(`Question deleted successfully.`, { variant: "success" });
        },
      }
    );
  };

  return (
    <Stack>
      {isLoadingQuestion ? (
        <Stack p={1} spacing={1}>
          <Box>
            <Skeleton variant="rectangular" height={20} sx={{ mb: 0.5 }} />
            <Skeleton variant="rectangular" height={43} />
          </Box>
          <Box>
            <Skeleton variant="rectangular" height={20} sx={{ mb: 0.5 }} />
            <Skeleton variant="rectangular" height={90} />
          </Box>
          <Divider />
          <Box>
            <Skeleton variant="rectangular" height={27} width={134} sx={{ marginLeft: "auto" }} />
          </Box>
        </Stack>
      ) : (
        <Stack p={1} spacing={1}>
          {isTitleAndDescription ? (
            <>
              <Stack>
                <SmallInputLabel>Title</SmallInputLabel>
                <TextField
                  disabled={isUpdatingSurveyQuestionName}
                  onBlur={handleNameChanged}
                  defaultValue={question.name}
                />
              </Stack>
              <Stack>
                <SmallInputLabel>Description</SmallInputLabel>
                <TextEditor
                  readonly={isUpdatingSurveyQuestionDescription}
                  initialValue={question.description}
                  onBlur={handleDescriptionChanged}
                />
              </Stack>
            </>
          ) : (
            <>
              <Stack>
                <SmallInputLabel>Question</SmallInputLabel>
                <TextField
                  disabled={isUpdatingSurveyQuestionName}
                  onBlur={handleNameChanged}
                  defaultValue={question?.name}
                />
              </Stack>
              <Stack>
                <SmallInputLabel>Question Description</SmallInputLabel>
                <TextField
                  disabled={isUpdatingSurveyQuestionDescription}
                  multiline
                  onBlur={(e) => handleDescriptionChanged(e.target.value)}
                  defaultValue={question?.description}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Switch
                  name="required"
                  onChange={handleIsRequiredChanged}
                  disabled={isUpdatingSurveyQuestionIsRequired}
                  checked={question?.isRequired}
                />
                <Typography>Required Question</Typography>
              </Stack>
              <Stack>
                <SmallInputLabel>Response Type</SmallInputLabel>
                <Select
                  disabled={isUpdatingSurveyQuestionType}
                  defaultValue={question?.questionTypeId.toString()}
                  onChange={handleResponseTypeChanged}
                >
                  <MenuItem value="2">Linear Scale</MenuItem>
                  <MenuItem value="1">Text</MenuItem>
                </Select>
              </Stack>
              {isLinearScale && (
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
                  <Box gridColumn="span 6">
                    <Stack>
                      <SmallInputLabel>Scale Points</SmallInputLabel>
                      <Select
                        disabled={isUpdatingSurveyQuestionType}
                        onChange={handleScalePointsChanged}
                        value={question.scalePoints?.toString()}
                      >
                        {[...Array(10)].map((_, index) => (
                          <MenuItem disabled={!index} key={index + 1} value={index + 1}>
                            {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  </Box>
                  <Box gridColumn="span 6">
                    <Stack>
                      <SmallInputLabel>Include non applicable option</SmallInputLabel>
                      <Select
                        disabled={isUpdatingSurveyQuestionType}
                        defaultValue={question.includeNotApplicableOption?.toString()}
                        onChange={handleIncludeOptionChanged}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                  <Box gridColumn="span 6">
                    <Stack>
                      <SmallInputLabel>Min Label</SmallInputLabel>
                      <TextField
                        disabled={isUpdatingSurveyQuestionType}
                        onBlur={handleMinLabelChanged}
                        defaultValue={question.minLabel || "Fully Disagree"}
                      />
                    </Stack>
                  </Box>
                  <Box gridColumn="span 6">
                    <Stack>
                      <SmallInputLabel>Max Label</SmallInputLabel>
                      <TextField
                        disabled={isUpdatingSurveyQuestionType}
                        onBlur={handleMaxLabelChanged}
                        defaultValue={question.maxLabel || "Fully Agree"}
                      />
                    </Stack>
                  </Box>
                  <Box gridColumn="span 12">
                    <Stack>
                      <SmallInputLabel>Question Group</SmallInputLabel>
                      <TagPicker
                        value={question.tags}
                        onChange={(value) => handleTagsChanged(value)}
                        tagType={TagType.SurveyQuestion}
                      />
                    </Stack>
                  </Box>
                </Box>
              )}
            </>
          )}
          <Divider />
          <Stack flexDirection="row-reverse">
            <Button variant="tertiary" color="error" onClick={handleDeleteClicked}>
              Delete Question
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export { SurveyQuestionDrawerContent };
