import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IRoleAccountability } from "../../../models";
import { CreateRoleDto, CreateRoleResultDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  teamSlug: string;
  name: string;
  description: string;
  isOpen: boolean;
  accountabilities: IRoleAccountability[];
  tags: string[];
  members: string[];
  createdByUserId: string;
};

const useCreateRoleRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createRoleRequest(variables: Variables): Promise<CreateRoleResultDto> {
    const accessToken = await getAccessToken();
    const body: CreateRoleDto = {
      teamSlug: variables.teamSlug,
      name: variables.name,
      description: variables.description,
      isOpen: variables.isOpen,
      accountabilities: variables.accountabilities,
      tags: variables.tags,
      memberUserIds: variables.members,
      createdByUserId: variables.createdByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/roles`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { createRoleRequest };
};

const useCreateRole = () => {
  const { createRoleRequest } = useCreateRoleRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createRoleRequest,
    onSuccess: async (data) => {
      await queryClient.removeQueries({ queryKey: queryKeys.teams.getTeamNetworkList(data.teamSlug) });
    },
  });
};

export { useCreateRole };
