import { CircleOutlined, FlagOutlined, FormatListBulletedRounded, Verified } from "@mui/icons-material";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import { DEFAULT_ACTION_ITEM_STATUS, Links } from "../../constants";
import { GetActionItemDto } from "../../http";
import { ItemProperty } from "../ItemProperty";
import { StatusPicker } from "../StatusPicker";

interface IProps {
  actionItem: GetActionItemDto;
}

const ActionItemSupportingItemsProperty = (props: IProps) => {
  const supportingActionItems = props.actionItem.supportingItems.actionItems;
  const supportingIssues = props.actionItem.supportingItems.issues;

  return (
    <ItemProperty labelIcon={<FormatListBulletedRounded />} label="Supporting Items">
      {supportingActionItems.length > 0 || supportingIssues.length > 0 ? (
        <Stack spacing={0.5} divider={<Divider sx={{ borderStyle: "dashed" }} />}>
          {supportingActionItems.length > 0 && (
            <Stack>
              {supportingActionItems.map((actionItem) => {
                return (
                  // Left padding here to line these status dots with the main action item's status selector
                  <Stack sx={{ pl: "3px" }} direction="row" key={actionItem.guid} alignItems="baseline">
                    <StatusPicker
                      actionItemGuid={actionItem.guid}
                      status={actionItem.currentStatus ?? DEFAULT_ACTION_ITEM_STATUS}
                      variant="button"
                      disabled={true}
                    />
                    <Link href={Links.ActionItemDetail(actionItem.guid)}>
                      {actionItem.description == null || actionItem.description.trim() === "" ? (
                        <Typography variant="placeholder">Nothing here yet.</Typography>
                      ) : (
                        <Typography variant="body1">{actionItem.description}</Typography>
                      )}
                    </Link>
                  </Stack>
                );
              })}
            </Stack>
          )}

          {supportingIssues.length > 0 && (
            <Stack>
              {supportingIssues.map((issue) => {
                return (
                  <Stack sx={{ pl: "3px" }} direction="row" key={issue.guid} alignItems="baseline">
                    <Box sx={{ px: "5px", mr: 0.25 }}>
                      {issue.closed ? (
                        <Verified color="primary" sx={{ fontSize: "16px" }} />
                      ) : (
                        <CircleOutlined sx={{ color: "#C9C9C9", fontSize: "16px" }} />
                      )}
                    </Box>
                    <Link href={Links.IssueDetail(issue.guid)}>
                      <Stack direction="row" alignItems="baseline">
                        <FlagOutlined fontSize="small" />
                        {issue.description == null || issue.description.trim() === "" ? (
                          <Typography variant="placeholder">Nothing here yet.</Typography>
                        ) : (
                          <Typography variant="body1">{issue.description}</Typography>
                        )}
                      </Stack>
                    </Link>
                  </Stack>
                );
              })}
            </Stack>
          )}
        </Stack>
      ) : (
        <Typography variant="placeholder">No supporting items.</Typography>
      )}
    </ItemProperty>
  );
};

export { ActionItemSupportingItemsProperty };
