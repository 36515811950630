import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError } from "../../types";
import { DeleteSurveyInstanceDto } from "../../types/surveys";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  instanceGuid: string;
  surveyGuid: string;
};

const useDeleteSurveyInstanceRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteSurveyInstanceRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: DeleteSurveyInstanceDto = {
      instanceGuid: variables.instanceGuid
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-instances/${variables.instanceGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteSurveyInstanceRequest };
};

const useDeleteSurveyInstance = () => {
  const { deleteSurveyInstanceRequest } = useDeleteSurveyInstanceRequest();
  const queryClient = useQueryClient();

  return useMutation(({
    mutationFn: deleteSurveyInstanceRequest,
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({queryKey: queryKeys.surveys.getSurvey(variables.surveyGuid)});
    },
  }))
};

export { useDeleteSurveyInstance };
