import { Box, Stack, SxProps, Typography } from "@mui/material";
import React from "react";

interface IProps {
  title: string;
  code?: number | undefined;
  description?: React.ReactNode;
  sx?: SxProps;
}

const GenericError = (props: IProps) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", ...props.sx }}>
      <Stack spacing={0.25} sx={{ alignItems: "center", textAlign: "center", width: "fit-content" }}>
        <Typography variant="h3">{props.title}</Typography>
        {props.description && props.description}
        {props.code && <Typography>{props.code}</Typography>}
      </Stack>
    </Box>
  );
};

export { GenericError };
