import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { IRoleDetail } from "../../../models";
import { FetchError, RoleDetailDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetRoleRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getRoleRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.roles.getRole>>): Promise<RoleDetailDto> {
    const accessToken = await getAccessToken();
    const [{ guid }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/roles/${guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getRoleRequest };
};

const useGetRole = (guid: string) => {
  const { getRoleRequest } = useGetRoleRequest();

  return useQuery({
    queryKey: queryKeys.roles.getRole(guid),
    queryFn: getRoleRequest,
    select: (data): IRoleDetail => {
      return {
        guid: data.guid,
        parentTeam: data.parentTeam,
        name: data.name,
        description: data.description,
        isOpen: data.isOpen,
        isOpenLastModifiedUtc: DateTime.fromISO(data.isOpenLastModifiedUtc, { zone: "utc" }),
        accountabilities: data.accountabilities,
        tags: data.tags,
        members: data.members,
        historyLedger: data.historyLedger.map((x) => ({
          changes: x.changes,
          changeType: x.changeType,
          timestampUtc: DateTime.fromISO(x.timestampUtc, { zone: "utc" }),
          user: x.user,
        })),
      };
    },
  });
};

export { useGetRole };
