import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetActionItemDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetActionItemDetailRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getActionItemDetailRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.actionItems.getActionItemDetail>>): Promise<GetActionItemDto> {
    const accessToken = await getAccessToken();
    const [{ guid }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/action-items/${guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getActionItemDetailRequest };
};

const useGetActionItemDetail = (guid: string | undefined) => {
  const { getActionItemDetailRequest } = useGetActionItemDetailRequest();

  return useQuery({
    queryKey: queryKeys.actionItems.getActionItemDetail(guid),
    queryFn: getActionItemDetailRequest,
    enabled: guid !== undefined,
  });
};

export { useGetActionItemDetail };
