enum SignalRClientMethod {
  ReceiveChatMessage = "ReceiveChatMessage",
  ReceiveCreateComment = "ReceiveCreateComment",
  ReceiveEditComment = "ReceiveEditComment",
  ReceiveDeleteComment = "ReceiveDeleteComment",
  ReceiveActionItemUpdated = "ReceiveActionItemUpdated",
}

enum SignalRServerMethod {
  JoinGroup = "JoinGroup",
  LeaveGroup = "LeaveGroup",
  SendChatMessage = "SendChatMessage",
  ActionItemUpdated = "ActionItemUpdated",
  CreateComment = "CreateComment",
  EditComment = "EditComment",
  DeleteComment = "DeleteComment",
}

export { SignalRClientMethod, SignalRServerMethod };
