import { Box, InputBase, InputProps, css, styled } from "@mui/material";
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

const MIN_WIDTH = 40;

const Container = styled(Box)<{ $isEditable: boolean; $isFocused: boolean }>`
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(40px auto);
  border-radius: 8px;
  border-style: solid;
  min-width: 0;

  /* Having the border set to 1px here helps 4K monitors with interface scaling enabled.
     Without it there would be a slight jiggle when the hover border comes in. */
  border-width: 1px;
  border-color: transparent;

  > div {
    padding: 4px 9px 4px 4px;
  }

  ${({ $isEditable }) =>
    $isEditable &&
    css`
      &:hover {
        border-color: ${({ theme }) => theme.palette.common.black};
      }

      ${({ $isFocused, theme }) =>
        $isFocused &&
        css`
          border-color: ${theme.palette.turquoise.main};
          border-width: 2px;
          > div {
            padding: 3px 8px 3px 3px;
          }

          &:hover {
            border-color: ${theme.palette.turquoise.main};
            border-width: 2px;
            > div {
              padding: 3px 8px 3px 3px;
            }
          }
        `}
    `}
`;

const Shadow = styled(Box)`
  visibility: hidden;
  white-space: pre-wrap;
  min-width: ${MIN_WIDTH}px;
  line-height: 24px;
  padding: 0px;
  grid-row: 1;
  grid-column: 1;
`;

const StyledInput = styled(InputBase)`
  white-space: pre-wrap;
  min-width: ${MIN_WIDTH}px;
  line-height: 24px;
  padding: 0px;
  grid-row: 1;
  grid-column: 1;
`;

interface IProps extends InputProps {
  isEditable?: boolean;
}

const InlineTextField = React.forwardRef((props: IProps, ref: React.Ref<unknown>) => {
  const { value, sx, placeholder, onBlur, onFocus, isEditable, ...otherProps } = props;
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [internalValue, setInternalValue] = useState<string>((value as string) ?? "");
  const [shadowValue, setShadowValue] = useState<string>((value as string) ?? "");
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        focus() {
          setIsFocused(true);
          inputRef.current?.focus();
        },
      };
    },
    []
  );

  const setInternalAndShadowValues = useCallback(
    (value: string) => {
      setInternalValue(value);

      if (value === "") {
        setShadowValue(placeholder ?? "");
      } else {
        setShadowValue(value);
      }
    },
    [placeholder]
  );

  useEffect(() => {
    const newValue = (value as string) ?? "";
    setInternalAndShadowValues(newValue);
  }, [setInternalAndShadowValues, value]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInternalAndShadowValues(e.target.value);
  }

  return (
    <Container
      $isEditable={isEditable ?? true}
      $isFocused={isFocused}
      sx={{
        ...sx,
      }}
    >
      {/* This component takes up the space that the StyledInput normally would 
      since StyledInput is being absolutely positioned. This allows the container to
      size to the input's content more directly. See comment about `field-sizing` above. */}
      <Shadow>{shadowValue}</Shadow>

      <StyledInput
        inputRef={inputRef}
        {...otherProps}
        readOnly={!isEditable}
        placeholder={placeholder}
        value={internalValue}
        onChange={handleChange}
        onFocus={(e) => {
          onFocus?.(e);
          setIsFocused(true);
        }}
        onBlur={(e) => {
          onBlur?.(e);
          setIsFocused(false);
        }}
        multiline
        fullWidth
      />
    </Container>
  );
});

export { InlineTextField };
