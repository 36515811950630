import { KeyboardArrowDownRounded, ViewWeekOutlined } from "@mui/icons-material";
import { Button, Divider, Popover, Stack, Switch } from "@mui/material";
import { GridColDef, GridColumnVisibilityModel, gridColumnVisibilityModelSelector } from "@mui/x-data-grid-pro";
import { GridApiCommunity } from "@mui/x-data-grid-pro/internals";
import { useEffect, useState } from "react";
import { Typography } from "..";

interface IProps {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  columns: GridColDef[];
  hiddenColumns: string[];
  alwaysVisibleColumns: string[];
}

const ColumnVisibilityButton = (props: IProps) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  // Set initial column visibility model
  useEffect(() => {
    setColumnVisibilityModel(gridColumnVisibilityModelSelector(props.apiRef));
  }, [props.apiRef]);

  // Keep visibility model in sync
  useEffect(() => {
    return props.apiRef.current.subscribeEvent("columnVisibilityModelChange", (model) => {
      setColumnVisibilityModel(model);
    });
  }, [props.apiRef]);

  return (
    <>
      <Button
        variant="tertiary"
        startIcon={<ViewWeekOutlined />}
        endIcon={<KeyboardArrowDownRounded />}
        onClick={(e) => setMenuAnchor(e.currentTarget)}
      >
        Columns
      </Button>

      <Popover
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ m: 0.5 }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "10px",
            },
          },
        }}
      >
        <Stack spacing={0.5} sx={{ m: 0.5 }}>
          <Stack spacing={0.25}>
            {props.columns
              .filter(
                (column) =>
                  !props.hiddenColumns.includes(column.field) && !props.alwaysVisibleColumns.includes(column.field)
              )
              .map((column, index) => (
                <Stack direction="row" alignItems="center" spacing={1} key={index}>
                  <Switch
                    onChange={(e) => props.apiRef.current.setColumnVisibility(column.field, e.target.checked)}
                    checked={columnVisibilityModel[column.field] ?? true}
                  />
                  <Typography>{column.headerName}</Typography>
                </Stack>
              ))}
          </Stack>
          <Divider variant="dashed" sx={{ color: "#c9c9c9" }} />
          <Stack direction="row" spacing={0.5}>
            <Button
              variant="tertiary"
              onClick={() =>
                props.apiRef.current.setColumnVisibilityModel(
                  props.columns.reduce((prev, curr) => {
                    return {
                      ...prev,
                      [curr.field]: props.alwaysVisibleColumns.includes(curr.field) ? true : false,
                    };
                  }, {})
                )
              }
            >
              Hide All
            </Button>
            <Button
              variant="tertiary"
              onClick={() =>
                props.apiRef.current.setColumnVisibilityModel(
                  props.columns.reduce((prev, curr) => {
                    return {
                      ...prev,
                      [curr.field]: props.hiddenColumns.includes(curr.field) ? false : true,
                    };
                  }, {})
                )
              }
            >
              Show All
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export { ColumnVisibilityButton };
