import { Typography } from "@mui/material";
import { EditHintCard } from "../EditHintCard";

const MilestoneHintCard = () => {
  return (
    <EditHintCard title="Milestone">
      <Typography variant="body1">
        Acts as a progress marker of meaningful progression towards the mission. A team will identify several milestones
        that will be check points indicating progress toward the goal.
      </Typography>
      <Typography variant="body1">
        What deliverable or outcome will indicate that we have made meaningful progress?
      </Typography>
    </EditHintCard>
  );
};

export { MilestoneHintCard };
