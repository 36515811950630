import { Box, SxProps, styled, useTheme } from "@mui/material";
import { useContext } from "react";
import { BlockLink } from "../../../../components";
import { DiffStatus } from "../../../../constants";
import { TeamPlanContext } from "./TeamPlanContext";

const DiffStatusBox = styled(Box)<{ $diffStatus?: DiffStatus }>`
  background-color: ${({ $diffStatus, theme }) =>
    $diffStatus === DiffStatus.Added
      ? theme.palette.diff.added
      : $diffStatus === DiffStatus.Edited || $diffStatus === DiffStatus.Deleted
      ? theme.palette.diff.removed
      : undefined};
  padding-top: ${({ theme }) => theme.spacing(0.25)};
  padding-bottom: ${({ theme }) => theme.spacing(0.25)};
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  width: 100%;

  > * {
    text-decoration: ${({ $diffStatus }) => ($diffStatus === DiffStatus.Deleted ? "line-through" : undefined)};
  }
`;

interface IProps {
  href: string;
  disabled?: boolean;
  diffStatus?: DiffStatus;
  sx?: SxProps;
  children?: React.ReactNode;
}

const LinkBox = (props: IProps) => {
  const { showChanges } = useContext(TeamPlanContext);
  const theme = useTheme();

  if (props.disabled) {
    return (
      <DiffStatusBox $diffStatus={showChanges ? props.diffStatus : undefined} sx={props.sx}>
        {props.children}
      </DiffStatusBox>
    );
  }

  return (
    <BlockLink href={props.href} backgroundColorOnHover={showChanges ? undefined : theme.palette.primary.transparent5}>
      <DiffStatusBox $diffStatus={showChanges ? props.diffStatus : undefined} sx={props.sx}>
        {props.children}
      </DiffStatusBox>
    </BlockLink>
  );
};

export { LinkBox };
