import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetFeedDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetActionItemFeedRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getActionItemFeedRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.actionItems.getActionItemFeed>>): Promise<GetFeedDto> {
    const accessToken = await getAccessToken();
    const [{ guid }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/action-items/${guid}/feed`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getActionItemFeedRequest };
};

const useGetActionItemFeed = (guid: string) => {
  const { getActionItemFeedRequest } = useGetActionItemFeedRequest();

  return useQuery({
    queryKey: queryKeys.actionItems.getActionItemFeed(guid),
    queryFn: getActionItemFeedRequest,
  });
};

export { useGetActionItemFeed };
