import { ExpandMoreRounded } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon as MuiListItemIcon,
  Skeleton,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useSettings } from "../../hooks";
import { ITeamSummary } from "../../models";
import { SideMenuTeamButton } from "./SideMenuTeamButton";

const TeamListHeader = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.sideMenu};
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
`;

const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 24px;
`;

const ListItemText = styled(Typography)<{ $isExpanded: boolean }>`
  opacity: ${({ $isExpanded }) => ($isExpanded ? 1 : 0)};
  margin-left: ${({ $isExpanded }) => ($isExpanded ? "5px" : 0)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.875rem;
  transition: ${({ theme }) => theme.transitions.create(["opacity", "margin"])};
  text-transform: uppercase;
`;

interface IProps {
  isLoading?: boolean;
  teams: ITeamSummary[];
  title?: string;
  icon: React.ReactNode;
}

const SideMenuTeamList = (props: IProps) => {
  const { sideMenuSettings } = useSettings();
  const [isTeamsExpanded, setIsTeamsExpanded] = useState<boolean>(true);
  const theme = useTheme();

  return (
    <Stack>
      <TeamListHeader>
        <ListItemButton sx={{ p: 0, borderRadius: "8px" }} onClick={() => setIsTeamsExpanded(!isTeamsExpanded)}>
          <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center" sx={{ pr: "8px" }}>
            <ListItemIcon sx={{ color: theme.palette.grey[400], width: 40, minWidth: 40 }}>{props.icon}</ListItemIcon>

            <ListItemText noWrap textOverflow="ellipsis" $isExpanded={sideMenuSettings.isExpanded} sx={{ minWidth: 0 }}>
              {props.title}
            </ListItemText>

            <Box sx={{ flexGrow: 1 }}></Box>

            {sideMenuSettings.isExpanded && (
              <ExpandMoreRounded fontSize="large" sx={{ transform: isTeamsExpanded ? "rotate(180deg)" : "none" }} />
            )}
          </Stack>
        </ListItemButton>
      </TeamListHeader>

      {props.isLoading ? (
        <List sx={{ p: 0, pr: 0.5, height: isTeamsExpanded ? "auto" : 0, overflow: "hidden" }}>
          {[...new Array(3)].map((_, i) => (
            <ListItem key={i} sx={{ py: 0.2, px: "13px" }}>
              <Stack direction="row" sx={{ width: "100%", alignItems: "center" }}>
                <Skeleton
                  variant="circular"
                  sx={{ minWidth: "12px", minHeight: "12px", maxHeight: "12px", mr: 0.25 }}
                />
                <Skeleton height={24} width={"100%"} />
              </Stack>
            </ListItem>
          ))}
        </List>
      ) : (
        <List sx={{ p: 0, pr: 0.5, height: isTeamsExpanded ? "auto" : 0, overflow: "hidden" }}>
          {props.teams.map((team, index) => (
            <SideMenuTeamButton key={index} team={team} isExpanded={isTeamsExpanded} />
          ))}
        </List>
      )}
    </Stack>
  );
};

export { SideMenuTeamList };
