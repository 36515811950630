import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError } from "../../types";
import { EditSurveyInstanceDto } from "../../types/surveys";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  instanceGuid: string;
  surveyGuid: string;
  startDateUtc: string;
  endDateUtc: string | null;
  postSubmissionMessage: string;
  notificationMessage: string | null;
  notificationDateTimesUtc: string[];
};

const useEditSurveyInstanceRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editSurveyInstanceRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditSurveyInstanceDto = {
      startDateUtc: variables.startDateUtc,
      endDateUtc: variables.endDateUtc,
      postSubmissionMessage: variables.postSubmissionMessage,
      notificationMessage: variables.notificationMessage,
      notificationDateTimesUtc: variables.notificationDateTimesUtc
    };

    const response = await fetch(`${configuration.apiRootUrl}/survey-instances/${variables.instanceGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editSurveyInstanceRequest };
};

const useEditSurveyInstance = () => {
  const { editSurveyInstanceRequest } = useEditSurveyInstanceRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editSurveyInstanceRequest,
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurvey(variables.surveyGuid) });
      await queryClient.invalidateQueries({ queryKey: queryKeys.surveys.getSurveyInstance(variables.instanceGuid) });
    },
  });
}

export { useEditSurveyInstance };
