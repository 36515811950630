import { Box, Chip, Stack, Typography, alpha, useTheme } from "@mui/material";
import { BarController, BarElement, CategoryScale, Chart as ChartJS, ChartMeta, LinearScale, Tooltip } from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import { useCoherenceReportContext } from "../CoherenceReportPage";

ChartJS.register(CategoryScale, LinearScale, BarController, BarElement, Tooltip);

interface IProps {
  question: { name: string; tags: string[]; maxPoints: number; minLabel: string; maxLabel: string };
  selectedTeamsAverage?: number;
  comparisonTeamsAverage?: number;
}

interface IDataset {
  label: string;
  data: number[];
  backgroundColor: string;
}

const BinaryResult = (props: IProps) => {
  const chartRef = useRef<ChartJS>(null);
  const [labels, setLabels] = useState<string[]>([]);
  const [datasets, setDatasets] = useState<IDataset[]>([]);
  const { selectedTeamLabel, selectedSurveyInstanceName, comparisonTeamLabel, comparisonSurveyInstanceName } =
    useCoherenceReportContext();
  const theme = useTheme();

  useEffect(() => {
    let labels: string[] = [];
    let datasets: IDataset[] = [];

    // Averages are converted to a percentage.
    // One pair of datasets is used for each team selection to "fill" the stacked bar.
    if (props.selectedTeamsAverage) {
      labels.push("");

      datasets.push({
        label: `${selectedTeamLabel ?? "Me"}, ${selectedSurveyInstanceName}`,
        data: [1 - (props.selectedTeamsAverage - 1), 0],
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
      });

      datasets.push({
        label: "",
        data: [props.selectedTeamsAverage - 1, 0],
        backgroundColor: theme.palette.primary.main,
      });
    }

    if (props.comparisonTeamsAverage) {
      labels.push("");

      datasets.push({
        label: comparisonTeamLabel !== undefined ? `${comparisonTeamLabel}, ${comparisonSurveyInstanceName}` : "-",
        data: props.selectedTeamsAverage
          ? [0, 1 - (props.comparisonTeamsAverage - 1)]
          : [1 - (props.comparisonTeamsAverage - 1)],
        backgroundColor: alpha(theme.palette.orange.main, 0.5),
      });

      datasets.push({
        label: "",
        data: props.selectedTeamsAverage ? [0, props.comparisonTeamsAverage - 1] : [props.comparisonTeamsAverage - 1],
        backgroundColor: theme.palette.orange.main,
      });
    }

    setLabels(labels);
    setDatasets(datasets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTeamsAverage, props.comparisonTeamsAverage]);

  function drawLabels() {
    const chart = chartRef.current;

    if (chart == null) {
      return;
    }

    const context = chart.canvas.getContext("2d");

    if (context == null) {
      return;
    }

    context.font = "12px Open Sans";

    if (chart.data.datasets.length >= 2) {
      let datasetMetadata = chart.getDatasetMeta(0);

      // Add a dataset label above the bar if there are multiple datasets
      drawDatasetLabel(chart, datasetMetadata, context, 0);

      drawDataValueLabel(chart, datasetMetadata, context, 0, 0, "NO");
      drawDataValueLabel(chart, datasetMetadata, context, 1, 0, "YES");
    }

    if (chart.data.datasets.length === 4) {
      let datasetMetadata = chart.getDatasetMeta(2);

      // Add a dataset label above the bar if there are multiple datasets
      drawDatasetLabel(chart, datasetMetadata, context, 1);

      drawDataValueLabel(chart, datasetMetadata, context, 2, 1, "NO");
      drawDataValueLabel(chart, datasetMetadata, context, 3, 1, "YES");
    }
  }

  function drawDatasetLabel(
    chart: ChartJS,
    datasetMetadata: ChartMeta,
    context: CanvasRenderingContext2D,
    dataIndex: number
  ) {
    if (chart.data.datasets.length > 2) {
      context.fillStyle = "#4d4d4d";
      context.textAlign = "left";
      context.fillText(datasetMetadata.label, 15, datasetMetadata.data[dataIndex].y - 15);
    }
  }

  function drawDataValueLabel(
    chart: ChartJS,
    datasetMetadata: ChartMeta,
    context: CanvasRenderingContext2D,
    datasetIndex: number,
    dataIndex: number,
    label: string
  ) {
    const value = chart.data.datasets[datasetIndex].data[dataIndex] as number;

    // Add a percentage label to the bar if there are any responses for that value
    if (value > 0) {
      const valueLabel = `${label} (${(value * 100).toFixed(0).toString()}%)`;
      const x = label === "NO" ? datasetMetadata.data[dataIndex].x - 5 : chart.width - 5;

      context.fillStyle = "#fff";
      context.textAlign = "right";
      context.fillText(valueLabel, x, datasetMetadata.data[dataIndex].y + 4);
    }
  }

  return (
    <Stack spacing={0.5}>
      <Typography variant="body2">{props.question.name}</Typography>

      {props.question.tags && props.question.tags.length > 0 && (
        <Stack direction="row" spacing={0.5}>
          {props.question.tags.map((questionTag, index) => (
            <Chip key={index} label={questionTag} sx={{ color: theme.palette.grey[300] }} />
          ))}
        </Stack>
      )}

      <Box>
        <Box sx={{ height: props.comparisonTeamsAverage ? 125 : 75 }}>
          <Chart
            type="bar"
            ref={chartRef}
            options={{
              indexAxis: "y",
              responsive: true,
              maintainAspectRatio: false,
              events: [],
              plugins: {
                title: {
                  display: false,
                },
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              datasets: {
                bar: {
                  maxBarThickness: 20,
                  categoryPercentage: 1,
                },
              },
              scales: {
                x: {
                  min: 0,
                  max: 1,
                  stacked: true,
                  grid: {
                    drawBorder: true,
                  },
                  ticks: {
                    stepSize: 1,
                    display: false,
                  },
                },
                y: {
                  stacked: true,
                  grid: {
                    drawOnChartArea: false,
                  },
                  ticks: {
                    display: false,
                  },
                },
              },
              animation: {
                duration: 1,
                onComplete: () => {
                  drawLabels();
                },
              },
            }}
            data={{
              labels: labels,
              datasets: datasets,
            }}
          />
        </Box>
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between", px: "3px" }}>
          <Typography variant="caption" sx={{ color: theme.palette.grey[400], textTransform: "uppercase" }}>
            No
          </Typography>
          <Typography variant="caption" sx={{ color: theme.palette.grey[400], textTransform: "uppercase" }}>
            Yes
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export { BinaryResult };
