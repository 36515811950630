import { configuration } from "../configuration";
import { CommentSubject } from "../constants";
import { CreateCommentDto, CreateCommentResultDto, EditCommentDto, GetCommentsDto, useAccessToken } from "../http";
import { CommentMapper } from "../mapping";
import { ApplicationError, IComment, Result } from "../models";

/**
 * @deprecated
 */
const useComments = () => {
  const { getAccessToken } = useAccessToken();

  /**
   * @deprecated Errors should be handled by the ErrorBoundary
   */
  async function getComments(subjectGuid: string): Promise<Result<IComment[], ApplicationError>> {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(`${configuration.apiRootUrl}/threads/${subjectGuid}/comments`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      var responseData: GetCommentsDto = await response.json();

      if (response.ok && responseData) {
        return Result.success(CommentMapper.fromGetCommentsDto(responseData));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  /**
   * @deprecated Errors should be handled by the ErrorBoundary
   */
  async function createComment(
    subjectType: CommentSubject,
    subjectGuid: string,
    commentGuid: string,
    createdByUserId: string,
    text: string
  ): Promise<Result<IComment, ApplicationError>> {
    const accessToken = await getAccessToken();

    const createCommentDto: CreateCommentDto = {
      subjectType: subjectType,
      commentGuid,
      createdByUserId,
      text,
    };

    try {
      const response = await fetch(`${configuration.apiRootUrl}/threads/${subjectGuid}/comments`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(createCommentDto),
      });

      var responseData: CreateCommentResultDto = await response.json();

      if (response.ok && responseData) {
        return Result.success(CommentMapper.fromCreateCommentResultDto(responseData));
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  /**
   * @deprecated Errors should be handled by the ErrorBoundary
   */
  async function editComment(guid: string, text: string): Promise<Result<void, ApplicationError>> {
    const accessToken = await getAccessToken();

    const editCommentDto: EditCommentDto = {
      text,
    };

    try {
      const response = await fetch(`${configuration.apiRootUrl}/comments/${guid}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(editCommentDto),
      });

      if (response.ok) {
        return Result.success(undefined);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  /**
   * @deprecated Errors should be handled by the ErrorBoundary
   */
  async function deleteComment(guid: string): Promise<Result<void, ApplicationError>> {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(`${configuration.apiRootUrl}/comments/${guid}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "DELETE",
      });

      if (response.ok) {
        return Result.success(undefined);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  return {
    getComments,
    createComment,
    editComment,
    deleteComment,
  };
};

export { useComments };
