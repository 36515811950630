import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useSession } from "../../hooks";
import { lexorank } from "../../utilities";
import { CreateEditMetricForm, ICreateEditMetricFormState } from ".";
import { useCreateMetric, useGetMetricsForTeam } from "../../http";
import { ConnectObjectType, GRAPH_COLORS } from "../../constants";
import { LexoRank } from "lexorank";
import { useEffect } from "react";

interface IParams {
  slug: string;
}

interface IProps {
  visible: boolean;
  onAdded: () => void;
  onCancelled: () => void;
}

const CreateMetricModal = (props: IProps) => {
  const { slug } = useParams<keyof IParams>() as IParams;
  const { userId } = useSession();
  const { data: metrics, isLoading } = useGetMetricsForTeam(slug, props.visible);
  const { mutate, isLoading: isCreating } = useCreateMetric();

  useEffect(() => {
    if (!props.visible) {
      setTimeout(() => form.reset(undefined, { keepDefaultValues: true }), 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  const form = useForm<ICreateEditMetricFormState>({
    defaultValues: {
      title: "",
      description: "",
      datasets: [
        { title: "Current", color: GRAPH_COLORS[2], dotted: false, data: [], sortOrder: LexoRank.middle().toString() },
        {
          title: "Target",
          color: GRAPH_COLORS[4],
          dotted: true,
          data: [],
          sortOrder: LexoRank.middle().genNext().toString(),
        },
      ],
    },
  });

  async function handleCreateClicked() {
    form.handleSubmit((data) => {
      mutate(
        {
          teamSlug: slug,
          title: data.title,
          description: data.description,
          sortOrder: lexorank.getNextRank(metrics),
          datasets: data.datasets.map((dataset, datasetIndex) => {
            return {
              guid: dataset.guid,
              title: dataset.title,
              color: dataset.color,
              dotted: dataset.dotted,
              data: dataset.data.map((data) => {
                return {
                  rowGuid: data.rowGuid,
                  date: data.date,
                  value: data.value,
                  modifiedByUserId: userId!,
                };
              }),
              sortOrder: dataset.sortOrder,
              modifiedByUserId: userId!,
            };
          }),
          modifiedByUserId: userId!,
        },
        {
          onSuccess: () => {
            props.onAdded();
          },
        }
      );
    })();
  }

  function handleClose(_: object, reason: string) {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    handleCancelClicked();
  }

  function handleCancelClicked() {
    props.onCancelled();
  }

  return (
    <Dialog open={props.visible} onClose={handleClose}>
      <DialogTitle>Create {ConnectObjectType.Metric}</DialogTitle>
      <DialogContent>
        <CreateEditMetricForm form={form} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isLoading || isCreating} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isLoading || isCreating}
          endIcon={isLoading || isCreating ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleCreateClicked}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { CreateMetricModal };
