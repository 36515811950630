import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { mergeRegister } from "@lexical/utils";
import { Box, Button, Popover, Stack, TextField } from "@mui/material";
import {
  $createParagraphNode,
  $createTextNode,
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isRootNode,
  $isTextNode,
  BaseSelection,
  COMMAND_PRIORITY_LOW,
  LexicalEditor,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import React, { useEffect, useRef, useState } from "react";
import { SmallInputLabel } from "../SmallInputLabel";
import { getSelectedNode } from "./plugins/ToolbarPlugin";

interface Props {
  editor: LexicalEditor;
  open: boolean;
  anchorEl: Element | null;
  onClose: () => void;
}

const TextEditorLinkModal = (props: Props) => {
  const [linkText, setLinkText] = useState("");
  const [linkUrl, setLinkUrl] = useState("https://");
  const [lastSelection, setLastSelection] = useState<BaseSelection | null>(null);
  const linkTextRef = useRef<HTMLInputElement>(null);
  const linkUrlRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    return mergeRegister(
      props.editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkModal();
        });
      }),

      props.editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkModal();
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [props.editor]);

  useEffect(() => {
    props.editor.getEditorState().read(() => {
      updateLinkModal();
    });
  }, [props.editor]);

  useEffect(() => {
    if (props.open) {
      if (linkText === "") {
        linkTextRef.current?.focus();
      } else {
        linkUrlRef.current?.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  function updateLinkModal() {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      setLinkText(selection.getTextContent());

      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    } else {
      setLinkText("");
      setLinkUrl("");
    }

    setLastSelection(selection);
  }

  function handleTextChanged(e: React.ChangeEvent<HTMLInputElement>) {
    setLinkText(e.target.value);
  }

  function handleUrlChanged(e: React.ChangeEvent<HTMLInputElement>) {
    setLinkUrl(e.target.value);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSaveClicked();
    }
  }

  function handleSaveClicked() {
    if (lastSelection !== null && linkText !== "" && linkUrl !== "") {
      props.editor.update(() => {
        if ($isRangeSelection(lastSelection)) {
          let selection: RangeSelection | null = lastSelection;
          let selectedNode = getSelectedNode(lastSelection);

          // If the editor is empty, create a paragraph node.
          if ($isRootNode(selectedNode)) {
            const paragraphNode = $createParagraphNode();
            selectedNode.append(paragraphNode);
            selectedNode = paragraphNode;
          }

          // If the editor is empty, but has a paragraph node, create a text node.
          if ($isParagraphNode(selectedNode)) {
            const textNode = $createTextNode();
            selectedNode.append(textNode);
            selectedNode = textNode;
            selection = textNode.select();
          }

          // If the current selection is a text node, but doesn't include any text,
          // insert the link text into the current text node and select it.
          if ($isTextNode(selectedNode) && selection.getTextContent() === "") {
            selection.insertText(linkText);
            selectedNode.select(selection.anchor.offset);
          }
        }
      });

      // Turns the current selection into a link.
      props.editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
        url: linkUrl.startsWith("http") ? linkUrl : `https://${linkUrl}`,
        target: "_blank",
      });

      props.onClose();
    }
  }

  return (
    <Popover
      open={props.open}
      onClose={props.onClose}
      anchorEl={props.anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      keepMounted
    >
      <Stack spacing={0.5} sx={{ p: 0.5, alignItems: "flex-end" }}>
        <Box>
          <SmallInputLabel>Text</SmallInputLabel>
          <TextField
            inputRef={linkTextRef}
            placeholder="Enter Text..."
            value={linkText}
            onChange={handleTextChanged}
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box>
          <SmallInputLabel>URL</SmallInputLabel>
          <TextField
            inputRef={linkUrlRef}
            placeholder="Enter URL..."
            value={linkUrl}
            onChange={handleUrlChanged}
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Stack direction="row" spacing={0.5}>
          <Button variant="outlined" onClick={props.onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSaveClicked}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Popover>
  );
};

export { TextEditorLinkModal };
