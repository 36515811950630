import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Typography } from "@mui/material";
import React from "react";
import { FetchError } from "../../http";
import { appInsights } from "../../o11y";
import { IRouterProps, withRouter } from "../../utilities";
import { GenericError } from "./GenericError";
import { NotFoundError } from "./NotFoundError";

interface IProps extends IRouterProps {
  children: React.ReactNode;
}

interface IState {
  error: Error | undefined;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, _prevState: Readonly<IState>, _snapshot?: any) {
    if (prevProps.router?.location.pathname !== this.props.router?.location.pathname) {
      this.reset();
    }
  }

  componentDidCatch(error: Error, _errorInfo: React.ErrorInfo) {
    if (error instanceof FetchError) {
      if (error.status !== 404) {
        appInsights.trackException({ error, severityLevel: SeverityLevel.Error });
      }
    } else {
      appInsights.trackException({ error, severityLevel: SeverityLevel.Error });
    }
  }

  reset() {
    this.setState({
      error: undefined,
    });
  }

  render(): React.ReactNode {
    if (this.state.error !== undefined) {
      if (this.state.error instanceof FetchError) {
        switch (this.state.error.status) {
          case 404:
            return <NotFoundError />;
          default:
            return (
              <GenericError
                title="Oops! Something went wrong."
                description={<Typography>Please try again.</Typography>}
                code={this.state.error.status}
              />
            );
        }
      } else {
        return (
          <GenericError title="Oops! Something went wrong." description={<Typography>Please try again.</Typography>} />
        );
      }
    }

    return this.props.children;
  }
}

const ErrorBoundaryWithRouterProps = withRouter(ErrorBoundary);

export { ErrorBoundaryWithRouterProps as ErrorBoundary };
