import { ClearRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Autocomplete as MuiAutocomplete, AutocompleteProps, CircularProgress, styled } from "@mui/material";
import React from "react";

const StyledMuiAutocomplete = styled(MuiAutocomplete)`
  .MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.primary.main};

    &.Mui-disabled {
      color: ${({ theme }) => theme.palette.text.disabled};
    }
  }

  .MuiAutocomplete-endAdornment .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .MuiChip-root {
    height: 32px;

    .MuiChip-label {
      font-size: ${({ theme }) => theme.typography.body1.fontSize};
      text-transform: none;
    }
  }
` as typeof MuiAutocomplete;

interface IProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  isLoading: boolean;
}

const Autocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: IProps<T, Multiple, DisableClearable, FreeSolo>,
  ref: React.Ref<unknown>
) => {
  const { isLoading, ...other } = props;

  return (
    <StyledMuiAutocomplete
      ref={ref}
      blurOnSelect
      handleHomeEndKeys
      clearIcon={<ClearRounded sx={{ fontSize: "20px" }} />}
      popupIcon={
        isLoading ? (
          <CircularProgress size={20} sx={{ color: "text.disabled" }} />
        ) : props.disabled ? null : (
          <ExpandMoreRounded sx={{ color: "primary.main", fontSize: "24px" }} />
        )
      }
      {...other}
    />
  );
};

const RefForwardedAutocomplete = React.forwardRef(Autocomplete) as <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: IProps<T, Multiple, DisableClearable, FreeSolo> & { ref?: React.Ref<unknown> }
) => React.ReactElement;

export { RefForwardedAutocomplete as Autocomplete };
