import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { CreateResourceDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  teamSlug: string;
  title: string;
  url: string;
  isEmbedded: boolean;
  sortOrder: string;
  createdByUserId: string;
};

const useCreateResourceRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createResourceRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: CreateResourceDto = {
      teamSlug: variables.teamSlug,
      title: variables.title,
      url: variables.url,
      isEmbedded: variables.isEmbedded,
      sortOrder: variables.sortOrder,
      createdByUserId: variables.createdByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/resources`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { createResourceRequest };
};

const useCreateResource = () => {
  const { createResourceRequest } = useCreateResourceRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createResourceRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeam(variables.teamSlug) });
      await queryClient.refetchQueries({ queryKey: queryKeys.resources.getResourcesForTeam(variables.teamSlug) });
    },
  });
};

export { useCreateResource };
