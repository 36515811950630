import { DateTime } from "luxon";
import { GetPlanVersionsDto } from "../http";
import { IUser } from "./User";

interface IPlanVersionSummary {
  planVersionId: string;
  teamSlug: string;
  isDraft: boolean;
  isPublished: boolean;
  notes: string;
  lastModifiedUtc: DateTime;
  createdByUser: IUser;
}

class PlanVersionSummary {
  planVersionId: string;
  teamSlug: string;
  isDraft: boolean;
  isPublished: boolean;
  notes: string;
  lastModifiedUtc: DateTime;
  createdByUser: IUser;

  constructor(
    planVersionId: string,
    teamSlug: string,
    isDraft: boolean,
    isPublished: boolean,
    notes: string,
    lastModifiedUtc: DateTime,
    createdByUser: IUser
  ) {
    this.planVersionId = planVersionId;
    this.teamSlug = teamSlug;
    this.isDraft = isDraft;
    this.isPublished = isPublished;
    this.notes = notes;
    this.lastModifiedUtc = lastModifiedUtc;
    this.createdByUser = createdByUser;
  }

  static fromDto(dto: GetPlanVersionsDto): PlanVersionSummary[] {
    return dto.planVersions.map(
      (x) =>
        new PlanVersionSummary(
          x.planVersionId,
          x.teamSlug,
          x.isDraft,
          x.isPublished,
          x.notes,
          DateTime.fromISO(x.lastModifiedUtc, { zone: "utc" }),
          { ...x.createdByUser, profilePhoto: undefined }
        )
    );
  }
}

export { PlanVersionSummary };
export type { IPlanVersionSummary };
