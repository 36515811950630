import { useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IPlanSummary } from "../../../models";
import { FetchError, GetPlansDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetPlansRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getPlansRequest(): Promise<GetPlansDto> {
    const accessToken = await getAccessToken();

    var response = await fetch(`${configuration.apiRootUrl}/plans`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getPlansRequest };
};

const useGetPlans = () => {
  const { getPlansRequest } = useGetPlansRequest();

  return useQuery({
    queryKey: queryKeys.plans.getPlans(),
    queryFn: getPlansRequest,
    select: (data): IPlanSummary[] => {
      return data.plans.map((x) => ({
        name: x.name,
        slug: x.slug,
      }));
    },
  });
};

export { useGetPlans };
